import { VehicleInfo } from '@electro/consumersite/generated/graphql'

export enum PncStagesEnum {
  VEHICLE_REG_LOOKUP = 'VEHICLE_REG_LOOKUP',
  ENTER_VEHICLE_VIN = 'ENTER_VEHICLE_VIN',
  INCOMPATIBLE_VEHICLE = 'INCOMPATIBLE_VEHICLE',
  CONFIRM_VEHICLE_DETAILS = 'CONFIRM_VEHICLE_DETAILS',
  CONTRACT_CREATION_ERROR = 'CONTRACT_CREATION_ERROR',
  COMPLETE = 'COMPLETE',
}

export type PncStage = `${PncStagesEnum}`

export interface VinFormFields {
  pcid: string
}

export interface Vehicle {
  regNumber: string
  info: VehicleInfo
}
