import { useCallback, useEffect } from 'react'

import { useToastNotification } from '@electro/shared-ui-components'
import { ApolloError } from '@apollo/client'

interface UseErrorNotificationEffectArgs {
  error: ApolloError | boolean
  message?: string
  heading?: string
}

export function useErrorNotificationEffect({
  error,
  message = 'There was a problem fetching data!',
  heading = 'Error',
}: UseErrorNotificationEffectArgs): { errorNotification: () => void } {
  const { showToastNotification } = useToastNotification()
  const errorNotification = useCallback(
    () =>
      showToastNotification({
        heading,
        variant: 'error',
        body: message,
      }),
    [heading, message, showToastNotification],
  )

  useEffect(() => {
    if (error) errorNotification()
  }, [error, errorNotification])
  return {
    errorNotification,
  }
}
