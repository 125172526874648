import * as Sentry from '@sentry/nextjs'
import { useState, useMemo, useCallback, ReactNode } from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { useDebounce } from 'react-use'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
  Input,
  Modal,
  Typography,
  TruncatedText,
  IconButton,
  Tag,
  Tooltip,
  Alert,
} from '@electro/shared-ui-components'
import { PostDate } from '@electro/consumersite/src/components'
import { BackspaceIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { GTM } from '../../utils/event-triggers'

type ContentSearchRenderProps = ({
  toggleSearchModal,
}: {
  toggleSearchModal?: () => void
}) => ReactNode

interface ContentSearchProps {
  /**
   * The storyblok directory you want to search in e.g. 'community' or 'faq'
   * @default 'community'
   */
  searchDirectory?: string
  children: ContentSearchRenderProps
}

const styles = {
  modal: {
    root: 'h-auto -translate-x-1/2 left-1/2 translate-y-1/2 bottom-1/2  max-h-[calc(100vh-theme(spacing.4))] max-w-[calc(100vw-theme(spacing.2))]',
  },
  search: {
    input: '-mb-4 pr-8 rounded-full pl-10',
    result: {
      root: tw(
        'p-4 transition-all block no-underline rounded-lg  border-none mb-3 last:mb-0 outline-none ring-0',
        'active:border-tertiary active:bg-secondary ring-0 group ',
        'focus:bg-secondary focus:border-tertiary',
        'hover:bg-secondary border-2',
      ),
      date: 'text-secondary-light group-hover:text-white',
      categoryTag: 'inline-block hover:underline',
    },
  },
  icon: {
    magnifyingGlass: 'h-6 w-6 absolute left-3 top-1/2 -translate-y-1/2 -mt-0.5 pointer-events-none',
    button: 'absolute right-3 top-1/2 -translate-y-1/2 -mt-0.5',
  },
}

const fetchSearchResults = async (query: string, searchDirectory: string, locale: string) => {
  if (!query) {
    return null
  }
  const options = {
    locale,
    filterQueries: [
      {
        field: 'pageTitle',
        value: query,
      },
      {
        field: 'teaserText',
        value: query,
      },
      {
        field: 'categories',
        value: query,
      },
    ],
  }
  GTM.contentSearchSubmitted({ searchString: query })
  const response = await fetch(`/api/search/${searchDirectory}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(options),
  })
  const data = await response.json()
  return data
}

export const ContentSearch = ({ searchDirectory = 'community', children }: ContentSearchProps) => {
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [searchResults, setSearchResults] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { locale, push } = useRouter()
  const { t } = useTranslation('common')

  const toggleSearchModal = () => {
    setSearchModalOpen(!searchModalOpen)
  }

  const handleSearchChange = (e) => {
    setSearchString(e.target.value)
  }

  useDebounce(
    async () => {
      if (!searchString || searchString.length < 3) {
        setSearchResults(null)
        return
      }
      try {
        setErrorMessage(null)
        setLoading(true)
        const data = await fetchSearchResults(searchString, searchDirectory, locale)
        setSearchResults(data.results)
      } catch (err) {
        setErrorMessage(err)
        Sentry.captureException(err)
      } finally {
        setLoading(false)
      }
    },
    400,
    [searchString, searchDirectory, locale],
  )

  const resetSearch = useCallback(() => {
    setSearchString('')
    setSearchResults(null)
  }, [])

  const noResults = useMemo(
    () => searchString?.length > 0 && searchResults?.length === 0,
    [searchString, searchResults],
  )

  const handleClose = useCallback(() => {
    if (searchString) {
      resetSearch()
      return
    }
    setSearchModalOpen(false)
  }, [resetSearch, searchString])

  const handleClickStoryCategory = (category: string) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    setSearchModalOpen(false)
    push(`/community/${category}`)
  }

  return (
    <>
      {typeof children === 'object' ? children : children?.({ toggleSearchModal })}
      <Modal
        dialogClassName={styles.modal.root}
        size="lg"
        onClose={toggleSearchModal}
        open={searchModalOpen}
        onAfterLeave={resetSearch}
      >
        <div className="relative">
          <Input
            value={searchString}
            autoFocus
            fullWidth
            className={styles.search.input}
            name="search-input"
            data-testid="community-search"
            placeholder={t('search.placeholder')}
            onChange={handleSearchChange}
          />
          <MagnifyingGlassIcon className={styles.icon.magnifyingGlass} />
          {searchString ? (
            <IconButton className={styles.icon.button} onClick={resetSearch} tabIndex={-1}>
              <Tooltip placement="right">
                <Tooltip.Trigger delay={250} ariaLabel="delete content">
                  <BackspaceIcon className="w-6 h-6" />
                </Tooltip.Trigger>
                <Tooltip.Body>{t('search.clear')}</Tooltip.Body>
              </Tooltip>
            </IconButton>
          ) : (
            <IconButton className={styles.icon.button} onClick={handleClose} tabIndex={-1}>
              <Tooltip placement="right">
                <Tooltip.Trigger delay={250} ariaLabel="delete content">
                  <XMarkIcon className="w-6 h-6" />
                </Tooltip.Trigger>
                <Tooltip.Body>{t('search.close')}</Tooltip.Body>
              </Tooltip>
            </IconButton>
          )}
        </div>

        {!loading && errorMessage ? <Alert variant="error">{t('common.error')}</Alert> : null}

        {searchResults ? (
          <Modal.Body>
            {searchResults?.map((result) =>
              result ? (
                <Link
                  onClick={() => {
                    setSearchModalOpen(false)
                  }}
                  data-testid="search-result"
                  key={result.uuid}
                  href="/community/[category]/[post]"
                  as={`${result.path}`}
                  className={styles.search.result.root}
                >
                  <PostDate className={styles.search.result.date} date={result?.firstPublishedAt} />
                  <Typography className="mb-1" variant="h3">
                    {result.title}
                  </Typography>
                  {result?.categories?.length > 0 ? (
                    <div className="-ml-1 mb-1" data-testid="search-result-category-links">
                      {result?.categories?.map((category) => (
                        <Tag
                          onClick={handleClickStoryCategory(category)}
                          label={t(`blog.categories.${category}`)}
                          key={category}
                          colour="lightGrey"
                          variant="outlined"
                          className={styles.search.result.categoryTag}
                        />
                      ))}
                    </div>
                  ) : null}
                  <Typography variant="small">
                    <TruncatedText maxLength={100}>{result.teaserText}</TruncatedText>
                  </Typography>
                </Link>
              ) : null,
            )}
          </Modal.Body>
        ) : null}

        {loading && !noResults ? <Typography>{t('search.loading')}</Typography> : null}

        {!loading && noResults ? <Typography>{t('search.no_results')}</Typography> : null}
      </Modal>
    </>
  )
}
