import Link from 'next/link'
import { useRef, useState } from 'react'
import { Transition } from '@headlessui/react'
import { useClickAway, useUpdateEffect } from 'react-use'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { Button, ButtonProps } from '@electro/shared-ui-components'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { FacebookIcon, LinkedInIcon, TwitterXIcon } from '@electro/icons/24/logos'
import { LinkIcon } from '@heroicons/react/24/outline'

const styles = {
  root: 'bg-base rounded-t-2xl absolute bottom-[57px] left-0 w-36 h-36 py-4',
  container: {
    root: 'flex flex-col items-center gap-y-1 mx-4',
    title: '-mb-2',
    buttonGroup: 'w-full flex items-center justify-between [&>a]:no-underline [&>a]:ring-0',
    socialPlatforms: {
      // Show brand colours on hover
      twitterX: 'hover:bg-black',
      facebook: 'hover:bg-[#0866ff]',
      linkedIn: 'hover:bg-[#0a66c2]',
    },
  },
  shareButton: {
    root: 'flex flex-col items-center w-14 [&>div]:relative [&>div>*]:absolute',
    button: {
      logo: 'p-2.5 [&>svg]:size-6 hover:border-secondary',
      icon: 'p-2 [&>svg]:size-7',
    },
    label: 'text-2xs font-semibold mb-0 text-center h-4',
    successAnimation: 'w-24 h-24 -m-6 mt-0 opacity-0',
  },
}

const PLATFORM_SHARE_LINKS = {
  twitterX: 'https://twitter.com/intent/tweet?text=',
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  linkedIn: 'https://www.linkedin.com/sharing/share-offsite/?url=',
  whatsApp: 'https://wa.me/?text=',
}

const ENCODED_EMOJI_TEXT = '%20%F0%9F%97%BA%EF%B8%8F%20%E2%9A%A1%EF%B8%8F'

interface ShareLinkProps {
  link: string
  /** Use this to set the 'link' prop to undefined.
   * Closing the drawer is handled internally by useClickAway */
  onClose: () => void
}

/** A reusable component for sharing links to various platforms.
 * E.g. Social Media, Copy Link, Send to Device, etc. */
export const ShareLinkDrawer = ({ link, onClose }: ShareLinkProps) => {
  const { t } = useTranslation('common')

  const shareDrawerRef = useRef(null)
  const [showChild, setShowChild] = useState<boolean>(false)

  /** Hide the drawer by clicking anywhere on the page  */
  useClickAway(shareDrawerRef, () => {
    // The delay prevents any external logic checking if the drawer is open before closing it
    if (link) setTimeout(() => onClose(), 200)
  })

  /** Hide after playing the success animation */
  const hideAfterDelay = () => setTimeout(() => onClose(), 1750)

  const encodedText = `${encodeURIComponent(t('map.sharing_drawer.link_text'))}%0A%0A`
  const encodedLink = encodeURIComponent(link)
  const sharingLinks = {
    twitterX: `${PLATFORM_SHARE_LINKS.twitterX}${encodedText}${encodedLink}`,
    facebook: `${PLATFORM_SHARE_LINKS.facebook}${encodedLink}`,
    linkedIn: `${PLATFORM_SHARE_LINKS.linkedIn}${encodedLink}&text=${encodedText}`,
    whatsApp: `${PLATFORM_SHARE_LINKS.whatsApp}${encodedText.replace(ENCODED_EMOJI_TEXT, '')}${encodedLink}`,
  }

  return (
    <Transition
      show={Boolean(link)}
      unmount={false}
      ref={shareDrawerRef}
      className={styles.root}
      afterEnter={() => setShowChild(true)}
      beforeLeave={() => setShowChild(false)}
      enter="transition-transform ease-out duration-500"
      enterFrom="origin-bottom scale-y-[0]"
      enterTo="origin-bottom scale-y-[1]"
      leave="transition-transform ease-out duration-500 delay-150"
      leaveFrom="origin-bottom scale-y-[1]"
      leaveTo="origin-bottom scale-y-[0]"
      data-testid="share-link-drawer"
    >
      <Transition
        show={showChild}
        className={styles.container.root}
        enter="transition-opacity ease-out duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-out duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <h3 className={styles.container.title}>{t('map.sharing_drawer.share_to')}</h3>

        <div className={styles.container.buttonGroup}>
          <Link href={sharingLinks.facebook} target="_blank">
            <ShareIconButton
              socialPlatform
              label="Facebook"
              onClick={() => {
                hideAfterDelay()
                GTM.shareURL({ type: 'filters', platform: 'Facebook' })
              }}
              className={styles.container.socialPlatforms.facebook}
            >
              <FacebookIcon />
            </ShareIconButton>
          </Link>

          <Link href={sharingLinks.twitterX} target="_blank">
            <ShareIconButton
              socialPlatform
              label="X"
              onClick={() => {
                hideAfterDelay()
                GTM.shareURL({ type: 'filters', platform: 'TwitterX' })
              }}
              className={styles.container.socialPlatforms.twitterX}
            >
              <TwitterXIcon />
            </ShareIconButton>
          </Link>

          <Link href={sharingLinks.linkedIn} target="_blank">
            <ShareIconButton
              socialPlatform
              label="LinkedIn"
              onClick={() => {
                hideAfterDelay()
                GTM.shareURL({ type: 'filters', platform: 'LinkedIn' })
              }}
              className={styles.container.socialPlatforms.linkedIn}
            >
              <LinkedInIcon />
            </ShareIconButton>
          </Link>

          <ShareIconButton
            label={t('map.sharing_drawer.copy_link')}
            onClick={() => {
              navigator.clipboard.writeText(link)
              hideAfterDelay()
              GTM.shareURL({ type: 'filters', platform: 'Copy Link' })
            }}
          >
            <LinkIcon />
          </ShareIconButton>
        </div>
      </Transition>
    </Transition>
  )
}

interface ShareIconButtonProps extends ButtonProps {
  label?: string
  socialPlatform?: boolean
}

/** Mini-component to handle button and label styles, with a built-in success animation */
const ShareIconButton = ({
  label,
  socialPlatform,
  className,
  onClick,
  ...props
}: ShareIconButtonProps) => {
  const animationRef = useRef(null)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)

  /** On click, play a success animation at x1.5 speed */
  useUpdateEffect(() => {
    if (showSuccess) {
      animationRef?.current?.setSpeed(1.5)
      animationRef?.current?.goToAndPlay(0)
    }
  }, [showSuccess])

  return (
    <span className={styles.shareButton.root}>
      <div className="flex grow items-center justify-center w-20 h-20">
        <div className={tw(styles.shareButton.successAnimation, showSuccess && 'opacity-100')}>
          <AnimatedComponent
            name="share-success"
            getAnimationData={ANIMATIONS.paymentSuccess}
            lottieProps={{ loop: false, autoPlay: false, lottieRef: animationRef }}
          />
        </div>
        {!showSuccess ? (
          <div>
            <Button
              size="2xs"
              className={tw({
                [styles.shareButton.button.icon]: !socialPlatform,
                [styles.shareButton.button.logo]: socialPlatform,
                [className]: !!className,
              })}
              onClick={(e) => {
                setShowSuccess(true)
                if (onClick) onClick(e)
              }}
              {...props}
            />
          </div>
        ) : null}
      </div>

      <p className={styles.shareButton.label}>{label}</p>
    </span>
  )
}
