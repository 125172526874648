import Image from 'next/image'
import { BatteryIcon } from '@electro/consumersite/src/icons'
import { RouteDictionaryRouteType } from '@electro/consumersite/src/components/Map/types'
import { getMarkerIconPath } from '@electro/consumersite/src/components/Map/helpers'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  root: 'flex items-center -my-1 last:mb-1',
  icon: {
    root: 'z-10 w-7 h-fit -ml-1 mr-2',
    nonMarkerBackground: 'bg-base rounded-full p-px',
  },
  waypoint: {
    root: 'w-full flex items-center gap-x-2 py-1 px-2 bg-base rounded-lg border-2 border-transparent',
    text: 'text-sm font-semibold line-clamp-2',
    battery: {
      root: 'ml-auto mr-1 flex flex-col items-center text-xs font-mono font-semibold',
      icon: 'w-6 h-6 -mb-1.5 ml-0.5',
    },
  },
}

interface RouteBreakdownWaypointStepProps {
  step: RouteDictionaryRouteType['markers'][number]
}

export const RouteBreakdownWaypointStep = ({ step }: RouteBreakdownWaypointStepProps) => (
  <div className={styles.root}>
    <Image
      src={getMarkerIconPath(step.icon)}
      className={tw({
        [styles.icon.root]: true,
        [styles.icon.nonMarkerBackground]: step.icon.startsWith('station-'),
      })}
      alt="route marker"
      height={28}
      width={28}
    />

    <span className={styles.waypoint.root}>
      <h5 className={styles.waypoint.text}>{step.address}</h5>

      <span className={styles.waypoint.battery.root}>
        <BatteryIcon
          percentage={step.arrivalBatteryPercentage}
          className={styles.waypoint.battery.icon}
        />
        {step.arrivalBatteryPercentage}%
      </span>
    </span>
  </div>
)
