import AppleIcon from './apple.svg'
import FacebookIcon from './facebook.svg'
import GoogleMapsIcon from './google-maps.svg'
import GooglePlayIcon from './google-play.svg'
import InstagramIcon from './instagram.svg'
import LinkedInIcon from './linkedin.svg'
import TwitterXIcon from './twitter-x.svg'
import WhatsAppIcon from './whatsapp.svg'
import YoutubeIcon from './youtube.svg'

export {
  AppleIcon,
  FacebookIcon,
  GoogleMapsIcon,
  GooglePlayIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterXIcon,
  WhatsAppIcon,
  YoutubeIcon,
}
