import { FetchPlaceFeatureArgs, fetchPlaceFeature } from '@electro/shared/services/mapbox'
import { PlaceDetails } from '@electro/consumersite/src/components/Map/helpers'

interface GetPlaceFeatureArgs {
  place: PlaceDetails
  sessionToken: FetchPlaceFeatureArgs['sessionToken']
  locale: FetchPlaceFeatureArgs['locale']
}

/** Appends the MapBox feature position information onto a PlaceDetails object */
export const retrievePlaceDetailsPosition = async ({
  place,
  sessionToken,
  locale,
}: GetPlaceFeatureArgs): Promise<PlaceDetails | undefined> => {
  const fetchParams = { id: place.id, sessionToken, locale }
  const { features } = await fetchPlaceFeature(fetchParams)
  if (features?.length === 0) return place

  const { properties } = features[0]
  const { coordinates, bbox } = properties
  const { longitude: lng, latitude: lat } = coordinates

  return { ...place, coordinates: { lng, lat }, bbox }
}
