import { v1 as uuid } from 'uuid'

interface LoadingTableRowsPlaceholderProps {
  rows: number
}

/**
 * @deperecated please use <Skeleton/> component
 */
export const LoadingTableRowsPlaceholder = ({ rows = 10 }: LoadingTableRowsPlaceholderProps) => (
  <>
    {Array.from(Array(rows)).map(() => (
      <tr key={uuid()} data-testid="table-loading-placeholder">
        <td colSpan={100} className="pt-8">
          <div className="cp-tableRow" />
        </td>
      </tr>
    ))}
  </>
)
