/* eslint-disable jsx-a11y/label-has-associated-control */
import { useFormik } from 'formik'
import { tw } from '@electro/shared/utils/tailwind-merge'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { ukVehicleRegistrationValidator } from '@electro/shared/utils/validators'

export type VehicleRegLookupFields = {
  regNumber: string
  useHeuristics?: boolean
}

export const REGISTRATION_LOOKUP_FORM_ID = 'searchByRegForm'

interface VehicleRegLookupProps {
  onSubmit: (fieldValues: VehicleRegLookupFields) => void
  loading: boolean
  error?: ApolloError
  errorMessage?: string
  isElectric?: boolean
  initialRegNumber: string
}

const styles = {
  root: 'pb-4 text-center flex flex-col items-center',
  label: {
    root: 'block',
    info: 'text-sm pb-2 text-white',
  },
  input: {
    plate: tw(
      'w-64 border  shadow-lg rounded-md relative overflow-hidden',
      'after:absolute after:left-0 after:top-0 after:w-7 after:h-full',
      'border-white text-black ',
    ),
    evPlate: 'after:bg-action-success',
    dirtyPlate: 'after:bg-action-danger',
    textField:
      'py-2 pl-7 px-0 text-4xl w-full uppercase font-medium text-center outline-none bg-white placeholder:text-white/40',
  },
  error: 'error border-action-danger',
  errorMessage: 'text-sm mt-2 text-action-danger border-action-danger',
}

const validationSchema = Yup.object().shape({
  regNumber: Yup.string()
    .required('Your vehicle registration number is required.')
    .test('regNumber', 'Enter a valid UK Vehicle registration!', ukVehicleRegistrationValidator),
})

const VehicleRegLookup = ({
  loading,
  error,
  errorMessage,
  onSubmit,
  isElectric = true,
  initialRegNumber = '',
}: VehicleRegLookupProps) => {
  const handleFormSubmit = ({ regNumber }: VehicleRegLookupFields) => {
    onSubmit({ regNumber })
  }

  const formik = useFormik({
    initialValues: {
      regNumber: initialRegNumber,
    },
    validationSchema,
    onSubmit: handleFormSubmit,
    validateOnBlur: true,
    validateOnChange: false,
  })

  const handleRegistrationChange = (e) => {
    const formattedRegistration = e.target.value.toUpperCase()

    formik.setFieldValue('regNumber', formattedRegistration)
  }
  const hasError = !!formik.errors.regNumber || !!error || !isElectric || !!errorMessage

  return (
    <form
      data-testid="vehicleRegLookup"
      className={styles.root}
      id={REGISTRATION_LOOKUP_FORM_ID}
      onSubmit={formik.handleSubmit}
    >
      <label className={styles.label.root} htmlFor="plateInput">
        <div
          className={tw({
            [styles.input.plate]: true,
            [styles.input.evPlate]: isElectric || loading,
            [styles.input.dirtyPlate]: hasError && !loading,
          })}
        >
          <input
            maxLength={9}
            type="text"
            id="plateInput"
            name="plateInput"
            placeholder="OC70 PUS"
            className={styles.input.textField}
            onChange={handleRegistrationChange}
            value={formik.values.regNumber}
            disabled={loading}
          />
        </div>
      </label>
      {hasError && (
        <div className={styles.errorMessage}>
          {formik.errors.regNumber || error?.message || errorMessage}
        </div>
      )}
    </form>
  )
}
export { VehicleRegLookup }
