import { FeatureCollection, LineString } from 'geojson'
import { MarkerNames, RouteTitlesType } from '@electro/consumersite/src/components/Map/helpers'
import { DestinationInput, VehicleType } from '@electro/consumersite/generated/graphql'

export enum RouteStatusEnum {
  OK = 'ok',
  INVALID = 'invalid',
  NOTFOUND = 'notfound',
  ADDRESS_NOT_FOUND = 'address_not_found',
  ADDRESS_DIFFERENT_REGIONS = 'address_different_regions',
  ERROR = 'error',
}
export type RouteStatus = `${RouteStatusEnum}`

export enum RouteDetailStagesEnum {
  SELECTING_ROUTE = 'SELECTING_ROUTE',
  VIEWING_ROUTE_STEPS = 'VIEWING_ROUTE_STEPS',
}

export type RouteDetailStages = `${RouteDetailStagesEnum}`

export interface RouteDictionaryType {
  uuid?: string
  status?: RouteStatus
  vehicle: VehicleType
  destinations: Pick<DestinationInput, 'lat' | 'lon' | 'address'>[]
  startCharge: number
  endCharge: number
  routes?: {
    id: number
    summary: {
      title?: RouteTitlesType
      distanceMetres: number
      driveDurationSeconds: number
      chargeDurationSeconds: number
      energyConsumedKWh: number
      chargingStopsCount: number
    }
    markers: {
      id: string
      address: string
      coordinates: { lat: number; lng: number }
      icon: MarkerNames
      arrivalBatteryPercentage: number
      departureBatteryPercentage: number
      driveDuration: number
      driveDistance: number
      isStationStart: boolean
      isStationEnd: boolean
      charger?: {
        id: string
        isEjnLocation: boolean
        chargeDuration: number
      }
    }[]
    polyline: FeatureCollection<
      LineString,
      {
        id: string
        lineStartBatteryPercentage: number
        lineEndBatteryPercentage: number
      }
    >[]
  }[]
}

export type RouteDictionaryRouteType = RouteDictionaryType['routes'][number]
