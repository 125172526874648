import { TableHeader } from '@electro/shared-ui-components'
import { DataGridColumnDef } from '../..'
import { TableSortHeaderButton } from '..'
import { useDataGrid } from '../../hooks/useDataGrid'

interface DataGridRowProps {
  column: DataGridColumnDef
}

export const DataGridHeader = ({ column }: DataGridRowProps) => {
  const [{ ordering }, { handleOrderChange }] = useDataGrid()

  if (column.orderField) {
    const [order] = ordering

    return (
      <TableHeader>
        <TableSortHeaderButton
          orderBy={column.orderField}
          orderDirection={order?.direction}
          fieldName={order?.orderBy}
          label={column.name}
          onClick={() => handleOrderChange(column.orderField)}
        />
      </TableHeader>
    )
  }

  return <TableHeader>{column.name}</TableHeader>
}
