/* eslint-disable react/jsx-no-useless-fragment */
import { Locale } from '@electro/shared/types/locales'
import { useLocalizationFlags } from '@electro/consumersite/src/hooks/useLocalizationFlags'
import { ReactNode } from 'react'

interface RestrictToLocalesProps {
  locales: Locale[]
  children: ReactNode | ReactNode[] | string
}

export const RestrictToLocales = ({ locales, children }: RestrictToLocalesProps) => {
  const { restrictToLocales } = useLocalizationFlags()

  return restrictToLocales(locales) ? <>{children}</> : null
}
