import { useState, createContext, useContext, ReactNode, useCallback, FC } from 'react'

type AccountVehicleManagerModalContextType = {
  showModal: boolean
  setShowModal: (nextShowModal: boolean) => () => void
}

const AccountVehicleManagerModalContext = createContext(null)

const useAccountVehicleManagerModalProvider = (): AccountVehicleManagerModalContextType => {
  const [showModal, setShowModal] = useState(false)

  const setStepHandler = useCallback(
    (nextShowModal: boolean) => () => setShowModal(nextShowModal),
    [],
  )

  return { showModal, setShowModal: setStepHandler }
}

interface Props {
  children: ReactNode | ReactNode[]
}

export const AccountVehicleManagerModalProvider: FC<Props> = ({ children }) => {
  const value = useAccountVehicleManagerModalProvider()
  return (
    <AccountVehicleManagerModalContext.Provider value={value}>
      {children}
    </AccountVehicleManagerModalContext.Provider>
  )
}

export const useAccountVehicleManagerModal = (): AccountVehicleManagerModalContextType => {
  const context = useContext(AccountVehicleManagerModalContext)
  if (!context)
    throw new Error(
      'useAccountVehicleManagerModal() cannot be used outside of <AccountVehicleManagerModalProvider/>',
    )
  return context
}
