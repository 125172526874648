import { useCallback, useContext } from 'react'
import { ToastPositions, ToastVariantType } from './Toast'
import { ToastNotificationContext } from './ToastNotificationProvider'

export interface ShowToastNotificationArguments {
  heading: string
  body?: any
  variant: ToastVariantType
  position?: ToastPositions
  timeout?: number
}

type ShowToastNotification = ({ heading, body, variant }: ShowToastNotificationArguments) => void

export function useToastNotification(): {
  showToastNotification: ShowToastNotification
} {
  const toastContext = useContext(ToastNotificationContext)

  const showToastNotification = useCallback(({ heading, body, variant, position, timeout }) => {
    toastContext?.setNotification(() => ({
      heading,
      body,
      variant,
      position,
      timeout,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    showToastNotification,
  }
}
