import { TabsList, Tab } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { useContext, useState, createContext, useCallback, useMemo } from 'react'

const TabContext = createContext(null)

const LocationTabsContent = ({ children }) => {
  const { tabIndex } = useContext(TabContext)
  return children({ tabIndex })
}

export const LocationDetailsTabs = ({ children }) => {
  const [tabIndex, setTabIndex] = useState<number>(0)
  const { t } = useTranslation('common')
  const handleTabIndexChange = useCallback((index: number) => () => setTabIndex(index), [])

  const context = useMemo(() => ({ tabIndex }), [tabIndex])

  return (
    <TabContext.Provider value={context}>
      <TabsList fullWidth data-testid="location-details-tabs" className="mt-4 mb-4">
        <Tab onClick={handleTabIndexChange(0)} active={tabIndex === 0}>
          {t('map.location.tabs.connectors')}
        </Tab>
        <Tab onClick={handleTabIndexChange(1)} active={tabIndex === 1}>
          {t('map.location.tabs.information')}
        </Tab>
      </TabsList>
      <div>
        <LocationTabsContent>{children}</LocationTabsContent>
      </div>
    </TabContext.Provider>
  )
}
