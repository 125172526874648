import { ChargingLocationMetadataType } from '@electro/consumersite/generated/graphql'
import getLowestRatesfromEvses from '@electro/consumersite/src/components/Map/helpers/getLowestRatesFromEvses'
import { useMemo } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

import { SaplingIcon } from '@electro/consumersite/src/icons'

import useTranslation from 'next-translate/useTranslation'
import { Typography } from '@electro/shared-ui-components'
import { formatPriceComponentToLocalisedValue } from '@electro/shared/utils/formatters'

interface SummaryProps {
  chargingLocation: ChargingLocationMetadataType
}

export const Summary = ({ chargingLocation }: SummaryProps) => {
  const { t } = useTranslation('common')

  const lowestPrice = useMemo(
    () => getLowestRatesfromEvses(chargingLocation?.evses?.edges),
    [chargingLocation?.evses?.edges],
  )
  const locationHasMulipleRateTypes = useMemo(
    () => !!lowestPrice?.timeRate && lowestPrice?.consumptionRate,
    [lowestPrice?.consumptionRate, lowestPrice?.timeRate],
  )

  const showGreenEnergyBadge = useMemo(
    () => chargingLocation?.operator?.isGreenEnergy && chargingLocation?.country === 'GBR',
    [chargingLocation],
  )

  const isAnyConnectorFree = chargingLocation?.evses?.edges?.some((evse) =>
    evse.node.connectors.edges.some((connector) => connector.node.isChargingFree),
  )

  return (
    <>
      <div
        className="grid grid-cols-2 gap-2 mt-2 tracking-wider"
        data-testid="location-details-summary"
      >
        <div
          className={tw({
            'mt-2 mb-0 flex items-center': true,
            'col-span-2': locationHasMulipleRateTypes,
          })}
        >
          <Typography variant="h2" as="span" className="mr-3">
            {chargingLocation.evses.totalCount}
          </Typography>
          <Typography variant="small" as="span" className="mr-3">
            {chargingLocation.evses.totalCount > 1
              ? t('map.location.summary_charge_point_plural')
              : t('map.location.summary_charge_point_singular')}
          </Typography>
        </div>

        {isAnyConnectorFree ? (
          <div data-testid="summary-free" className="flex flex-col items-center">
            <Typography variant="h3" as="span" className="mr-3">
              {t('map.location.info.capability.free')}
            </Typography>
            <Typography variant="small" as="span" className="mr-3">
              {t('map.location.info.price_from')}
            </Typography>
          </div>
        ) : null}

        {chargingLocation.isEjnLocation && !isAnyConnectorFree && (
          <>
            {!!lowestPrice?.timeRate && (
              <div data-testid="summary-timeRate" className="flex flex-col items-center">
                <Typography variant="h3" as="span" className="mr-3">
                  {formatPriceComponentToLocalisedValue(lowestPrice.timeRate)}
                </Typography>
                <Typography variant="small" as="span" className="mr-3">
                  {t('map.location.info.price_from')}
                </Typography>
              </div>
            )}
            {!!lowestPrice?.consumptionRate && (
              <div data-testid="summary-consumptionRate" className="flex flex-col items-center">
                <Typography variant="h3" as="span" className="mr-3">
                  {formatPriceComponentToLocalisedValue(lowestPrice.consumptionRate)}
                </Typography>
                <Typography variant="small" as="span" className="mr-3">
                  {t('map.location.info.price_from')}
                </Typography>
              </div>
            )}
            {!!lowestPrice?.parkingTimeRate && (
              <div
                data-testid="summary-parkingTimeRate"
                className="mt-2 flex items-center col-span-2"
              >
                <Typography variant="small" as="span" className="mr-3">
                  {t('map.location.info.operator_idle_fee')}
                </Typography>
                <Typography variant="h3" as="span" className="mr-3">
                  {formatPriceComponentToLocalisedValue(lowestPrice.parkingTimeRate)}
                </Typography>
              </div>
            )}
            {!!lowestPrice?.connectionFee && (
              <div
                data-testid="summary-connectionFee"
                className="mt-2 flex items-center col-span-2"
              >
                <Typography variant="small" as="span" className="mr-3">
                  {t('map.location.info.connection_fee')}
                </Typography>
                <Typography variant="h3" as="span" className="mr-3">
                  {formatPriceComponentToLocalisedValue(lowestPrice.connectionFee)}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
      {showGreenEnergyBadge && (
        <div className="mt-3 py-1 px-4 bg-action-success bg-opacity-10 rounded-full flex items-center text-xs text-action-success border border-action-success">
          <SaplingIcon className="w-6 h-6 mr-2" />
          {t('map.location.alert.100_perc_green_energy')}
        </div>
      )}
    </>
  )
}
