import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

interface Props {
  setShowPassword: (value: boolean) => void
  showPassword: boolean
  passwordInputType: 'text' | 'password'
}

export const ToggleShowPassword = ({ setShowPassword, showPassword, passwordInputType }: Props) => (
  <>
    {passwordInputType === 'text' && (
      <EyeSlashIcon
        aria-label="hide password"
        role="button"
        onClick={() => setShowPassword(!showPassword)}
        className="text-tertiary-shade h-8 w-8 cursor-pointer hover:text-primary absolute top-1 pt-1 right-2"
      />
    )}
    {passwordInputType === 'password' && (
      <EyeIcon
        aria-label="show password"
        role="button"
        onClick={() => setShowPassword(!showPassword)}
        className="text-tertiary-shade h-8 w-8 cursor-pointer hover:text-primary absolute top-1 pt-1 right-2 "
      />
    )}
  </>
)
