import * as Yup from 'yup'
import { OrderCardFormFieldNames } from '@electro/consumersite/src/components/Signup/types'

const { FIRST_NAME, LAST_NAME, ADDRESS1, ADDRESS_CITY, ADDRESS_REGION, POSTCODE, COUNTRY_CODE } =
  OrderCardFormFieldNames

export const MAX_ADDRESS_LENGTH = 35

export interface OrderCardFormFieldsType {
  [FIRST_NAME]: string
  [LAST_NAME]: string
  [ADDRESS1]: string
  [ADDRESS_CITY]: string
  [ADDRESS_REGION]: string
  [POSTCODE]: string
  [COUNTRY_CODE]: string
}

export type OrderCardFormFieldsTypeKeys = keyof OrderCardFormFieldsType
export type OrderCardFormFieldsTypeValues = OrderCardFormFieldsType[OrderCardFormFieldsTypeKeys]

export const INITIAL_VALUES: OrderCardFormFieldsType = {
  [FIRST_NAME]: '',
  [LAST_NAME]: '',
  [ADDRESS1]: '',
  [ADDRESS_CITY]: '',
  [ADDRESS_REGION]: '',
  [POSTCODE]: '',
  [COUNTRY_CODE]: '',
}

export const VALIDATION_SCHEMA = Yup.object().shape({
  [COUNTRY_CODE]: Yup.string().required('form.validation.required_field'),
  [FIRST_NAME]: Yup.string().required('form.validation.required_field'),
  [LAST_NAME]: Yup.string().required('form.validation.required_field'),
  [ADDRESS1]: Yup.string()
    .required('form.validation.required_field')
    .max(MAX_ADDRESS_LENGTH, 'form.validation.address_max_length_error'),
  [ADDRESS_CITY]: Yup.string()
    .required('form.validation.required_field')
    .max(MAX_ADDRESS_LENGTH, 'form.validation.address_max_length_error'),
  [ADDRESS_REGION]: Yup.string().max(
    MAX_ADDRESS_LENGTH,
    'form.validation.address_max_length_error',
  ),
  [POSTCODE]: Yup.string().required('form.validation.required_field'),
})
