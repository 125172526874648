import { tw } from '@electro/shared/utils/tailwind-merge'
import { forwardRef, HTMLAttributes, ReactNode, ForwardedRef } from 'react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'

const styles = {
  root: 'lg:p-4 p-3 rounded-4xl flex text-sm text-white font-medium',
  variant: {
    default: 'bg-secondary-light',
    error: 'bg-action-danger',
    warn: 'bg-action-warning',
    success: 'bg-action-success text-base-dark',
    info: 'bg-tertiary-shade text-secondary',
  },
}
export type AlertVariantType = keyof typeof styles.variant

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | string
  className?: string
  variant?: AlertVariantType
}

export const Alert = forwardRef(
  (
    { children, className, variant = 'default', ...rest }: AlertProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      data-testid={`${String(variant)}-alert`}
      ref={ref}
      className={tw({
        [styles.root]: true,
        [styles.variant[variant]]: true,
        [className]: !!className,
      })}
      {...rest}
    >
      {variant !== 'default' && (
        <div className="w-6 mr-2">
          {variant === 'warn' && (
            <ExclamationTriangleIcon data-testid="warn-icon" className="w-5 h-5" />
          )}
          {variant === 'error' && (
            <ExclamationCircleIcon data-testid="error-icon" className="w-5 h-5" />
          )}
          {variant === 'info' && (
            <InformationCircleIcon data-testid="info-icon" className="w-5 h-5 text-secondary" />
          )}
          {variant === 'success' && (
            <CheckCircleIcon data-testid="success-icon" className="w-5 h-5" />
          )}
        </div>
      )}
      <div className="flex-grow">{children}</div>
    </div>
  ),
)
