import { OperationVariables, QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client'
import MAP_FILTERS_QUERY from '@electro/consumersite/src/graphql/mapFiltersQuery.graphql'

import { Query, QueryExtendedVehicleLookupArgs } from '@electro/consumersite/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type MapFiltersQuery = Pick<
  Query,
  'connectorStandardGroups' | 'cpoGroups' | 'chargingSpeeds'
>

type UseMapFiltersQuery = [
  ApolloLazyQueryFunction<MapFiltersQuery>,
  QueryResult<MapFiltersQuery, OperationVariables>,
]

type MapFiltersQueryArgs = QueryHookOptions<MapFiltersQuery, QueryExtendedVehicleLookupArgs>

export function useFetchMapFilters(options?: MapFiltersQueryArgs): UseMapFiltersQuery {
  const [lazyMapFiltersQuery, extendedVehicleLookupResult] = useLazyQuery<MapFiltersQuery>(
    MAP_FILTERS_QUERY,
    options,
  )

  return [lazyMapFiltersQuery, extendedVehicleLookupResult]
}
