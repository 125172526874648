import { ForwardedRef, ReactNode, TdHTMLAttributes, forwardRef } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface Props extends TdHTMLAttributes<HTMLTableCellElement> {
  children?: ReactNode | ReactNode[]
}

export const TableCell = forwardRef(
  ({ className, children, ...rest }: Props, ref: ForwardedRef<HTMLTableCellElement>) => (
    <td
      ref={ref}
      className={tw({
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </td>
  ),
)
