import React, { HTMLAttributes } from 'react'
import { LocationDetailsPanel } from '@electro/consumersite/src/components/Map/components/MapDrawer/panels'

const DrawerContainer = ({ children, className }: HTMLAttributes<HTMLDivElement>) => (
  <div className={className} data-testid="map-drawer">
    {children}
  </div>
)

/** Map Drawer Panels */
DrawerContainer.LocationDetails = LocationDetailsPanel

export { DrawerContainer }
