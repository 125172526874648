import { useState } from 'react'
import {
  OperatorListFilters,
  OperatorListSearch,
  OperatorListTable,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/OperatorList/components'

export const OperatorListPanelContent = () => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [showPartneredOnly, setShowPartneredOnly] = useState<boolean>(false)

  return (
    <>
      <OperatorListSearch setSearchQuery={setSearchQuery} />

      <OperatorListFilters
        showPartneredOnly={showPartneredOnly}
        setShowPartneredOnly={setShowPartneredOnly}
      />

      <OperatorListTable searchQuery={searchQuery} showPartneredOnly={showPartneredOnly} />
    </>
  )
}
