import { Fragment, useState, useEffect } from 'react'
import { CalendarIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Popover, Transition } from '@headlessui/react'
import { tw } from '@electro/shared/utils/tailwind-merge'
/* eslint-disable */
import { format, Locale } from 'date-fns'
/* eslint-disable */
import { enGB } from 'date-fns/locale'
import { READABLE_DATE_FORMAT } from '@electro/shared/constants/dates'
import { Card } from '@electro/shared-ui-components'
import { DateRangePicker } from './DateRangePicker'

export type DateRangeType = {
  startDate: Date
  endDate: Date
  fromDate?: Date
  toDate?: Date
  dateFnsLocale?: Locale
  placeholder?: string
  buttonTextReset?: string
  buttonTextApply?: string
}

interface DateRangeProps extends DateRangeType {
  onChange: ({ startDate, endDate }: DateRangeType) => void
  label?: string
}

const styles = {
  label: 'text-sm pl-2 font-normal text-white mb-1 text-right lg:text-left',
  button: {
    root: tw(
      'border-b-2 w-full focus:outline-none flex justify-start pl-2 pt-2 border-2 rounded-lg placeholder-tertiary-shade',
      'focus:border-tertiary border-secondary hover:border-tertiary text-white',
    ),
    inactive: 'hover:border-tertiary',
    active: ' border-tertiary hover:border-tertiary',
  },
  calendarIcon: 'w-5 h-5 mr-2 mb-3 text-secondary',
  placeholder: 'text-tertiary-shade',
  popover: {
    root: 'lg:absolute z-40 lg:mt-2 lg:top-auto lg:w-screen lg:right-0 lg:max-w-3xl lg:m-auto lg:inset-auto lg:block h-screen lg:overflow-hidden fixed inset-0 top-0 overflow-auto',
    card: 'overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 relative p-10',
  },
  card: 'sm:h-fit h-full overflow-auto flex justify-center flex-nowrap',
  closeButton: tw(
    'h-6 w-6 absolute top-4 right-4 lg:hidden z-10 rounded-lg text-white',
    'focus:text-tertiary hover:text-tertiary hover:opacity-100 focus:ring-opacity-100 focus:ring-tertiary',
    'focus:outline-none focus:ring-2',
  ),
}

export const DateRange = ({
  onChange,
  label,
  startDate,
  endDate,
  fromDate,
  toDate,
  dateFnsLocale = enGB,
  placeholder = 'Enter a date range...',
  buttonTextReset = 'Reset',
  buttonTextApply = 'Apply',
}: DateRangeProps) => {
  const [rangeStartDate, setRangeStartDate] = useState<Date>(startDate)
  const [rangeEndDate, setRangeEndDate] = useState<Date>(endDate)

  useEffect(() => {
    setRangeStartDate(startDate)
    setRangeEndDate(endDate)
  }, [startDate, endDate])

  const handleApplyDateRangeWith =
    (close) =>
    ({ startDate: nextStartDate, endDate: nextEndDate }) => {
      setRangeStartDate(nextStartDate)
      setRangeEndDate(nextEndDate)
      onChange({ startDate: nextStartDate, endDate: nextEndDate })
      close()
    }

  const dateRangeVisible = rangeStartDate && rangeEndDate

  return (
    <div data-testid="date-range">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <div className={styles.label}>{label}</div>
            <Popover.Button
              className={tw({
                [styles.button.root]: true,
                [styles.button.inactive]: !open,
                [styles.button.active]: open,
              })}
              data-testid="date-range-button"
            >
              <CalendarIcon className={styles.calendarIcon} />
              <div>
                {dateRangeVisible && (
                  <span>{`${format(rangeStartDate, READABLE_DATE_FORMAT, { locale: dateFnsLocale })} - ${format(
                    rangeEndDate,
                    READABLE_DATE_FORMAT,
                    { locale: dateFnsLocale },
                  )}`}</span>
                )}
                {!dateRangeVisible && <span className={styles.placeholder}>{placeholder}</span>}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={styles.popover.root}>
                <Card className={styles.card}>
                  <button
                    aria-label="Close dialog"
                    onClick={close}
                    className={styles.closeButton}
                    type="button"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>
                  <DateRangePicker
                    dateFnsLocale={dateFnsLocale}
                    startDate={rangeStartDate}
                    endDate={rangeEndDate}
                    onApply={handleApplyDateRangeWith(close)}
                    fromDate={fromDate || null}
                    toDate={toDate || null}
                    buttonTextApply={buttonTextApply}
                    buttonTextReset={buttonTextReset}
                  />
                </Card>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  )
}
