import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { SidebarNavIcon } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'
import { FiltersPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { FiltersIcon } from '@electro/consumersite/src/icons'
import useTranslation from 'next-translate/useTranslation'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  navIcon: 'w-full p-px',
}

export const FiltersSidebarIcon = () => {
  const [{ filtersActive }] = useMapFilters()

  return (
    <SidebarNavIcon panelName={SidebarPanels.FILTERS}>
      <FiltersIcon className={tw(styles.navIcon, filtersActive && 'text-tertiary')} />
    </SidebarNavIcon>
  )
}

export const FiltersPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.FILTERS}
      label={t('map.filters.title')}
      showStickyButtons
    >
      <FiltersPanelContent />
    </SidebarPanelContainer>
  )
}
