import { create } from 'zustand'

interface State {
  email: string
  setUserEmail: (email: string) => void
}

/**
 * Simple store to hold the user's email address during the login flow.
 * The email field and password forms are on separate pages, so we need to
 * persist the email address in a store to avoid encoding it in the URL.
 *
 * TODO: also store the route the user was on before being redirected to the
 * login page, so we can redirect them back to that page after login.
 */
export const useLoginStore = create<State>((set) => ({
  email: null,
  setUserEmail: (email: string) => set({ email }),
}))
