import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'

import { SelectMenu } from '@electro/shared-ui-components'
import {
  CountryType,
  QueryCountriesArgs,
  useCountriesQuery,
} from '@electro/consumersite/generated/graphql'

interface CountrySelectProps {
  disabled?: boolean
  onSelect: (country: CountryType) => void
  errorMessage?: string
  /**
   * @filters - filters function
   */
  filter?: (countries: CountryType[]) => CountryType[]
  /**
   * @queryVariables - variables to pass directly to the countries query
   */
  queryVariables?: QueryCountriesArgs
  name?: string
  label?: string
}

export const CountrySelect = ({
  disabled = false,
  onSelect,
  errorMessage,
  filter,
  queryVariables,
  name = 'countryCode',
  label = 'form.field.address_country',
}: CountrySelectProps) => {
  const { t } = useTranslation('common')
  const { data, loading, error: queryError } = useCountriesQuery({ variables: queryVariables })

  const filteredItems = useMemo(() => {
    const items = data?.countries.edges.map((edge) => edge.node) || []
    return filter ? filter(items) : items
  }, [data?.countries?.edges, filter])

  const error = useMemo(
    () => errorMessage || queryError?.message,
    [queryError?.message, errorMessage],
  )

  const handleSelect = (country: CountryType) => {
    onSelect(country)
  }

  return (
    <SelectMenu
      label={t(label) || t('form.field.address_country')}
      errorMessage={error}
      items={filteredItems}
      name={name}
      placeholder={t(loading ? 'utility.loading_message' : 'common.select')}
      disabled={loading || disabled}
      onChange={handleSelect}
    />
  )
}
