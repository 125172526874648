import { LazyQueryHookOptions, OperationVariables, QueryResult, useLazyQuery } from '@apollo/client'
import EXTENDED_VEHICLE_LOOKUP_QUERY from '@electro/consumersite/src/graphql/extendedVehicleLookupQuery.graphql'

import { Query, QueryExtendedVehicleLookupArgs } from '@electro/consumersite/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchExtendedVehicleLookup = Pick<Query, 'extendedVehicleLookup'>

type UseFetchExtendedVehicleLookup = [
  ApolloLazyQueryFunction<FetchExtendedVehicleLookup>,
  QueryResult<FetchExtendedVehicleLookup, OperationVariables>,
]

type FetchExtendedVehicleLookupArgs = LazyQueryHookOptions<
  FetchExtendedVehicleLookup,
  QueryExtendedVehicleLookupArgs
>

export function useFetchVehiclesByReg(
  options?: FetchExtendedVehicleLookupArgs,
): UseFetchExtendedVehicleLookup {
  const [lazyFetchExtendedVehicleLookup, extendedVehicleLookupResult] =
    useLazyQuery<FetchExtendedVehicleLookup>(EXTENDED_VEHICLE_LOOKUP_QUERY, options)

  return [lazyFetchExtendedVehicleLookup, extendedVehicleLookupResult]
}
