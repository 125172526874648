import { useSourceAttribution } from '@electro/consumersite/src/hooks'
import { Button, Input, Typography } from '@electro/shared-ui-components'
import {
  MutationSendMagicLinkArgs,
  useSendMagicLinkMutation,
} from '@electro/consumersite/generated/graphql'
import { Formik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import Link from 'next/link'
import { useState } from 'react'
import * as Yup from 'yup'
import * as Sentry from '@sentry/nextjs'
import { featureFlagEnableGoogleRecaptchaOnSignUp } from '@electro/consumersite/src/utils/envFeatureFlags'
import { verifyWithRecaptcha } from '@electro/consumersite/src/utils/verifyWithRecaptcha'

interface RequestMagicLinkFormProps {
  onComplete: ({ email }: { email: string }) => void
  emailAddress?: string
  showTerms?: boolean
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('create_account_invalid_email')
    .required('create_account_invalid_email')
    .nullable(),
})

export const RequestMagicLinkForm = ({
  onComplete,
  emailAddress = '',
  showTerms = true,
}: RequestMagicLinkFormProps) => {
  const { t } = useTranslation('common')
  const [requestMagicLink, { loading: sending }] = useSendMagicLinkMutation()
  const [errorMessage, setErrorMessage] = useState('')
  const { attribution } = useSourceAttribution()

  const handleMagicLinkFormSubmit = async ({ email }) => {
    setErrorMessage('')
    const variables: MutationSendMagicLinkArgs = {
      email,
    }
    if (attribution?.referralCode) variables.referralCode = attribution?.referralCode
    if (attribution?.campaignCode) variables.campaignCode = attribution.campaignCode

    try {
      if (featureFlagEnableGoogleRecaptchaOnSignUp) {
        const verifiedByRecaptcha = await verifyWithRecaptcha()

        if (!verifiedByRecaptcha) {
          setErrorMessage('common_error_title')
          return
        }
      }

      const { data } = await requestMagicLink({
        variables,
      })
      if (data?.sendMagicLink?.success) {
        onComplete({ email })
      } else {
        setErrorMessage('common_error_title')
      }
    } catch (err) {
      Sentry.captureException(err, (scope) =>
        scope.setExtras({ email, apolloVariables: variables }),
      )
      setErrorMessage(err.message)
    }
  }

  return (
    <Formik
      initialValues={{ email: emailAddress }}
      validationSchema={validationSchema}
      onSubmit={handleMagicLinkFormSubmit}
      validateOnChange={false}
    >
      {({ handleBlur, values, errors, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <Input
            fullWidth
            placeholder={`${t('user.menu.hello')}@octopus.energy`}
            label={t('common_email')}
            name="email"
            value={values.email || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            errorMessage={t((errors.email as string) || errorMessage)}
            disabled={sending}
            required
          />
          <Button fullWidth type="submit" loading={sending}>
            {t('common.button_status.continue')}
          </Button>
          {showTerms ? (
            <div className="mt-6">
              <Typography variant="small" as="p">
                <Trans
                  i18nKey="common:common.terms_and_conditions"
                  components={{
                    termsLink: <Link href="/legal/terms/">{t('common.terms')}</Link>,
                    privacy: <Link href="/legal/privacy/">{t('common.privacy_policy')}</Link>,
                  }}
                />
              </Typography>
            </div>
          ) : null}
        </form>
      )}
    </Formik>
  )
}
