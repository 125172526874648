import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useState, Fragment } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Button } from '@electro/shared-ui-components'
import { Transition } from '@headlessui/react'
import { MobilePrimaryNavItemProps } from '@electro/consumersite/src/components/TopNavigation/types'
import {
  LoginButton,
  UserMenu,
  SignUpButton,
  PrimaryNavItem,
} from '@electro/consumersite/src/components/TopNavigation/components'
import useTranslation from 'next-translate/useTranslation'
import { HideWhenSiteUnderMaintenance, LanguageSelect } from '@electro/consumersite/src/components'
import { PRIMARY_NAV } from '@electro/consumersite/src/components/TopNavigation/constants'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import ElectroverseLogoWeb from '@electro/consumersite/public/images/electroverse-logo-dark-web.svg'
import ElectroverseLogoIsolated from '@electro/consumersite/public/images/electroverse-logo-dark-isolated.svg'

const styles = {
  root: 'fixed top-0 left-0 z-40 w-full',
  nav: 'overflow-y-auto',
  header: tw(
    'flex justify-between items-center h-16 p-2',
    'bg-gradient-to-r from-[#1b014d] via-[#350c77] via-65% to-[#5014ac] shadow-md', // Hardcoded values mimic the homepage gradient mesh
  ),
  navWrapper: tw(
    'flex flex-col justify-between backdrop-blur-lg h-dvh', // apply dynamic viewport height to make sure content will always fit the screen as the browser UI expands/contracts
    'bg-gradient-to-r from-[#1b014d] via-[#350c77] via-65% to-[#5014ac] shadow-md', // Hardcoded values mimic the homepage gradient mesh
  ),
  menu: ' h-full w-full',
}

export const MobileNavigation = () => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleExpanded = useCallback(() => setExpanded((oldExpanded) => !oldExpanded), [])
  const { locale, asPath, push } = useRouter()
  const { t } = useTranslation('common')
  const isBusinessRoute = asPath.includes('/business')

  const handleLogin = () => {
    GTM.clickSignUpOnNav()
    toggleExpanded()
    push('/log-in')
  }

  const handleSignUp = () => {
    GTM.clickSignUpOnNav()
    push('/sign-up/magic')
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Button
          variant={expanded ? 'default' : 'outline'}
          size="xs"
          onClick={toggleExpanded}
          className="shrink-0"
        >
          <span className="px-4">{expanded ? t('nav.menu.close') : t('nav.menu.menu')}</span>
        </Button>
        <Link
          href="/"
          className="cursor-pointer rounded-full focus:ring-0"
          aria-label="Go to homepage"
          draggable="false"
        >
          <ElectroverseLogoWeb className="h-16 p-[3px] hidden sm:block" />
          <ElectroverseLogoIsolated className="h-16 p-[3px] block sm:hidden" />
        </Link>
        <div className="flex items-center">
          <UserMenu />
          <LanguageSelect />
        </div>
      </div>
      <Transition
        show={expanded}
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 -translate-y-5"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-5"
      >
        <div className={styles.navWrapper}>
          <nav className={styles.nav} data-testid="desktop-navigation">
            <ul className={styles.menu}>
              {PRIMARY_NAV.map((item: MobilePrimaryNavItemProps) =>
                item.locales?.includes(locale) ? (
                  <PrimaryNavItem.Mobile
                    key={item.label}
                    label={t(item.label)}
                    href={item.href}
                    secondaryNav={item.secondaryNav}
                  />
                ) : null,
              )}
            </ul>
          </nav>
          <div className="flex flex-col py-4 px-4 mb-16 gap-2 border-t-2 border-t-secondary/40">
            <HideWhenSiteUnderMaintenance>
              {!isBusinessRoute ? (
                <>
                  <SignUpButton onClick={handleSignUp} fullWidth />
                  <LoginButton onClick={handleLogin} fullWidth />
                </>
              ) : null}
            </HideWhenSiteUnderMaintenance>
          </div>
        </div>
      </Transition>
    </div>
  )
}
