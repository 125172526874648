import { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { Modal, Typography, Button } from '@electro/shared-ui-components'
import { ApolloError } from '@apollo/client'
import {
  useRetryPaymentMutation,
  StripeFailureReason,
} from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import { useManageOutstandingPayments } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/hooks'
import { ModalScreensEnum } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/ManageOutstandingPayments.types'
import { OutstandingPaymentDetails } from '../OutstandingPaymentDetails'

interface PaymentRetryArgs {
  onSuccess?: () => void
  onError?: (errorMessage?: StripeFailureReason | ApolloError) => void
  paymentId: number
}

export const PaymentDetailsModalScreen = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const { setActiveModalScreen, setErrorMessage, payment } = useManageOutstandingPayments()
  const { pk } = payment.paymentRecord
  const { receiptCurrency } = payment.paymentSource

  const receiptAmount = formatPriceToLocalisedValue({
    price: Math.abs(payment?.grossAmount),
    currency: receiptCurrency.isoCode,
    locale,
    isFloat: false,
  })

  const [retryPayment, { loading: retryPaymentLoading, error: retryPaymentError }] =
    useRetryPaymentMutation()

  const retryOutstandingPayment = useCallback(
    async ({ onSuccess, onError, paymentId }: PaymentRetryArgs) => {
      try {
        const { data } = await retryPayment({
          variables: {
            paymentId,
          },
        })
        if (data?.retryPayment?.success === true) {
          onSuccess?.()
        } else {
          onError?.()
          setErrorMessage(data?.retryPayment?.failureReason)
        }
      } catch (err) {
        onError?.()
        setErrorMessage(err.message)
      }
    },
    [retryPayment, setErrorMessage],
  )

  useEffect(() => {
    if (retryPaymentLoading && !retryPaymentError) {
      setActiveModalScreen(ModalScreensEnum.PAYMENT_PROCESSING_MODAL_SCREEN)
    }
  }, [retryPaymentError, retryPaymentLoading, setActiveModalScreen])

  const handlePayment = async () => {
    await retryOutstandingPayment({
      onSuccess: () => setActiveModalScreen(ModalScreensEnum.PAYMENT_SUCCESS_MODAL_SCREEN),
      onError: () => {
        setActiveModalScreen(ModalScreensEnum.PAYMENT_FAIL_MODAL_SCREEN)
      },
      paymentId: pk,
    })
  }

  return (
    <>
      <Modal.Body>
        <Typography variant="h3" as="h1" className="text-center mb-8">
          {t('profile.outstanding_payments.resolve_payment_modal.details_screen.title')}
        </Typography>
        <OutstandingPaymentDetails payment={payment} />
        <hr className="mt-8 border-secondary" />
        <div className="flex justify-between my-8 mx-4">
          <Typography className="font-semibold uppercase">
            {t('profile.outstanding_payments.resolve_payment_modal.details_screen.total_to_pay')}
          </Typography>
          <Typography className="font-semibold">{receiptAmount}</Typography>
        </div>
      </Modal.Body>
      <Modal.Actions center>
        <Button onClick={handlePayment}>
          {t('profile.outstanding_payments.resolve_payment_modal.cta_buttons.pay_now')}
        </Button>
      </Modal.Actions>
    </>
  )
}
