/**
 * Hook provides source attribution data gathered from specified URL paramaters and stores
 * them in local storage. This is so we can read the source provided to the landing page at a later time.
 * You will need to manually remove the source attribution from local storage when you are done with it.
 *
 * This is so we can read the source provided to the landing page at a later time.
 *
 * Sources/parameters:
 *
 * search param: 'cid'
 * Use case: campaign ids linked to affiliate campaigns.
 *
 * search param: 'referralCode'
 * Use case: Refer a friend code used to attribute credit to a referring users account.
 */

import {
  EJN_SIGNUP_SOURCE_ATTRIBUTION_KEY,
  SIGN_UP_REFERRAL_CODE_PARAM,
  SOURCE_ATTRIBUTION_CAMPAIGN_ID_PARAM,
} from '@electro/shared/constants'
import { useState, useEffect, useContext, createContext, useCallback } from 'react'

import { useLocalStorage } from 'react-use'
import { GTM } from '../utils/event-triggers'

interface UseSourceAttribution {
  attribution?: {
    referralCode?: string
    campaignCode?: string
  }
  removeAttributionFromLocalStorage: () => void
}

const SourceAttribution = createContext<UseSourceAttribution>(null)

export const useSourceAttribution = (): UseSourceAttribution => {
  const context = useContext(SourceAttribution)
  if (!context) {
    throw new Error(
      `SourceAttribution() cannot be used outside the context of <SourceAttributionProvider/>`,
    )
  }
  return context
}

function useProvideSourceAttribution(): UseSourceAttribution {
  const [attribution, setAttribution, removeAttributionFromLocalStorage] = useLocalStorage(
    EJN_SIGNUP_SOURCE_ATTRIBUTION_KEY,
    null,
  )
  const [campaignCodeParam, setCampaignCodeParam] = useState<string>(null)

  /**
   * We are setting source attribution to local from the URL params
   * on page load. This is so we can read the source provided to the
   * landing page at a later time. This is implemented this way because
   * when the user signs up with a magic link they leave the site and
   * we lose application state. We need to be able to read the source
   * attribution when they return so we can pass it to the backend.
   */
  const setUrlSourceAttribution = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const cid = urlParams.get(SOURCE_ATTRIBUTION_CAMPAIGN_ID_PARAM)
    const referralCode = urlParams.get(SIGN_UP_REFERRAL_CODE_PARAM)
    if (cid || referralCode) {
      setCampaignCodeParam(cid)
      setAttribution((oldAttribution) => ({
        ...oldAttribution,
        referralCode,
        campaignCode: cid,
      }))
    }
  }, [setAttribution])

  /**
   * When we first load the page we want to wait for
   * Google Tag managers dataLayer to be available before
   * we try to push any events to it! We're using a recursive
   * setTimeout to achieve this.
   */
  useEffect(() => {
    setUrlSourceAttribution()
    function checkForDataLayer() {
      if (!!window.dataLayer && campaignCodeParam) {
        GTM.userWithAffiliateCampaignCode({ campaignCode: campaignCodeParam })
      } else {
        setTimeout(checkForDataLayer, 200)
      }
    }
    checkForDataLayer()
  }, [campaignCodeParam, setUrlSourceAttribution])

  return {
    attribution,
    removeAttributionFromLocalStorage,
  }
}

export const SourceAttributionProvider = ({ children }) => {
  const context = useProvideSourceAttribution()
  return <SourceAttribution.Provider value={context}>{children}</SourceAttribution.Provider>
}
