export enum MapConfigEnum {
  SHOW_NAVBAR = 'showNavbar',
  ALLOW_USER_LOGIN = 'allowUserLogin',
}

const { SHOW_NAVBAR, ALLOW_USER_LOGIN } = MapConfigEnum

export type MapConfigType = {
  [SHOW_NAVBAR]: boolean
  [ALLOW_USER_LOGIN]: boolean
}
