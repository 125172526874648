import Link from 'next/link'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { Locale } from '@electro/shared/types/locales'
import {
  BlogTitleEnum,
  selectRegionalisedBlogLink,
} from '@electro/consumersite/src/helpers/selectLocalisedBlog'

const Transition = dynamic(() => import('@headlessui/react').then((mod) => mod.Transition))

const styles = {
  banners: {
    large: 'z-30 fixed bottom-7 right-12 cursor-pointer hidden lg:flex',
    small: 'z-30 fixed bottom-2 sm:bottom-7 right-12 -mb-0.5 -mr-0.5 cursor-pointer flex lg:hidden',
  },
}

const START_DATE = '29 MAR 2024 10:00:00'
const END_DATE = '29 MAR 2024 18:00:00'

/** Displays timed marketing announcements in the form of localised images */
export const EventBanner = () => {
  const { locale, asPath } = useRouter()
  const [showEvent, setShowEvent] = useState<boolean>(null)

  const date = useMemo(() => new Date(), [])
  const [eventStart, eventEnd] = useMemo(() => [new Date(START_DATE), new Date(END_DATE)], [])

  /** Show/hide banner on opening/closing a charging location
   * Also adds a delay to showing the banner on page load */
  useEffect(() => {
    if (asPath.includes('location')) setShowEvent(false)
    else if (date >= eventStart && date <= eventEnd) {
      if (showEvent === null) setTimeout(() => setShowEvent(true), 2000)
      else setShowEvent(true)
    }
  }, [asPath, date, eventEnd, eventStart, showEvent])

  return (
    <Transition
      show={showEvent ?? false}
      enter="transition ease-out duration-700"
      enterFrom="translate-y-32"
      enterTo="translate-y-0"
      leave="transition ease-out duration-700"
      leaveFrom="translate-y-0"
      leaveTo="translate-y-32"
    >
      <Link
        className="focus:shadow-none"
        href={selectRegionalisedBlogLink(locale as Locale, BlogTitleEnum.EASTER_EGG_HUNT_2024)}
      >
        <Image
          width={496.35}
          height={90}
          src={`/images/seasonal/easter/egg-hunt/${locale}/easter-egg-hunt-banner.svg`}
          alt="Timed event banner - large"
          className={styles.banners.large}
        />
        <Image
          width={265.2}
          height={48.6}
          src={`/images/seasonal/easter/egg-hunt/${locale}/easter-egg-hunt-banner-sm.svg`}
          alt="Timed event banner - small"
          className={styles.banners.small}
        />
      </Link>
    </Transition>
  )
}
