import { Button } from '@electro/shared-ui-components'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { BookmarkIcon, ChevronRightIcon } from '@heroicons/react/16/solid'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  button: 'shrink-0 rounded-md text-sm [&>p]:mb-0',
  bookmarkIcon: 'w-4 h-4 mx-2',
  chevronIcon: 'w-5 h-5 ml-auto mr-2',
}

export const RoutePlannerSavedRoutesButton = () => {
  const { t } = useTranslation('common')
  const [, { showPanel }] = useMapSidebar()

  return (
    <Button
      size="xs"
      fullWidth
      variant="raised"
      className={styles.button}
      onClick={() => showPanel(SidebarPanels.SAVED_ROUTES)}
    >
      <BookmarkIcon className={styles.bookmarkIcon} />
      <p>{t('map.saved_routes.title')} </p>
      <ChevronRightIcon className={styles.chevronIcon} />
    </Button>
  )
}
