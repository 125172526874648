import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { Input, Button } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

interface PasswordFormFields {
  password: string
}

export interface PasswordFormProps {
  /**
   * @default common_password
   * translation key for the password label
   */
  labelKey?: string
  loading?: boolean
  error?: Partial<ApolloError>
  onSubmit: ({ password }: PasswordFormFields) => void
  buttonText?: string
}

const newPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('required_field'),
})

export const PasswordForm = ({
  labelKey = 'common_password',
  loading,
  error,
  onSubmit,
  buttonText = 'Log in',
}: PasswordFormProps) => {
  const { t } = useTranslation('common')
  const handleSubmit = (values: PasswordFormFields) => {
    onSubmit(values)
  }
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: newPasswordValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  return (
    <div aria-label="login-with-password-form">
      <form onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
        <Input
          disabled={loading}
          fullWidth
          name="password"
          password
          label={t(labelKey)}
          placeholder="********"
          value={formik.values.password}
          errorMessage={t(formik.errors.password) || error?.message}
          onChange={formik.handleChange}
        />
        <Button data-testid="Submit" fullWidth type="submit" loading={loading}>
          {buttonText}
        </Button>
      </form>
    </div>
  )
}
