import Link from 'next/link'
import { tw } from '@electro/shared/utils/tailwind-merge'

import { DataSourceEntry } from '@electro/consumersite/types/storyblok'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useDraggable } from 'react-use-draggable-scroll'
import { useRef } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { IconButton } from '@electro/shared-ui-components'
import { ContentSearch } from '@electro/consumersite/src/components'

interface CategorySubnavigationProps {
  categories: DataSourceEntry[]
}

const styles = {
  menu: {
    root: tw(
      'electroverse-bg-gradient-light flex shadow-lg relative z-20 max-w-6xl mx-auto',
      'mb-16 mt-16 overflow-x-auto rounded-2xl',
    ),
    item: {
      root: tw(
        'flex w-auto text-center relative flex-grow',
        'rounded-2xl',
        'after:border-base-dark after:border-r-2 after:h-8 after:absolute after:-right-0.5 after:top-1/2 after:-translate-y-1/2 after:last:hidden',
      ),
      active: tw(
        'text-white  bg-base-dark pointer-events-none after:hidden group [&>a]:text-white',
      ),
      inactive: 'text-base-dark [&>a]:text-base-dark',
    },
    link: {
      root: tw(
        'text-sm uppercase leading-tight',
        'no-underline py-3 px-1 inline-block font-medium',
        'flex flex-col justify-center',
        'xl:w-full xl:px-6',
        'w-40 px-3',
        'rounded-2xl',
        'mx-auto',
        'focus:ring-tertiary focus:ring-inset focus:ring-offset-1',
      ),
    },
  },
}

export const CategorySubnavigation = ({ categories }: CategorySubnavigationProps) => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const categoryNavRef = useRef(null)
  const { events: draggableEvents } = useDraggable(categoryNavRef, {
    applyRubberBandEffect: true,
  })

  return (
    <nav data-testid="community-post-sub-navigation">
      <ul className={styles.menu.root} ref={categoryNavRef} {...draggableEvents}>
        <li
          className={tw('px-4', {
            [styles.menu.item.root]: true,
            [styles.menu.item.inactive]: true,
          })}
          data-testid="sub-navigation-item"
        >
          <ContentSearch searchDirectory="/community">
            {({ toggleSearchModal }) => (
              <IconButton tabIndex={-1} onClick={toggleSearchModal} className="mx-auto my-auto">
                <MagnifyingGlassIcon className="w-8 h-8 text-base-dark" />
              </IconButton>
            )}
          </ContentSearch>
        </li>
        <li
          className={tw({
            [styles.menu.item.root]: true,
            [styles.menu.item.inactive]: router.asPath !== '/community',
            [styles.menu.item.active]: router.asPath === '/community',
          })}
          data-testid="sub-navigation-item"
        >
          <Link
            scroll={false}
            href="/community"
            className={tw({
              [styles.menu.link.root]: true,
            })}
          >
            {t(`blog.categories.recent`)}
          </Link>
        </li>
        {categories.map((category, i) => {
          const categoryLink = `/community/${category.value}`
          const active = router.asPath.includes(categoryLink)

          return (
            <li
              key={category.id}
              className={tw({
                [styles.menu.item.root]: true,
                [styles.menu.item.inactive]: !active,
                [styles.menu.item.active]: active,
              })}
              data-testid="sub-navigation-item"
            >
              <Link
                scroll={false}
                href={categoryLink}
                data-testid={`${category.value}${active ? '-active' : ''}`}
                className={tw({
                  [styles.menu.link.root]: true,
                })}
              >
                {t(`blog.categories.${category.value}`)}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
