import Link from 'next/link'
import { tw } from '@electro/shared/utils/tailwind-merge'
import Image from 'next/image'
import { ArrowRightIcon } from '@heroicons/react/24/solid'
import {
  DesktopSecondaryNavItemProps,
  MobileSecondaryNavItemProps,
} from '@electro/consumersite/src/components/TopNavigation/types'

const desktopStyles = {
  root: 'no-underline hover:text-white focus:text-white focus:ring-0',
  link: tw(
    'flex rounded-lg p-2 my-2 items-center cursor-pointer no-underline',
    'hover:bg-secondary-light/40 hover:text-white focus:text-white focus:ring-0',
    'transition-colors duration-300 ease-in-out',
  ),
  linkContainer: ' w-full flex flex-col',
  labelContainer: 'w-full shrink-0 flex items-center justify-between',
  label: 'text-wrap text-md w-3/4',
  description: 'text-white/70 text-xs mb-0 text-wrap w-3/4 shrink-0 line-clamp-2',
  thumbnail: 'rounded-xl mr-2 max-height-[61px] self-start',
  icon: 'size-5 text-primary',
}

const mobileStyles = {
  link: tw(
    'flex rounded-lg cursor-pointer py-2 px-4',
    'hover:text-white/70 hover:bg-secondary/[.3] focus:text-white/70 focus:ring-0',
    'text-lg font-normal text-white no-underline',
    'transition-colors duration-300 ease-in-out',
  ),
}

const Desktop = ({
  label,
  href,
  description = '',
  withIcon = true,
  withThumbnail = false,
  thumbnailSrc = '/images/nav-thumbnail-default.png',
}: DesktopSecondaryNavItemProps) => (
  <a className={desktopStyles.root}>
    <Link className={desktopStyles.link} href={href} draggable="false">
      {withThumbnail ? (
        <Image
          className={desktopStyles.thumbnail}
          src={thumbnailSrc}
          alt="thumbnail"
          width={60}
          height={61}
        />
      ) : null}
      <div className={desktopStyles.linkContainer}>
        <div className={desktopStyles.labelContainer}>
          <div className={desktopStyles.label}>{label}</div>
          {withIcon ? <ArrowRightIcon className={desktopStyles.icon} /> : null}
        </div>
        {description ? <p className={desktopStyles.description}>{description}</p> : null}
      </div>
    </Link>
  </a>
)

const Mobile = ({ label, href }: MobileSecondaryNavItemProps) => (
  <Link className={mobileStyles.link} href={href} draggable="false">
    {label}
  </Link>
)

export const SecondaryNavItem = {
  Mobile,
  Desktop,
}
