import { ApolloClient, createHttpLink, InMemoryCache, from, Observable } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { EJN_AUTH_TOKEN_KEY } from '@electro/shared/constants'
import { ACCOUNT_ID } from '@electro/consumersite/src/constants/localStorage'
import { ErrorCode } from '@electro/consumersite/generated/graphql'
import { GraphQLError } from 'graphql'
import { logoutAndReload } from '@electro/consumersite/src/utils/logoutAndReload'
import { USER_SESSION_EXPIRED_MODAL_TRIGGER_PARAM } from '@electro/consumersite/src/constants/urlParams'

interface CustomGraphQLError extends GraphQLError {
  errorCode?: string
}

const createAuthLink = () =>
  setContext((_, { headers, uri }) => {
    let nextHeaders

    /**
     * Requests to the sandbox endpoint should not include the JWT token
     * as they are not authenticated.
     */
    const isFleetsSandboxRequest = uri?.includes(
      process.env.NEXT_PUBLIC_FLEETS_SANDBOX_GQL_ENDPOINT,
    )

    if (window !== undefined && window?.localStorage) {
      const token = localStorage.getItem(EJN_AUTH_TOKEN_KEY)
      nextHeaders = { ...headers }
      if (token && !isFleetsSandboxRequest) {
        nextHeaders.authorization = `JWT ${token}`
      }
    }
    return {
      headers: {
        ...nextHeaders,
      },
    }
  })

const accountTypeLink = () =>
  setContext((_, { headers }) => {
    let nextHeaders
    if (window !== undefined && window?.localStorage && window?.localStorage?.getItem(ACCOUNT_ID)) {
      const accountId = window?.localStorage?.getItem(ACCOUNT_ID)
      nextHeaders = { ...headers, 'X-Account-Id': accountId }
    } else {
      /**
       * Makes sure we return any custom headers attached to the request
       * otherwise all custom headers will be stripped out of the request
       * if the condition above is not met.
       */
      nextHeaders = { ...headers }
    }

    return {
      headers: {
        ...nextHeaders,
      },
    }
  })

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_EJN_API_ENDPOINT,
  headers: {
    'Api-key': process.env.NEXT_PUBLIC_EJN_API_KEY,
    'Accept-Encoding': 'gzip, deflate, br',
    source: 'web',
  },
})

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  /**
   * Critical errors that will result in the user being logged out.
   */
  const criticalErrorCodes = [
    ErrorCode.InvalidJwtToken,
    ErrorCode.RefreshTokenExpired,
    ErrorCode.InvalidRefreshToken,
  ]

  const criticalErrors = (graphQLErrors as CustomGraphQLError[])?.filter(({ errorCode }) =>
    criticalErrorCodes.includes(errorCode as ErrorCode),
  )

  /**
   * We want to swallow errors if they are in the criticalErrors list.
   * This is because we want to logout users with invalid or expired
   * refresh tokens without showing them error messages.
   */
  if (criticalErrors?.length > 0) {
    logoutAndReload({ urlParams: { [USER_SESSION_EXPIRED_MODAL_TRIGGER_PARAM]: 'true' } })

    /**
     * We return an Observable with a null error to prevent
     * the error from being displayed to the user.
     */
    return new Observable((observer) => {
      const modifiedResponse = {
        ...operation.getContext().response,
        errors: null,
      }
      observer.next(modifiedResponse)
      observer.complete()
    })
  }
  return forward(operation)
})

const additiveLink = from([errorLink, accountTypeLink(), createAuthLink(), httpLink])

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
})

/**
 * Work around to call apollo client in
 * getServerSideProps without auth!
 */
export const serverSideClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})
