import DELETE_USER_VEHICLE_MUTATION from '@electro/consumersite/src/graphql/routePlanMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationRoutePlanArgs } from '@electro/consumersite/generated/graphql'

type RoutePlanMutation = Pick<Mutation, 'routePlan'>

type UseRoutePlan = [ApolloMutationFunction<RoutePlanMutation>, MutationResult<RoutePlanMutation>]

type UseAddVehicleArgs = MutationHookOptions<RoutePlanMutation, MutationRoutePlanArgs>

export function useCreateRoutePlan(options?: UseAddVehicleArgs): UseRoutePlan {
  const [RoutePlanMutation, routePlanResult] = useMutation<RoutePlanMutation>(
    DELETE_USER_VEHICLE_MUTATION,
    options,
  )

  return [RoutePlanMutation, routePlanResult]
}
