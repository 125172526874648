import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { IconButton } from '@electro/shared-ui-components'

export type ToastVariantType = 'success' | 'error' | 'default' | 'dark'
export type ToastPositions = 'topRight' | 'bottomLeft'

interface ToastProps {
  display: boolean
  heading?: string
  body?: any
  variant?: ToastVariantType
  onClose?: () => void
  position?: ToastPositions
  className?: string
}

export const Toast = ({
  body,
  heading,
  display = false,
  variant = 'default',
  onClose,
  position = 'topRight',
  className = '',
}: ToastProps) => {
  const [visible, setVisible] = useState(display)

  useEffect(() => {
    setVisible(display)
  }, [display])

  const styles = {
    root: 'p-4 fixed z-50',
    topRight: 'top-0 right-0 animate-toastEnterTop',
    bottomLeft: 'bottom-0 left-0 animate-toastEnterBottom',
    toast: 'rounded-md py-4 sm:py-6 max-w-xl shadow-xl inline-block text-white',
    default: 'bg-base border-secondary border-2',
    success: 'text-base-dark hover:text-base-dark bg-action-success',
    dark: 'bg-base-dark',
    error: 'bg-action-danger',
    header: 'mb-0 pl-10 pr-14',
    closeBtn: 'h-6 w-6 absolute top-0 right-6 hover:text-white',
    body: 'pl-4 sm:pl-10 pr-12',
  }

  function closeHandler() {
    setVisible(false)
    if (onClose) onClose()
  }

  if (!visible) return null

  return (
    <div
      className={tw({
        [styles.root]: true,
        [styles.topRight]: position === 'topRight',
        [styles.bottomLeft]: position === 'bottomLeft',
        [className]: true,
      })}
      data-testid="toast"
    >
      <div
        className={tw({
          [styles.toast]: true,
          [styles.default]: variant === 'default',
          [styles.success]: variant === 'success',
          [styles.error]: variant === 'error',
          [styles.dark]: variant === 'dark',
        })}
      >
        <div className="relative">
          {Boolean(heading) && (
            <h3
              className={tw({
                [styles.header]: true,
                [styles.success]: variant === 'success',
                [styles.error]: variant === 'error',
                [styles.dark]: variant === 'dark',
                'pb-2': !!body,
              })}
            >
              {heading}
            </h3>
          )}
          <IconButton
            aria-label="Close notification"
            onClick={closeHandler}
            className={tw({
              [styles.closeBtn]: true,
              [styles.success]: variant === 'success',
              [styles.error]: variant === 'error',
              [styles.dark]: variant === 'dark',
            })}
          >
            <XMarkIcon className="h-6 w-6" />
          </IconButton>
        </div>
        <div
          className={tw({
            [styles.body]: true,
            [styles.success]: variant === 'success',
            [styles.error]: variant === 'error',
            [styles.dark]: variant === 'dark',
          })}
        >
          {body}
        </div>
      </div>
    </div>
  )
}
