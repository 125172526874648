export const isNumber = (value: any) => {
  try {
    return Number(value) === value
  } catch {
    return false
  }
}

export const isFunction = (value: any) =>
  !!(value && value.constructor && value.call && value.apply)

export const isArray = (value: any) => Array.isArray(value)

export const isObject = (value: any) => !!value && value.constructor === Object
