import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import Trans from 'next-translate/Trans'
import { useRouter } from 'next/router'

import { usePlugAndChargeSignup } from '@electro/consumersite/src/components/PlugAndChargeSignup/hooks'
import { Button, Typography } from '@electro/shared-ui-components'
import { PncStagesEnum } from '@electro/consumersite/src/components/PlugAndChargeSignup/types'
import {
  selectRegionalisedBlogLink,
  BlogTitleEnum,
} from '@electro/consumersite/src/helpers/selectLocalisedBlog'
import { Locale } from '@electro/shared/types/locales'

enum incompatibilityReasonEnum {
  notAnEV = 'NOT_AN_EV',
  incompatibleModel = 'INCOMPATIBLE_MODEL',
}

export const IncompatibleVehicleStage = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [{ vehicle }, { setActiveStage }] = usePlugAndChargeSignup()

  const handleBack = () => {
    setActiveStage(PncStagesEnum.VEHICLE_REG_LOOKUP)
  }

  const handleEnterVin = () => {
    setActiveStage(PncStagesEnum.ENTER_VEHICLE_VIN)
  }

  const incompatibiltyReason =
    vehicle?.info?.isElectric === false
      ? incompatibilityReasonEnum.notAnEV
      : incompatibilityReasonEnum.incompatibleModel

  let incompatibiltyReasonMessage

  switch (incompatibiltyReason) {
    case incompatibilityReasonEnum.notAnEV:
      incompatibiltyReasonMessage = (
        <>
          <Typography variant="p" as="p">
            <strong>{t('plug_and_charge.incompatible_vehicle_stage.not_an_ev')}</strong>
          </Typography>
          <Typography variant="p" as="p">
            {t('plug_and_charge.incompatible_vehicle_stage.check_your_reg_plate_try_again')}
          </Typography>
        </>
      )
      break
    case incompatibilityReasonEnum.incompatibleModel:
      incompatibiltyReasonMessage = (
        <Typography variant="p" as="p">
          <strong>{t('plug_and_charge.incompatible_vehicle_stage.incompatible_model')}</strong>{' '}
          <Trans
            i18nKey="common:plug_and_charge.incompatible_vehicle_stage.read_more_about_eligibility"
            components={{
              blogLink: (
                <Link
                  href={selectRegionalisedBlogLink(
                    router.locale as Locale,
                    BlogTitleEnum.PLUG_AND_CHARGE_BLOG,
                  )}
                >
                  {t('utility.blog')}
                </Link>
              ),
            }}
          />{' '}
          {t('plug_and_charge.incompatible_vehicle_stage.other_ways_to_charge')}
        </Typography>
      )
      break

    default:
      incompatibiltyReasonMessage = (
        <Typography variant="p" as="p">
          {t('plug_and_charge.incompatible_vehicle_stage.check_reg_plate')}
        </Typography>
      )
      break
  }

  return (
    <div>
      <Typography variant="h1" as="h1">
        {t('plug_and_charge.incompatible_vehicle_stage.title')}
      </Typography>
      <Image
        src="/images/mr-zap-oh-no.png"
        alt="illustration of Mr Zap looking disappointed"
        width={240}
        height={105}
        className="mx-auto mt-1 mb-10"
      />
      <Typography as="h2" className="text-2xl font-medium mb-4">
        {vehicle?.regNumber}
      </Typography>
      <Typography variant="p" as="p">
        {t('plug_and_charge.incompatible_vehicle_stage.vehicle_description')}{' '}
        <span>
          {vehicle?.info?.colour} {vehicle?.info?.make} {vehicle?.info?.model} (
          {vehicle?.info?.yearOfManufacture})
        </span>
        .
      </Typography>
      {incompatibiltyReasonMessage}
      <div className="gap-4 flex flex-col mt-6">
        <Button variant="outline" fullWidth onClick={handleBack}>
          {t('plug_and_charge.incompatible_vehicle_stage.not_your_vehicle')}
        </Button>
        <Button variant="naked" fullWidth onClick={handleEnterVin}>
          {t('plug_and_charge.incompatible_vehicle_stage.enter_a_vin')}
        </Button>
      </div>
    </div>
  )
}
