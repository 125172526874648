import React, { createContext, useContext, ReactNode } from 'react'

type StepperContextReturnType = {
  activeStep?: number
  orientation?: 'vertical' | 'horizontal'
}

interface Props {
  activeStep?: number
  orientation?: 'vertical' | 'horizontal'
}

const StepperContext = createContext<StepperContextReturnType>(null)

const useStepperProvider = ({ activeStep, orientation }: Props): StepperContextReturnType => ({
  activeStep,
  orientation,
})

interface StepperProviderProps extends Props {
  children: ReactNode | ReactNode[]
}

export const StepperProvider = ({ activeStep, orientation, children }: StepperProviderProps) => {
  const value = useStepperProvider({ activeStep, orientation })

  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
}

export const useStepper = (): StepperContextReturnType => {
  const context = useContext(StepperContext)

  return context
}
