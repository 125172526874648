import { forwardRef, ForwardedRef } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { SecondaryNavItem } from '@electro/consumersite/src/components/TopNavigation/components'
import { DesktopSecondaryNavItemProps } from '@electro/consumersite/src/components/TopNavigation/types'

const styles = {
  root: 'py-2 px-4',
}

export const SecondaryNav = forwardRef(
  ({ items }: { items: DesktopSecondaryNavItemProps[] }, ref: ForwardedRef<HTMLUListElement>) => {
    const { t } = useTranslation('common')
    return (
      <ul ref={ref} className={styles.root}>
        {items.map((item: DesktopSecondaryNavItemProps) => (
          <SecondaryNavItem.Desktop key={item.label} {...item} label={t(item.label)} />
        ))}
      </ul>
    )
  },
)
