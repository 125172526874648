import { useUpdateEffect } from 'react-use'
import { useState, useCallback, useMemo, createContext, useContext, ReactNode, useRef } from 'react'
import { ConnectorsMacroCaseEnum } from '@electro/consumersite/src/components/Map/utils'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import {
  MapFiltersType,
  DEFAULT_FILTERS,
  MapParamsEnum,
  MapFiltersEnum,
} from '@electro/consumersite/src/components/Map/types'

const {
  ELECTROVERSE_COMPATIBLE,
  OPERATORS,
  SOCKET_TYPES,
  CHARGE_POINT_SPEEDS,
  MIN_CHARGE_POINTS,
  ACCESS,
} = MapFiltersEnum

interface State {
  /** Used on the electroverse map as a method to share current filters */
  filtersSearchParams: string
  /** Used on the elastic search REST API to generate vector tiles to be consumed by MapBox. */
  elasticSearchParams: string
  currentFilters: MapFiltersType
  filtersActive: boolean
}

interface Handlers {
  applyInitialFilters: (filterParams: Partial<MapFiltersType>) => void
  updateFilters: (args: Partial<MapFiltersType>) => void
  resetFilters: () => void
}

type UseMapFilters = [state: State, handlers: Handlers]

const UseMapFiltersContext = createContext<UseMapFilters>(null)

function useMapFiltersProvider({ pageFilters }: Partial<UseMapFiltersProps>): UseMapFilters {
  const [{ activeVehicleSockets }] = useUserVehicles()

  const skipDefineInitialSocketTypes = useRef<boolean>(false)

  const [currentFilters, setCurrentFilters] = useState<MapFiltersType>(DEFAULT_FILTERS)
  const [filtersActive, setFiltersActive] = useState<boolean>(false)

  /** Overwrites existing filter properties and sends the list to analytics */
  const updateFilters = useCallback((args: Partial<MapFiltersType>) => {
    setCurrentFilters((prev) => {
      const updatedFilters = { ...prev, ...args }

      const operatorsNameArray = updatedFilters.operators.map(({ name }) => name)
      GTM.submitFilters({ currentFilters: { ...updatedFilters, [OPERATORS]: operatorsNameArray } })

      return updatedFilters
    })
  }, [])

  /** Default filters with activeVehicle and activeVehicle sockets included */
  const defaultFiltersWithVehicle: MapFiltersType = useMemo(() => {
    const urlSafeSockets = activeVehicleSockets.map((socket) => ConnectorsMacroCaseEnum[socket])
    return { ...DEFAULT_FILTERS, [SOCKET_TYPES]: urlSafeSockets }
  }, [activeVehicleSockets])

  /** Sets the current filters to their default state + active vehicle */
  const resetFilters = useCallback(
    () => updateFilters(defaultFiltersWithVehicle),
    [updateFilters, defaultFiltersWithVehicle],
  )

  /** Compares the current filters with the default state + active vehicle
   * to determine if the filters have been customised */
  useUpdateEffect(() => {
    setFiltersActive(JSON.stringify(currentFilters) !== JSON.stringify(defaultFiltersWithVehicle))
  }, [currentFilters, defaultFiltersWithVehicle])

  /** Updates the filtered socket types to match any changes to the selected vehicle */
  useUpdateEffect(() => {
    if (skipDefineInitialSocketTypes.current) skipDefineInitialSocketTypes.current = false
    else {
      const urlSafeSockets = activeVehicleSockets.map((socket) => ConnectorsMacroCaseEnum[socket])
      updateFilters({ [SOCKET_TYPES]: urlSafeSockets })
    }
  }, [updateFilters, activeVehicleSockets])

  /** Define the initial filters from page settings and URL search parameters.
   * If URL search parameters exist, it skips applying active vehicle socket types */
  const applyInitialFilters = useCallback(
    (filterParams: MapFiltersType) => {
      updateFilters({ ...pageFilters, ...filterParams })
      if (Object.keys(filterParams).length > 0) skipDefineInitialSocketTypes.current = true
    },
    [updateFilters, pageFilters],
  )

  /** Converts the filters object into a shareable string for the Electroverse map
   *  https://www.notion.so/Map-params-e3eec67cf3cc428897243922c0c277e9 */
  const filtersSearchParams = useMemo(() => {
    const urlParams = [
      currentFilters[ELECTROVERSE_COMPATIBLE] === false
        ? `${MapParamsEnum.ELECTROVERSE_COMPATIBLE}=false`
        : '',

      currentFilters[OPERATORS].length > 0
        ? `${MapParamsEnum.OPERATORS}=${currentFilters[OPERATORS].map(({ pk }) => pk).join(',')}`
        : '',
      currentFilters[SOCKET_TYPES].length > 0
        ? `${MapParamsEnum.SOCKET_TYPES}=${currentFilters[SOCKET_TYPES].join(',')}`
        : '',

      currentFilters[MIN_CHARGE_POINTS] > 0
        ? `${MapParamsEnum.MIN_CHARGE_POINTS}=${currentFilters[MIN_CHARGE_POINTS]}`
        : '',
      currentFilters[CHARGE_POINT_SPEEDS][0] > 0 || currentFilters[CHARGE_POINT_SPEEDS][1] < 350
        ? `${MapParamsEnum.CHARGE_POINT_SPEEDS}=${currentFilters[CHARGE_POINT_SPEEDS].join(',')}`
        : '',

      currentFilters[ACCESS].length > 0
        ? `${MapParamsEnum.ACCESS}=${currentFilters[ACCESS].join(',')}`
        : '',
    ].filter(Boolean)

    return urlParams.length > 0 ? `?${urlParams.join('&')}` : ''
  }, [currentFilters])

  /** Converts the filters object into a string for use on the elastic search API */
  const elasticSearchParams = useMemo(() => {
    const urlParams = [
      currentFilters[ELECTROVERSE_COMPATIBLE] ? 'exclude_non_ejn_locations=true' : '',

      currentFilters[OPERATORS].map(({ pk }) => `operator_groups[]=${pk}`).join('&'),
      currentFilters[SOCKET_TYPES].map((id) => `socket_groups_mr[]=${id}`).join('&'),

      currentFilters[MIN_CHARGE_POINTS] > 0
        ? `min_number_of_connectors=${currentFilters[MIN_CHARGE_POINTS]}`
        : '',
      currentFilters[CHARGE_POINT_SPEEDS][0] > 0
        ? `min_charge_speed=${currentFilters[CHARGE_POINT_SPEEDS][0]}`
        : '',
      currentFilters[CHARGE_POINT_SPEEDS][1] < 350
        ? `max_charge_speed=${currentFilters[CHARGE_POINT_SPEEDS][1]}`
        : '',

      currentFilters[ACCESS].map((id) => `capabilities[]=${id}`).join('&'),
    ].filter(Boolean)

    return urlParams.join('&')
  }, [currentFilters])

  const state = useMemo(
    () => ({ filtersSearchParams, elasticSearchParams, currentFilters, filtersActive }),
    [filtersSearchParams, elasticSearchParams, currentFilters, filtersActive],
  )
  const handlers = useMemo(
    () => ({ applyInitialFilters, updateFilters, resetFilters }),
    [applyInitialFilters, updateFilters, resetFilters],
  )

  return [state, handlers]
}

interface UseMapFiltersProps {
  children: ReactNode | ReactNode[]
  pageFilters?: Partial<MapFiltersType>
}

export const UseMapFiltersProvider = ({ children, pageFilters }: UseMapFiltersProps) => {
  const ctx = useMapFiltersProvider({ pageFilters })
  return <UseMapFiltersContext.Provider value={ctx}>{children}</UseMapFiltersContext.Provider>
}

export const useMapFilters = (): UseMapFilters => {
  const context = useContext(UseMapFiltersContext)
  if (!context)
    throw new Error('useMapFilters() cannot be used outside of <UseMapFiltersProvider/>')
  return context
}
