import electroTheme from '@electro/shared/theme/electro'

const HIGH_BATTERY_THRESHOLD = 25
const MID_BATTERY_THRESHOLD = 15
const LOW_BATTERY_THRESHOLD = 10

const HIGH_BATTERY_COLOUR = '#32CD32' // Green
const MID_BATTERY_COLOUR = '#FB6100' // Orange
const LOW_BATTERY_COLOUR = '#FF3F14' // Blood Orange
const CRITICAL_BATTERY_COLOUR = '#E12424' // Red

/** Ensure the percentage property is a valid number */
const sanitisePercentage = (percent: number) => Math.max(0, Math.min(100, percent))

interface ConvertBatteryPercentageToHexOptions {
  /** Determines the colours to return according to the light/dark backgrounds they're displayed on */
  type?: 'route' | 'icon'
}

/** Converts a battery percentage to a matching colour, based on some defined thresholds. */
export const convertBatteryPercentageToHex = (
  percent: number,
  { type }: ConvertBatteryPercentageToHexOptions = { type: 'route' },
) => {
  const batteryPercentage = sanitisePercentage(percent)

  if (type === 'route') {
    if (batteryPercentage > HIGH_BATTERY_THRESHOLD) return HIGH_BATTERY_COLOUR
    if (batteryPercentage > MID_BATTERY_THRESHOLD) return MID_BATTERY_COLOUR
    if (batteryPercentage > LOW_BATTERY_THRESHOLD) return LOW_BATTERY_COLOUR

    return CRITICAL_BATTERY_COLOUR
  }

  if (type === 'icon') {
    if (batteryPercentage > HIGH_BATTERY_THRESHOLD) return electroTheme.extend.colors.action.success
    if (batteryPercentage > MID_BATTERY_THRESHOLD) return electroTheme.extend.colors.action.warning

    return electroTheme.extend.colors.action.danger
  }

  return '#000000'
}
