import { Typography, Skeleton } from '@electro/shared-ui-components'
import {
  useElectrocardOrdersQuery,
  RfidCardOrderEdge,
} from '@electro/consumersite/generated/graphql'
import { CardOrderItem } from '@electro/consumersite/src/components/AccountElectrocardsManager/components'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  root: 'my-8 px-1',
  title: 'flex justify-center md:justify-start',
}

const LoadingSkeleton = () => (
  <div data-testid="loading-electrocard-orders">
    <div className="flex justify-center md:justify-start">
      <Skeleton className="mb-10" width={174} height={28} />
    </div>
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-0" />
  </div>
)

export const ManageElectrocardOrders = () => {
  const { t } = useTranslation('common')
  const { loading, data, error } = useElectrocardOrdersQuery({ fetchPolicy: 'network-only' })
  const electrocardOrders = data?.rfidCardOrders.edges ?? []

  return (
    <div data-testid="manage-electrocard-orders" className={styles.root}>
      {error && <div className="text-action-danger">{error?.message}</div>}
      {loading && <LoadingSkeleton />}
      {!error && !loading && electrocardOrders.length > 0 ? (
        <>
          <Typography variant="h3" as="h1" className={styles.title}>
            {t('profile.electrocard_orders.heading')}
          </Typography>
          <div className="mt-10">
            {electrocardOrders.map((electrocardOrder: RfidCardOrderEdge) => {
              const { createdAt, status, pk } = electrocardOrder.node

              return <CardOrderItem key={pk} orderDate={createdAt} orderStatus={status} />
            })}
          </div>
        </>
      ) : null}
    </div>
  )
}
