import {
  ComponentType,
  Dispatch,
  MouseEvent,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { DataGridRowDef, DataGridChangeParams } from 'libs/shared-ui-components/src/lib/DataGrid'
import { useDebounce, useUpdateEffect } from 'react-use'

export enum OrderingDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export interface Ordering<T> {
  orderBy: T
  direction: OrderingDirectionEnum
}

interface Handlers<T> {
  setOffset: Dispatch<SetStateAction<number>>
  resultPerPage?: number
  handleOrderChange: (nextOrderBy: T) => void
  handleRowClick: (row: DataGridRowDef, event: MouseEvent<HTMLTableRowElement>) => void
  handleSearchText: (text: string) => void
  nextHandler: () => void
  prevHandler: () => void
}

interface State<T> {
  ordering: Ordering<T>[]
  offset: number
  resultPerPage: number
  hasDetailPanel: boolean
  searchText: string
}

type DataGridContextReturnType<T> = [state: State<T>, handlers: Handlers<T>]

export interface InitialState<T> {
  ordering?: Ordering<T>[]
  offset?: number
  searchText?: string
}

interface DetailPanelProps {
  row: DataGridRowDef
}

export interface DataGridProviderProps<T> {
  /**
   * Sets the default search text value for the search box and sets the
   * default UI state for the orderBy & direction columns
   */
  initialState?: InitialState<T>
  resultPerPage?: number
  detailPanel?: ComponentType<DetailPanelProps>
  onChange?: ({ first, offset, ordering, search }: DataGridChangeParams<T>) => void
  onRowClick?: (row: DataGridRowDef, event: MouseEvent<HTMLTableRowElement>) => void
  children?: ReactNode | ReactNode[]
}

const toggleOrderDirection = (orderDirection) => {
  if (orderDirection === OrderingDirectionEnum.Asc) {
    return OrderingDirectionEnum.Desc
  }

  return OrderingDirectionEnum.Asc
}

const useDataGridProvider = <T extends string>({
  initialState = {},
  resultPerPage = 20,
  onChange,
  onRowClick,
  detailPanel,
}: DataGridProviderProps<T>): DataGridContextReturnType<T> => {
  const defaultOffset = useMemo(() => {
    if (initialState.offset) {
      return initialState.offset
    }

    return 0
  }, [initialState.offset])
  const defaultOrdering: Ordering<T>[] = useMemo(() => {
    if (initialState.ordering) {
      return initialState.ordering
    }

    return []
  }, [initialState.ordering])
  const defaultSearchText = useMemo(() => initialState.searchText ?? '', [initialState.searchText])
  const hasDetailPanel = useMemo(() => !!detailPanel, [detailPanel])

  const [offset, setOffset] = useState<number>(defaultOffset)
  const [prevOffset, setPrevOffset] = useState<number>(0) // we need to hold the previous offset when we perform search
  const [ordering, setOrdering] = useState<Ordering<T>[]>(defaultOrdering)
  const [searchText, setSearchText] = useState<string>(defaultSearchText)
  const [debouncedSearchText, setDebouncedSearchText] = useState<string>('')
  const [isSearchProceeding, setIsSearchProceeding] = useState(false)

  const nextHandler = useCallback(() => {
    const nextOffset = offset + resultPerPage
    setOffset(nextOffset)
    if (onChange)
      onChange({ first: resultPerPage, offset: nextOffset, ordering, search: searchText })
  }, [offset, ordering, resultPerPage, onChange, searchText])

  const prevHandler = useCallback(() => {
    const nextOffset = Math.max(offset - resultPerPage, 0)
    setOffset(nextOffset)
    if (onChange)
      onChange({ first: resultPerPage, offset: nextOffset, ordering, search: searchText })
  }, [offset, ordering, resultPerPage, onChange, searchText])

  const handleOrderChange = useCallback(
    (nextOrderBy: T) => {
      const matchOldOrdering = ordering.find((order) => order.orderBy === nextOrderBy)

      if (matchOldOrdering) {
        const newDirection = toggleOrderDirection(matchOldOrdering.direction)
        const newOrdering: Ordering<T> = {
          direction: newDirection,
          orderBy: matchOldOrdering.orderBy,
        }
        setOrdering([newOrdering])
        if (onChange)
          onChange({ first: resultPerPage, offset, ordering: [newOrdering], search: searchText })
      } else {
        const defaultDirection = OrderingDirectionEnum.Asc
        const newOrdering: Ordering<T> = { direction: defaultDirection, orderBy: nextOrderBy }
        setOrdering([newOrdering])
        if (onChange)
          onChange({ first: resultPerPage, offset, ordering: [newOrdering], search: searchText })
      }
    },
    [offset, ordering, resultPerPage, onChange, searchText],
  )

  const handleRowClick = useCallback(
    (row: DataGridRowDef, event: MouseEvent<HTMLTableRowElement>) => {
      if (onRowClick) {
        onRowClick(row, event)
      }
    },
    [onRowClick],
  )

  const handleSearchText = (text: string) => setSearchText(text)

  /**
   * When user is searching we need to take some actions beforehand. Start pagination from the beginning by
   * setting offset to 0 and also keep the previous offset in order to use it when user stop searching.
   */
  const performSearch = () => {
    // if search just started we keep the prev offset
    if (!isSearchProceeding) {
      setPrevOffset(offset)
    }

    setOffset(0)
    setIsSearchProceeding(true)
    onChange({ first: resultPerPage, offset: 0, ordering, search: debouncedSearchText })
  }

  /**
   * When user stop searching we need to take them back exactly where they was before starting the searching.
   * We can succeed that because we have kept the previous offset on performSearch function before.
   */
  const searchCleanup = () => {
    setOffset(prevOffset)
    setIsSearchProceeding(false)
    onChange({ first: resultPerPage, offset: prevOffset, ordering, search: debouncedSearchText })
  }

  useUpdateEffect(() => {
    if (debouncedSearchText) {
      performSearch()
    } else {
      searchCleanup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText])

  useDebounce(() => setDebouncedSearchText(searchText), 250, [searchText])

  const state = useMemo(
    () => ({
      ordering,
      offset,
      resultPerPage,
      hasDetailPanel,
      searchText,
    }),
    [ordering, offset, resultPerPage, hasDetailPanel, searchText],
  )

  const handlers = useMemo(
    () => ({
      handleOrderChange,
      handleRowClick,
      nextHandler,
      prevHandler,
      setOffset,
      handleSearchText,
    }),
    [handleOrderChange, nextHandler, prevHandler, handleRowClick],
  )

  return [state, handlers]
}

const DataGridContext = createContext(null)

const useDataGrid = () => {
  const context = useContext<DataGridContextReturnType<any>>(DataGridContext)
  if (!context) {
    throw new Error(`useDataGrid() cannot be used outside the context of <DataGridProvider/> `)
  }
  return context
}

const DataGridProvider = <T extends string>({ children, ...rest }: DataGridProviderProps<T>) => {
  const context = useDataGridProvider(rest)
  return <DataGridContext.Provider value={context}>{children}</DataGridContext.Provider>
}

export { DataGridProvider, useDataGrid }
