import { ChangeEvent, ForwardedRef, Fragment, forwardRef, useMemo, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { usePopper } from 'react-popper'
import { AutocompleteProps } from './Autocomplete.types'
import { LoadingSpinner } from '../LoadingSpinner'

const styles = {
  root: 'w-fit',
  fullWidth: 'w-full',
  comboboxContent: 'relative mt-1',
  input: {
    wrapper: 'relative',
    root: tw(
      'block  border-2 bg-transparent rounded-lg pl-2 pt-2 pb-2 w-full',
      'placeholder-white/60',
      'focus:outline-none focus:border-tertiary hover:border-tertiary',
      'text-white border-secondary text-base',
    ),
    disabled:
      'cursor-not-allowed text-white border-white hover:border-white placeholder-white opacity-60',
    error: 'border-action-danger !text-action-danger',
    errorMessage: 'text-sm text-action-danger border-action-danger pt-0.5',
  },
  label: {
    root: 'block font-normal text-sm pl-1 pb-1 text-white',
  },
  options: tw(
    'absolute w-full py-2 mt-1 overflow-auto text-base rounded-xl z-10',
    'shadow-lg max-h-96 focus:outline-none',
    'bg-base border-2 border-secondary',
  ),
  option: {
    root: tw(
      'select-none relative py-2 pl-10 pr-4 text-white',
      'hover:bg-tertiary-shade hover:bg-opacity-25 cursor-pointer hover:text-tertiary',
    ),
    active: 'bg-tertiary-shade bg-opacity-25',
    iconWrapper: {
      root: 'absolute inset-y-0 left-0 flex items-center pl-3 text-teal-600',
      active: 'text-white',
    },
    icon: 'h-5 w-5 text-white',
    text: {
      root: 'font-normal',
      selected: 'font-medium',
    },
    empty: 'cursor-default select-none relative py-2 pl-10 pr-4 text-white',
    loading: 'flex justify-start items-center select-none relative py-0 p-4 pr-4 -my-1 text-white',
  },
  button: {
    root: 'absolute right-0 top-0 h-full flex items-end pr-2',
    icon: 'h-5 w-5 text-gray-400 mb-3',
  },
}

const defaultGetOptionLabel = (option): string => option.label ?? option

export const Autocomplete = forwardRef(
  (
    {
      options = [],
      value,
      defaultValue,
      label,
      name,
      loading,
      loadingMessage = 'Loading...',
      noOptionsText = 'No options',
      errorMessage,
      onChange,
      onInputChange,
      placeholder,
      disabled,
      fullWidth = false,
      getOptionLabel: getOptionLabelProp,
      getOptionKey: getOptionKeyProp,
      onClose,
      onOpen,
      filterOptions,
    }: AutocompleteProps,
    ref: ForwardedRef<HTMLTableElement>,
  ) => {
    const [query, setQuery] = useState('')
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const { styles: popperStyles, attributes: popperAttr } = usePopper(
      referenceElement,
      popperElement,
    )

    const isOptionObject = useMemo(
      () => options.every((option) => typeof option === 'object'),
      [options],
    )

    const getOptionLabel = getOptionLabelProp || defaultGetOptionLabel

    const currentValue = useMemo(() => {
      if (value === undefined) {
        return undefined
      }

      if (isOptionObject) {
        return options.find(
          (option) => getOptionLabel(option).toLowerCase() === getOptionLabel(value).toLowerCase(),
        )
      }

      return options.find((option) => option.toLowerCase() === value.toLowerCase())
    }, [options, value, getOptionLabel, isOptionObject])

    const isControlled = currentValue !== undefined

    const currentDefaultValue = useMemo(() => {
      if (defaultValue === undefined) {
        return undefined
      }

      if (isOptionObject) {
        return options.find(
          (option) =>
            getOptionLabel(option).toLowerCase() === getOptionLabel(defaultValue).toLowerCase(),
        )
      }

      return options.find((option) => option.toLowerCase() === defaultValue.toLowerCase())
    }, [options, defaultValue, getOptionLabel, isOptionObject])

    const [selectedOption, setSelectedOption] = useState(currentDefaultValue)
    const filteredOptions = useMemo(() => {
      if (query !== '') {
        return options.filter((option) => {
          if (filterOptions) {
            return filterOptions(option, query)
          }

          return getOptionLabel(option).toLowerCase().includes(query.toLowerCase())
        })
      }

      return options
    }, [query, options, getOptionLabel, filterOptions])

    const handleChange = (option) => {
      onChange?.(option)

      if (!isControlled) {
        setSelectedOption(option)
      }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value)

      if (onInputChange) {
        onInputChange(event)
      }
    }

    const handleAfterLeave = () => {
      setQuery('')
      onClose?.()
    }

    const handleAfterEnter = () => {
      onOpen?.()
    }

    return (
      <div
        className={tw({
          [styles.root]: true,
          [styles.fullWidth]: fullWidth,
        })}
        ref={ref}
      >
        <Combobox
          data-testid="autocomplete"
          value={isControlled ? value : selectedOption}
          defaultValue={defaultValue}
          onChange={handleChange}
          disabled={disabled}
        >
          <div className={styles.comboboxContent}>
            <div className={styles.input.wrapper} ref={setReferenceElement}>
              <label htmlFor={name}>
                <span
                  className={tw({
                    [styles.label.root]: true,
                    [styles.input.error]: !!errorMessage,
                    [styles.input.disabled]: !!disabled,
                  })}
                >
                  {label}
                </span>
                <Combobox.Input
                  id={name}
                  type="text"
                  data-testid="autocomplete-input"
                  className={tw({
                    [styles.input.root]: true,
                    [styles.input.disabled]: disabled,
                    [styles.input.error]: errorMessage,
                  })}
                  placeholder={placeholder}
                  displayValue={getOptionLabel}
                  onChange={handleInputChange}
                />
              </label>

              <Combobox.Button className={styles.button.root}>
                <ChevronUpDownIcon className={styles.button.icon} aria-hidden="true" />
              </Combobox.Button>
            </div>

            {!!errorMessage && <div className={styles.input.errorMessage}>{errorMessage}</div>}

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={handleAfterLeave}
              afterEnter={handleAfterEnter}
            >
              <Combobox.Options
                ref={setPopperElement}
                className={styles.options}
                data-testid="autocomplete-options"
                style={popperStyles.popper}
                {...popperAttr.popper}
              >
                {!loading &&
                  filteredOptions.map((option) => (
                    <Combobox.Option
                      key={JSON.stringify(option)}
                      className={({ active }) =>
                        tw({
                          [styles.option.root]: true,
                          [styles.option.active]: active,
                        })
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={tw({
                              [styles.option.text.root]: true,
                              [styles.option.text.selected]: selected,
                            })}
                          >
                            {getOptionLabel(option)}
                          </span>

                          {selected && (
                            <span
                              className={tw({
                                [styles.option.iconWrapper.root]: true,
                                [styles.option.iconWrapper.active]: active,
                              })}
                            >
                              <CheckIcon className={styles.option.icon} aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}

                {!loading && filteredOptions.length === 0 && (
                  <span className={styles.option.empty}>{noOptionsText}</span>
                )}

                {loading && (
                  <span className={styles.option.loading}>
                    <LoadingSpinner className="h-6 w-6 mr-4" />
                    {loadingMessage}
                  </span>
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    )
  },
)
