import { Component, ComponentType, ReactComponentElement } from 'react'
import * as Sentry from '@sentry/nextjs'
import { ErrorMessage } from './ErrorMessage'

interface ErrorState {
  hasError: boolean
}

interface ErrorProps {
  children: ReactComponentElement<ComponentType>
  ErrorComponent?: ComponentType | string
}

export class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, (scope) =>
      scope.setExtras({ errorInfo, errorBoundaryReached: true } as any),
    )
  }

  render() {
    const { children, ErrorComponent = ErrorMessage } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <ErrorComponent />
    }

    return children
  }
}
