import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ApolloError } from '@apollo/client'
import { Input, Button, Alert } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { MutationChangePasswordArgs } from '@electro/consumersite/generated/graphql'

export type ChangePasswordFormFields = MutationChangePasswordArgs

export interface ChangePasswordFormProps {
  loading?: boolean
  error?: Partial<ApolloError>
  onSubmit: (args: ChangePasswordFormFields) => void
}

const newPasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('validation.required_field'),
  newPassword: Yup.string()
    .required('validation.required_field')
    .not([Yup.ref('currentPassword'), null], 'validation.new_password_must_not_match_old'),
})

export const ChangePasswordForm = ({ loading, error, onSubmit }: ChangePasswordFormProps) => {
  const { t } = useTranslation('common')
  const handleSubmit = (values: ChangePasswordFormFields) => {
    onSubmit(values)
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
    },
    validationSchema: newPasswordValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  return (
    <div aria-label="change-password-form">
      <form onBlur={validateOnBlur} onSubmit={formik.handleSubmit}>
        <Input
          disabled={loading}
          fullWidth
          name="currentPassword"
          password
          label={t('form.current_password')}
          placeholder="********"
          value={formik.values.currentPassword}
          errorMessage={t(formik.errors.currentPassword)}
          onChange={formik.handleChange}
        />
        <Input
          disabled={loading}
          fullWidth
          name="newPassword"
          password
          label={t('form.new_password')}
          placeholder="********"
          value={formik.values.newPassword}
          errorMessage={t(formik.errors.newPassword)}
          onChange={formik.handleChange}
        />
        {error?.message ? (
          <Alert variant="error" className="mb-4">
            {t(error?.message)}
          </Alert>
        ) : null}
        <Button data-testid="Submit" fullWidth type="submit" loading={loading}>
          {t('common.button.change_password')}
        </Button>
      </form>
    </div>
  )
}
