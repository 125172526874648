import React, { createContext, useContext, ReactNode } from 'react'

type StepContextReturnType = {
  active?: boolean
}

interface Props {
  active?: boolean
}

const StepContext = createContext<StepContextReturnType>(null)

const useStepProvider = ({ active }: Props): StepContextReturnType => ({ active })

interface StepProviderProps extends Props {
  children: ReactNode | ReactNode[]
}

export const StepProvider = ({ active, children }: StepProviderProps) => {
  const value = useStepProvider({ active })

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>
}

export const useStep = (): StepContextReturnType => {
  const context = useContext(StepContext)

  return context
}
