import VIN_LOOKUP_MUTATION from '@electro/consumersite/src/graphql/vinLookupFromRegNumberMutation.graphql'

import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import {
  Mutation,
  MutationVinLookupFromRegNumberArgs,
} from '@electro/consumersite/generated/graphql'

type VinLookupFromRegNumberMutation = Pick<Mutation, 'vinLookupFromRegNumber'>

type UseVinLookupFromRegNumber = [
  ApolloMutationFunction<VinLookupFromRegNumberMutation>,
  MutationResult<VinLookupFromRegNumberMutation>,
]

type VinLookupArgs = MutationHookOptions<
  VinLookupFromRegNumberMutation,
  MutationVinLookupFromRegNumberArgs
>

export function useVinLookupFromRegNumber(options?: VinLookupArgs): UseVinLookupFromRegNumber {
  const [mutation, result] = useMutation<VinLookupFromRegNumberMutation>(
    VIN_LOOKUP_MUTATION,
    options,
  )

  return [mutation, result]
}
