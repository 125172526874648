import { Alert, Button, LoadingBolt, Modal, Typography } from '@electro/shared-ui-components'
import { useUserQuery } from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useMemo } from 'react'

export const OnboardingModalScreens = () => {
  const { t } = useTranslation('common')
  const { push } = useRouter()
  const { data, loading, error } = useUserQuery()

  const newUser = useMemo(
    () => !data?.me?.hasElectricJuiceCard && !data?.me?.hasValidPaymentInstruction,
    [data?.me],
  )

  /* First propmt displayed - either for new sign-ups or existing users that haven't started the onboarding */
  const addPaymentMethodPrompt = useMemo(
    () => !data?.me?.hasValidPaymentInstruction || newUser,
    [data?.me, newUser],
  )

  /* Second prompt displayed - for users that haven't finished the onboarding (yes payment method, no Electrocard ordered) */
  const orderElectrocardPrompt = useMemo(
    () => data?.me?.hasValidPaymentInstruction && !data?.me?.hasElectricJuiceCard,
    [data?.me],
  )

  return (
    <>
      <Modal.CloseButton />
      {loading ? <LoadingBolt subtitle={t('utility.loading_message')} className="my-16" /> : null}
      {error && !loading ? <Alert variant="error">{error.message}</Alert> : null}
      {!loading && !error && data ? (
        <>
          {addPaymentMethodPrompt ? (
            <Modal.Header>{t('onboarding_modal.actions.add_payment_method.title')}</Modal.Header>
          ) : null}
          {orderElectrocardPrompt ? (
            <Modal.Header>{t('onboarding_modal.actions.order_electrocard.title')}</Modal.Header>
          ) : null}
          <Modal.Body>
            <div className="justify-center flex flex-col text-center items-center">
              <div className="block w-32 mb-2 md:w-48">
                {addPaymentMethodPrompt ? (
                  <Image
                    src="/images/payment-cards-octo.png"
                    alt="Illustration of a credit card and a debit card"
                    width="258"
                    height="308"
                  />
                ) : null}
                {orderElectrocardPrompt ? (
                  <Image
                    src="/images/cards/electrocards.png"
                    alt="Illustration of two Electrocards"
                    width="258"
                    height="308"
                  />
                ) : null}
              </div>
              <div className="py-6">
                {addPaymentMethodPrompt ? (
                  <Typography variant="body">
                    {t('onboarding_modal.actions.add_payment_method.description')}
                  </Typography>
                ) : null}
                {orderElectrocardPrompt ? (
                  <Typography variant="body">
                    {t('onboarding_modal.actions.order_electrocard.description')}
                  </Typography>
                ) : null}
              </div>
            </div>
          </Modal.Body>

          <Modal.Actions stacked>
            {addPaymentMethodPrompt ? (
              <Button
                fullWidth
                data-testid="add-payment-card-button"
                onClick={() => push('/user/account/payment-methods')}
              >
                {t('onboarding_modal.actions.add_payment_method.cta')}
              </Button>
            ) : null}
            {orderElectrocardPrompt ? (
              <Button
                fullWidth
                data-testid="add-electro-card-button"
                onClick={() => push('/user/account/electrocards')}
              >
                {t('onboarding_modal.actions.order_electrocard.cta')}
              </Button>
            ) : null}
          </Modal.Actions>
        </>
      ) : null}
    </>
  )
}
