import {
  FiltersAccessTypes,
  FiltersChargeSpeedSlider,
  FiltersSocketTypes,
  FiltersMinimumChargePoints,
  FiltersOperatorList,
  FiltersCompatibleToggle,
  FiltersStickyButtons,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/Filters/components'

const styles = {
  breakpoint: 'border-secondary',
}

export const FiltersPanelContent = () => (
  <>
    <FiltersCompatibleToggle />

    <FiltersOperatorList />
    <hr className={styles.breakpoint} />

    <FiltersSocketTypes />
    <hr className={styles.breakpoint} />

    <FiltersChargeSpeedSlider />
    <hr className={styles.breakpoint} />

    <FiltersMinimumChargePoints />
    <hr className={styles.breakpoint} />

    <FiltersAccessTypes />

    <FiltersStickyButtons />
  </>
)
