import { tw } from '@electro/shared/utils/tailwind-merge'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ForwardedRef, forwardRef, HTMLAttributes, MouseEventHandler, useState } from 'react'
import { IconButton } from '@electro/shared-ui-components'

export const VibrantTagColours = [
  'brown',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
  'red',
]
export const BoringTagColours = ['lightGrey', 'grey']
export const AllTagColours = [...VibrantTagColours, ...BoringTagColours]

export type TagColours =
  | 'lightGrey'
  | 'grey'
  | 'brown'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'purple'
  | 'pink'
  | 'red'
  | 'tertiary'
  | 'secondary'
  | 'primary'
export type TagVariants = 'filled' | 'outlined'
export type TagSizes = 'xs' | 'sm' | 'md'

const styles = {
  root: tw(
    'flex justify-center items-center w-fit px-2 cursor-default',
    'rounded-full ring-1 ring-inset',
    'font-medium text-center leading-4',
  ),
  colour: {
    primary: 'bg-primary ring-primary/50 text-primary',
    secondary: 'bg-secondary ring-secondary/50 text-secondary',
    tertiary: 'bg-tertiary ring-tertiary/50 text-tertiary',
    lightGrey: 'bg-slate-300 ring-slate-300/50 text-slate-200',
    grey: 'bg-gray-400 ring-gray-400/50 text-gray-400',
    brown: 'bg-amber-700 ring-amber-700/50 text-amber-600',
    orange: 'bg-orange-400 ring-orange-400/50 text-orange-400',
    yellow: 'bg-yellow-400 ring-yellow-400/50 text-yellow-400',
    green: 'bg-green-400 ring-green-400/50 text-green-400',
    blue: 'bg-blue-400 ring-blue-400/50 text-blue-400',
    purple: 'bg-purple-400 ring-purple-400/50 text-purple-400',
    pink: 'bg-pink-400 ring-pink-400/50 text-pink-400',
    red: 'bg-red-500 ring-red-400/50 text-red-400',
  },
  size: {
    xs: 'text-xs font-normal py-px',
    sm: 'text-sm m-1',
    md: 'text-sm m-1 py-1',
  },
  variant: {
    filled: 'bg-opacity-100 text-black font-normal',
    outlined: 'bg-opacity-20',
  },
  closeButton: '-m-2 ml-0 z-20 text-inherit hover:text-inherit hover:opacity-60',
  onClose: 'hidden',
}
export const tagStyles = styles

interface Props extends HTMLAttributes<HTMLDivElement> {
  label: string
  colour?: TagColours
  variant?: TagVariants
  size?: TagSizes
  onClose?: MouseEventHandler<HTMLButtonElement>
}

export const Tag = forwardRef(
  (
    {
      label,
      variant = 'filled',
      size = 'sm',
      onClose,
      colour = 'lightGrey',
      className,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [open, setOpen] = useState<boolean>(true)

    return (
      <div
        ref={ref}
        className={tw({
          [styles.root]: true,
          [styles.size[size]]: true,
          [styles.colour[colour]]: true,
          [styles.variant[variant]]: true,
          [className]: !!className,
          [styles.onClose]: !open,
        })}
        {...rest}
      >
        {label}

        {onClose ? (
          <IconButton
            onClick={(e) => {
              onClose(e)
              setOpen(false)
            }}
            className={styles.closeButton}
            size="xs"
          >
            <XMarkIcon />
          </IconButton>
        ) : null}
      </div>
    )
  },
)
