import useTranslation from 'next-translate/useTranslation'
import { SwitchToggle } from '@electro/shared-ui-components'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersEnum } from '@electro/consumersite/src/components/Map/types'

const styles = {
  root: 'flex items-center justify-between mx-2',
  text: 'leading-4',
}

const { ELECTROVERSE_COMPATIBLE } = MapFiltersEnum

/** Switch to show/hide operators not partnered with Electroverse */
export const FiltersCompatibleToggle = () => {
  const { t } = useTranslation('common')
  const [{ currentFilters }, { updateFilters }] = useMapFilters()

  return (
    <span className={styles.root} data-testid="filters-compatible-toggle">
      <h4 className={styles.text}>{t('map.filters.electroverse_compatible')}</h4>

      <SwitchToggle
        checked={currentFilters[ELECTROVERSE_COMPATIBLE]}
        onChange={() =>
          updateFilters({ [ELECTROVERSE_COMPATIBLE]: !currentFilters[ELECTROVERSE_COMPATIBLE] })
        }
      />
    </span>
  )
}
