import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { mapCapabilityToIcon } from '@electro/consumersite/src/components/Map/helpers'
import { MapFiltersEnum } from '@electro/consumersite/src/components/Map/types'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { Capabilities } from '@electro/consumersite/generated/graphql'
import { Button } from '@electro/shared-ui-components'
// eslint-disable-next-line import/no-unresolved
import { mapFiltersOptions } from '@electro/consumersite/generated/mapBuildData'

const styles = {
  heading: 'mb-2',
  container: 'flex flex-wrap gap-2',
  socket: {
    root: 'flex flex-col w-full mx-1',
    button: {
      root: 'flex justify-start items-center w-full h-8 rounded-lg bg-base/70',
      active: 'bg-secondary/60 hover:bg-secondary/80',
      icon: 'w-5 h-5 mr-2',
    },
    label: 'text-[11px] font-medium tracking-wide mb-0',
  },
}

const { ACCESS } = MapFiltersEnum

const { Card, Contactless, EjnApp, PlugAndCharge } = Capabilities

const ALLOWED_CAPABILITIES = [Card, Contactless, EjnApp, PlugAndCharge]

/** Filters for specific capabilities relating to charging access methods */
export const FiltersAccessTypes = () => {
  const { t } = useTranslation('common')
  const [{ currentFilters }, { updateFilters }] = useMapFilters()

  return (
    <div data-testid="filters-access-types">
      <h4 className={styles.heading}>{t('map.filters.subtitles.access')}</h4>

      <div className={styles.container}>
        {mapFiltersOptions.capabilities.map((accessType: Capabilities) => {
          if (!ALLOWED_CAPABILITIES.includes(accessType)) return null

          const { icon, label } = mapCapabilityToIcon(accessType)
          const isActive = currentFilters[ACCESS].includes(accessType)

          /** Adds/removes the access type from the current filters */
          const onButtonClick = () => {
            const updatedAccessTypes = isActive
              ? currentFilters[ACCESS].filter((capability) => capability !== accessType)
              : [...currentFilters[ACCESS], accessType]

            updateFilters({ [ACCESS]: updatedAccessTypes })
          }

          return (
            <div key={accessType} className={styles.socket.root}>
              <Button
                size="2xs"
                onClick={onButtonClick}
                overrideDisabledInteractivity
                className={tw({
                  [styles.socket.button.root]: true,
                  [styles.socket.button.active]: isActive,
                })}
              >
                {icon}

                <p className={styles.socket.label}>{t(label)}</p>
              </Button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
