import { tw } from '@electro/shared/utils/tailwind-merge'
import { useRouter } from 'next/router'
import { format } from 'date-fns'
import Image from 'next/image'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import { roundDecimalNumber } from '@electro/shared/utils/roundDecimalNumber'
import { Card, Typography } from '@electro/shared-ui-components'
import { useManageOutstandingPayments } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/hooks'

const styles = {
  userDebtWarning: {
    icon: 'absolute top-0.5 left-0.5 w-5 h-5 lg:top-2 lg:left-2',
    escalated: 'bg-escalated-warning',
    issued: 'bg-issued-warning',
  },
}

const getFirstTwoWords = (str: string) => {
  const words = str.split(' ')
  return words.slice(0, 2).join(' ').replace(/,\s*$/, '')
}

export const OutstandingPaymentDetails = ({ payment }) => {
  const { locale } = useRouter()
  const { userHasEscalatedDebt, userHasIssuedDebt } = useManageOutstandingPayments()
  const { paymentSource } = payment

  if (paymentSource !== undefined) {
    const { pk, periodStart, kwhUsed, operator, location, receiptCurrency } = paymentSource
    const { isoCode } = receiptCurrency
    const transactionTime = format(new Date(periodStart), 'HH:mm')
    const formattedKwhUsed = roundDecimalNumber(kwhUsed)

    const receiptAmount = formatPriceToLocalisedValue({
      price: payment?.grossAmount,
      currency: isoCode,
      locale,
      isFloat: false,
    })

    return (
      <Card
        key={pk}
        frosted
        density="high"
        className="flex justify-between w-full mb-4 lg:mb-0 lg:mr-4"
      >
        <div className="flex lg:min-w-[180px]">
          <div className="mr-2">
            {userHasEscalatedDebt || userHasIssuedDebt ? (
              <div
                data-testid="warning-status-icon"
                className={tw({
                  [styles.userDebtWarning.icon]: true,
                  [styles.userDebtWarning.escalated]: userHasEscalatedDebt,
                  [styles.userDebtWarning.issued]: userHasIssuedDebt,
                })}
              />
            ) : null}
            <Image
              className="rounded-md"
              src={operator?.logoDark}
              alt="logo operator"
              height={40}
              width={40}
            />
          </div>
          <div className="mr-2">
            <Typography variant="small">{getFirstTwoWords(location?.address)}</Typography>
            <Typography variant="small">
              {transactionTime} / {`${formattedKwhUsed}kWh`}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col items-end shrink-0">
          <Typography variant="small" className="font-medium">
            {receiptAmount}
          </Typography>

          <Typography className="text-xs" variant="small">
            {isoCode}
          </Typography>
        </div>
      </Card>
    )
  }
  return null
}
