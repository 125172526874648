export const EJN_AUTH_TOKEN_KEY = 'ejnat'
export const EJN_REFRESH_TOKEN_KEY = 'ejnrt'
export const EJN_SIGNUP_SOURCE_ATTRIBUTION_KEY = 'ejnssa'
export const FLEETS_AUTH_TOKEN_KEY = 'fleets-auth-token'
export const FLEETS_REFRESH_TOKEN_KEY = 'fleets-ref-tok'
export const FLEETS_FIRST_TIME_USER_WELCOME = 'fleets-fist-time-user-welcome'
export const MAP_SEARCH_HISTORY = 'map-search-history'
export const ACTIVE_USER_VEHICLE = 'activeUserVehicle'
export const KRAKEN_OAUTH_CHALLENGE_CODE = 'krakenChallenge'
export const FLEETS_BUSINESS_INFO_TYPEFORM = 'fleets-business-info-typeform'
export const FEEDBACK_SUBMISSION_DATE = 'feedbackSubmissionDate'
