import { Formik } from 'formik'
import * as Sentry from '@sentry/nextjs'
import * as Yup from 'yup'
import useTranslation from 'next-translate/useTranslation'
import { Alert, Button, Input } from '@electro/shared-ui-components'
import { useMutation, gql } from '@apollo/client'
import { useState } from 'react'

interface RequestFleetsDemoEmailProps {
  onSuccess: ({ email }: { email: string }) => void
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('form.validation.first_name').nullable(),
  lastName: Yup.string().required('form.validation.last_name').nullable(),
  businessName: Yup.string().required('form.validation.business_name').nullable(),
  email: Yup.string()
    .email('form.validation.email_address')
    .required('form.validation.email_address')
    .nullable(),
})

/**
 * IMPORTANT:
 * This cross domain mutation (requestFleetsDemoEmail) is used outside of
 * graphQl codegen because it will eventually be moved out of the consumer schema
 * and into the fleets schema.
 *
 * It will send an email to the user with a link in it to fire up a demo session
 * for fleets.
 *
 * If it is added to the codegen, it will break future builds when it is removed
 * from the consumer schema.
 */

const REQUEST_FLEETS_DEMO_EMAIL_MUTATION = gql`
  mutation fleetsRequestFleetDemoEmail(
    $email: EmailType!
    $businessName: String!
    $firstName: String!
    $lastName: String!
  ) {
    fleetsRequestFleetDemoEmail(
      email: $email
      businessName: $businessName
      firstName: $firstName
      lastName: $lastName
    ) {
      success
    }
  }
`

export const RequestFleetsDemoEmail = ({ onSuccess }: RequestFleetsDemoEmailProps) => {
  const { t } = useTranslation('common')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [hasClickedSubmit, setHasClickedSubmit] = useState<boolean>(false)
  const [requestFleetsDemoEmail, { loading }] = useMutation(REQUEST_FLEETS_DEMO_EMAIL_MUTATION, {
    context: {
      uri: process.env.NEXT_PUBLIC_FLEETS_SANDBOX_GQL_ENDPOINT,
      headers: {
        'Api-key': `${process.env.NEXT_PUBLIC_FLEETS_SANDBOX_API_KEY}`,
        'Accept-Encoding': 'gzip, deflate, br',
        source: 'web',
      },
    },
  })
  const handleRequestDemoEmailSubmit = async ({ email, firstName, lastName, businessName }) => {
    setErrorMessage('')

    try {
      const { data } = await requestFleetsDemoEmail({
        variables: {
          email,
          firstName,
          lastName,
          businessName,
        },
      })
      if (data?.fleetsRequestFleetDemoEmail?.success) {
        onSuccess({ email })
      }
    } catch (err) {
      Sentry.captureException(err)
      setErrorMessage(err.message)
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        businessName: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleRequestDemoEmailSubmit}
      validateOnBlur={hasClickedSubmit}
      validateOnChange={hasClickedSubmit}
    >
      {({ handleBlur, values, errors, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit} className="w-full">
          <Input
            fullWidth
            placeholder={t('form.placeholder.email_placeholder')}
            label={t('form.field.email_address')}
            name="email"
            value={values.email || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            errorMessage={t(errors.email as string)}
            disabled={loading}
            required
          />
          <Input
            fullWidth
            placeholder={t('form.placeholder.first_name_placeholder')}
            label={t('form.field.first_name')}
            name="firstName"
            value={values.firstName || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            errorMessage={t(errors.firstName as string)}
            disabled={loading}
            required
          />
          <Input
            fullWidth
            placeholder={t('form.placeholder.last_name_placeholder')}
            label={t('form.field.last_name')}
            name="lastName"
            value={values.lastName || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            errorMessage={t(errors.lastName as string)}
            disabled={loading}
            required
          />
          <Input
            fullWidth
            placeholder={t('form.placeholder.business_name_placeholder')}
            label={t('form.field.business_name')}
            name="businessName"
            value={values.businessName || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            errorMessage={t(errors.businessName as string)}
            disabled={loading}
            required
          />
          {errorMessage ? (
            <Alert className="mb-4" variant="error">
              {errorMessage}
            </Alert>
          ) : null}
          <Button
            className="mt-8 mb-6"
            onClick={() => setHasClickedSubmit(true)}
            fullWidth
            type="submit"
            loading={loading}
          >
            {t('utility.button.lets_go')}
          </Button>
        </form>
      )}
    </Formik>
  )
}
