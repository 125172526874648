import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Menu } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { ClockIcon, MagnifyingGlassCircleIcon } from '@heroicons/react/20/solid'
import { useMapSearch } from '@electro/consumersite/src/components/Map/hooks'
import { PlaceDetails } from '@electro/consumersite/src/components/Map/helpers'
import { mapFeatureTypeToIcon } from '@electro/consumersite/src/components/Map/utils/mapFeatureTypeToIcon'
import { CurrentLocationMenuItem } from '@electro/consumersite/src/components/Map/components/MapLocationSearch/components'
import { searchbarSuggestions } from '@electro/consumersite/generated/mapStaticData'

const styles = {
  menuItems: 'outline-none',
  title: 'text-xs ml-2 mt-0',
  button: {
    root: tw(
      'flex items-center flex-grow gap-x-2',
      'rounded-lg w-full min-h-10',
      'pt-1 pb-1.5 pl-1 pr-2 mt-0.5 last:-mb-3',
    ),
    buttonActive: 'bg-secondary/20 ring-secondary ring-2',
    featureTypeIcon: '[&>svg]:w-5 [&>svg]:h-5 [&>svg]:max-w-5',
    historyClockIcon: 'absolute text-primary bg-base rounded-full ml-2 mt-3.5 w-3.5',
    text: 'text-left leading-5 [&_*]:truncate min-w-0', // min-w-0 required for truncate in flex
    subtext: 'text-white/70 text-xs -mt-1 min-h-1',
  },
}
export const menuItemsStyles = styles

/** Show a users recent history in the searchbar.
 *  Also shows search suggestions if the history is empty. */
export const HistoryMenuItems = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const [{ showSearchHistory, searchHistory }, { handleLocationSearchPlaceSelection }] =
    useMapSearch()
  const [searchSuggestions, setSearchSuggestions] = useState<PlaceDetails[]>()

  /** Sends the location place details to the map, identifying the search result type */
  const onSelectHistory = (place: PlaceDetails) =>
    handleLocationSearchPlaceSelection({ place, searchType: 'history' })

  /** Sends the location place details to the map, identifying the search result type */
  const onSelectSuggestion = (place: PlaceDetails) =>
    handleLocationSearchPlaceSelection({ place, searchType: 'suggestion' })

  /** Determine how many suggestions to show based on the number of history items in state */
  useEffect(() => {
    if (searchbarSuggestions[locale]) {
      const updatedSuggestions = searchbarSuggestions[locale]
        .filter(Boolean)
        .filter((item) => !searchHistory.some((history) => history.id === item.id))

      while (updatedSuggestions.length + searchHistory.length > 4) updatedSuggestions.pop()
      setSearchSuggestions(updatedSuggestions)
    }
  }, [locale, searchHistory])

  return (
    <Menu.Items ref={ref} className={styles.menuItems} data-testid="history-menu-items">
      <CurrentLocationMenuItem show={showSearchHistory} />
      {showSearchHistory ? (
        <>
          {searchHistory.length > 0 ? (
            <>
              <div className={styles.title}>{t('map.search.recent_searches_title')}</div>
              {searchHistory.map((item: PlaceDetails) => (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <button
                      data-testid="history-menu-item"
                      onClick={() => onSelectHistory(item)}
                      onKeyDown={() => onSelectHistory(item)}
                      className={tw({
                        [styles.button.root]: true,
                        [styles.button.buttonActive]: active,
                      })}
                    >
                      <span className={styles.button.featureTypeIcon}>
                        {mapFeatureTypeToIcon(item.type)}
                      </span>

                      <ClockIcon className={styles.button.historyClockIcon} />

                      <div className={styles.button.text}>
                        <div>{item.name}</div>
                        <div className={styles.button.subtext}>{item.subtext}</div>
                      </div>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </>
          ) : null}

          {searchHistory.length < 4 && searchSuggestions?.length > 0 ? (
            <>
              <div className={styles.title}>{t('map.search.suggestions.title')}</div>
              {searchSuggestions.map((item: PlaceDetails) => (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <button
                      data-testid="suggestion-menu-item"
                      onClick={() => onSelectSuggestion(item)}
                      onKeyDown={() => onSelectSuggestion(item)}
                      className={tw({
                        [styles.button.root]: true,
                        [styles.button.buttonActive]: active,
                      })}
                    >
                      <span className={styles.button.featureTypeIcon}>
                        {mapFeatureTypeToIcon(item.type)}
                      </span>

                      <MagnifyingGlassCircleIcon
                        className={tw(styles.button.historyClockIcon, 'text-tertiary')}
                      />

                      <div className={styles.button.text}>
                        <div>{item.name}</div>
                        <div className={styles.button.subtext}>{item.subtext}</div>
                      </div>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </>
          ) : null}
        </>
      ) : null}
    </Menu.Items>
  )
})
