import Image from 'next/image'
import { useState } from 'react'
import confetti from 'canvas-confetti'
import { Transition } from '@headlessui/react'
import { useLocalStorage, useMount } from 'react-use'
import { Button, ButtonProps } from '@electro/shared-ui-components'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { FEEDBACK_SUBMISSION_DATE } from '@electro/shared/constants'
import useTranslation from 'next-translate/useTranslation'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  root: 'flex flex-col items-center mb-4 text-center text-sm',
  subtitle: 'font-semibold',
  container: {
    root: 'min-h-[42px]',
    buttons: 'flex gap-x-3 pointer-events-none [&>*]:pointer-events-auto',
    submittedText: {
      root: '[&>p]:text-action-success [&>p]:mb-0',
      subText: 'text-xs',
    },
  },
  disabledText: 'text-xs mt-4 mb-0 text-action-warning',
  moodButtons: {
    all: 'p-[5px] border-[3px]',
    face: {
      1: 'border-[#FF0074] hover:bg-[#FF0074]/30',
      2: 'border-[#FF7A00] hover:bg-[#FF7A00]/30',
      3: 'border-[#FFDA6F] hover:bg-[#FFDA6F]/30',
      4: 'border-[#15EBB2] hover:bg-[#15EBB2]/30',
      5: 'border-[#60F0F8] hover:bg-[#60F0F8]/30',
    },
  },
}

/** Allows users to vote 1-5 on their happiness with the current experience */
export const FeedbackMoodLevel = () => {
  const { t } = useTranslation('common')
  const [feedbackSubmissionDate, setFeedbackSubmissionDate] =
    useLocalStorage<string>(FEEDBACK_SUBMISSION_DATE)

  const [showButtons, setShowButtons] = useState<boolean>(true)
  const [showThanks, setShowThanks] = useState<boolean>(false)
  const [analyticsDisabled, setAnalyticsDisabled] = useState<boolean>(false)

  useMount(() => {
    // Displays a message if we're unable to take their rating due to analytics being disabled
    setAnalyticsDisabled(!window.document.cookie.includes('analytics:yes'))

    // Checks if the user submitted a rating within the last month
    if (feedbackSubmissionDate) {
      const submissionDate = new Date(parseInt(feedbackSubmissionDate, 10))
      const isNextMonth = new Date().getMonth() > submissionDate.getMonth()
      const isNextYear = new Date().getFullYear() > submissionDate.getFullYear()

      if (isNextMonth || isNextYear) {
        setShowButtons(false)
        setShowThanks(true)
      }
    }
  })

  const onButtonClick = () => {
    setShowButtons(false)
    setFeedbackSubmissionDate(Date.now().toString())
    setTimeout(() => setShowThanks(true), 400)
  }

  return (
    <div className={styles.root}>
      <p className={styles.subtitle}>{t('map.feedback.mood_level.rate_your_experience')}</p>

      <div className={styles.container.root}>
        <Transition
          show={showButtons}
          onClick={onButtonClick}
          className={styles.container.buttons}
          leave="transition ease-in-out duration-300"
          leaveTo="translate-y-1/2 opacity-0"
        >
          <MoodButton moodLevel={1} disabled={analyticsDisabled} />
          <MoodButton moodLevel={2} disabled={analyticsDisabled} />
          <MoodButton moodLevel={3} disabled={analyticsDisabled} />
          <MoodButton moodLevel={4} disabled={analyticsDisabled} />
          <MoodButton moodLevel={5} disabled={analyticsDisabled} />
        </Transition>

        <Transition
          show={showThanks}
          className={styles.container.submittedText.root}
          enter="transition ease-in-out duration-300"
          enterFrom="-translate-y-full opacity-0"
        >
          <p>{t('map.feedback.mood_level.submitted.thanks')}</p>
          <p className={styles.container.submittedText.subText}>
            {t('map.feedback.mood_level.submitted.another_rating')}
          </p>
        </Transition>
      </div>

      {analyticsDisabled && !showThanks ? (
        <p className={styles.disabledText}>{t('map.feedback.mood_level.disabled')}</p>
      ) : null}
    </div>
  )
}
const CONFETTI_DEFAULTS = { angle: 60, origin: { x: 0, y: 1 }, disableForReducedMotion: true }

const MOOD_MAP = {
  1: { img: '/images/mood-faces/sad.svg', confettiProps: {} },
  2: { img: '/images/mood-faces/disappointed.svg', confettiProps: {} },
  3: { img: '/images/mood-faces/neutral.svg', confettiProps: {} },
  4: { img: '/images/mood-faces/happy.svg', confettiProps: { spread: 50, particleCount: 75 } },
  5: { img: '/images/mood-faces/ecstatic.svg', confettiProps: { spread: 70, particleCount: 100 } },
}

/** Button representing various happiness levels which triggers an analytics event */
const MoodButton = ({ moodLevel, ...props }: { moodLevel: number } & ButtonProps) => {
  const { img, confettiProps } = MOOD_MAP[moodLevel]

  const onMoodClick = () => {
    GTM.feedbackMoodLevel({ moodLevel })
    if ([5, 4].includes(moodLevel)) {
      setTimeout(() => confetti({ ...CONFETTI_DEFAULTS, ...confettiProps }), 500)
    }
  }

  return (
    <Button
      variant="outline"
      onClick={onMoodClick}
      data-testid="mood-button"
      className={tw(styles.moodButtons.all, styles.moodButtons.face[moodLevel])}
      {...props}
    >
      <Image src={img} alt={`Rating: ${moodLevel}`} width={26} height={26} />
    </Button>
  )
}
