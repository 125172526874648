import { Locales, Locale } from '@electro/shared/types/locales'
import { mapLocaleToCountryDetails } from '@electro/shared/utils/mapLocaleToCountryDetails'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'
import { Menu } from '@electro/shared-ui-components'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { devOrPreviewEnv } from '@electro/shared/utils/isEnv'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  switcher: {
    button: tw(
      'rounded-full lg:shadow-lg !p-0 h-10 w-10 mt-1 border-2 border-secondary',
      'overflow-hidden relative',
      '[&>div]:relative [&>div]:h-full [&>div]:w-full [&>div]:flex [&>div]:items-center [&>div]:justify-center',
      'flex flex-col items-center justify-center',
    ),
    flag: 'h-full w-full object-cover',
    active: 'text-white ',
    list: 'mt-4 ml-4 lg:ml-0 lg:mt-1 lg:mr-1',
  },
}

const locales = [Locales.EN, Locales.FR, Locales.ES, Locales.IT, Locales.DE]

// TODO: Remove this when PT_PT is ready for production
if (devOrPreviewEnv) locales.push(Locales.PT_PT)

export const LanguageSelect = () => {
  const { locale, asPath } = useRouter()
  const getLocaleAlpha2Code = useCallback(
    (loc: Locale): string => mapLocaleToCountryDetails(loc).alpha2 || '',
    [],
  )

  /**
   * Not all regions have equivalent pages! To work around this
   * when a user switches language. The only page they will stay
   * on is the map page. If they are on any other page they will be
   * sent to the homepage. This is to prevent users from seeing bilingual
   * page content or 404 errors.
   */
  const handleSwitchLocale = useCallback(
    (loc: Locale) => () => {
      const nextPath = `/${loc}${asPath.includes('/map') ? asPath : '/'}`
      const sanitizedDefaultPaths = nextPath.replace(`${Locales.EN}/`, '')
      window.location.href = `${window.location.origin}${sanitizedDefaultPaths}`
    },
    [asPath],
  )

  return (
    <div data-testid="language-select-menu">
      <Menu>
        <Menu.Button
          chevron={false}
          className={styles.switcher.button}
          activeClassName={styles.switcher.active}
          chevronActiveClassName={styles.switcher.active}
        >
          <FlagIcon
            code={getLocaleAlpha2Code(locale as Locale) as FlagIconCode}
            alt={mapLocaleToCountryDetails(locale).alpha3}
            className={styles.switcher.flag}
            width={50}
            height={50}
          />
          <span className="sr-only">{getLocaleAlpha2Code(locale as Locale)}</span>
        </Menu.Button>
        <Menu.MenuItemList className={styles.switcher.list}>
          {locales.map((loc) =>
            loc !== locale ? (
              <Menu.MenuItem key={loc} onSelect={handleSwitchLocale(loc)}>
                <div className="!p-0 h-6 w-6 rounded-full overflow-hidden">
                  <FlagIcon
                    code={getLocaleAlpha2Code(loc) as FlagIconCode}
                    className={styles.switcher.flag}
                    size={30}
                  />
                </div>
                <span data-testid="locale-menu-item" className="ml-2">
                  {getLocaleAlpha2Code(loc)}
                </span>
              </Menu.MenuItem>
            ) : null,
          )}
        </Menu.MenuItemList>
      </Menu>
    </div>
  )
}
