import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from 'react'
import {
  ModalScreensEnum,
  OutstandingPayments,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/ManageOutstandingPayments.types'
import {
  DebtWarningStatus,
  UnsettledPaymentsQuery,
  useUnsettledPaymentsQuery,
  useUserQuery,
} from '@electro/consumersite/generated/graphql'
import { ApolloError } from '@apollo/client'

interface ManageOutstandingPaymentsProviderProps {
  children: ReactNode
}

interface UseManageOutstadingPaymentsContextReturnType {
  data: UnsettledPaymentsQuery
  loading: boolean
  error: ApolloError
  refetchPayments: () => void
  refetchUser: () => void
  activeModalScreen: ModalScreensEnum
  setActiveModalScreen: Dispatch<SetStateAction<ModalScreensEnum>>
  errorMessage: string | null
  setErrorMessage: Dispatch<SetStateAction<string | null>>
  closeModalScreen: () => void
  userHasEscalatedDebt: boolean
  userHasIssuedDebt: boolean
  payment: OutstandingPayments['payment']
  setPayment: Dispatch<SetStateAction<OutstandingPayments['payment']>>
}

const useManageOutstandingPaymentsProvider = (): UseManageOutstadingPaymentsContextReturnType => {
  const [activeModalScreen, setActiveModalScreen] = useState(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [payment, setPayment] = useState(null)
  const { data: userData, refetch: refetchUser } = useUserQuery({
    fetchPolicy: 'network-only',
  })
  const {
    data,
    loading,
    error,
    refetch: refetchPayments,
  } = useUnsettledPaymentsQuery({
    fetchPolicy: 'network-only',
  })

  const closeModalScreen = useCallback(() => {
    refetchPayments()
    refetchUser() // this is to make sure we refetch the user data with any changes on the DebtStatus
    setActiveModalScreen(null)
    setPayment(null)
  }, [refetchPayments, refetchUser])

  const userHasIssuedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Issued,
    [userData],
  )
  const userHasEscalatedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Escalated,
    [userData],
  )

  return {
    data,
    loading,
    error,
    refetchPayments,
    refetchUser,
    activeModalScreen,
    setActiveModalScreen,
    errorMessage,
    setErrorMessage,
    closeModalScreen,
    userHasEscalatedDebt,
    userHasIssuedDebt,
    payment,
    setPayment,
  }
}

const ManageOutstandingPaymentsContext =
  createContext<UseManageOutstadingPaymentsContextReturnType>(null)

const useManageOutstandingPayments = () => {
  const context = useContext(ManageOutstandingPaymentsContext)
  if (!context) {
    throw new Error(
      `useManageOutstandingPayments() cannot be used outside the context of <ManageOutstandingPaymentsProvider/> `,
    )
  }
  return context
}

const ManageOutstandingPaymentsProvider = ({
  children,
}: ManageOutstandingPaymentsProviderProps) => {
  const context = useManageOutstandingPaymentsProvider()
  return (
    <ManageOutstandingPaymentsContext.Provider value={context}>
      {children}
    </ManageOutstandingPaymentsContext.Provider>
  )
}

export { ManageOutstandingPaymentsProvider, useManageOutstandingPayments }
