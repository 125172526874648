import { Modal } from '@electro/shared-ui-components'
import * as Sentry from '@sentry/nextjs'
import { SHOW_USER_ONBOARDING_MODAL_TRIGGER_PARAM } from '@electro/shared/constants'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useUserLazyQuery } from '@electro/consumersite/generated/graphql'
import { useAuth } from '@electro/consumersite/src/hooks'
import { OnboardingModalScreens } from './components'

/**
 * The parameter that triggers this modal to show is set to the URL
 * during the login & sign up process. There we check that the
 * user has a valid payment instruction and electric juice card.
 *
 * If they don't have either a payment method or electric juice card, we add the
 * 'onboarding=true' param to the URL.
 *
 * This component checks for the presence of that param, and if it's there,
 * shows the modal.
 */
export const OnboardingModal = () => {
  const router = useRouter()
  const [show, setShow] = useState(false)
  const [{ session }] = useAuth()

  const [fetchUserData] = useUserLazyQuery({
    onError(err) {
      Sentry.captureException(err)
    },
  })

  const handleCloseModal = () => {
    setShow(false)
    /**
     * Using reduce over object destructuring so we can keep the
     * query params as a constant.
     */
    const query = Object.keys(router.query).reduce((acc, key) => {
      if (key !== SHOW_USER_ONBOARDING_MODAL_TRIGGER_PARAM) {
        acc[key] = router.query[key]
      }
      return acc
    }, {})
    router.replace({ query })
  }

  /**
   * We only want to show this if users are logged in and
   * the onboarding trigger param is set to true.
   *
   * We also don't want to show the onboarding modal to users who are
   * Fleets admins or Drivers. This is because they can't
   * add a payment method or order an Electrocard.
   */
  useEffect(() => {
    async function checkIsFleetsUser(callback) {
      try {
        const { data } = await fetchUserData()
        const isFleetsUser = data?.me?.activeAccount?.entityDomain === 'FLEETS'
        callback({ isFleetsUser })
      } catch (err) {
        Sentry.captureException(err)
      }
    }
    if (session) {
      checkIsFleetsUser(({ isFleetsUser }) =>
        !isFleetsUser
          ? setShow(router.query[SHOW_USER_ONBOARDING_MODAL_TRIGGER_PARAM] === 'true')
          : null,
      )
    }
  }, [fetchUserData, router, session])

  return show ? (
    <Modal data-testid="onboarding-modal" open onClose={handleCloseModal}>
      <OnboardingModalScreens />
    </Modal>
  ) : null
}
