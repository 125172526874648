import { ForwardedRef, forwardRef, HTMLAttributes } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

enum Densities {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NONE = 'none',
}

type DensityType = `${Densities}`

/**
 * The props frosted and frostedDark are mutually exclusive
 */
type FrostedOptionallyExclusive =
  | { frosted: boolean; frostedDark?: never }
  | { frosted?: never; frostedDark: boolean }
  | { frosted?: never; frostedDark?: never }

interface BaseProps extends HTMLAttributes<HTMLDivElement> {
  children: any
  className?: string
  density?: DensityType
  variant?: keyof typeof styles.variant
  transparent?: boolean
}

type CardProps = FrostedOptionallyExclusive & BaseProps

const { HIGH, MEDIUM, LOW, NONE } = Densities

const styles = {
  root: 'rounded-2xl shadow-xl border-2  bg-base',
  transparent: 'bg-transparent',
  frosted: 'backdrop-blur bg-secondary/20',
  frostedDark: 'backdrop-blur bg-base/80',
  variant: {
    error: 'border-action-danger',
    warning: 'border-action-warning',
    success: 'border-action-success',
    default: 'border-secondary',
  },
  density: {
    [LOW]: 'lg:p-12 p-6',
    [MEDIUM]: 'lg:p-8 p-4',
    [HIGH]: 'lg:p-4 p-2',
    [NONE]: 'p-0',
  },
}

export const Card = forwardRef(
  (
    {
      children,
      className,
      frosted,
      frostedDark,
      transparent,
      density = MEDIUM,
      variant = 'default',
      ...rest
    }: CardProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      ref={ref}
      className={tw({
        [styles.root]: true,
        [styles.density[HIGH]]: density === HIGH,
        [styles.density[MEDIUM]]: density === MEDIUM,
        [styles.density[LOW]]: density === LOW,
        [styles.density[NONE]]: density === NONE,
        [styles.frosted]: frosted,
        [styles.frostedDark]: frostedDark,
        [styles.transparent]: transparent,
        [styles.variant[variant]]: !!variant,
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </div>
  ),
)
