import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { MyEVsIcon } from '@electro/consumersite/src/icons'
import { useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import {
  RouteBreakdownWaypointStep,
  RouteBreakdownChargerStep,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/RouteBreakdown/components'
import {
  formatKilometresToLocalisedDistance,
  formatSecondsToLocalisedDuration,
} from '@electro/shared/utils/formatters'

const styles = {
  root: 'relative bg-base/50 rounded-xl p-3',
  dottedLine: 'absolute w-0.5 h-full py-12 -mt-3.5 ml-[9px] vertical-dashed-line bg-clip-content',
  steps: {
    root: 'flex flex-col gap-y-4',
    driveInfo: {
      root: 'flex items-center mx-2 ml-8',
      icon: 'h-4 mr-1 text-secondary',
      text: 'mb-0 text-sm',
      line: 'grow mx-2 border-tertiary-lavender',
    },
  },
}

export const RouteBreakdownDetails = () => {
  const { locale } = useRouter()
  const [{ routeDetails, activeRouteID }] = useRoutePlanner()

  const activeRoute = useMemo(
    () => routeDetails?.routes?.[activeRouteID],
    [routeDetails?.routes, activeRouteID],
  )

  return (
    <div className={styles.root}>
      <div className={styles.dottedLine} />

      <div className={styles.steps.root}>
        {activeRoute.markers.map((marker) => {
          if (marker.isStationStart) return null

          const isCharger = Boolean(marker.charger)
          const driveDuration = formatSecondsToLocalisedDuration(marker.driveDuration, { locale })
          const driveDistance = formatKilometresToLocalisedDistance(marker.driveDistance / 1000, {
            locale,
          })

          return (
            <>
              {isCharger ? (
                <RouteBreakdownChargerStep step={marker} />
              ) : (
                <RouteBreakdownWaypointStep step={marker} />
              )}

              {marker.driveDuration ? (
                <span className={styles.steps.driveInfo.root}>
                  <MyEVsIcon className={styles.steps.driveInfo.icon} />

                  <p className={styles.steps.driveInfo.text}>
                    {driveDuration} • {driveDistance}
                  </p>

                  <hr className={styles.steps.driveInfo.line} />
                </span>
              ) : null}
            </>
          )
        })}
      </div>
    </div>
  )
}
