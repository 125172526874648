import { useMemo } from 'react'
import { useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import {
  RouteBreakdownActionButtons,
  RouteBreakdownDetails,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/RouteBreakdown/components'
import {
  RouteOverviewNoRoute,
  RouteOverviewSelectRouteButton,
  RouteOverviewStickyButtons,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/RouteOverview/components'

export const RouteBreakdownPanelContent = () => {
  const [{ activeRouteID, routeDetails }] = useRoutePlanner()

  const activeRoute = useMemo(
    () => routeDetails?.routes?.[activeRouteID],
    [routeDetails?.routes, activeRouteID],
  )

  if (!activeRoute) return <RouteOverviewNoRoute />

  return (
    <>
      <RouteOverviewSelectRouteButton route={activeRoute} hideButton />

      <RouteBreakdownActionButtons />

      <RouteBreakdownDetails />

      <RouteOverviewStickyButtons />
    </>
  )
}
