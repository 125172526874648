import ADD_USERVEHICLE_MUTATION from '@electro/consumersite/src/graphql/addUserVehicleMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationAddUserVehicleArgs } from '@electro/consumersite/generated/graphql'

type AddUserVehicleMutation = Pick<Mutation, 'addUserVehicle'>

type UseAddUserVehicle = [
  ApolloMutationFunction<AddUserVehicleMutation>,
  MutationResult<AddUserVehicleMutation>,
]

type UseAddVehicleArgs = MutationHookOptions<AddUserVehicleMutation, MutationAddUserVehicleArgs>

export function useAddUserVehicle(options?: UseAddVehicleArgs): UseAddUserVehicle {
  const [addUserVehicleMutation, addUserVehicleResult] = useMutation<AddUserVehicleMutation>(
    ADD_USERVEHICLE_MUTATION,
    options,
  )

  return [addUserVehicleMutation, addUserVehicleResult]
}
