import { Switch } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/16/solid'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  switch: {
    root: 'flex items-center w-12 h-6 border border-secondary/70 bg-secondary-dark/40 rounded-full',
    checked: 'ej-gradient-full border-0',
  },
  thumb: {
    root: tw(
      'w-4 h-4 bg-white rounded-full',
      'translate-x-1 transition duration-200 ease-linear',
      '[&>svg]:w-full [&>svg]:h-full [&>svg]:p-px [&>svg]:text-base [&>svg]:opacity-0',
    ),
    checked: 'translate-x-7 [&>svg]:opacity-100',
  },
}

export interface SwitchToggleProps {
  checked: boolean
  onChange: () => void
  className?: string
  thumbIcon?: JSX.Element
}

export const SwitchToggle = ({
  checked,
  onChange,
  className,
  thumbIcon = <CheckIcon />,
}: SwitchToggleProps) => (
  <Switch
    id="switch-toggle"
    data-testid="switch-toggle"
    aria-hidden="true"
    checked={checked}
    onChange={onChange}
    className={tw({
      [styles.switch.root]: true,
      [styles.switch.checked]: checked,
      [className]: !!className,
    })}
  >
    <span
      data-testid="switch-thumb"
      className={tw({ [styles.thumb.root]: true, [styles.thumb.checked]: checked })}
    >
      {thumbIcon}
    </span>
  </Switch>
)
