import { ReceiptPaymentType, TransactionType } from '@electro/consumersite/generated/graphql'

type PaymentGeneric<T> = TransactionType & {
  paymentSource: T
}
export interface OutstandingPayments {
  payments?: TransactionType[]
  payment?: PaymentGeneric<ReceiptPaymentType>
}

export enum ModalScreensEnum {
  PAYMENT_DETAILS_MODAL_SCREEN = 'PAYMENT_DETAILS_MODAL_SCREEN',
  PAYMENT_PROCESSING_MODAL_SCREEN = 'PAYMENT_PROCESSING_MODAL_SCREEN',
  PAYMENT_SUCCESS_MODAL_SCREEN = 'PAYMENT_SUCCESS_MODAL_SCREEN',
  PAYMENT_FAIL_MODAL_SCREEN = 'PAYMENT_FAIL_MODAL_SCREEN',
}
