import useTranslation from 'next-translate/useTranslation'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import { useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import { RouteDictionaryRouteType } from '@electro/consumersite/src/components/Map/types'
import { MyEVsVehicleButton } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/MyEVs/components'
import {
  RouteOverviewNoRoute,
  RouteOverviewSelectRouteButton,
  RouteOverviewStickyButtons,
  RouteOverviewWaypoints,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/RouteOverview/components'

const styles = {
  breakpoint: 'border-secondary',
  vehicleInfo: 'border-transparent bg-base hover:bg-base cursor-default',
  loading: {
    root: 'flex flex-col items-center my-auto [&>div]:w-56',
    text: 'ellipsis-loading',
  },
  noRoute: 'flex flex-col items-center [&>img]:w-64',
}

export const RouteOverviewPanelContent = () => {
  const { t } = useTranslation('common')
  const [{ routeDetails, routeLoading }] = useRoutePlanner()

  if (!routeDetails) return <RouteOverviewNoRoute />

  return (
    <>
      <MyEVsVehicleButton
        vehicle={routeDetails?.vehicle}
        className={styles.vehicleInfo}
        disableClickFeedback
      />

      <RouteOverviewWaypoints />

      <hr className={styles.breakpoint} />

      {routeLoading ? (
        <div className={styles.loading.root}>
          <AnimatedComponent name="route-loading" getAnimationData={ANIMATIONS.routePlanLoading} />
          <h3 className={styles.loading.text}>{t('map.route_planner.calculating_route')}</h3>
        </div>
      ) : (
        <div className="flex flex-col gap-y-4">
          {routeDetails.routes.map((route: RouteDictionaryRouteType) => (
            <RouteOverviewSelectRouteButton route={route} key={route.id} />
          ))}
        </div>
      )}

      <RouteOverviewStickyButtons />
    </>
  )
}
