import React, {
  ReactNode,
  Fragment,
  ReactElement,
  MutableRefObject,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { tw } from '@electro/shared/utils/tailwind-merge'

import { Dialog as DialogHeadless, Transition } from '@headlessui/react'
import { Card, IconButton } from '@electro/shared-ui-components'

const styles = {
  header: {
    root: 'text-lg font-medium leading-6 text-white mb-0',
  },
  body: {
    root: 'mt-4 flex-grow overflow-auto pr-2 -mr-2 flex flex-col',
    content: 'text-left',
  },
  actions: {
    root: 'mt-4 flex flex-col gap-2 sm:flex-row',
    stacked: 'sm:flex-col sm:px-12 group',
    center: 'justify-center items-center',
  },
  actionButton: {
    root: 'mb-0',
  },
  overlay: {
    root: 'fixed inset-0 bg-base-dark bg-opacity-50 pointer-events-none z-40',
    hiddenSpacer: 'inline-block h-screen align-middle',
  },
  modal: {
    root: 'fixed inset-0 overflow-hidden z-100',
    layout: 'text-center pointer-events-none [&>*]:pointer-events-auto',
    dialog: {
      root: tw(
        'absolute bottom-0 left-0 z-50 w-full h-full pb-2 pt-4 px-4 lg:p-8 p-3',
        'inline-flex flex-col transition-all overflow-y-auto',
        'sm:-translate-x-1/2 sm:left-1/2 sm:translate-y-1/2 sm:bottom-1/2 sm:h-auto',
        'sm:shadow-xl sm:rounded-3xl ',
        'sm:w-full sm:p-6 sm:my-0 sm:overflow-hidden',
        'sm:text-left sm:align-middle',
        'sm:max-h-[calc(100vh-theme(spacing.4))]',
        'bg-base-dark text-white',
      ),
      small: 'sm:max-w-sm',
      medium: 'sm:max-w-md',
      large: 'sm:max-w-xl',
      xLarge: 'sm:max-w-3xl',
      full: 'sm:max-w-full',
    },
    closeButton: 'h-6 absolute top-4 right-4',
  },
}

enum ModalSizesEnum {
  small = 'sm',
  medium = 'md',
  large = 'lg',
  xLarge = 'xl',
  full = 'full',
}

export type ModalSizeType = `${ModalSizesEnum}`

export interface ModalProps {
  open: boolean
  onClose: (value?: boolean) => void
  onAfterLeave?: () => void
  children: ReactNode | ReactNode[]
  overlay?: boolean
  size?: ModalSizeType
  initialFocus?: MutableRefObject<any>
  variant?: 'default' | 'error' | 'warning' | 'success'
  // style overrides for the modal card
  dialogClassName?: string
}

interface ChildrenOnlyProps {
  children: ReactNode | ReactNode[]
}

interface ActionsProps extends ChildrenOnlyProps {
  center?: boolean
  stacked?: boolean
  children: ReactNode | ReactNode[]
}

interface BodyProps extends ChildrenOnlyProps {
  className?: string
}

interface IModalContext {
  handleCloseModal: (value?: boolean) => void
}

const ModalContext = createContext<IModalContext>(null)

const Header = ({ children }: ChildrenOnlyProps) => (
  <DialogHeadless.Title as="h2" className={styles.header.root}>
    {children}
  </DialogHeadless.Title>
)

const Body = ({ children, className }: BodyProps) => (
  <div
    className={tw({
      [styles.body.root]: true,
      [className]: !!className,
    })}
    data-testid="modal-body"
  >
    <div className={styles.body.content}>{children}</div>
  </div>
)

const Actions = ({ children, center = false, stacked = false }: ActionsProps) => (
  <div
    className={tw({
      [styles.actions.root]: true,
      [styles.actions.center]: center,
      [styles.actions.stacked]: stacked,
    })}
  >
    {children &&
      React.Children.map(
        children,
        (child: ReactElement) => child && React.cloneElement(child, { ...child?.props }),
      )}
  </div>
)

const Modal = ({
  open = true,
  onClose,
  onAfterLeave,
  children,
  overlay = true,
  size = 'md',
  initialFocus = null,
  variant = 'default',
  dialogClassName = '',
  ...rest
}: ModalProps) => {
  const context = useMemo(() => ({ handleCloseModal: onClose }), [onClose])

  useEffect(() => {
    const htmlElement = document.documentElement
    const scrollbarOverflow = htmlElement.scrollHeight > htmlElement.clientHeight
    if (open && scrollbarOverflow) {
      document.documentElement.className += ' override-modal-no-scroll'
    } else {
      htmlElement.className = htmlElement.className.replace(' override-modal-no-scroll', '')
    }
  }, [open])

  return (
    <ModalContext.Provider value={context}>
      <Transition appear show={open} as={Fragment} afterLeave={onAfterLeave}>
        <DialogHeadless
          initialFocus={initialFocus}
          as="div"
          className={tw({
            [styles.modal.root]: true,
          })}
          onClose={onClose}
        >
          <div className={styles.modal.layout} data-testid="modal" {...rest}>
            {overlay && (
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <DialogHeadless.Overlay
                  onClick={() => onClose()}
                  data-testid="modal-overlay"
                  className={styles.overlay.root}
                />
              </Transition.Child>
            )}
            <span className={styles.overlay.hiddenSpacer} aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Card
                data-testid="modal-card"
                variant={variant}
                frostedDark
                className={tw({
                  [styles.modal.dialog.root]: true,
                  [styles.modal.dialog.small]: size === ModalSizesEnum.small,
                  [styles.modal.dialog.medium]: size === ModalSizesEnum.medium,
                  [styles.modal.dialog.large]: size === ModalSizesEnum.large,
                  [styles.modal.dialog.xLarge]: size === ModalSizesEnum.xLarge,
                  [styles.modal.dialog.full]: size === ModalSizesEnum.full,
                  [dialogClassName]: dialogClassName !== '',
                })}
              >
                {children}
              </Card>
            </Transition.Child>
          </div>
        </DialogHeadless>
      </Transition>
    </ModalContext.Provider>
  )
}

const CloseButton = () => {
  const { handleCloseModal } = useContext(ModalContext)

  return (
    <IconButton
      aria-label="Close dialog"
      onClick={() => handleCloseModal()}
      className={styles.modal.closeButton}
    >
      <XMarkIcon className="w-6 h-6" />
    </IconButton>
  )
}

Modal.CloseButton = CloseButton
Modal.Header = Header
Modal.Body = Body
Modal.Actions = Actions

export { Modal }
