import { Button, Modal, Typography } from '@electro/shared-ui-components'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { USER_SESSION_EXPIRED_MODAL_TRIGGER_PARAM } from '@electro/consumersite/src/constants/urlParams'
import Link from 'next/link'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

/**
 * In order to feedback to users that their session has expired, we're
 * showing a modal. This modal can be triggered anywhere on the site
 * by simply adding a query param to the URL.
 */
export const SessionExpiredModal = () => {
  const [hide, setHide] = useState(true)
  const { query, replace } = useRouter()
  const { t } = useTranslation('common')

  useEffect(() => {
    if (query?.[USER_SESSION_EXPIRED_MODAL_TRIGGER_PARAM] === 'true') {
      setHide(false)
      // remove the session expired parameter from the URL and replace it
      // so we don't add the param change to the users browser history.
      const { [USER_SESSION_EXPIRED_MODAL_TRIGGER_PARAM]: _, ...nextQuery } = query
      replace({
        query: nextQuery,
      })
    }
  }, [query, replace])

  const handleClose = () => {
    setHide(true)
  }

  return hide ? null : (
    <Modal open onClose={handleClose}>
      <Modal.Body>
        <Image
          className="mx-auto mb-6"
          src="/images/zap-using-the-app.svg"
          alt="zap using a laptop"
          width={145}
          height={197}
        />
        <Typography className="text-center" as="h2" variant="h2">
          {t('modal.session_expired.title')}
        </Typography>
        <Typography className="text-center" as="p" variant="body">
          {t('modal.session_expired.body')}
        </Typography>
      </Modal.Body>
      <Modal.Actions stacked>
        <Link href="/log-in" className="no-underline">
          <Button fullWidth onClick={handleClose}>
            {t('user.log_in')}
          </Button>
        </Link>
        <Button variant="outline" fullWidth onClick={handleClose}>
          {t('nav.menu.close')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
