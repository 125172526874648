import 'mapbox-gl/dist/mapbox-gl.css'
import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
import { ProjectionSpecification } from 'mapbox-gl'
import { useMount, useUpdateEffect } from 'react-use'
import { supported } from '@mapbox/mapbox-gl-supported'
import { Map, NavigationControl, GeolocateControl, useMap, ScaleControl } from 'react-map-gl'
import { useCurrentLocation } from '@electro/consumersite/src/components/Map/hooks/useCurrentLocation'
import { countryBoundingBoxes } from '@electro/consumersite/generated/mapStaticData'
import { MapUnsupported } from '@electro/consumersite/src/components/Map/components'
import { PlaceDetails } from '@electro/consumersite/src/components/Map/helpers'

interface BaseMapProps {
  children?: ReactNode | ReactNode[]
  mapProjection?: ProjectionSpecification['name']
}

export const BaseMap = ({ children, mapProjection = 'globe' }: BaseMapProps) => {
  const { baseMap } = useMap()
  const { locale } = useRouter()
  const [{ geoControlRef }, { onGeolocate, onError }] = useCurrentLocation()
  const { coordinates } = countryBoundingBoxes[locale] as PlaceDetails

  const [mapLoaded, setMapLoaded] = useState<boolean>(false)
  const [mapSupported, setMapSupported] = useState<boolean>(true)

  /** Check if the map correctly renders on the users browser */
  useMount(() => setMapSupported(supported()))

  /** Update the language based on locale selector */
  useUpdateEffect(() => {
    baseMap?.setLanguage(locale)
  }, [baseMap, locale])

  return mapSupported ? (
    <Map
      id="baseMap"
      initialViewState={{ latitude: 90, longitude: coordinates.lng, zoom: 1 }}
      style={{ width: '100dvw', height: '100dvh' }}
      mapStyle="mapbox://styles/electricjuice/clzchk93v009h01qy1gxyde10"
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_KEY}
      onLoad={(map) => {
        map.target.setFog({})
        setMapLoaded(true)
      }}
      projection={{ name: mapProjection }}
    >
      <NavigationControl visualizePitch position="bottom-right" />
      <GeolocateControl
        ref={geoControlRef}
        style={{ display: 'none' }}
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation
        onGeolocate={onGeolocate}
        onError={onError}
      />
      <ScaleControl position="bottom-left" />
      {mapLoaded ? children : null}
    </Map>
  ) : (
    <MapUnsupported />
  )
}
