import { useMemo } from 'react'
import {
  ApolloError,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client'

import VEHICLE_MAKES_QUERY from '@electro/consumersite/src/graphql/vehicleMakesQuery.graphql'
import VEHICLE_MODELS_QUERY from '@electro/consumersite/src/graphql/vehicleModelsQuery.graphql'
import VEHICLE_MODEL_VERSIONS_QUERY from '@electro/consumersite/src/graphql/vehicleModelVersionsQuery.graphql'
import VEHICLES from '@electro/consumersite/src/graphql/vehiclesQuery.graphql'

import { Query } from '@electro/consumersite/generated/graphql'

export type VehicleLazyQueryType<T extends keyof Query> = (
  options?: QueryLazyOptions<OperationVariables>,
) => Promise<LazyQueryResult<Pick<Query, T>, OperationVariables>>

interface UseFetchVehicleDetails {
  loading: boolean
  error: ApolloError
  fetchVehicleMakes: VehicleLazyQueryType<'vehicleMakes'>
  fetchVehicleModels: VehicleLazyQueryType<'vehicleModels'>
  fetchVehicleModelVersions: VehicleLazyQueryType<'vehicleModelVersions'>
  fetchVehiclesFull: VehicleLazyQueryType<'vehicles'>
}

export const useFetchVehicleDetails = (): UseFetchVehicleDetails => {
  const [fetchVehicleMakes, vehicleMakesQuery] = useLazyQuery<Pick<Query, 'vehicleMakes'>>(
    VEHICLE_MAKES_QUERY,
    { notifyOnNetworkStatusChange: true },
  )

  const [fetchVehicleModels, vehicleModelsQuery] = useLazyQuery<Pick<Query, 'vehicleModels'>>(
    VEHICLE_MODELS_QUERY,
    { notifyOnNetworkStatusChange: true },
  )

  const [fetchVehicleModelVersions, vehicleModelVersionsQuery] = useLazyQuery<
    Pick<Query, 'vehicleModelVersions'>
  >(VEHICLE_MODEL_VERSIONS_QUERY, { notifyOnNetworkStatusChange: true })

  const [fetchVehiclesFull, vehiclesFullQuery] = useLazyQuery<Pick<Query, 'vehicles'>>(VEHICLES, {
    notifyOnNetworkStatusChange: true,
  })

  const error = useMemo(() => {
    const queries = [
      vehicleMakesQuery,
      vehicleModelsQuery,
      vehicleModelVersionsQuery,
      vehiclesFullQuery,
    ]
    return queries.find(({ error: err }) => err)?.error
  }, [vehicleMakesQuery, vehicleModelsQuery, vehicleModelVersionsQuery, vehiclesFullQuery])

  const loading = useMemo(() => {
    const queries = [
      vehicleMakesQuery,
      vehicleModelsQuery,
      vehicleModelVersionsQuery,
      vehiclesFullQuery,
    ]
    return queries.find(({ loading: l }) => l)?.loading
  }, [vehicleMakesQuery, vehicleModelsQuery, vehicleModelVersionsQuery, vehiclesFullQuery])

  return {
    loading,
    error,
    fetchVehicleMakes,
    fetchVehicleModels,
    fetchVehicleModelVersions,
    fetchVehiclesFull,
  }
}
