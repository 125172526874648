import { Locale } from '@electro/shared/types/locales'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export const useLocalizationFlags = () => {
  const { locale } = useRouter()
  const restrictToLocales = useCallback(
    (locales: Locale[]) => locales.includes(locale as Locale),
    [locale],
  )

  return {
    restrictToLocales,
  }
}
