export const TransportIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 17 20">
    <path
      d="M16.3636 13.1818V4.54545C16.3623 3.34026 15.8829 2.18498 15.0308 1.33286C14.1787 0.480736 13.0234 0.00134199 11.8182 0H4.54545C3.34026 0.00135281 2.18498 0.480736 1.33286 1.33286C0.480736 2.18498 0.00134199 3.34026 0 4.54545V13.1818C0.0015219 13.989 0.271238 14.7727 0.76671 15.4097C1.26234 16.0469 1.95567 16.5011 2.73775 16.7012L1.56944 17.5191C1.25051 17.7422 1.04216 18.091 0.996839 18.4776C0.951519 18.8644 1.07361 19.2519 1.33234 19.5428C1.59106 19.8336 1.96173 20 2.351 20H14.0116C14.4009 20 14.7717 19.8336 15.0304 19.5428C15.2893 19.2521 15.4114 18.8645 15.3661 18.4777C15.3208 18.091 15.1124 17.7422 14.7935 17.5191L13.625 16.7011C14.4071 16.5012 15.1006 16.0471 15.5964 15.41C16.0922 14.7729 16.3621 13.9891 16.3636 13.1818L16.3636 13.1818ZM11.8182 1.81818C12.5413 1.81903 13.2346 2.1065 13.7458 2.61788C14.2571 3.12909 14.5446 3.82238 14.5455 4.54545V7.27273H9.09091V1.81818H11.8182ZM1.81818 4.54545C1.81903 3.82238 2.1065 3.12905 2.61788 2.61788C3.12909 2.10654 3.82238 1.81905 4.54545 1.81818H7.27273V7.27273H1.81818V4.54545ZM3.79398 18.1818L5.74238 16.8182H10.6225L12.5698 18.1818H3.79398ZM14.5455 13.1818C14.545 13.6639 14.3532 14.1259 14.0123 14.4668C13.6714 14.8077 13.2094 14.9995 12.7273 15H3.63641C3.15429 14.9995 2.69229 14.8077 2.35139 14.4668C2.01048 14.1259 1.8187 13.6639 1.81823 13.1818V9.09091H14.5455V13.1818Z"
      fill="currentColor"
    />
    <path
      d="M5.45453 11.8182C5.45453 12.3203 5.04751 12.7273 4.54544 12.7273C4.04338 12.7273 3.63635 12.3203 3.63635 11.8182C3.63635 11.3161 4.04338 10.9091 4.54544 10.9091C5.04751 10.9091 5.45453 11.3161 5.45453 11.8182Z"
      fill="currentColor"
    />
    <path
      d="M12.7272 11.8182C12.7272 12.3203 12.3202 12.7273 11.8181 12.7273C11.3161 12.7273 10.9091 12.3203 10.9091 11.8182C10.9091 11.3161 11.3161 10.9091 11.8181 10.9091C12.3202 10.9091 12.7272 11.3161 12.7272 11.8182Z"
      fill="currentColor"
    />
  </svg>
)
