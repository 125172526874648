import { TableCell, IconButton, Menu } from '@electro/shared-ui-components'
import { v1 as uuid } from 'uuid'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { DataGridColumnDef, DataGridRowDef } from '../..'
import { cleanRow } from '../../utils/cleanRow'

interface DataGridRowProps {
  row: DataGridRowDef
  column: DataGridColumnDef
}

export const DataGridActionsCell = ({ row, column }: DataGridRowProps) => {
  const options = column.getActions(cleanRow(row))
  const iconButtons = options.filter((option: React.ReactElement) => !option.props.showInMenu)
  const menuButtons = options.filter((option: React.ReactElement) => option.props.showInMenu)

  const handleClick = (event) => event.stopPropagation() // we need to stop propagation which prevent click the collapse row

  return (
    <TableCell className="w-[1%]" onClick={handleClick}>
      <div className="flex items-center">
        {iconButtons}

        {menuButtons.length > 0 && (
          <Menu>
            <Menu.Button
              className="flex"
              noPadding
              chevron={false}
              data-testid="data-grid-more-options-button"
            >
              <IconButton>
                <EllipsisVerticalIcon className="w-6 h-6 hover:text-tertiary" />
              </IconButton>
            </Menu.Button>

            <Menu.MenuItemList variant="dark">
              {menuButtons.map((menuButton: React.ReactElement) => {
                const { onClick, disabled, loading, label } = menuButton.props

                return (
                  <Menu.MenuItem
                    key={uuid()}
                    disabled={loading || disabled}
                    onSelect={onClick}
                    data-testid={`data-grid-menu-action-button-${label}`}
                  >
                    {menuButton}
                  </Menu.MenuItem>
                )
              })}
            </Menu.MenuItemList>
          </Menu>
        )}
      </div>
    </TableCell>
  )
}
