import { useCallback, useMemo, createContext, useContext, ReactNode, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { useUpdateEffect } from 'react-use'
import { useAuth } from '@electro/consumersite/src/hooks'
import useTranslation from 'next-translate/useTranslation'
import { useToastNotification } from '@electro/shared-ui-components'
import { getErrorMessage } from '@electro/shared/utils/getErrorMessage'
import {
  SavedRouteMetadata,
  useAddSavedRouteMutation,
  useRemoveSavedRouteMutation,
  useSavedRouteMetadataQuery,
} from '@electro/consumersite/generated/graphql'

interface State {
  savedRoutes: SavedRouteMetadata[]
}

interface Handlers {
  addSavedRoute: (routeId: string) => Promise<void>
  removeSavedRoute: (routeId: string) => Promise<void>
}

type UseSavedRoutes = [state: State, handlers: Handlers]

const UseSavedRoutesContext = createContext<UseSavedRoutes>(null)

function useSavedRoutesProvider(): UseSavedRoutes {
  const [{ session }] = useAuth()
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()

  const { data, refetch } = useSavedRouteMetadataQuery({ skip: !session })

  const [saveRoute] = useAddSavedRouteMutation()
  const [removeRoute] = useRemoveSavedRouteMutation()

  const [savedRoutes, setSavedRoutes] = useState<SavedRouteMetadata[]>([])

  /** Store the saved routes in state in order of most recently created */
  useUpdateEffect(() => {
    setSavedRoutes(data?.savedRouteMetadata?.edges?.map(({ node }) => node).reverse() ?? [])
  }, [data])

  /** Add a route UUID to user's saved routes, and refetch their saved routes list */
  const addSavedRoute = useCallback(
    async (routeId: string) => {
      try {
        const addSavedRouteResult = await saveRoute({ variables: { routeId } })
        if (addSavedRouteResult?.data?.addSavedRoute?.success) await refetch()
      } catch (error) {
        const errorMessage = getErrorMessage(error)

        showToastNotification({
          heading: t('utility.something_went_wrong.title'),
          body: errorMessage,
          variant: 'error',
        })
        Sentry.captureMessage(`SavedRoutes, addSavedRoute. Error => ${errorMessage}`)
      }
    },
    [t, saveRoute, refetch, showToastNotification],
  )

  /** Remove a route UUID from a user's saved routes, and refetch their saved routes list */
  const removeSavedRoute = useCallback(
    async (routeId: string) => {
      try {
        const removeSavedRouteResult = await removeRoute({ variables: { routeId } })
        if (removeSavedRouteResult?.data?.removeSavedRoute?.success) await refetch()
      } catch (error) {
        const errorMessage = getErrorMessage(error)

        showToastNotification({
          heading: t('utility.something_went_wrong.title'),
          body: errorMessage,
          variant: 'error',
        })
        Sentry.captureMessage(`SavedRoutes, removeSavedRoute. Error => ${errorMessage}`)
      }
    },
    [t, removeRoute, refetch, showToastNotification],
  )

  const state = useMemo(() => ({ savedRoutes }), [savedRoutes])

  const handlers = useMemo(
    () => ({ addSavedRoute, removeSavedRoute }),
    [addSavedRoute, removeSavedRoute],
  )

  return [state, handlers]
}

export const UseSavedRoutesProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useSavedRoutesProvider()
  return <UseSavedRoutesContext.Provider value={ctx}>{children}</UseSavedRoutesContext.Provider>
}

export const useSavedRoutes = (): UseSavedRoutes => {
  const context = useContext(UseSavedRoutesContext)
  if (!context)
    throw new Error('useSavedRoutes() cannot be used outside of <UseSavedRoutesProvider/>')
  return context
}
