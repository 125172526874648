import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import { VehicleRegLookup, REGISTRATION_LOOKUP_FORM_ID } from '@electro/shared/components'
import { usePlugAndChargeSignup } from '@electro/consumersite/src/components/PlugAndChargeSignup/hooks'
import { Button, Typography } from '@electro/shared-ui-components'
import {
  selectRegionalisedBlogLink,
  BlogTitleEnum,
} from '@electro/consumersite/src/helpers/selectLocalisedBlog'
import { Locale } from '@electro/shared/types/locales'
import { PncStagesEnum } from '../../types'

export const RegLookupStage = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [{ loading, vehicle, errorMessage }, { handleSubmitRegNumber, setActiveStage }] =
    usePlugAndChargeSignup()
  const handleEnterVin = () => {
    setActiveStage(PncStagesEnum.ENTER_VEHICLE_VIN)
  }
  return (
    <div className="text-center">
      <img
        src="/images/mr-zap-charging-an-ev.png"
        alt="illustration of Mr Zap charging an EV"
        width={483}
        height={210}
        className="mx-auto mt-1 mb-10"
      />
      <Typography variant="h1" as="h1">
        {t('plug_and_charge.reg_lookup.title')}
      </Typography>
      <Typography variant="small" as="p">
        {t('plug_and_charge.reg_lookup.to_get_started')}
      </Typography>
      <Typography variant="small" as="p">
        <a
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
          href={selectRegionalisedBlogLink(
            router.locale as Locale,
            BlogTitleEnum.PLUG_AND_CHARGE_BLOG,
          )}
        >
          {t('plug_and_charge.reg_lookup.learn_more')}
        </a>
      </Typography>
      <Typography variant="body" as="p" className="mb-2">
        {t('plug_and_charge.reg_lookup.registration_plate')}
      </Typography>
      <VehicleRegLookup
        loading={loading}
        initialRegNumber={vehicle?.regNumber || ''}
        onSubmit={handleSubmitRegNumber}
        errorMessage={errorMessage}
      />
      {errorMessage && (
        <div className="mt-2 text-sm text-action-danger">
          Alternatively, you can try entering your vehicle&apos;s VIN/PCID!
        </div>
      )}
      <Button
        fullWidth
        loading={loading}
        type="submit"
        form={REGISTRATION_LOOKUP_FORM_ID}
        className="mt-4"
      >
        {t('utility.submit')}
      </Button>

      <Button
        fullWidth
        onClick={handleEnterVin}
        disabled={loading}
        className="mt-4"
        variant="naked"
      >
        {t('plug_and_charge.reg_lookup.enter_vin_instead')}
      </Button>
    </div>
  )
}
