/* eslint-disable no-underscore-dangle */
import React from 'react'
import {
  ConnectionFee,
  ConsumptionRate,
  GenericConnectorConnectionTypeEdge,
  GenericEvseConnectionTypeEdge,
  TimeRate,
} from '@electro/consumersite/generated/graphql'
import { Availability } from '@electro/consumersite/src/components/Map/components/MapLocationDetailsPanel/components'

import { Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { formatPriceComponentToLocalisedValue } from '@electro/shared/utils/formatters'
import {
  ConnectorsHumanNameType,
  retrieveSocketTypeDetails,
} from '@electro/consumersite/src/components/Map/utils'

interface ConnectorsListProps {
  evses: GenericEvseConnectionTypeEdge[]
}

type PriceComponent = ConsumptionRate & TimeRate & ConnectionFee

export const ConnectorsList = ({ evses }: ConnectorsListProps) => {
  const { t } = useTranslation('common')
  return (
    <div
      className="pt-4 pb-2 px-2 mt-4 rounded-4xl bg-base/60 border-2 border-secondary"
      data-testid="location-details-connectors"
    >
      {evses.map((evse: GenericEvseConnectionTypeEdge) => (
        <div
          key={evse.node.pk + evse.node.physicalReference}
          className="mb-5 last:mb-0"
          data-testid={evse.node.pk + evse.node.physicalReference}
        >
          {!!evse.node.physicalReference && (
            <>
              <Typography variant="h4" className="uppercase pl-5 pr-5 pb-2 break-words min-w-full">
                <span className="pr-2">{evse.node.physicalReference}</span>
                {!!evse.node.status && <Availability status={evse.node.status} />}
              </Typography>
              {/* 
              We need an old school clear-fix because the availability 
              text layout floats to the right
            */}
              <div className="clear-both" />
            </>
          )}
          {evse.node.connectors.edges.map((connector: GenericConnectorConnectionTypeEdge) => {
            const { isChargingFree } = connector.node
            const { name, iconURL } = retrieveSocketTypeDetails(
              connector.node.standard?.humanName as ConnectorsHumanNameType,
            )

            return (
              <div
                key={connector.node.pk}
                className="rounded-4xl p-5 mb-2 last:mb-0 bg-base border-2 border-secondary"
                data-testid="location-evse-connector"
              >
                <div className="flex clear-both items-center">
                  <img
                    src={iconURL}
                    alt={connector.node.standard?.humanName}
                    className="w-9 mr-2"
                  />
                  <div className="flex-grow flex justify-between">
                    <div>
                      <Typography className="text-white">{name}</Typography>
                      <Typography variant="h4">{connector.node.kilowatts}kW</Typography>
                    </div>
                    {!isChargingFree && connector.node?.priceComponents && (
                      <div className="text-sm flex flex-col justify-center">
                        {connector.node.priceComponents
                          .filter((priceComponent: PriceComponent) => priceComponent.unitAmount > 0)
                          .map((priceComponent: PriceComponent, index: number) =>
                            ['ConsumptionRate', 'TimeRate', 'ParkingTimeRate'].includes(
                              priceComponent.__typename,
                            ) ? (
                              <Typography
                                data-testid={priceComponent.__typename}
                                key={`${connector.node.pk * index}`}
                              >
                                {formatPriceComponentToLocalisedValue(priceComponent)}
                              </Typography>
                            ) : null,
                          )}
                      </div>
                    )}

                    {!isChargingFree &&
                    (connector.node.priceComponents.length === 0 ||
                      connector.node.priceComponents
                        .filter((priceComponent: PriceComponent) => priceComponent.unitAmount)
                        .every(
                          (priceComponent: PriceComponent) => priceComponent.unitAmount === 0,
                        )) ? (
                      <div data-testid="price-unknown" className="self-center">
                        <Typography variant="small" as="span">
                          {t('map.location.price_unknown')}
                        </Typography>
                      </div>
                    ) : null}

                    {isChargingFree ? (
                      <div data-testid="price-unknown" className="self-center">
                        <Typography>{t('map.location.info.capability.free')}</Typography>
                      </div>
                    ) : null}
                  </div>
                </div>

                {!isChargingFree &&
                  connector.node.priceComponents.map(
                    (priceComponent: PriceComponent) =>
                      priceComponent.__typename === 'ConnectionFee' &&
                      priceComponent.unitAmount > 0 && (
                        <div key={priceComponent.__typename} data-testid="ConnectionFee">
                          <div className="flex justify-between pt-2 text-sm">
                            <Typography variant="small" as="span">
                              {t('map.location.info.connection_fee_short')}
                            </Typography>{' '}
                            <Typography variant="small" as="span" data-testid="ConnectionFee-value">
                              {formatPriceComponentToLocalisedValue(priceComponent)}
                            </Typography>
                          </div>
                        </div>
                      ),
                  )}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}
