import { useState } from 'react'
import { useMount } from 'react-use'

/** Reports if the primary input method for the device is a touch screen */
export const useTouchDevice = () => {
  const [state, setState] = useState<boolean>(false)

  useMount(() => setState(window.matchMedia('(pointer: coarse)').matches))

  return state
}
