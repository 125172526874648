export const ShelterIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 27 19">
    <path
      d="M15.8015 8.44734C15.8015 9.7186 14.7709 10.7492 13.4997 10.7492C12.2285 10.7492 11.1979 9.71855 11.1979 8.44734C11.1979 7.17608 12.2285 6.14551 13.4997 6.14551C14.7709 6.14551 15.8015 7.17613 15.8015 8.44734Z"
      fill="currentColor"
    />
    <path
      d="M16.7395 12.8237L16.6259 12.6532C16.2281 11.9144 15.6312 11.5449 14.8924 11.5449H12.1075C11.3402 11.5449 10.7718 11.9144 10.374 12.6532L10.2602 12.8237C9.86235 13.5342 9.49285 14.273 9.32239 14.8415C9.18026 15.4384 8.83931 17.0865 8.83931 17.1719C8.75407 17.655 9.06668 18.1097 9.54976 18.2233C9.60666 18.2233 9.66335 18.2517 9.72024 18.2517C10.1464 18.2517 10.516 17.9674 10.5728 17.5412C10.5728 17.5129 10.9138 15.8646 11.0559 15.3246C11.1128 15.1256 11.255 14.7846 11.4254 14.472L11.3118 18.3086C11.3118 18.6212 11.5676 18.8769 11.8802 18.8769H15.1482C15.4608 18.8769 15.7165 18.6212 15.7165 18.3086L15.6029 14.472C15.7734 14.7846 15.9155 15.1256 15.9724 15.3246C16.1145 15.8646 16.4271 17.5129 16.4555 17.5412C16.5407 18.0243 17.0238 18.3369 17.5069 18.2517C17.99 18.1664 18.3026 17.6834 18.2173 17.2003C18.2173 17.1434 17.8764 15.4667 17.7343 14.8699C17.5069 14.2732 17.1091 13.5342 16.7395 12.8237L16.7395 12.8237Z"
      fill="currentColor"
    />
    <path
      d="M26.7996 8.30523L13.7272 0.0639297C13.585 -0.0213099 13.4146 -0.0213099 13.2724 0.0639297L0.20004 8.30523C0.00101604 8.41881 -0.0556782 8.67473 0.0579086 8.87354L0.598261 9.69777C0.711848 9.8968 0.96776 9.95349 1.16658 9.8399L4.34937 7.82211V18.4221C4.34937 18.6495 4.51985 18.82 4.74722 18.82H5.74196C5.96933 18.82 6.1398 18.6495 6.1398 18.4221V6.68538L13.5001 2.05335L20.8605 6.68558V18.4223C20.8605 18.6497 21.0309 18.8202 21.2583 18.8202H22.253C22.4804 18.8202 22.6509 18.6497 22.6509 18.4223V7.82231L25.8337 9.84011C26.0327 9.95369 26.2884 9.897 26.402 9.69798L26.942 8.87395C27.0558 8.67492 26.9989 8.41901 26.7999 8.30543L26.7996 8.30523Z"
      fill="currentColor"
    />
  </svg>
)
