import { useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { Button } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { BookmarkIcon } from '@heroicons/react/24/outline'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { RoutePlannerIcon } from '@electro/icons/24'
import {
  useMapSidebar,
  useRoutePlanner,
  useSavedRoutes,
} from '@electro/consumersite/src/components/Map/hooks'

const styles = {
  root: 'bg-base/10 backdrop-blur absolute left-0 bottom-0.5 w-full rounded-br-md pl-3',
  breakpoint: 'border-secondary',
  buttonGroup: {
    root: 'flex gap-x-2 mx-4',
    button: {
      root: 'w-full py-1 my-3',
      viewMap: 'flex sm:hidden',
    },
    icon: {
      root: 'shrink-0 size-4 mr-1',
      filled: 'fill-white',
    },
  },
}

export const RouteOverviewStickyButtons = () => {
  const { t } = useTranslation('common')
  const [, { showPanelExclusive }] = useMapSidebar()
  const [{ routeDetails, routeLoading }] = useRoutePlanner()
  const [{ savedRoutes }, { addSavedRoute, removeSavedRoute }] = useSavedRoutes()

  const isSavedMemo = useMemo(
    () => savedRoutes?.some((route) => route.planUuid === routeDetails?.uuid),
    [savedRoutes, routeDetails?.uuid],
  )

  // The loading prop on the mutation created a blip where saved state was unclear.
  // Clicking the button in this confusion would create weird problems. This solves that.
  const [isSaved, setIsSaved] = useState<boolean>(isSavedMemo)
  useUpdateEffect(() => setIsSaved(isSavedMemo), [isSavedMemo])

  /** Switches the local state, then calls the relevant mutation */
  const switchSavedRouteState = () => {
    setIsSaved(!isSaved)

    if (isSavedMemo) removeSavedRoute(routeDetails?.uuid)
    else addSavedRoute(routeDetails?.uuid)
  }

  return (
    <div className={styles.root}>
      <hr className={styles.breakpoint} />

      <div className={styles.buttonGroup.root}>
        <Button
          onClick={switchSavedRouteState}
          variant={isSaved ? 'raised' : 'outline'}
          className={styles.buttonGroup.button.root}
          loading={isSaved !== isSavedMemo}
          disabled={routeLoading}
        >
          <BookmarkIcon
            className={tw({
              [styles.buttonGroup.icon.root]: true,
              [styles.buttonGroup.icon.filled]: isSaved,
            })}
          />
          {isSaved ? t('map.saved_routes.saved') : t('map.saved_routes.save')}
        </Button>

        <Button
          variant="raised"
          onClick={() => showPanelExclusive(SidebarPanels.ALL_PANELS_HIDDEN)}
          className={tw(styles.buttonGroup.button.root, styles.buttonGroup.button.viewMap)}
        >
          <RoutePlannerIcon className={styles.buttonGroup.icon.root} />
          {t('map.route_overview.route')}
        </Button>
      </div>
    </div>
  )
}
