import useTranslation from 'next-translate/useTranslation'
import { Skeleton, Typography } from '@electro/shared-ui-components'
import {
  OutstandingPayment,
  OutstandingPaymentModal,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/components'
import {
  ManageOutstandingPaymentsProvider,
  useManageOutstandingPayments,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/hooks'

const styles = {
  errorMessage: 'text-warning-500',
  root: 'mt-8 mb-2 px-1 max-w-lg',
}

const LoadingSkeleton = () => (
  <div className="mt-8 mb-2 px-1 max-w-lg'" data-testid="outstanding-payments-loading">
    <Skeleton className="w-1/3 h-8 mb-4" />
    <Skeleton className="w-2/3 h-4 mb-8" />
    <Skeleton className="w-1/3 h-8 mb-4" />
    <div className="flex flex-col items-center justify-between lg:flex-row">
      <Skeleton
        className="flex justify-between w-full h-18 mb-4 lg:mb-0 lg:mr-4 rounded"
        variant="rectangular"
      />
      <Skeleton className="h-12" variant="circular" />
    </div>
  </div>
)

const ManageOutstandingPayments = () => {
  const { t } = useTranslation('common')
  const { data, error, loading } = useManageOutstandingPayments()

  if (error) return <div className={styles.errorMessage}>{error.message}</div>
  if (loading && !error) return <LoadingSkeleton />
  if (!loading && !error && data && data?.unsettledPayments?.length > 0)
    return (
      <div className={styles.root}>
        <Typography variant="h3" as="h1">
          {t('profile.outstanding_payments.title')}
        </Typography>
        <Typography className="mb-8">{t('profile.outstanding_payments.description')}</Typography>
        {data?.unsettledPayments?.map((payment) => (
          <OutstandingPayment key={payment?.pk} payment={payment} />
        ))}
        <OutstandingPaymentModal />
      </div>
    )
  return null
}

const ManageOutstandingPaymentsContainer = () => (
  <ManageOutstandingPaymentsProvider>
    <ManageOutstandingPayments />
  </ManageOutstandingPaymentsProvider>
)

export { ManageOutstandingPaymentsContainer as ManageOutstandingPayments }
