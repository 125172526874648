import { useMemo } from 'react'

import * as Sentry from '@sentry/nextjs'

import { useRouter } from 'next/router'
import { useAuth } from '@electro/consumersite/src/hooks'
import {
  useThirdPartyAuthenticationMutation,
  useUserLazyQuery,
} from '@electro/consumersite/generated/graphql'
import {
  SIGN_UP_PASSWORD_REQUIRED_PARAM,
  SIGN_UP_VERIFICATION_TOKEN_PARAM,
} from '@electro/shared/constants'
import { ApolloErrorWithErrorCode } from '@electro/consumersite/types/errorCodes'
import { handlePostLoginRedirect } from '@electro/consumersite/src/utils/handlePostLoginRedirect'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { getMessageFromErrorCode } from '@electro/consumersite/src/utils/getMessageFromErrorCode'
import { useToastNotification } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

interface HandleThirdPartySignInArgs {
  idToken: string
  providerName: 'APPLE' | 'GOOGLE'
}

type UseHandleThirdPartySignIn = [State, Handlers]

interface State {
  loading: boolean
  error: ApolloErrorWithErrorCode
}

interface Handlers {
  handleThirdPartySignIn: (args: HandleThirdPartySignInArgs) => void
}

export const useHandleThirdPartySignIn = (): UseHandleThirdPartySignIn => {
  const router = useRouter()
  const [{ sessionLoading, error: authError }, { loginWithJwt }] = useAuth()
  const { showToastNotification } = useToastNotification()
  const [fetchUser, { loading: fetchUserLoading, error: fetchUserError }] = useUserLazyQuery()
  const { t } = useTranslation('common')
  const [signInWithThirdParty, { loading: signInLoading, error: signInError }] =
    useThirdPartyAuthenticationMutation({
      onError: (err) => {
        Sentry.captureException(err)
      },
    })

  const handleThirdPartySignIn = async ({ idToken, providerName }) => {
    if (!idToken || !providerName) return
    try {
      const { data, errors } = await signInWithThirdParty({
        variables: {
          idToken,
          providerName,
        },
      })

      if (errors) {
        const errorCode = (errors as unknown as ApolloErrorWithErrorCode)?.graphQLErrors[0]
          ?.errorCode

        showToastNotification({
          heading: t('oauth.login.error'),
          body: t(getMessageFromErrorCode(errorCode)),
          variant: 'error',
          position: 'topRight',
        })
      }

      /**
       * BE will only return relevant data if the user is logging in or signing up.
       * They are mutually exclusive.
       */
      const userIsLoggingIn =
        data?.thirdPartyAuthentication?.login?.token &&
        data?.thirdPartyAuthentication?.login?.refreshToken

      const userIsSigningUp = data?.thirdPartyAuthentication?.signup?.token

      if (userIsLoggingIn) {
        await loginWithJwt({
          token: data.thirdPartyAuthentication?.login?.token,
          refreshToken: data.thirdPartyAuthentication?.login?.refreshToken,
        })

        const { data: userData } = await fetchUser()
        GTM.thirdPartyLoginSuccessful({ authProvider: providerName })
        handlePostLoginRedirect({ userData, router })
      } else if (userIsSigningUp) {
        /**
         * If we have a new user signing up we want to redirect them to the verification page
         * where we collect the remaining data that is required for sign up. We set a URL param
         * to indicate that the user does not need to set a password.
         */
        GTM.thirdPartyLoginSuccessful({ authProvider: providerName })
        router.push(
          `/sign-up/magic/verify?${SIGN_UP_VERIFICATION_TOKEN_PARAM}=${data.thirdPartyAuthentication?.signup?.token}&${SIGN_UP_PASSWORD_REQUIRED_PARAM}=false`,
        )
      }
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const loading = useMemo(
    () => signInLoading || fetchUserLoading || sessionLoading,
    [signInLoading, fetchUserLoading, sessionLoading],
  )

  const error: ApolloErrorWithErrorCode = useMemo(
    () =>
      (authError as ApolloErrorWithErrorCode) ||
      (fetchUserError as ApolloErrorWithErrorCode) ||
      (signInError as ApolloErrorWithErrorCode),
    [authError, fetchUserError, signInError],
  )

  return [{ loading, error }, { handleThirdPartySignIn }]
}
