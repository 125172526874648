import React, { createContext, useContext, ReactNode, useMemo } from 'react'

interface TableProps {
  loading?: boolean
  loadingMessage?: ReactNode
  error?: boolean
  errorMessage?: ReactNode
  emptyTable?: boolean
  emptyTableMessage?: ReactNode
  children?: ReactNode | ReactNode[]
}

const TableContext = createContext(null)

const useTableProvider = ({
  loading,
  loadingMessage,
  error,
  errorMessage,
  emptyTable,
  emptyTableMessage,
}: TableProps): TableProps => {
  const value = useMemo(
    () => ({
      loading,
      loadingMessage,
      error,
      errorMessage,
      emptyTable,
      emptyTableMessage,
    }),
    [loading, loadingMessage, error, errorMessage, emptyTable, emptyTableMessage],
  )

  return value
}

export const TableProvider = ({ children, ...rest }: TableProps) => {
  const value = useTableProvider(rest)

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export const useTable = (): TableProps => {
  const context = useContext(TableContext)

  return context
}
