import { tw } from '@electro/shared/utils/tailwind-merge'
import { ForwardedRef, HTMLAttributes, ReactNode, forwardRef } from 'react'

interface Props extends HTMLAttributes<HTMLTableRowElement> {
  children: ReactNode | ReactNode[]
}

const styles = {
  root: 'hover:bg-secondary-dark',
}

export const TableRow = forwardRef(
  ({ className, children, ...rest }: Props, ref: ForwardedRef<HTMLTableRowElement>) => (
    <tr
      ref={ref}
      className={tw({
        [styles.root]: true,
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </tr>
  ),
)
