import { LazyQueryHookOptions, OperationVariables, QueryResult, useLazyQuery } from '@apollo/client'
import USER_QUERY from '@electro/consumersite/src/graphql/userQuery.graphql'

import { Query } from '@electro/consumersite/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

type LazyFetchUser = Pick<Query, 'me'>

type UseFetchUser = [
  ApolloLazyQueryFunction<LazyFetchUser>,
  QueryResult<LazyFetchUser, OperationVariables>,
]

interface UseFetchUserArgs {
  options?: LazyQueryHookOptions<LazyFetchUser, OperationVariables>
}

export function useFetchUser({ options }: UseFetchUserArgs = {}): UseFetchUser {
  const [fetchUser, userQuery] = useLazyQuery<LazyFetchUser>(USER_QUERY, options)

  return [fetchUser, userQuery]
}
