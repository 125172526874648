import useTranslation from 'next-translate/useTranslation'

import { usePlugAndChargeSignup } from '@electro/consumersite/src/components/PlugAndChargeSignup/hooks'
import { Button, Typography } from '@electro/shared-ui-components'
import { PncStagesEnum } from '../../types'

export const ConfirmVehicleDetailsStage = () => {
  const { t } = useTranslation('common')
  const [{ vehicle, loading, errorMessage }, { handleConfirmVehicle, setActiveStage }] =
    usePlugAndChargeSignup()

  const handleBack = () => {
    setActiveStage(PncStagesEnum.VEHICLE_REG_LOOKUP)
  }

  const handleEnterVin = () => {
    setActiveStage(PncStagesEnum.ENTER_VEHICLE_VIN)
  }

  return (
    <div>
      <Typography variant="h1" as="h1">
        {t('plug_and_charge.confirm_vehicle.title')}
      </Typography>
      <Typography variant="h1" as="p">
        {vehicle?.regNumber}
      </Typography>
      <Typography variant="p" as="p">
        {t('plug_and_charge.confirm_vehicle.found_your_vehicle')}
      </Typography>
      <Typography variant="p" as="p">
        {t('plug_and_charge.confirm_vehicle.looks_like_you_are_setting_up_a')}{' '}
        <strong>
          {vehicle?.info?.colour} {vehicle?.info?.make} {vehicle?.info?.model} (
          {vehicle?.info?.yearOfManufacture})
        </strong>
      </Typography>

      <Typography variant="p" as="p">
        {t('plug_and_charge.confirm_vehicle.create_certificate_on_click')}
      </Typography>

      {errorMessage && <div className="text-action-danger text-sm mt-2">{errorMessage}</div>}

      <div className="gap-4 flex flex-col mt-6">
        <Button fullWidth loading={loading} onClick={handleConfirmVehicle}>
          {t('utility.confirm')}
        </Button>

        <Button variant="naked" fullWidth onClick={handleBack}>
          {t('plug_and_charge.confirm_vehicle.not_your_vehicle')}
        </Button>
        <Button variant="naked" fullWidth onClick={handleEnterVin}>
          {t('plug_and_charge.confirm_vehicle.enter_vin_number')}
        </Button>
      </div>
    </div>
  )
}
