import ADD_KRAKEN_MEMBERSHIP_MUTATION from '@electro/consumersite/src/graphql/addKrakenMembershipMutation.graphql'
import { useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation } from '@electro/consumersite/generated/graphql'

type AddKrakenMembershipMutation = Pick<Mutation, 'addKrakenMembership'>

interface UseAddKrakenMembership {
  addKrakenMembershipMutation: ApolloMutationFunction<AddKrakenMembershipMutation>
}

export function useAddKrakenMembership(): UseAddKrakenMembership {
  const [addKrakenMembershipMutation] = useMutation<AddKrakenMembershipMutation>(
    ADD_KRAKEN_MEMBERSHIP_MUTATION,
  )

  return { addKrakenMembershipMutation }
}
