import { Locale } from '@electro/shared/types/locales'
import { SearchBoxRetrieveResponse } from '@mapbox/search-js-core'

const MAPBOX_SEARCHBOX_API_ENDPOINT = 'https://api.mapbox.com/search/searchbox/v1/reverse?'

interface FetchReverseLookupArgs {
  /** @lng is the longitude value to search around  */
  lng: number
  /** @lat is the latitude value to search around  */
  lat: number

  /**
   * @locale is requested language format. e.g. en-GB
   */
  locale?: Locale | string
  /**
   * @country is a comma-separated list of ISO 3166 alpha 2 country codes.
   * Results will be more relevant for the countries provided.
   */
  country?: string
  /**
   * @types is a comma separated list of types to filter results by.
   * e.g. 'country,region,postcode,district,place,city,locality,neighborhood,street,address,poi,category'
   */
  types?: string
  /**
   * @limit is the maximum number of results to return, up to 10.
   */
  limit?: string
}

/** https://docs.mapbox.com/api/search/search-box/#reverse-lookup */
export const fetchReverseLookup = async ({
  lng,
  lat,
  locale,
  country,
  types = 'postcode',
  limit = '1',
}: FetchReverseLookupArgs): Promise<SearchBoxRetrieveResponse> => {
  if (!process.env.NEXT_PUBLIC_MAPBOX_API_KEY) {
    return { url: '', type: 'FeatureCollection', features: [] }
  }

  const params = {
    longitude: lng.toString(),
    latitude: lat.toString(),
    access_token: process.env.NEXT_PUBLIC_MAPBOX_API_KEY,

    ...(locale ? { language: locale.slice(0, 2) } : {}),
    ...(country ? { country } : {}),
    ...(types ? { types } : {}),
    ...(limit ? { limit } : {}),
  }

  const requestURL = `${MAPBOX_SEARCHBOX_API_ENDPOINT}${new URLSearchParams(params)}`
  const response = await fetch(requestURL, { method: 'GET' })

  return response.json()
}
