export const searchbarSuggestions = {
  "en-GB": [
    {
      "id": "dXJuOm1ieHBvaTpmOWQzZGM4OC1jY2FiLTQwZTItYTc3OS03OTljZTJjNTFhMmQ",
      "name": "Natural History Museum / Cromwell Road (Stop GM)",
      "subtext": "London, SW7 5BD, United Kingdom",
      "type": "poi",
      "coordinates": {
        "lng": -0.1788882,
        "lat": 51.49579886
      }
    },
    {
      "id": "dXJuOm1ieHBsYzo2MUV1VHc",
      "name": "EH1 2NG",
      "subtext": "Edinburgh, Edinburgh, Scotland, United Kingdom",
      "type": "postcode",
      "coordinates": {
        "lng": -3.201478,
        "lat": 55.948965
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "Paris",
      "subtext": "France",
      "type": "region",
      "coordinates": {
        "lng": 2.348392,
        "lat": 48.853495
      },
      "bbox": [
        2.224229,
        48.815562,
        2.469851,
        48.902148
      ]
    },
    {
      "id": "123456789",
      "name": "Octopus HQ",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "es": [
    {
      "id": "dXJuOm1ieHBvaTpjYmIwOTBlZC03YWY2LTQwZjMtODY4Mi1jYmMyYzE4NjFiZjQ",
      "name": "Museo de Historia Natural",
      "subtext": "Cromwell Road, Londres, SW7 5BD, Reino Unido",
      "type": "poi",
      "coordinates": {
        "lng": -0.176206,
        "lat": 51.4964692
      }
    },
    {
      "id": "dXJuOm1ieHBsYzo2MUV1VHc",
      "name": "EH1 2NG",
      "subtext": "Edimburgo, Edimburgo, Escocia, Reino Unido",
      "type": "postcode",
      "coordinates": {
        "lng": -3.201478,
        "lat": 55.948965
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "París",
      "subtext": "Francia",
      "type": "region",
      "coordinates": {
        "lng": 2.348392,
        "lat": 48.853495
      },
      "bbox": [
        2.224229,
        48.815562,
        2.469851,
        48.902148
      ]
    },
    {
      "id": "123456789",
      "name": "Cuartel general del pulpo",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "fr": [
    {
      "id": "dXJuOm1ieHBvaTpjYmIwOTBlZC03YWY2LTQwZjMtODY4Mi1jYmMyYzE4NjFiZjQ",
      "name": "Musée d'Histoire Naturelle de Londres",
      "subtext": "Cromwell Road, Londres, SW7 5BD, Royaume-Uni",
      "type": "poi",
      "coordinates": {
        "lng": -0.176206,
        "lat": 51.4964692
      }
    },
    {
      "id": "dXJuOm1ieHBsYzo2MUV1VHc",
      "name": "EH1 2NG",
      "subtext": "Édimbourg, Édimbourg, Écosse, Royaume-Uni",
      "type": "postcode",
      "coordinates": {
        "lng": -3.201478,
        "lat": 55.948965
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "Paris",
      "subtext": "France",
      "type": "region",
      "coordinates": {
        "lng": 2.348392,
        "lat": 48.853495
      },
      "bbox": [
        2.224229,
        48.815562,
        2.469851,
        48.902148
      ]
    },
    {
      "id": "123456789",
      "name": "QG d'Octopus",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "it": [
    {
      "id": "dXJuOm1ieHBvaTpjYmIwOTBlZC03YWY2LTQwZjMtODY4Mi1jYmMyYzE4NjFiZjQ",
      "name": "Museo di storia naturale",
      "subtext": "Cromwell Road, Londra, SW7 5BD, Regno Unito",
      "type": "poi",
      "coordinates": {
        "lng": -0.176206,
        "lat": 51.4964692
      }
    },
    {
      "id": "dXJuOm1ieHBsYzo2MUV1VHc",
      "name": "EH1 2NG",
      "subtext": "Edimburgo, Edimburgo, Scozia, Regno Unito",
      "type": "postcode",
      "coordinates": {
        "lng": -3.201478,
        "lat": 55.948965
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "Parigi",
      "subtext": "Francia",
      "type": "region",
      "coordinates": {
        "lng": 2.348392,
        "lat": 48.853495
      },
      "bbox": [
        2.224229,
        48.815562,
        2.469851,
        48.902148
      ]
    },
    {
      "id": "123456789",
      "name": "Sede centrale del polpo",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "de": [
    {
      "id": "dXJuOm1ieHBvaTpmOWQzZGM4OC1jY2FiLTQwZTItYTc3OS03OTljZTJjNTFhMmQ",
      "name": "Natural History Museum / Cromwell Road (Stop GM)",
      "subtext": "London, SW7 5BD, Vereinigtes Königreich",
      "type": "poi",
      "coordinates": {
        "lng": -0.1788882,
        "lat": 51.49579886
      }
    },
    {
      "id": "dXJuOm1ieHBsYzo2MUV1VHc",
      "name": "EH1 2NG",
      "subtext": "Edinburgh, Edinburgh, Schottland, Vereinigtes Königreich",
      "type": "postcode",
      "coordinates": {
        "lng": -3.201478,
        "lat": 55.948965
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "Paris",
      "subtext": "Frankreich",
      "type": "region",
      "coordinates": {
        "lng": 2.348392,
        "lat": 48.853495
      },
      "bbox": [
        2.224229,
        48.815562,
        2.469851,
        48.902148
      ]
    },
    {
      "id": "123456789",
      "name": "Octopus-Hauptquartier",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ],
  "pt-PT": [
    {
      "id": "dXJuOm1ieHBvaTpmOWQzZGM4OC1jY2FiLTQwZTItYTc3OS03OTljZTJjNTFhMmQ",
      "name": "Natural History Museum / Cromwell Road (Stop GM)",
      "subtext": "Londres, SW7 5BD, Reino Unido",
      "type": "poi",
      "coordinates": {
        "lng": -0.1788882,
        "lat": 51.49579886
      }
    },
    {
      "id": "dXJuOm1ieHBsYzo2MUV1VHc",
      "name": "EH1 2NG",
      "subtext": "Edimburgo, Edimburgo, Escócia, Reino Unido",
      "type": "postcode",
      "coordinates": {
        "lng": -3.201478,
        "lat": 55.948965
      }
    },
    {
      "id": "dXJuOm1ieHBsYzpEYVJO",
      "name": "Paris",
      "subtext": "França",
      "type": "region",
      "coordinates": {
        "lng": 2.348392,
        "lat": 48.853495
      },
      "bbox": [
        2.224229,
        48.815562,
        2.469851,
        48.902148
      ]
    },
    {
      "id": "123456789",
      "name": "QG do Polvo",
      "subtext": "38°10'24.0\"N 27°00'33.0\"W",
      "coordinates": {
        "lng": -27.009158334665926,
        "lat": 38.17333864152954
      },
      "type": "octopus_hq",
      "maxZoom": 7
    }
  ]
}

export const countryBoundingBoxes = {
  "en-GB": {
    "id": "dXJuOm1ieHBsYzpJazg",
    "name": "United Kingdom",
    "type": "country",
    "coordinates": {
      "lng": -2.36967,
      "lat": 54.237933
    },
    "bbox": [
      -8.737768,
      49.807775,
      1.860413,
      60.905572
    ]
  },
  "es": {
    "id": "dXJuOm1ieHBsYzpJa1k",
    "name": "España",
    "type": "country",
    "coordinates": {
      "lng": -4.055685,
      "lat": 41.294856
    },
    "bbox": [
      -18.255774,
      27.554509,
      4.424429,
      43.859277
    ]
  },
  "fr": {
    "id": "dXJuOm1ieHBsYzpJazA",
    "name": "France",
    "type": "country",
    "coordinates": {
      "lng": 2.618787,
      "lat": 47.824905
    },
    "bbox": [
      -5.239048,
      41.290592,
      9.659969,
      51.151934
    ]
  },
  "it": {
    "id": "dXJuOm1ieHBsYzpJbkE",
    "name": "Italia",
    "type": "country",
    "coordinates": {
      "lng": 12.646361,
      "lat": 42.504154
    },
    "bbox": [
      6.626832,
      35.412053,
      18.615704,
      47.092146
    ]
  },
  "de": {
    "id": "dXJuOm1ieHBsYzpJam8",
    "name": "Deutschland",
    "type": "country",
    "coordinates": {
      "lng": 10.018343,
      "lat": 51.133481
    },
    "bbox": [
      5.866315,
      47.270114,
      15.041797,
      55.151245
    ]
  },
  "pt-PT": {
    "id": "dXJuOm1ieHBsYzpJcm8",
    "name": "Portugal",
    "type": "country",
    "coordinates": {
      "lng": -8.562731,
      "lat": 39.600995
    },
    "bbox": [
      -31.334986,
      29.951211,
      -6.189178,
      42.154227
    ]
  }
}

