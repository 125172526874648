import ConstantineIcon from './constantine.svg'
import ElectroverseMobileDeviceIcon from './electroverse-mobile-device.svg'
import PercentIcon from './percent.svg'
import ReceiptXIcon from './receipt-x.svg'
import ReceiptIcon from './receipt.svg'
import RoutePlannerIcon from './route-planner.svg'

export {
  ConstantineIcon,
  ElectroverseMobileDeviceIcon,
  PercentIcon,
  ReceiptXIcon,
  ReceiptIcon,
  RoutePlannerIcon,
}
