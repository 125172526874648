import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { ReactNode } from 'react'

interface ErrorMessageProps {
  titleText?: string
  bodyText?: string
  children?: string | ReactNode
}

export const ErrorMessage = ({
  titleText = 'utility.something_went_wrong.title',
  bodyText = 'utility.something_went_wrong.body',
  children,
}: ErrorMessageProps) => {
  const styles = {
    root: 'mx-auto h-screen w-screen flex flex-col justify-center items-center content-center text-center bg-gradient-mesh',
    logo: 'flex justify-center pb-5 -mb-10',
    title: 'text-3xl text-white',
    copy: 'px-12 text-lg text-white',
  }

  const { t } = useTranslation('common')

  return (
    <div className={styles.root}>
      <div className="max-w-xl">
        <div className={styles.logo}>
          <Image
            src="/images/mr-zap-oh-no.png"
            alt="Mr Zap looking very sad because he has dropped his ice cream!"
            width="317"
            height="317"
            priority
          />
        </div>
        <h1 className={styles.title}>{t(titleText)}</h1>
        <p className={styles.copy}>{t(bodyText)}</p>
        {children}
      </div>
    </div>
  )
}
