import { useSavedRoutes } from '@electro/consumersite/src/components/Map/hooks'
import {
  SavedRoutesNoRoutes,
  SavedRoutesSummaryButton,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/SavedRoutes/components'

export const SavedRoutesPanelContent = () => {
  const [{ savedRoutes }] = useSavedRoutes()

  if (savedRoutes?.length === 0) return <SavedRoutesNoRoutes />

  return savedRoutes.map((route) => <SavedRoutesSummaryButton route={route} key={route.planUuid} />)
}
