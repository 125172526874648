import useTranslation from 'next-translate/useTranslation'
import { useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'

export const SidebarNavLabelsPanelContent = () => {
  const { t } = useTranslation('common')
  const [{ hiddenPanels }] = useMapSidebar()

  const navLabelsTranslationMap: Partial<Record<SidebarPanels, string>> = {
    [SidebarPanels.MAP]: t('nav.menu.item.map'),
    [SidebarPanels.FILTERS]: t('map.filters.title'),
    [SidebarPanels.ROUTE_PLANNER]: t('map.route_planner.title'),
    [SidebarPanels.MY_EVS]: t('map.my_evs.title'),
    [SidebarPanels.ELECTROVERSE_FEATURES]: t('map.electroverse_features.title'),
    [SidebarPanels.FEEDBACK]: t('map.feedback.title'),
    [SidebarPanels.KEY]: t('map.key.title'),
  }

  const visibleNavLabels = (
    Object.entries(navLabelsTranslationMap) as [key: SidebarPanels, value: string][]
  ).filter(([key]) => !hiddenPanels.includes(key))

  return visibleNavLabels.map(([, value]) => (
    <p key={value} aria-hidden="true">
      {value}
    </p>
  ))
}
