// Allows functions to make use of the tailwind breakpoints for screen sizes
// Always use CSS as a preference. This is more useful for adjusting viewport calculations
// E.g. Changing the map zoom padding depending on where elements exist for different breakpoints
import { screens } from '@electro/shared/theme/electro'

const breakpoints = {
  sm: parseInt(screens.sm, 10),
  md: parseInt(screens.md, 10),
  lg: parseInt(screens.lg, 10),
  xl: parseInt(screens.xl, 10),
  '2xl': parseInt(screens['2xl'], 10),
}

export const getResponsiveBreakpoint = () => {
  if (window.innerWidth < breakpoints.sm) return 'DEFAULT'
  if (window.innerWidth < breakpoints.md) return 'sm'
  if (window.innerWidth < breakpoints.lg) return 'md'
  if (window.innerWidth < breakpoints.xl) return 'lg'
  if (window.innerWidth < breakpoints['2xl']) return 'xl'
  return '2xl'
}

export const isBreakpointDEFAULT = () => getResponsiveBreakpoint() === 'DEFAULT'
export const isBreakpointSM = () => getResponsiveBreakpoint() === 'sm'
export const isBreakpointMD = () => getResponsiveBreakpoint() === 'md'
export const isBreakpointLG = () => getResponsiveBreakpoint() === 'lg'
export const isBreakpointXL = () => getResponsiveBreakpoint() === 'xl'
export const isBreakpoint2XL = () => getResponsiveBreakpoint() === '2xl'
