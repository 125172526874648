export const FiltersIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.56439 16.9906C9.56439 18.4923 8.48326 19.2708 7.14962 19.2708C5.81598 19.2708 4.73485 18.4923 4.73485 16.9906M9.56439 16.9906C9.56439 15.489 8.48326 14.5833 7.14962 14.5833C5.81598 14.5833 4.73485 15.489 4.73485 16.9906M9.56439 16.9906H20.8333M4.73485 16.9906H3.125"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3985 8.54744C14.3985 7.04578 15.4796 6.26727 16.8133 6.26727C18.1469 6.26727 19.228 7.04578 19.228 8.54744M14.3985 8.54744C14.3985 10.0491 15.4796 10.9548 16.8133 10.9548C18.1469 10.9548 19.228 10.0491 19.228 8.54744M14.3985 8.54744H3.12956M19.228 8.54744H20.8379"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
