import { SidebarNavIcon } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { MapIcon } from '@heroicons/react/24/outline'

const styles = {
  navIcon: 'w-full p-px',
}

export const MapSidebarIcon = () => (
  <SidebarNavIcon panelName={SidebarPanels.MAP}>
    <MapIcon className={styles.navIcon} />
  </SidebarNavIcon>
)
