import { useState, createContext, useContext, ReactNode, useCallback, useMemo } from 'react'
import { MapModalScreenName } from '@electro/consumersite/src/components/Map/types'

interface MapModalProviderProps {
  children: ReactNode | ReactNode[]
}

const MapModalContext = createContext(null)

interface State {
  activeModalScreen: MapModalScreenName
  previousModalScreen: MapModalScreenName
}

interface Handlers {
  isActiveModalScreen: (modalName: MapModalScreenName) => boolean
  setActiveModalScreen: (nextModal: MapModalScreenName) => () => void
  closeModal: () => void
}

type UseMapModalController = [state: State, handlers: Handlers]

function useProvideMapModal(): UseMapModalController {
  const [activeModalScreen, setActive] = useState<MapModalScreenName>(null)
  const [previousModalScreen, setPreviousModal] = useState<MapModalScreenName>(null)

  const setActiveModalScreen = useCallback(
    (nextModalName: MapModalScreenName) => () => {
      setPreviousModal(activeModalScreen)
      setActive(nextModalName)
    },
    [activeModalScreen],
  )

  const closeModal = useCallback(() => setActive(null), [])

  const isActiveModalScreen = useCallback(
    (modalName: MapModalScreenName | null) => activeModalScreen === modalName,
    [activeModalScreen],
  )

  const state = useMemo(
    () => ({
      activeModalScreen,
      previousModalScreen,
    }),
    [activeModalScreen, previousModalScreen],
  )

  const handlers = useMemo(
    () => ({
      closeModal,
      isActiveModalScreen,
      setActiveModalScreen,
    }),
    [closeModal, isActiveModalScreen, setActiveModalScreen],
  )

  return [state, handlers]
}

export const MapModalProvider = ({ children }: MapModalProviderProps) => {
  const value = useProvideMapModal()
  return <MapModalContext.Provider value={value}>{children}</MapModalContext.Provider>
}

export const useMapModal = (): UseMapModalController => {
  const context = useContext(MapModalContext)
  if (!context) throw new Error('useMapModal() cannot be used outside of <MapModalProvider/>')
  return context
}
