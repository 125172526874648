import { tw } from '@electro/shared/utils/tailwind-merge'
import { ForwardedRef, ReactNode, forwardRef, HTMLAttributes } from 'react'
import { useTable } from '../Table/TableContext'
import { TableRow } from '../TableRow'
import { TableCell } from '../TableCell'

interface Props extends HTMLAttributes<HTMLTableSectionElement> {
  children: ReactNode | ReactNode[]
}

export const TableBody = forwardRef(
  ({ className, children, ...rest }: Props, ref: ForwardedRef<HTMLTableSectionElement>) => {
    const { loading, loadingMessage, error, errorMessage, emptyTable, emptyTableMessage } =
      useTable()

    return (
      <tbody
        ref={ref}
        className={tw({
          [className]: !!className,
        })}
        {...rest}
      >
        {loading && (
          <TableRow>
            <TableCell colSpan={1000} className="text-center">
              {loadingMessage}
            </TableCell>
          </TableRow>
        )}

        {!loading && error && (
          <TableRow>
            <TableCell colSpan={1000} className="text-center">
              {errorMessage}
            </TableCell>
          </TableRow>
        )}

        {!loading && !error && emptyTable && (
          <TableRow>
            <TableCell colSpan={1000} className="text-center">
              {emptyTableMessage}
            </TableCell>
          </TableRow>
        )}

        {!loading && !error && children}
      </tbody>
    )
  },
)
