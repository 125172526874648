import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from 'react'
import { useDebounce, useMount } from 'react-use'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Skeleton } from '@electro/shared-ui-components'
import { useAuth } from '@electro/consumersite/src/hooks'
import { useHandleThirdPartySignIn } from '@electro/consumersite/src/components/Login/hooks/useHandlethirdPartySignIn'
import { ApolloErrorWithErrorCode } from '@electro/consumersite/types/errorCodes'
import GoogleLogo from '@electro/consumersite/public/images/google-logo.svg'

interface UseGoogleSignInProps {
  children: ReactNode | ReactNode[]
}

interface State {
  initialised: boolean
  loading: boolean
  error: ApolloErrorWithErrorCode
}

type UseGoogleSignIn = [state: State]

const GOOGLE_SIGN_IN_BUTTON_ID = 'gsi-button'

const UseGoogleSignInContext = createContext<UseGoogleSignIn>(null)

const GoogleSignIn = ({ children }) => <UseGoogleSignInProvider>{children}</UseGoogleSignInProvider>

interface ButtonProps {
  text?: 'signup_with' | 'signin_with' | 'continue_with' | 'signin'
  className?: string
}

function useGoogleSignInProvider(): UseGoogleSignIn {
  const [initialised, setInitialised] = useState<boolean>(false)
  const [{ loading, error }, { handleThirdPartySignIn }] = useHandleThirdPartySignIn()

  const handleCredentialResponse = useCallback(
    async (response) => {
      handleThirdPartySignIn({
        idToken: response.credential,
        providerName: 'GOOGLE', // This value will be changed to an enum in the schema, for now we are simply hardcoding it.
      })
    },
    [handleThirdPartySignIn],
  )

  const initializeGsi = () => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_SIGN_IN_CLIENT_ID,
        callback: handleCredentialResponse,
      })
      setInitialised(true)
    }
  }

  const loadGoogleSignInScript = (onLoadCallback: () => void) => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    script.onload = onLoadCallback
    document.body.appendChild(script)
  }

  useMount(() => {
    loadGoogleSignInScript(initializeGsi)
  })

  const state = useMemo(
    () => ({
      initialised,
      loading,
      error,
    }),
    [initialised, loading, error],
  )

  return [state]
}

export const UseGoogleSignInProvider = ({ children }: UseGoogleSignInProps) => {
  const ctx = useGoogleSignInProvider()
  return <UseGoogleSignInContext.Provider value={ctx}>{children}</UseGoogleSignInContext.Provider>
}

export const useGoogleSignIn = (): UseGoogleSignIn => {
  const context = useContext(UseGoogleSignInContext)
  if (!context)
    throw new Error('useGoogleSignIn() cannot be used outside of <UseGoogleSignInProvider/>')
  return context
}

const Prompt = () => {
  const router = useRouter()
  const [{ initialised }] = useGoogleSignIn()
  const [{ session, sessionLoading }] = useAuth()

  const showPrompt = useMemo(() => {
    // paths where we don't want to trigger the google sign in prompt.
    const BLACKLISTED_PATHS = [
      '/sign-up',
      '/log-in',
      '/user',
      '/where-in-the-universe',
      '/electrocard',
      '/map/all',
      '/map/embed',
      '/operators',
    ]
    const pathAllowed = !BLACKLISTED_PATHS.some((path) => router.asPath.match(path))

    return pathAllowed && !session && !sessionLoading && initialised
  }, [initialised, router.asPath, session, sessionLoading])

  useEffect(() => {
    if (showPrompt && window.google) {
      /**
       * Triggers a 'sign in with google' modal across the website.
       * The callback for this is handled by handleCredentialResponse()
       * in the useGoogleSignInProvider hook.
       */
      window.google.accounts.id.prompt()
    }
  }, [showPrompt])
  return null
}

const Button = ({ text = 'continue_with', className }: ButtonProps) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const [{ initialised }] = useGoogleSignIn()
  const gsiButtonRef = useRef<HTMLDivElement>(null)
  const [buttonWidth, setButtonWidth] = useState(400)
  const [resizing, setResizing] = useState(false)

  const sharedButtonProps = useMemo(
    () => ({
      theme: 'outline',
      size: 'large',
      text,
      shape: 'pill',
      locale,
    }),
    [locale, text],
  )

  useDebounce(
    () => {
      /**
       * Debouncing the button width so we don't trigger re-renders on every resize event.
       */
      if (window.google && initialised) {
        window.google.accounts.id.renderButton(document.getElementById(GOOGLE_SIGN_IN_BUTTON_ID), {
          ...sharedButtonProps,
          width: `${buttonWidth}`,
        })
      }
    },
    200,
    [buttonWidth],
  )

  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null

    /**
     * We need to observe the button's width to ensure that the Google Sign In button
     * is always rendered with the correct width. We're doing this because the button
     * does not come with an auto-resize feature.
     */
    if (gsiButtonRef.current) {
      resizeObserver = new ResizeObserver((entries) => {
        if (entries[0].contentRect.width !== buttonWidth) {
          setResizing(true)
          setButtonWidth(entries[0].contentRect.width)
        }
      })
      resizeObserver.observe(gsiButtonRef.current)
    }

    return () => {
      if (resizeObserver) {
        setResizing(false)
        resizeObserver.disconnect()
      }
    }
  }, [buttonWidth, initialised, locale, sharedButtonProps, text, resizing])

  return !resizing && initialised ? (
    <div className="relative">
      {/**
       * Here we're rendering a cover over the Google Sign In button to keep design consistency.
       * The actual Google Sign In button is rendered inside the div with the id GOOGLE_SIGN_IN_BUTTON_ID.
       */}
      <div
        data-testid="display-only-google-click-through-cover"
        className="h-10 w-full rounded-full bg-white absolute top-0 left-0 z-50 pointer-events-none flex items-center justify-center text-center"
      >
        <GoogleLogo className="w-6 h-6  flex-shrink-0 ml-2.5" />
        <div className="flex-grow w-full pr-3">{t('oauth.login.continue_with_google')}</div>
      </div>
      <div
        id={GOOGLE_SIGN_IN_BUTTON_ID}
        // setting !important here to prevent a small layout shift in the button when it's rendered.
        className={`!h-11 !overflow-hidden ${className}`}
        ref={gsiButtonRef}
      >
        <Skeleton variant="circular" height={40} />
      </div>
    </div>
  ) : null
}

GoogleSignIn.Button = Button
GoogleSignIn.Prompt = Prompt

export { GoogleSignIn }
