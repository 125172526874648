export enum CountryCodes {
  GBR = 'GBR',
  FRA = 'FRA',
  ESP = 'ESP',
  ITA = 'ITA',
  DEU = 'DEU',
  PRT = 'PRT',
}

export type CountryCode = `${CountryCodes}`
