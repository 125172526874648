import { ErrorCode } from '@electro/consumersite/generated/graphql'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong! Try again later.'

export function getMessageFromErrorCode(errorCode: string): string {
  switch (errorCode) {
    case ErrorCode.PncContractDataCreationError:
      return 'plug_and_charge.error_codes.vehicle_added_already'
    case ErrorCode.PncContractInvalidPaymentMethod:
      return 'plug_and_charge.error_codes.invalid_payment_method'
    case ErrorCode.PncProviderError:
      return 'plug_and_charge.error_codes.pnc_provider_error'
    case ErrorCode.ResetPasswordTokenDoesNotExist:
      return 'errors.reset_password_expired_token'
    case ErrorCode.ThirdPartyAuthIncorrectFlow:
      return 'errors.third_party_auth_incorrect_flow'
    default:
      return DEFAULT_ERROR_MESSAGE
  }
}
