/* eslint-disable react/jsx-no-useless-fragment */

import { ReactNode } from 'react'
import { CountryCodes } from '@electro/shared/types/countries'
import { useUserCountry } from '@electro/consumersite/src/hooks/useUserCountry'

interface RestrictToCountriesProps {
  countries: CountryCodes[]
  children: ReactNode | ReactNode[] | string
}

export const RestrictToCountries = ({ countries, children }: RestrictToCountriesProps) => {
  const { restrictToCountries } = useUserCountry()

  return restrictToCountries(countries) ? <>{children}</> : null
}
