import { useMount } from 'react-use'
import { Transition } from '@headlessui/react'
import { HTMLAttributes, useEffect, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { mapSidebarContainerStyling } from '@electro/consumersite/src/components/Map/components/MapSidebar'
import { useMapConfig, useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarPanelsType } from '@electro/consumersite/src/components/Map/types'
import { ThickArrowLeftIcon } from '@electro/consumersite/src/icons'
import { IconButton, ScrollShadow } from '@electro/shared-ui-components'
import { getScrollbarVisible } from '@electro/consumersite/src/utils/getScrollbarVisible'

const styles = {
  container: {
    root: 'z-10 flex',
    hiddenOnInitialRender: 'hidden',
    basicShapeWhenClosed: 'w-12 [&>div]:my-4',
  },
  verticalSlices: {
    // Slices are both mounted and use an !important display property for scaling/translating to work correctly
    centre: '!inline bg-base/80 border-secondary backdrop-blur border-y-2 w-[623px]',
    outer: {
      root: '!inline bg-base/80 border-secondary backdrop-blur border-2 w-5',
      left: 'w-5 border-2 border-r-0 rounded-l-xl',
      right: 'w-5 border-2 border-l-0 rounded-r-xl',
    },
  },
  content: {
    root: tw(
      'z-20 absolute flex flex-col ml-8 pl-3 pr-0.5 pt-3',
      'w-full max-w-[calc(100%-34px)]', // Prevent content overflowing the right slice
    ),
    label: tw(
      'flex items-center gap-x-2 pb-1 -mb-0.5 ml-1 2xs:ml-4 mr-4',
      'border-b-2 border-secondary/50 text-lg',
    ),
    backArrow: 'w-6 text-secondary',
    shadow: {
      root: 'h-full overflow-y-hidden rounded-br-lg',
      showStickyButtons: 'rounded-none mb-14',
    },
    children: {
      root: tw(
        'h-full overflow-y-scroll overflow-x-hidden',
        'flex flex-col gap-y-3 pt-3 pb-10 pl-1 2xs:pl-4',
        'pr-1 [@supports_not_(scrollbar-width:thin)]:pr-2',
      ),
      noScrollbar: 'pr-4 [@supports_not_(scrollbar-width:thin)]:pr-4',
    },
  },
}

interface SidebarPanelContainerProps extends HTMLAttributes<HTMLDivElement> {
  panelType?: SidebarPanelsType
  label?: string
  showStickyButtons?: boolean
  onBackArrowClick?: () => void
}

/** Utilises 9-slice scaling to create a clean animation: https://pqina.nl/blog/animating-width-and-height-without-the-squish-effect/ */
export const SidebarPanelContainer = ({
  className,
  children,
  panelType,
  label,
  showStickyButtons,
  onBackArrowClick,
}: SidebarPanelContainerProps) => {
  const [{ showNavbar }] = useMapConfig()
  const [{ visiblePanel }, { hidePanel }] = useMapSidebar()

  const [hideOnInitialRender, setHideOnInitialRender] = useState<boolean>(true)
  const [scrollbarVisible, setScrollbarVisible] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)

  // Prevent a blip of the container being visible on initial render
  useMount(() => setTimeout(() => setHideOnInitialRender(false), 300))

  // Adjust padding on panel based on the scrollbar visibility
  useMount(() => setScrollbarVisible(getScrollbarVisible()))

  useEffect(() => {
    // Shrink the container to fix a DOM order issue. We set visibility to false after the animation ends
    if (visiblePanel === panelType) setVisible(true)
  }, [visiblePanel, panelType])

  return (
    <div
      data-testid={`map-${panelType}-panel`}
      className={tw({
        [mapSidebarContainerStyling.all]: true,
        [mapSidebarContainerStyling.withPadding]: showNavbar,
        [styles.container.root]: true,
        [styles.container.hiddenOnInitialRender]: hideOnInitialRender,
        [styles.container.basicShapeWhenClosed]: !visible,
      })}
    >
      <div className={tw(styles.verticalSlices.outer.root, styles.verticalSlices.outer.left)} />
      <Transition
        // Panels are defined in their open state for frosted glass to work on Chrome.
        // The animation to "show" them actually shrinks the panels.
        // Likewise "closing" them resets them to full size. Hence, the hardcoded animation sizing values.
        appear
        show={visiblePanel !== panelType}
        unmount={false}
        className={styles.verticalSlices.centre}
        enter="transition-transform ease-in-out duration-500 delay-100"
        enterFrom="origin-left scale-x-[1]"
        enterTo="origin-left scale-x-[0.08]"
        leave="transition-transform ease-in-out duration-500 delay-100"
        leaveFrom="origin-left scale-x-[0.08]"
        leaveTo="origin-left scale-x-[1]"
        afterEnter={() => setVisible(false)}
      />
      <Transition
        appear
        show={visiblePanel !== panelType}
        unmount={false}
        className={tw(styles.verticalSlices.outer.root, styles.verticalSlices.outer.right)}
        enter="transition-transform ease-in-out duration-500 origin-left delay-100"
        enterFrom="translate-x-[-1px]"
        enterTo="translate-x-[-336px]"
        leave="transition-transform ease-in-out duration-500 origin-left delay-100"
        leaveFrom="translate-x-[-336px]"
        leaveTo="translate-x-[-1px]"
      />

      <Transition
        // The content transition works more predictably. It also unmounts the content when not visible.
        show={visiblePanel === panelType}
        className={tw({
          [mapSidebarContainerStyling.all]: true,
          [mapSidebarContainerStyling.withPadding]: showNavbar,
          [styles.content.root]: true,
          [className]: !!className,
        })}
        enter="transition-opacity ease-in-out duration-300 delay-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {label ? (
          <div className={styles.content.label}>
            <IconButton
              onClick={() => {
                hidePanel()
                onBackArrowClick?.()
              }}
            >
              <ThickArrowLeftIcon className={styles.content.backArrow} />
            </IconButton>
            {label}
          </div>
        ) : null}

        <ScrollShadow
          className={tw({
            [styles.content.shadow.root]: true,
            [styles.content.shadow.showStickyButtons]: showStickyButtons,
          })}
          data-testid={`map-${panelType}-content`}
        >
          <div
            className={tw({
              [styles.content.children.root]: true,
              [styles.content.children.noScrollbar]: !scrollbarVisible,
            })}
          >
            {children}
          </div>
        </ScrollShadow>
      </Transition>
    </div>
  )
}
