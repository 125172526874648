const ellipses = '...'

interface TruncatedTextProps {
  children: string
  maxLength: number
}

export const TruncatedText = ({ children, maxLength }: TruncatedTextProps) => {
  let truncatedText = children

  if (truncatedText.length > maxLength) {
    truncatedText = `${truncatedText.slice(0, maxLength + ellipses.length)}...`
  }

  return truncatedText
}
