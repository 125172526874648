import DELETE_USER_VEHICLE_MUTATION from '@electro/consumersite/src/graphql/deleteUserVehicleMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation, MutationDeleteUserVehicleArgs } from '@electro/consumersite/generated/graphql'

type DeleteUserVehicleMutation = Pick<Mutation, 'deleteUserVehicle'>

type UseDeleteUserVehicle = [
  ApolloMutationFunction<DeleteUserVehicleMutation>,
  MutationResult<DeleteUserVehicleMutation>,
]

type UseAddVehicleArgs = MutationHookOptions<
  DeleteUserVehicleMutation,
  MutationDeleteUserVehicleArgs
>

export function useDeleteUserVehicle(options?: UseAddVehicleArgs): UseDeleteUserVehicle {
  const [DeleteUserVehicleMutation, DeleteUserVehicleResult] =
    useMutation<DeleteUserVehicleMutation>(DELETE_USER_VEHICLE_MUTATION, options)

  return [DeleteUserVehicleMutation, DeleteUserVehicleResult]
}
