import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { useUpdateEffect } from 'react-use'
import useTranslation from 'next-translate/useTranslation'
import { useToastNotification } from '@electro/shared-ui-components'
import { useMapDrawer } from '@electro/consumersite/src/components/Map/hooks'
import { DrawerPanels } from '@electro/consumersite/src/components/Map/types'
import {
  ChargingLocationFragment,
  ChargingLocationMetadataType,
  useChargingLocationFragmentQuery,
  useChargingLocationQuery,
} from '@electro/consumersite/generated/graphql'
import {
  featureFlagEnableGoogleRecaptchaOnLocations,
  featureFlagEnableMapLocationSharing,
} from '@electro/consumersite/src/utils/envFeatureFlags'
import { verifyWithRecaptcha } from '@electro/consumersite/src/utils/verifyWithRecaptcha'

declare global {
  interface Window {
    grecaptcha: any
  }
}

interface State {
  locationID: string
  locationFragment: ChargingLocationFragment
  locationLoading: boolean
  locationData: ChargingLocationMetadataType
}

interface Handlers {
  updateLocationID: (pk: string) => Promise<ChargingLocationFragment>
  clearLocationID: () => void
}

type UseMapLocationID = [state: State, handlers: Handlers]
const UseMapLocationIDContext = createContext<UseMapLocationID>(null)

function useMapLocationIDProvider(): UseMapLocationID {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [, { updateVisibleDrawer }] = useMapDrawer()
  const { showToastNotification } = useToastNotification()

  const { refetch: fetchLocationFragment } = useChargingLocationFragmentQuery({ skip: true })
  const { refetch: fetchLocationData } = useChargingLocationQuery({
    skip: true,
    fetchPolicy: 'cache-and-network',
  })

  const [locationID, setLocationID] = useState<string>()
  const [locationFragment, setLocationFragment] = useState<ChargingLocationFragment>()
  const [locationLoading, setLocationLoading] = useState<boolean>(false)
  const [locationData, setLocationData] = useState<ChargingLocationMetadataType>()

  /** Adds chargingLocationPk to the URL to enable location sharing and mobile deep linking */
  const addLocationIDToURL = useCallback(
    (id: string) => {
      /**
       * If the feature flag is disabled, we do not want to add the location ID to the URL.
       * This is because sharing the URL won't load the location when this is off.
       */
      if (!featureFlagEnableMapLocationSharing) return
      if (router.asPath) {
        const { pathname, search, hash } = new URL(router.asPath, window.location.origin)

        const splitURL = pathname.split('/')
        const locationIndex = splitURL.indexOf('location')

        if (id) {
          if (locationIndex > 0) splitURL.splice(locationIndex + 1, 1, id).join('/')
          else splitURL.push('location', id)

          router.replace(`${splitURL.join('/')}${search}${hash}`, undefined, { shallow: true })
        }
      }
    },
    [router],
  )

  /** Removes /location/123456 from the URL if it exists */
  const removeLocationIDFromURL = useCallback(() => {
    if (router.asPath) {
      const { pathname, search, hash } = new URL(router.asPath, window.location.origin)

      const splitURL = pathname.split('/')
      const locationIndex = splitURL.indexOf('location')

      if (locationIndex > 0) {
        splitURL.splice(locationIndex, 2)

        router.replace(`${splitURL.join('/')}${search}${hash}`, undefined, { shallow: true })
      }
    }
  }, [router])

  /** Handles multiple interactions with the charging location query.
   * Including fetching a subset of data, fetching the full data,
   * adding the location ID to the URL and query error handling.
   *
   * Returns the subset of charger data. */
  const updateLocationID = useCallback(
    async (pk: string) => {
      try {
        if (featureFlagEnableGoogleRecaptchaOnLocations) {
          const recaptchaSuccess = await verifyWithRecaptcha()

          if (!recaptchaSuccess) {
            /**
             * At the moment this will clear the location ID and show an error notification.
             * It won't close the Location side panel without a substantial refactor
             * to the way the side panel toggle is managed.
             */
            setLocationData(null)
            setLocationID(null)
            setLocationLoading(false)
            removeLocationIDFromURL()
            showToastNotification({
              variant: 'error',
              heading: t('common.error'),
              body: t('error.location.blocked_by_recaptcha'),
            })
            // redirect bots to be counted in analytics.
            router.push('/map/no-bots')
            return null
          }
        }

        const { data: fragmentData } = await fetchLocationFragment({ pk })

        if (fragmentData?.chargingLocation) {
          setLocationFragment(fragmentData.chargingLocation)
          addLocationIDToURL(pk)
          setLocationID(pk)

          setLocationLoading(true)
          fetchLocationData({ pk }).then(({ data: fullData }) => {
            setLocationLoading(false)
            if (fullData?.chargingLocation) {
              setLocationData(fullData.chargingLocation as ChargingLocationMetadataType)
            }
          })

          return fragmentData.chargingLocation as ChargingLocationFragment
        }
      } catch (error) {
        showToastNotification({ variant: 'error', heading: t('common.error'), body: error.message })
      }

      setLocationLoading(false)
      return undefined
    },
    [
      fetchLocationFragment,
      removeLocationIDFromURL,
      addLocationIDToURL,
      fetchLocationData,
      showToastNotification,
      t,
      router,
    ],
  )

  /** Reset the context hook to default state */
  const clearLocationID = useCallback(() => {
    setLocationID(undefined)
    setLocationFragment(undefined)
    setLocationLoading(false)
    setLocationData(undefined)
    removeLocationIDFromURL()
  }, [removeLocationIDFromURL])

  /** Show the location drawer if locationID is defined */
  useUpdateEffect(() => {
    updateVisibleDrawer(locationID ? DrawerPanels.LOCATION_DETAILS : undefined)
  }, [locationID, updateVisibleDrawer])

  const state = useMemo(
    () => ({ locationID, locationFragment, locationLoading, locationData }),
    [locationID, locationFragment, locationLoading, locationData],
  )

  const handlers = useMemo(
    () => ({ updateLocationID, clearLocationID }),
    [updateLocationID, clearLocationID],
  )

  return [state, handlers]
}

export const UseMapLocationIDProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useMapLocationIDProvider()
  return <UseMapLocationIDContext.Provider value={ctx}>{children}</UseMapLocationIDContext.Provider>
}

export const useMapLocationID = (): UseMapLocationID => {
  const context = useContext(UseMapLocationIDContext)
  if (!context)
    throw new Error('useMapLocationID() cannot be used outside of <UseMapLocationIDProvider/>')
  return context
}
