import { useEffect, useState } from 'react'
import Image from 'next/image'
import NextHead from 'next/head'

import useTranslation from 'next-translate/useTranslation'
import { GTM } from '../../utils/event-triggers'

interface SharingLink {
  title: string
  id: string
  sharingUrl: string
  image: string
  platform: string
}

interface SocialSharingProps {
  pageDescription: string
}

export const SocialSharing = ({ pageDescription }: SocialSharingProps) => {
  const { t } = useTranslation('common')
  const [socialData, setSocialData] = useState<SharingLink[]>(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pageHref = `${window.location.origin}${window.location.pathname}`
      setSocialData([
        {
          platform: 'twitter',
          title: 'Share on Twitter',
          id: 'tw',
          sharingUrl: `http://twitter.com/share?url=${pageHref}&text=Interested in learning about EVs? Check out this article from Octopus Electroverse:&hashtags=evs,electricjuice,octopusenergy`,
          image: '/images/tw-share.svg',
        },
        {
          platform: 'facebook',
          title: 'Share on Facebook',
          id: 'fb',
          sharingUrl: `https://www.facebook.com/share.php?u=${pageHref}`,
          image: '/images/fb-share.svg',
        },
        {
          platform: 'linkedIn',
          title: 'Share on LinkedIn',
          id: 'li',
          sharingUrl: `https://www.linkedin.com/sharing/share-offsite/?url=${pageHref}`,
          image: '/images/in-share.svg',
        },
        {
          platform: 'email',
          title: 'Share by Email',
          id: 'em',
          sharingUrl: `mailto:?subject=${pageDescription}&amp;body=Interested in learning about #EVs? Check out this article from Octopus Electroverse:. ${pageHref}`,
          image: '/images/mail-share.svg',
        },
      ])
    }
  }, [pageDescription])

  if (!socialData) return null
  return (
    <>
      <NextHead>
        <meta property="og:type" content="website" />
        <meta property="og:description" content={pageDescription} />
      </NextHead>

      <div className="max-w-2xl w-full mx-auto text-center mb-20 mt-16">
        <h3>{t('blog.social.share_this_article')}</h3>
        <div className="flex justify-center gap-4 text-center">
          {socialData?.map((item: SharingLink) => (
            <a
              key={item.id}
              onClick={() =>
                GTM.shareOnSocials({ platform: item.platform, label: pageDescription })
              }
              target="_blank"
              rel="noreferrer"
              className="block p-0 m-0 rounded-full"
              href={item.sharingUrl}
              style={{ lineHeight: 0 }}
              title={item.title}
            >
              <Image src={item.image} width={48} height={48} alt={item.title} />
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
