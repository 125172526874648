import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from 'react'
import { useAuth } from '@electro/consumersite/src/hooks'
import {
  DefaultSidebarPanelsType,
  SidebarPanels,
  SidebarPanelsType,
} from '@electro/consumersite/src/components/Map/types'

interface State {
  visiblePanel: SidebarPanelsType
  disabledPanels: SidebarPanelsType[]
  hiddenPanels: SidebarPanelsType[]
}

interface Handlers {
  showPanelExclusive: (key: SidebarPanelsType) => void
  showPanel: (key: SidebarPanelsType) => void
  hidePanel: () => void
}

type UseMapSidebar = [state: State, handlers: Handlers]
const UseMapSidebarContext = createContext<UseMapSidebar>(null)

function useMapSidebarProvider({ panels }: { panels: DefaultSidebarPanelsType }): UseMapSidebar {
  const [{ session }] = useAuth()

  const [, setPanelHistory] = useState<SidebarPanelsType[]>([SidebarPanels.MAP])
  const [visiblePanel, setVisiblePanel] = useState<SidebarPanelsType>(SidebarPanels.MAP)
  const [disabledPanels, setDisabledPanels] = useState<SidebarPanelsType[]>(panels?.disabled ?? [])
  const [hiddenPanels] = useState<SidebarPanelsType[]>(panels?.hidden ?? [])

  const disabledPanelsRef = useRef<SidebarPanelsType[]>(disabledPanels)

  /** Disable some panels if the user is not logged in */
  useEffect(() => {
    disabledPanelsRef.current = session ? [] : panels?.disabled
    setDisabledPanels(disabledPanelsRef.current)
  }, [session, panels?.disabled])

  /** Display a sidebar panel, while clearing the history stack */
  const showPanelExclusive = useCallback((key: SidebarPanelsType) => {
    if (disabledPanelsRef.current.includes(key)) {
      setPanelHistory([SidebarPanels.ELECTROVERSE_FEATURES])
      setVisiblePanel(SidebarPanels.ELECTROVERSE_FEATURES)
    } else {
      setPanelHistory([key])
      setVisiblePanel(key)
    }
  }, [])

  /** Display a sidebar panel, adding it to the history stack */
  const showPanel = useCallback((key: SidebarPanelsType) => {
    if (disabledPanelsRef.current.includes(key)) return
    setPanelHistory((existingPanels) => [...existingPanels, key])
    setVisiblePanel(key)
  }, [])

  /** Hide a sidebar panel, displays the previous panel on the history stack */
  const hidePanel = useCallback(() => {
    setPanelHistory((existingPanels) => {
      existingPanels.pop()
      const updatedHistory = existingPanels
      const updatedVisiblePanel = updatedHistory[updatedHistory.length - 1]

      if (updatedVisiblePanel) {
        setVisiblePanel(updatedVisiblePanel)
        return updatedHistory
      }

      setVisiblePanel(SidebarPanels.MAP)
      return [SidebarPanels.MAP]
    })
  }, [])

  const state = useMemo(
    () => ({ visiblePanel, disabledPanels, hiddenPanels }),
    [visiblePanel, disabledPanels, hiddenPanels],
  )

  const handlers = useMemo(
    () => ({ showPanelExclusive, showPanel, hidePanel }),
    [showPanelExclusive, showPanel, hidePanel],
  )

  return [state, handlers]
}

interface UseMapSidebarProps {
  children: ReactNode | ReactNode[]
  panels?: DefaultSidebarPanelsType
}

export const UseMapSidebarProvider = ({ children, panels }: UseMapSidebarProps) => {
  const ctx = useMapSidebarProvider({ panels })
  return <UseMapSidebarContext.Provider value={ctx}>{children}</UseMapSidebarContext.Provider>
}

export const useMapSidebar = (): UseMapSidebar => {
  const context = useContext(UseMapSidebarContext)
  if (!context)
    throw new Error('useMapSidebar() cannot be used outside of <UseMapSidebarProvider/>')
  return context
}
