import React, { createContext, useContext, ReactNode, ChangeEvent, useMemo } from 'react'
import { isFunction } from '@electro/shared/utils/typed'

type RadioGroupContextReturnType = {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

interface RadioGroupProps {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const RadioGroupContext = createContext(null)

const useRadioGroupProvider = ({
  value: valueProp,
  onChange,
}: RadioGroupProps): RadioGroupContextReturnType => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (isFunction(onChange)) {
      onChange(event)
    }
  }

  const value = useMemo(() => valueProp, [valueProp])

  return { value, onChange: changeHandler }
}

interface RadioGroupProviderProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
  children: ReactNode | ReactNode[]
}

export const RadioGroupProvider = ({
  onChange,
  value: valueProp,
  children,
}: RadioGroupProviderProps) => {
  const value = useRadioGroupProvider({ onChange, value: valueProp })

  return <RadioGroupContext.Provider value={value}>{children}</RadioGroupContext.Provider>
}

export const useRadioGroup = (): RadioGroupContextReturnType => {
  const context = useContext(RadioGroupContext)

  return context
}
