import * as Yup from 'yup'
import { Formik } from 'formik'
import { Button, Input } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { CountryType } from '@electro/consumersite/generated/graphql'
import { CountrySelect } from '@electro/consumersite/src/components'
import { emailValidator } from '@electro/shared/utils/validators'

export interface NewUserDetailsFormFields {
  firstName: string
  lastName: string
  countryCode: string
}

interface NewUserDetailsFormProps {
  onSubmit: (args: NewUserDetailsFormFields) => void
  loading: boolean
}

const DEFAULT_VALUES = {
  firstName: '',
  lastName: '',
  countryCode: '',
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('form.validation.first_name')
    .test('firstName', 'form.validation.no_email_as_first_name', (value) => !emailValidator(value)),
  lastName: Yup.string()
    .required('form.validation.last_name')
    .test('lastName', 'form.validation.no_email_as_last_name', (value) => !emailValidator(value)),
  countryCode: Yup.string().required('form.validation.select_country'),
})

export const NewUserDetailsForm = ({ onSubmit, loading }: NewUserDetailsFormProps) => {
  const { t } = useTranslation('common')
  const handleFormSubmit = (values: NewUserDetailsFormFields) => {
    onSubmit(values)
  }
  return (
    <Formik
      initialValues={DEFAULT_VALUES}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleBlur, values, errors, handleSubmit, handleChange }) => {
        const handleCountrySelect = (country: CountryType) => {
          handleChange({ target: { name: 'countryCode', value: country.alpha3 } })
        }
        return (
          <form onSubmit={handleSubmit} className="text-left mt-8">
            <Input
              disabled={loading}
              fullWidth
              placeholder="e.g. Constantine"
              label={t('form.field.first_name')}
              name="firstName"
              value={values.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              errorMessage={t(errors.firstName)}
            />
            <Input
              disabled={loading}
              fullWidth
              placeholder="e.g. Smith"
              label={t('form.field.last_name')}
              name="lastName"
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              errorMessage={t(errors.lastName)}
            />
            <CountrySelect
              filter={(countries) => countries.filter((country) => country.isSignupSupported)}
              disabled={loading}
              label={t('country_of_residence')}
              onSelect={handleCountrySelect}
              errorMessage={t(errors.countryCode)}
            />
            <Button fullWidth type="submit" loading={loading}>
              {t('common.button_status.continue')}
            </Button>
          </form>
        )
      }}
    </Formik>
  )
}
