import { useState, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Transition } from '@headlessui/react'
import { SecondaryNavItem } from '@electro/consumersite/src/components/TopNavigation/components'
import {
  MobilePrimaryNavItemProps,
  DesktopPrimaryNavItemProps,
  MobileSecondaryNavItemProps,
} from '@electro/consumersite/src/components/TopNavigation/types'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

const mobileStyles = {
  root: 'px-4 py-2',
  item: tw(
    'w-full flex justify-between',
    'uppercase text-lg font-normal no-underline text-white focus:text-white/70 hover:text-white/70 focus:ring-0',
  ),
  divider: 'border-secondary/50 my-2',
  icon: 'size-7 self-center',
  secondaryNavContainer: 'flex flex-col space-y-2',
}

const desktopStyles = {
  listItem: tw(
    'text-center tracking-wide text-lg font-normal no-underline text-white uppercase',
    'focus:ring-0 focus:text-primary hover:text-white/70',
    'self-stretch',
  ),
  activeListItem: 'text-primary hover:text-primary/70 font-semibold',
  secondaryNavPadding: 'pb-6',
}

const Mobile = ({ href, label, secondaryNav }: MobilePrimaryNavItemProps) => {
  const { t } = useTranslation('common')
  const [expanded, setExpanded] = useState<boolean>(false)
  const toggleExpanded = useCallback(() => setExpanded((oldExpanded) => !oldExpanded), [])

  return (
    <li className={mobileStyles.root}>
      {secondaryNav ? (
        <>
          <button className={mobileStyles.item} key={label} onClick={toggleExpanded}>
            {label}
            {expanded ? (
              <ChevronUpIcon className={mobileStyles.icon} />
            ) : (
              <ChevronDownIcon className={mobileStyles.icon} />
            )}
          </button>
          <hr className={mobileStyles.divider} />
          <Transition
            show={expanded}
            appear
            enter="transition ease-out duration-300"
            enterFrom="opacity-0 transform -translate-y-3"
            enterTo="opacity-100 transform translate-y-0"
            leave="transition ease-out duration-300"
            leaveFrom="opacity-100 transform translate-y-0"
            leaveTo="opacity-0 transform -translate-y-3"
          >
            <ul className={mobileStyles.secondaryNavContainer}>
              {secondaryNav.map((item: MobileSecondaryNavItemProps) => (
                <SecondaryNavItem.Mobile key={item.label} {...item} label={t(item.label)} />
              ))}
            </ul>
          </Transition>
        </>
      ) : (
        <Link href={href} className={mobileStyles.item} draggable="false">
          {label}
        </Link>
      )}
    </li>
  )
}

const Desktop = ({ label, href, onMouseEnter, secondaryNav }: DesktopPrimaryNavItemProps) => {
  const { asPath } = useRouter()

  return (
    <li onMouseEnter={onMouseEnter}>
      <Link
        draggable="false"
        href={href}
        className={tw({
          [desktopStyles.listItem]: true,
          [desktopStyles.secondaryNavPadding]: secondaryNav,
          [desktopStyles.activeListItem]: asPath.startsWith(href),
        })}
      >
        {label}
      </Link>
    </li>
  )
}

export const PrimaryNavItem = {
  Desktop,
  Mobile,
}
