import { useCallback } from 'react'

import { CountryCode } from '@electro/shared/types/countries'
import { useUserQuery } from '@electro/consumersite/generated/graphql'

export const useUserCountry = () => {
  const { data: userData } = useUserQuery()

  const userCountry: CountryCode = userData?.me?.countryCode as CountryCode

  const restrictToCountries = useCallback(
    (countries: CountryCode[]) => countries.includes(userCountry as CountryCode),
    [userCountry],
  )
  return { userCountry, restrictToCountries }
}
