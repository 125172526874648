import useTranslation from 'next-translate/useTranslation'
import { Button, Typography } from '@electro/shared-ui-components'
import { useManageOutstandingPayments } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/hooks'
import { OutstandingPaymentDetails } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/components'
import { ModalScreensEnum } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/ManageOutstandingPayments.types'
import { FormattingOptionsEnum, useLocalisedDateFormat } from '@electro/shared/hooks'

export const OutstandingPayment = ({ payment }) => {
  const { t } = useTranslation('common')
  const { setActiveModalScreen, setPayment } = useManageOutstandingPayments()

  const paymentDate = useLocalisedDateFormat({
    date: payment?.paymentSource?.periodStart,
    formattingOption: FormattingOptionsEnum.DD_MMMM_yyyy,
  })

  const handleOpenModal = () => {
    setActiveModalScreen(ModalScreensEnum.PAYMENT_DETAILS_MODAL_SCREEN)
    setPayment(payment)
  }

  return (
    <div className="mb-4 last:mb-0">
      <div>
        <Typography variant="h3" as="h1">
          {paymentDate}
        </Typography>
        <div className="flex flex-col items-center justify-between lg:flex-row">
          <OutstandingPaymentDetails payment={payment} />
          <Button onClick={handleOpenModal} fullWidth>
            {t('profile.outstanding_payments.resolve_payment_cta')}
          </Button>
        </div>
      </div>
    </div>
  )
}
