import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import { AddAnEvModal } from '@electro/consumersite/src/components/AccountVehicleManager/components'
import { ManageUserVehicles } from '@electro/consumersite/src/components'
import {
  useAccountVehicleManagerModal,
  AccountVehicleManagerModalProvider,
} from '@electro/consumersite/src/components/AccountVehicleManager/hooks'
import { Button, IconButton, Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

const AccountVehicleManager = () => {
  const { t } = useTranslation('common')
  const { setStep } = useAccountManageMobileStep()
  const { setShowModal } = useAccountVehicleManagerModal()

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between">
        <IconButton
          className="block md:hidden"
          onClick={() => setStep(AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU)}
        >
          <ArrowLeftIcon className="w-6 h-6" />
        </IconButton>
        <Typography variant="h3" as="h1">
          {t('profile.navigation.evs_option')}
        </Typography>
        <div />
      </div>

      <div className="flex flex-col mt-6">
        <div className="flex">
          <div className="grow sm:grow-0 sm:basis-[550px]">
            <ManageUserVehicles
              emptyMessage={
                <div className="flex">
                  <Typography variant="small">{t('add_an_ev.no_vehicles')}</Typography>
                </div>
              }
            />
          </div>
        </div>

        <div>
          <Button className="mt-8 sm:w-auto" fullWidth onClick={setShowModal(true)}>
            {t('common.button_status.add_a_new_ev')}
          </Button>
        </div>
      </div>

      <AddAnEvModal />
    </div>
  )
}

const AccountVehicleManagerWithProviders = () => (
  <AccountVehicleManagerModalProvider>
    <AccountVehicleManager />
  </AccountVehicleManagerModalProvider>
)

export { AccountVehicleManagerWithProviders as AccountVehicleManager }
