import { useEffect, useState } from 'react'

// TO DO: we will need to modify how this is wired up in order to
// 1. support SSR
// 2. be typesafe
export const useFeatureFlag = (featureFlag: boolean) => {
  const [isFeatureFlagEnabled, setIsFeatureFlagEnabled] = useState(false)

  useEffect(() => {
    setIsFeatureFlagEnabled(featureFlag)
  }, [featureFlag])

  return isFeatureFlagEnabled
}
