export enum SidebarPanels {
  // VISIBLE
  MAP = 'map',
  FILTERS = 'filters',
  ROUTE_PLANNER = 'route-planner',
  MY_EVS = 'myEVs',
  FEEDBACK = 'feedback',
  KEY = 'key',

  // HIDDEN
  ALL_PANELS_HIDDEN = 'all-panels-hidden',
  ICON_NAV_LABELS = 'icon-nav-labels',
  ELECTROVERSE_FEATURES = 'electroverse-features',
  OPERATOR_LIST = 'operator-list',
  ROUTE_OVERVIEW = 'route-overview',
  ROUTE_BREAKDOWN = 'route-breakdown',
  SAVED_ROUTES = 'saved-routes',
}
export type SidebarPanelsType = `${SidebarPanels}`

const {
  MAP,
  ROUTE_PLANNER,
  MY_EVS,
  FEEDBACK,
  ELECTROVERSE_FEATURES,
  ROUTE_OVERVIEW,
  ROUTE_BREAKDOWN,
  SAVED_ROUTES,
} = SidebarPanels

export interface DefaultSidebarPanelsType {
  visible: SidebarPanelsType[]
  disabled: SidebarPanelsType[]
  hidden: SidebarPanelsType[]
}

export const DEFAULT_SIDEBAR_PANELS: DefaultSidebarPanelsType = {
  visible: [MAP],
  disabled: [ROUTE_PLANNER, SAVED_ROUTES, MY_EVS, ROUTE_OVERVIEW, ROUTE_BREAKDOWN],
  hidden: [ELECTROVERSE_FEATURES],
}

export const EMBED_HIDDEN_PANELS = [ROUTE_PLANNER, MY_EVS, FEEDBACK]
