import { useMemo } from 'react'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import { EjAuthorised } from '@electro/consumersite/src/components'
import { useMapFilters, useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersEnum, SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { MyEVsVehicleButton } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/MyEVs/components'
import { ConnectorsMacroCaseEnum } from '@electro/consumersite/src/components/Map/utils'

const styles = {
  myEVsButton: 'my-1',
}

const { SOCKET_TYPES } = MapFiltersEnum

/** Opens MyEVs to switch active vehicle, or updates the selected sockets depending on current filters */
export const FiltersVehicleSelector = () => {
  const [, { showPanel }] = useMapSidebar()
  const [{ currentFilters }, { updateFilters }] = useMapFilters()
  const [{ activeVehicle, activeVehicleSockets }] = useUserVehicles()

  /** Checks if the activeVehicle sockets match the currently filtered ones */
  const isMatchForFilters = useMemo(() => {
    const sameNumOfSockets = activeVehicleSockets.length === currentFilters[SOCKET_TYPES].length
    const everySocketExistsOnFilters = activeVehicleSockets.every(
      (socket, index) => ConnectorsMacroCaseEnum[socket] === currentFilters[SOCKET_TYPES][index],
    )

    return sameNumOfSockets && everySocketExistsOnFilters
  }, [activeVehicleSockets, currentFilters])

  /** Opens the My EVs panel if the selected vehicle sockets are in-use.
   * Otherwise, it updates the filters to use those sockets. */
  const onButtonClick = () => {
    if (isMatchForFilters || !activeVehicle) showPanel(SidebarPanels.MY_EVS)
    else {
      const urlSafeSockets = activeVehicleSockets.map((socket) => ConnectorsMacroCaseEnum[socket])
      updateFilters({ [SOCKET_TYPES]: urlSafeSockets })
    }
  }

  return (
    <EjAuthorised>
      <EjAuthorised.LoggedIn>
        <MyEVsVehicleButton
          vehicle={activeVehicle?.vehicle}
          onClick={onButtonClick}
          className={styles.myEVsButton}
          icon={isMatchForFilters ? 'arrowsRightLeft' : 'eyeSlash'}
          disabled={!(isMatchForFilters || !activeVehicle)}
          overrideDisabledInteractivity
        />
      </EjAuthorised.LoggedIn>
    </EjAuthorised>
  )
}
