import { Step } from '@electro/consumersite/generated/graphql'
import { MarkerNames, MarkerNamesEnum } from '@electro/consumersite/src/components/Map/helpers'

const {
  ROUTE_WAYPOINT,
  ROUTE_DESTINATION,
  ROUTE_CHARGE_STOP,

  ROUTE_STATION_KAYAK,
  ROUTE_STATION_JETPACK,
  ROUTE_STATION_SHARK,
} = MarkerNamesEnum

const CONSTANTINE_STATION_CROSSINGS = [
  ROUTE_STATION_KAYAK,
  ROUTE_STATION_JETPACK,
  ROUTE_STATION_SHARK,
]

interface GetMarkerIconFromRouteStepProps {
  routeID: number
  step: Pick<Step, 'waypointIdx' | 'isCharger' | 'isStation' | 'isEndStation'>
}

/** For details on what these fields mean see Iternio's API docs:
 * https://documenter.getpostman.com/view/7396339/SWTK3YsN#0796780b-0c95-46db-b003-a6387ca0ca6f
 *
 * NOTE: This documentation appears outdated. See the GraphQL schema for the full list of fields. */
export function getMarkerIconFromRouteStep({
  routeID,
  step,
}: GetMarkerIconFromRouteStepProps): MarkerNames {
  // Start Location
  if (step.waypointIdx === 0) return ROUTE_DESTINATION

  // Destination
  if (step.waypointIdx === 1) return ROUTE_DESTINATION

  // Charge Stop
  if (step.isCharger) return ROUTE_CHARGE_STOP

  // Station End (e.g. Ferry, Train)
  if (step.isEndStation) return CONSTANTINE_STATION_CROSSINGS[routeID]

  return ROUTE_WAYPOINT
}
