import { useCallback, useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'
import {
  Alert,
  IconButton,
  LoadingDoubleRowPlaceholder,
  Typography,
  useToastNotification,
} from '@electro/shared-ui-components'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import useTranslation from 'next-translate/useTranslation'
import { PasswordForm } from '@electro/consumersite/src/components/Signup/components'
import { PasswordFormFields } from '@electro/consumersite/src/components/Signup/types'
import {
  useSetPasswordMutation,
  useUserQuery,
  UserDocument,
  useGetKrakenAccountsQuery,
  useChangePasswordMutation,
} from '@electro/consumersite/generated/graphql'
import { ChangePasswordForm, ChangePasswordFormFields } from '../ChangePasswordForm'

export const AccountOverview = () => {
  const { t } = useTranslation('common')
  const { data, error, loading } = useUserQuery()
  const { showToastNotification } = useToastNotification()
  const [setPassword, { loading: settingPassword, error: passwordError }] = useSetPasswordMutation({
    refetchQueries: [UserDocument],
  })

  const [changePassword, { loading: changingPassword, error: changePasswordError }] =
    useChangePasswordMutation({
      refetchQueries: [UserDocument],
    })

  const {
    loading: krakenAccountsLoading,
    data: krakenAccountsData,
    error: krakenAccountError,
  } = useGetKrakenAccountsQuery()

  const { setStep } = useAccountManageMobileStep()

  const activeKrakenAccount = krakenAccountsData?.krakenAccounts.find(
    (account) => account.membershipActive,
  )
  const { firstName, lastName, email } = data?.me ?? {}

  const combinedError = useMemo(() => error ?? krakenAccountError, [error, krakenAccountError])

  const handleSetPassword = useCallback(
    async (fields: PasswordFormFields) => {
      try {
        await setPassword({
          variables: { password: fields.password },
        })
        showToastNotification({
          heading: t('common.success'),
          body: t('profile.general.password_updated'),
          variant: 'success',
        })
      } catch (err) {
        showToastNotification({
          heading: t('common.error'),
          body: err.message,
          variant: 'error',
        })
        Sentry.captureException(err)
      }
    },
    [setPassword, showToastNotification, t],
  )

  const handleChangePassword = useCallback(
    async (fields: ChangePasswordFormFields) => {
      try {
        await changePassword({
          variables: {
            currentPassword: fields.currentPassword,
            newPassword: fields.newPassword,
          },
        })
        showToastNotification({
          heading: t('common.success'),
          body: t('profile.general.password_updated'),
          variant: 'success',
        })
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    [changePassword, showToastNotification, t],
  )

  if (combinedError)
    return (
      <Alert variant="error" className="h-fit">
        {combinedError?.message}
      </Alert>
    )

  return (
    <>
      <IconButton
        className="block md:hidden mb-4"
        onClick={() => setStep(AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU)}
      >
        <ArrowLeftIcon className="w-6 h-6" />
      </IconButton>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full md:w-2/3">
        <div className="flex flex-col">
          <Typography variant="h3" as="h1">
            {t('profile.general.heading')}
          </Typography>

          <div className="flex flex-col">
            {krakenAccountsLoading ? (
              <LoadingDoubleRowPlaceholder rows={1} />
            ) : (
              <div className="flex flex-col mb-4">
                <Typography variant="h4">{t('profile.general.account_number')}</Typography>
                <Typography>{activeKrakenAccount?.number}</Typography>
              </div>
            )}

            {loading ? (
              <LoadingDoubleRowPlaceholder rows={4} />
            ) : (
              <>
                <div className="flex flex-col mb-4">
                  <Typography variant="h4">{t('first_name')}</Typography>
                  <Typography>{firstName}</Typography>
                </div>

                <div className="flex flex-col mb-4">
                  <Typography variant="h4">{t('last_name')}</Typography>
                  <Typography>{lastName}</Typography>
                </div>

                <div className="flex flex-col mb-4">
                  <Typography variant="h4">{t('common_email')}</Typography>
                  <Typography>{email}</Typography>
                </div>
                {data?.me?.canSetPassword && (
                  <div className="mt-6">
                    <Typography variant="h3" as="h2">
                      {data?.me?.hasSetPassword
                        ? t('profile.general.update_password')
                        : t('profile.general.create_password')}
                    </Typography>
                    {data?.me?.hasSetPassword ? (
                      <ChangePasswordForm
                        error={changePasswordError}
                        onSubmit={handleChangePassword}
                        loading={changingPassword}
                      />
                    ) : (
                      <PasswordForm
                        error={passwordError}
                        onSubmit={handleSetPassword}
                        loading={settingPassword}
                        buttonText={t('common.button.update_password')}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
