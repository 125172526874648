import { useAuth, UseAuth } from '@electro/consumersite/src/hooks'
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react'

interface EjAuthorisedProps {
  children: any
  onUnauthorised?: () => void
}

interface UseEjAuthorisedContext {
  authorised: boolean
  unauthorised: boolean
  loading: boolean
}

export const useEjAuthorisedProvider = ({ onUnauthorised }) => {
  const [{ sessionLoading, session }] = useAuth()

  const handleReject = useCallback(() => {
    if (onUnauthorised) onUnauthorised()
  }, [onUnauthorised])

  const loading = useMemo(() => sessionLoading, [sessionLoading])
  const unauthorised = useMemo(() => !session && !sessionLoading, [session, sessionLoading])
  const authorised = useMemo(() => session && !sessionLoading, [session, sessionLoading])

  /**
   * Watch the auth lifecycle, and give the option
   * to pass a callback as a custom handler if the
   * user fails auth
   */
  useEffect(() => {
    if (unauthorised && !!onUnauthorised) {
      handleReject()
    }
  }, [handleReject, onUnauthorised, unauthorised])

  return {
    loading,
    authorised,
    unauthorised,
  }
}

const EjAuthorisedContext = createContext<UseEjAuthorisedContext>(null)

const useEjAuthorised = () => {
  const context = useContext(EjAuthorisedContext)
  if (!context) {
    throw new Error(
      `EjAuthorised components cannot be used outside the context of <EjAuthorised/> `,
    )
  }
  return context
}

/**
 * For a little extra flexibility return the contents of
 * the auth hook to the component render e.g:
 *
 * <EjAuthorised>
 *  {([{authState}, {authHandlers}]) => <YourComponent/>}
 * </EjAuthorised>
 */
const EjAuthorisedRenderPropProvider = ({ children }) => {
  const auth: UseAuth = useAuth()
  if (typeof children === 'object') return children
  return children(auth as UseAuth)
}

const LoggedIn = ({ children }) => {
  const { authorised } = useEjAuthorised()
  return authorised ? children : null
}

const LoggedOut = ({ children }) => {
  const { unauthorised } = useEjAuthorised()
  return unauthorised ? children : null
}

/**
 * Will show when the useAuth hook is calling the
 * Back End to verify the user
 */
const Loading = ({ children }) => {
  const { loading } = useEjAuthorised()
  return loading ? children : null
}

const LoadingOrLoggedOut = ({ children }) => {
  const { loading, unauthorised } = useEjAuthorised()
  return loading || unauthorised ? children : null
}

const EjAuthorised = ({ children, onUnauthorised }: EjAuthorisedProps) => {
  const context = useEjAuthorisedProvider({ onUnauthorised })
  return (
    <EjAuthorisedContext.Provider value={context}>
      <EjAuthorisedRenderPropProvider>{children}</EjAuthorisedRenderPropProvider>
    </EjAuthorisedContext.Provider>
  )
}

EjAuthorised.LoggedOut = LoggedOut
EjAuthorised.LoggedIn = LoggedIn
EjAuthorised.Loading = Loading
EjAuthorised.LoadingOrLoggedOut = LoadingOrLoggedOut

export { EjAuthorised }
