import { HTMLAttributes, MutableRefObject, useEffect, useRef, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  shadow: {
    top: '[box-shadow:_inset_0_40px_40px_-40px_rgb(88_64_255)]',
    bottom: '[box-shadow:_inset_0_-40px_40px_-40px_rgb(88_64_255)]',
    both: '[box-shadow:_inset_0_40px_40px_-40px_rgb(88_64_255),_inset_0_-40px_40px_-40px_rgb(88_64_255)]',
  },
}

export const ScrollShadow = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const component: MutableRefObject<HTMLDivElement> = useRef(null)
  const [showShadow, setShowShadow] = useState<'top' | 'bottom' | 'both'>()

  const setScrollShadow = () => {
    if (component?.current?.children?.length > 0) {
      const contentHiddenAbove = component.current.children[0].scrollTop > 0
      const contentHiddenBelow =
        Math.abs(
          component.current.children[0].scrollHeight -
            component.current.children[0].clientHeight -
            component.current.children[0].scrollTop,
        ) > 1

      if (contentHiddenAbove && contentHiddenBelow) setShowShadow('both')
      else if (contentHiddenAbove) setShowShadow('top')
      else if (contentHiddenBelow) setShowShadow('bottom')
      else setShowShadow(undefined)
    }
  }

  useEffect(() => {
    if (component?.current?.children?.length > 0) {
      setTimeout(() => setScrollShadow(), 50)
      component.current.children[0].addEventListener('scroll', setScrollShadow)
      new ResizeObserver(setScrollShadow).observe(component.current.children[0])
      window.addEventListener('resize', setScrollShadow)
    }
  }, [])

  return <div ref={component} className={tw(styles.shadow[showShadow], className)} {...props} />
}
