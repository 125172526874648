import { useUpdateEffect } from 'react-use'
import useTranslation from 'next-translate/useTranslation'
import { Button, useToastNotification } from '@electro/shared-ui-components'
import { MyEVsVehicleButton } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/MyEVs/components'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import {
  useMapSidebar,
  useRoutePlanner,
  useRoutePlannerForm,
} from '@electro/consumersite/src/components/Map/hooks'
import {
  RoutePlannerLocationSearch,
  RoutePlannerChargeSliders,
  RoutePlannerToggles,
  RoutePlannerSavedRoutesButton,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/RoutePlanner/components'

const styles = {
  myEVsButton: 'my-1',
  breakpoint: 'border-secondary',
  submitButton: {
    root: 'mt-auto',
    button: 'mt-4 shrink-0',
  },
}

export const RoutePlannerPanelContent = () => {
  const { t } = useTranslation('common')
  const [, { showPanel }] = useMapSidebar()
  const [{ routeLoading }] = useRoutePlanner()
  const [{ activeVehicle }] = useUserVehicles()
  const [{ errors }, { submitForm }] = useRoutePlannerForm()
  const { showToastNotification } = useToastNotification()

  /** Displays route form validation errors when the user tries to submit */
  useUpdateEffect(() => {
    Object.values(errors).forEach((error) => {
      showToastNotification({
        variant: 'error',
        heading: `${t('map.route_planner.button_text')} ${t('common.error')}`,
        body: t(error.toString()),
        timeout: 4000,
      })
    })
  }, [errors, showToastNotification, t])

  return (
    <>
      <MyEVsVehicleButton
        icon="arrowsRightLeft"
        vehicle={activeVehicle?.vehicle}
        onClick={() => showPanel(SidebarPanels.MY_EVS)}
        className={styles.myEVsButton}
      />

      <hr className={styles.breakpoint} />
      <RoutePlannerLocationSearch />

      <hr className={styles.breakpoint} />
      <RoutePlannerChargeSliders />

      <hr className={styles.breakpoint} />
      <RoutePlannerToggles />

      <hr className={styles.breakpoint} />
      <RoutePlannerSavedRoutesButton />

      <div className={styles.submitButton.root}>
        <Button
          fullWidth
          onClick={submitForm}
          loading={routeLoading}
          className={styles.submitButton.button}
        >
          {t('map.route_planner.button.calculate_route')}
        </Button>
      </div>
    </>
  )
}
