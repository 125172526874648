export enum PaymentTypeEnum {
  KRAKEN = 'KRAKEN',
  CARD_PAYMENT = 'STRIPE_CARD',
  PAYPAL = 'PAYPAL',
}

export enum PaymentMethodStatus {
  ACTIVE = 'ACTIVE',
  AVAILABLE = 'AVAILABLE',
  PENDING = 'PENDING',
}
