import { useMapLocationID } from '@electro/consumersite/src/components/Map/hooks'
import { LoadingBolt } from '@electro/shared-ui-components'

export const LocationDetailsPanelContent = () => {
  const [{ locationFragment, locationLoading, locationData }] = useMapLocationID()

  return (
    <div className="relative h-full w-full">
      <p>{locationFragment?.operator?.name}</p>
      {locationLoading ? (
        <LoadingBolt />
      ) : (
        <div className="h-full text-sm ml-2 overflow-scroll flex flex-col">
          {locationData?.evses?.edges.map((edge) => (
            <div className="mb-4 flex">
              {edge.node?.connectors.edges.map((connectorEdge) => (
                <p className="mb-0">{connectorEdge.node?.kilowatts}kW</p>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
