/**
 * Only supports a single notification at the current time
 */

import { createContext, ReactNode, useMemo, useState } from 'react'
import { ShowToastNotificationArguments } from './useToastNotification'

interface ToastNotificationProviderProps {
  children: ReactNode
}

export const ToastNotificationContext = createContext(null)

export const ToastNotificationProvider = ({ children }: ToastNotificationProviderProps) => {
  const [notification, setNotification] = useState<ShowToastNotificationArguments>()

  const value = useMemo(() => ({ notification, setNotification }), [notification, setNotification])

  return (
    <ToastNotificationContext.Provider value={value}>{children}</ToastNotificationContext.Provider>
  )
}
