// Breaking changes to these params should include a handover period.

// Ensure you notify the partnerships and marketing teams beforehand of intended changes.

// For any changes affecting the office display maps, notify the #techops-help slack channel
// of the change in URL after the update is live.

/* https://www.notion.so/Map-params-e3eec67cf3cc428897243922c0c277e9 */
export enum MapParamsEnum {
  // MAPBOX
  POSITION = 'pos', // lat,lng,zoom
  SPEED = 's',
  PITCH = 'p',
  BEARING = 'b',
  /** @deprecated */
  LAT = 'lat',
  /** @deprecated */
  LNG = 'lng',
  /** @deprecated */
  ZOOM = 'zoom',
  /** @deprecated */
  PITCH_FULL = 'pitch',
  /** @deprecated */
  BEARING_FULL = 'bearing',

  // MAP FILTERS
  ELECTROVERSE_COMPATIBLE = 'ec',
  OPERATORS = 'op',
  SOCKET_TYPES = 'st',
  CHARGE_POINT_SPEEDS = 'cps',
  MIN_CHARGE_POINTS = 'mcp',
  ACCESS = 'acc',
  /** @deprecated */
  EJN_ONLY_FULL = 'electroverseOnly',
}
