import { useRouter } from 'next/router'
import React, { HTMLAttributes } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { mapSidebarContainerStyling } from '@electro/consumersite/src/components/Map/components/MapSidebar'
import { useMapConfig, useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { ScrollShadow } from '@electro/shared-ui-components'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

const styles = {
  navMenu: tw(
    'z-20 flex flex-col items-center gap-y-6 py-1 w-12',
    'rounded-xl bg-base border-secondary border-2',
    'overflow-y-hidden',
    '[&>*]:h-full [&>*]:flex [&>*]:flex-col [&>*]:items-center [&>*]:gap-y-2',
  ),
  navIcon: {
    root: 'flex items-center justify-center w-8 h-12 hover:opacity-70',
    active: 'text-primary',
    disabledActive: 'text-secondary-light',
    hidden: 'hidden',
    screenReaderText: 'sr-only',
  },
}

export const SidebarNav = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const [{ showNavbar }] = useMapConfig()

  return (
    <menu
      className={tw({
        [mapSidebarContainerStyling.all]: true,
        [mapSidebarContainerStyling.withPadding]: showNavbar,
        [styles.navMenu]: true,
      })}
    >
      <ScrollShadow>{children}</ScrollShadow>
    </menu>
  )
}

interface SidebarNavIconProps extends HTMLAttributes<HTMLButtonElement> {
  panelName?: SidebarPanels
}

export const SidebarNavIcon = ({ className, children, panelName }: SidebarNavIconProps) => {
  const router = useRouter()
  const [{ visiblePanel, disabledPanels, hiddenPanels }, { showPanelExclusive }] = useMapSidebar()

  const isMap = panelName === SidebarPanels.MAP
  const isActive = visiblePanel === panelName

  const isDisabled = disabledPanels.includes(panelName)
  const isDisabledActive = isDisabled && visiblePanel === SidebarPanels.ELECTROVERSE_FEATURES

  const isHidden = hiddenPanels.includes(panelName)

  const onNavIconClick = () => {
    const targetPanel = isActive || isDisabledActive ? SidebarPanels.MAP : panelName

    showPanelExclusive(targetPanel)
    GTM.showSidebarPanel(targetPanel)

    const urlHash = router.asPath.indexOf('#')
    if (!isMap || urlHash > 0) {
      try {
        router.replace(
          targetPanel === SidebarPanels.MAP ? router.asPath.slice(0, urlHash) : `#${panelName}`,
        )
      } catch (e) {
        // Ignores next router bug for URL hash in sentry: https://github.com/vercel/next.js/issues/37362
        if (!e.cancelled) throw e
      }
    }
  }

  return (
    <button
      onClick={onNavIconClick}
      data-testid={`sidebar-nav-icon-${panelName}`}
      className={tw({
        [styles.navIcon.root]: true,
        [styles.navIcon.active]: isActive,
        [styles.navIcon.disabledActive]: isDisabledActive,
        [styles.navIcon.hidden]: isHidden,
        [className]: !!className,
      })}
    >
      {children}
      <p className={styles.navIcon.screenReaderText}>{panelName}</p>
    </button>
  )
}
