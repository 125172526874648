export enum ModalScreenNames {
  FILTERS_SCREEN = 'FILTERS_SCREEN',
  FILTERS_ADD_AN_EV_SCREEN = 'FILTERS_ADD_AN_EV_SCREEN',
  ROUTE_PLAN_SCREEN = 'ROUTE_PLAN_SCREEN',
  ROUTE_PLAN_MANAGE_EVS_SCREEN = 'ROUTE_PLAN_MANAGE_EVS_SCREEN',
  ROUTE_PLAN_ADD_AN_EV_SCREEN = 'ROUTE_PLAN_ADD_AN_EV_SCREEN',
  ROUTE_PLAN_ERROR_SCREEN = 'ROUTE_PLAN_ERROR_SCREEN',
}

export type MapModalScreenName = `${ModalScreenNames}`
