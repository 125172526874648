import { format } from 'date-fns'

import { useRouter } from 'next/router'
import { Locale } from '@electro/shared/types/locales'
import { mapLocaleToCountryDetails } from '@electro/shared/utils/mapLocaleToCountryDetails'

export enum FormattingOptionsEnum {
  LLLL_yyyy = 'LLLL yyyy',
  LLLL_yy = 'LLLL yy',
  DD_MMMM_yyyy = 'dd MMMM yyyy',
}

export type FormattingOptions = `${FormattingOptionsEnum}`

export const useLocalisedDateFormat = ({
  date,
  formattingOption = FormattingOptionsEnum.LLLL_yy,
}: {
  date: string | string[] | number | Date | Date[]
  formattingOption?: FormattingOptions
}): string | string[] => {
  const { locale } = useRouter()
  if (!date) return ''

  const countryDateFormat = mapLocaleToCountryDetails(locale as Locale).dateFormat

  if (Array.isArray(date)) {
    return date.map((dateElement) =>
      format(new Date(dateElement), formattingOption, {
        locale: countryDateFormat,
      }),
    )
  }
  return format(new Date(date), formattingOption, {
    locale: countryDateFormat,
  })
}
