import Image from 'next/image'
import { Capabilities } from '@electro/consumersite/generated/graphql'
import { GiftIcon, GlobeAltIcon, QrCodeIcon } from '@heroicons/react/24/outline'

const { Card, Contactless, EjnApp, Free, OperatorApp, QrCode, Rfid, TeslaCar, Web, PlugAndCharge } =
  Capabilities

const imageProps = { height: 24, width: 24, className: 'w-5 h-5 ml-1 mr-2 text-white' }

const capabilityMap = {
  [Card]: {
    icon: <Image src="/images/capabilities/Electrocard.svg" alt="Electrocard" {...imageProps} />,
    label: 'map.location.info.capability.electrocard',
  },
  [OperatorApp]: {
    icon: <Image src="/images/capabilities/Operator_App.svg" alt="Operator App" {...imageProps} />,
    label: 'map.location.info.capability.app',
  },
  [EjnApp]: {
    // prettier-ignore
    icon: <Image src="/images/capabilities/Electroverse_App.svg" alt="Electroverse App" {...imageProps} />,
    label: 'map.location.info.capability.oeu_app',
  },
  [Contactless]: {
    icon: <Image src="/images/capabilities/Contactless.svg" alt="Contactless" {...imageProps} />,
    label: 'map.location.info.capability.contactless',
  },
  [Rfid]: {
    icon: <Image src="/images/capabilities/RFID_Card.svg" alt="RFID_Card" {...imageProps} />,
    label: 'map.location.info.capability.rfid',
  },
  [TeslaCar]: {
    icon: <Image src="/images/capabilities/Tesla_Car.svg" alt="Tesla_Car" {...imageProps} />,
    label: 'map.location.info.capability.tesla_car',
  },
  [PlugAndCharge]: {
    // prettier-ignore
    icon:<Image src="/images/capabilities/Plug_And_Charge.svg" alt="Plug_And_Charge" {...imageProps} />,
    label: 'map.location.info.capability.plug_and_charge',
  },
  [QrCode]: {
    icon: <QrCodeIcon className={imageProps.className} />,
    label: 'map.location.info.capability.qr_code',
  },
  [Free]: {
    icon: <GiftIcon className={imageProps.className} />,
    label: 'map.location.info.capability.free',
  },
  [Web]: {
    icon: <GlobeAltIcon className={imageProps.className} />,
    label: 'map.location.info.capability.website',
  },
}

/** Returns an icon and translation for each capability type available for chargers */
export const mapCapabilityToIcon = (capability: Capabilities) => {
  if (!capabilityMap[capability]) return { icon: null, label: '' }

  return capabilityMap[capability]
}
