import useTranslation from 'next-translate/useTranslation'
import { Typography } from '@electro/shared-ui-components'
import NoSavedRoutesIllustration from '@electro/consumersite/public/images/no-saved-routes.svg'

const styles = {
  root: 'h-full flex flex-col items-center gap-y-4 text-center',
  image: 'w-40 h-40 my-10',
  text: 'mx-5 [&>h3]:mx-6 [&>h3]:mb-6 [&>p]:text-sm',
}

export const SavedRoutesNoRoutes = () => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.root}>
      <NoSavedRoutesIllustration className={styles.image} />
      <div className={styles.text}>
        {t('map.saved_routes.no_routes')
          .split('\\n\\n')
          .map((line, index) => (
            <Typography key={line} as={index === 0 ? 'h3' : 'p'} variant={index === 0 ? 'h3' : 'p'}>
              {line}
            </Typography>
          ))}
      </div>
    </div>
  )
}
