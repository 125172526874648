import { tw } from '@electro/shared/utils/tailwind-merge'
import { ForwardedRef, HTMLAttributes, ReactNode, forwardRef } from 'react'

interface Props extends HTMLAttributes<HTMLTableCellElement> {
  children?: ReactNode | ReactNode[]
}

// Using `lg:text-[1rem]` instead of `text-base` because of the bug in text-base which turns the colour to black

export const TableHeader = forwardRef(
  ({ className, children, ...rest }: Props, ref: ForwardedRef<HTMLTableCellElement>) => (
    <th
      ref={ref}
      className={tw({
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </th>
  ),
)
