import { useMount } from 'react-use'
import { Dispatch, SetStateAction } from 'react'
import { Input } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid'

const styles = {
  root: 'relative mx-2 [&>div]:mb-0 bg-base rounded-4xl p-0.5',
  inputBox: 'rounded-4xl py-1 pl-8',
  icon: 'w-[18px] absolute top-2.5 left-3 mt-px',
}

interface OperatorListSearchProps {
  setSearchQuery: Dispatch<SetStateAction<string>>
}

/** Basic searchbox to filter the operator list strings */
export const OperatorListSearch = ({ setSearchQuery }: OperatorListSearchProps) => {
  const { t } = useTranslation('common')

  /** Automatically focus the searchbox on opening the panel */
  useMount(() => {
    // The 'autofocus' input prop breaks the animation, so this is the workaround
    setTimeout(() => document.getElementById('operator-list-search')?.focus(), 350)
  })

  return (
    <div className={styles.root}>
      <Input
        fullWidth
        className={styles.inputBox}
        onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
        placeholder={t('map.operator_list.search_placeholder')}
        name="operator-list-search"
        data-hj-allow
      />
      <MagnifyingGlassIcon className={styles.icon} />
    </div>
  )
}
