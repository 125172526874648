import Image from 'next/image'
import { Typography } from '@electro/shared-ui-components'
import { CountryCodes } from '@electro/shared/types/countries'
import useTranslation from 'next-translate/useTranslation'
import { useUserCountry } from '@electro/consumersite/src/hooks/useUserCountry'
import { useFeatureFlag } from '@electro/consumersite/src/hooks'

export const NoPaymentMethodSetupNotice = () => {
  const { restrictToCountries } = useUserCountry()
  const { t } = useTranslation('common')
  const showPayWithOeFeatureFlag = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_SHOW_PAY_WITH_OE === 'on',
  )

  return (
    <>
      <Image
        src="/images/payment-cards-octo.png"
        height="300"
        width="300"
        alt=""
        className="mx-auto"
      />
      {restrictToCountries([CountryCodes.GBR]) ? (
        <>
          <Typography as="h2" variant="h2">
            {t('profile.payment_methods.payment_method_actions.add_new_payment_method')}
          </Typography>
          {showPayWithOeFeatureFlag && (
            <Typography variant="body" as="p">
              {t('profile.payment_methods.pay_with_oe')}
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="body" as="p">
          {t('profile.payment_methods.no_payments_added')}
        </Typography>
      )}
    </>
  )
}
