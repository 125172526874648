import { tw } from '@electro/shared/utils/tailwind-merge'
import { useState, ComponentPropsWithoutRef, ReactNode, useMemo } from 'react'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@electro/shared-ui-components'
import { ToggleShowPassword } from './ToggleShowPassword'

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  value?: string | number
  label?: string | ReactNode
  name: string
  disabled?: boolean
  fullWidth?: boolean
  onChange?: (e?: any) => void
  success?: boolean
  password?: boolean
  errorMessage?: string
  successMessage?: string
  infoText?: string | ReactNode
  helpText?: string | ReactNode
  helpTextDelay?: number | null
  /**
   * applies a mono spaced font that clearly distinguishes between 0s and Os
   * useful for serial numbers input fields
   */
  mono?: boolean
}

const styles = {
  inputRoot: tw(
    'block  border-2 bg-transparent rounded-lg pl-2 pt-2 pb-2',
    'placeholder-white/60',
    'focus:outline-none  focus:border-tertiary hover:border-tertiary',
  ),
  inputIdle: 'text-white border-secondary',
  mono: 'font-mono',
  labelRoot: 'block font-normal text-sm pl-1 pb-1',
  labelIdle: 'text-white ',
  inputFieldWrapperRoot: 'relative inline-block',
  fullWidth: 'w-full',
  success: 'success text-action-success border-action-success',
  error: 'error border-action-danger text-action-danger',
  errorMessage: 'text-sm text-action-danger border-action-danger pt-0.5',
  successMessage: 'text-sm text-action-success border-action-success pt-0.5',
  errorIcon: 'text-action-danger w-4 h-4 absolute top-3 right-0',
  disabled:
    'cursor-not-allowed text-white border-white hover:border-white placeholder-white opacity-60',
  infoText: 'text-xs text-white/80 pt-0 pl-1 -mt-0.5 pb-2',
  questionMark: 'border-none w-5 h-5 inline-block ml-1',
  required: "after:content-['*'] after:text-action-danger after:ml-0.5",
}

export const Input = ({
  type = 'text',
  name,
  fullWidth = false,
  label,
  value,
  onChange,
  disabled,
  success = false,
  password = false,
  errorMessage,
  successMessage,
  required = false,
  infoText,
  className,
  mono = false,
  helpText = '',
  helpTextDelay = null,
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const passwordInputType = showPassword ? 'text' : 'password'

  const valueHadInput = useMemo(() => value !== '', [value])

  return (
    <div className="relative mb-4 ej-input-wrapper">
      {!!label && (
        <label
          className={tw({
            [styles.labelRoot]: true,
            [styles.labelIdle]: !success && !errorMessage && !disabled,
            [styles.success]: success,
            [styles.error]: !!errorMessage,
            [styles.disabled]: disabled,
            [styles.required]: required,
          })}
          htmlFor={name}
        >
          {label}
          {!!helpText && (
            <Tooltip placement="top">
              <Tooltip.Trigger ariaLabel={helpText.toString()} delay={helpTextDelay}>
                <QuestionMarkCircleIcon className={styles.questionMark} />
              </Tooltip.Trigger>
              <Tooltip.Body>{helpText}</Tooltip.Body>
            </Tooltip>
          )}
        </label>
      )}
      {infoText ? <div className={styles.infoText}>{infoText}</div> : null}
      <div
        className={tw({
          [styles.inputFieldWrapperRoot]: true,
          [styles.fullWidth]: fullWidth,
        })}
      >
        <input
          aria-label={name}
          onChange={onChange}
          disabled={disabled}
          id={name}
          name={name}
          className={tw({
            [styles.inputRoot]: true,
            [styles.inputIdle]: !success && !errorMessage && !disabled,
            [styles.mono]: mono,
            [styles.fullWidth]: !!fullWidth,
            [styles.success]: success,
            [styles.error]: !!errorMessage || (valueHadInput && required && value === ''),
            [styles.disabled]: disabled,
            [className]: !!className,
          })}
          value={value}
          {...rest}
          type={password ? passwordInputType : type}
          data-hj-allow={!password}
        />
        {password && (
          <ToggleShowPassword {...{ setShowPassword, showPassword, passwordInputType }} />
        )}
      </div>
      {!!errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
      {!!successMessage && <div className={styles.successMessage}>{successMessage}</div>}
    </div>
  )
}
