import { tw } from '@electro/shared/utils/tailwind-merge'
import React, { FC, ReactNode, ElementType, HTMLAttributes } from 'react'

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  variant?: 'body' | 'small' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'display' | 'p'
  className?: string
  children: ReactNode
  as?: ElementType
}

export const Typography: FC<TypographyProps> = ({
  variant = 'body',
  className,
  children,
  as: Component = 'div',
  ...rest
}) => {
  const baseClassName = 'text-white tracking-wide'

  const variantClasses = {
    display: 'text-4xl md:text-6xl lg:text-8xl font-medium uppercase',
    body: 'text-base font-normal',
    small: 'text-sm font-normal',
    h1: 'md:text-3xl font-medium',
    h2: 'text-base lg:text-xl font-medium',
    h3: 'text-lg font-medium',
    h4: 'text-base font-medium',
    h5: 'text-base font-medium',
    h6: 'text-base font-medium',
    p: 'text-base font-normal',
  }

  const classes = tw(baseClassName, variantClasses[variant], className)

  return (
    <Component className={classes} {...rest} data-hj-allow>
      {children}
    </Component>
  )
}
