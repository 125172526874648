import {
  DesktopNavigation,
  MobileNavigation,
} from '@electro/consumersite/src/components/TopNavigation/components'
import { useRouter } from 'next/router'
import { HEADLESS_NAVIGATION_PARAM } from '@electro/shared/constants'

export const TopNavigation = () => {
  const { query } = useRouter()

  const navigationIsHidden = query?.[HEADLESS_NAVIGATION_PARAM] === 'true'
  return !navigationIsHidden ? (
    <header
      data-testid="top-navigation"
      className="items-start self-stretch flex flex-col max-md:max-w-full"
    >
      <div
        data-testid="desktop-nav-wrapper"
        className="hidden lg:block [touch-action:none]" // Prevents gesture zooming on touch screens
      >
        <DesktopNavigation />
      </div>
      <div data-testid="mobile-nav-wrapper" className="lg:hidden">
        <MobileNavigation />
      </div>
    </header>
  ) : (
    <div className="-mb-20" data-testid="headless-navigation-spacer" />
  )
}
