import { tw } from '@electro/shared/utils/tailwind-merge'
import Image from 'next/image'
import { useMemo } from 'react'
import { Card, Typography } from '@electro/shared-ui-components'
import { EjnEjnCardOrderStatusChoices } from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { useLocalisedDateFormat, FormattingOptionsEnum } from '@electro/shared/hooks'

// TODO: add props.designOption when ready for this change
interface CardOrderItemProps {
  orderDate: string
  orderStatus: EjnEjnCardOrderStatusChoices
}

const styles = {
  root: 'flex mb-2 last:mb-0 rounded-md',
  detailsWrapper: 'ml-4 xs:ml-8 flex flex-col justify-center',
  label: 'text-xs xs:text-sm text-wrap text-white',
  value: 'text-inherit font-medium',
  status: {
    completed: 'text-action-success',
    cancelled: 'text-action-danger',
  },
}

export const CardOrderItem = ({ orderDate, orderStatus }: CardOrderItemProps) => {
  const { t } = useTranslation('common')
  const date = useLocalisedDateFormat({
    date: orderDate,
    formattingOption: FormattingOptionsEnum.DD_MMMM_yyyy,
  })

  const status = useMemo((): EjnEjnCardOrderStatusChoices | string => {
    switch (orderStatus) {
      case EjnEjnCardOrderStatusChoices.New:
        return t('profile.electrocard_orders.order.order_status.confirmed')
      case EjnEjnCardOrderStatusChoices.Pending || EjnEjnCardOrderStatusChoices.Unregistered:
        return t('profile.electrocard_orders.order.order_status.pending')
      case EjnEjnCardOrderStatusChoices.Cancelled:
        return t('profile.electrocard_orders.order.order_status.cancelled')
      case EjnEjnCardOrderStatusChoices.Completed:
        return t('profile.electrocard_orders.order.order_status.shipped')
      default:
        return t('profile.electrocard_orders.order.order_status.pending')
    }
  }, [orderStatus, t])

  return (
    <Card transparent density="high" className={styles.root}>
      <Image src="/images/cards/electrocard.svg" alt="Electrocard order" width={50} height={70} />
      <div className={styles.detailsWrapper}>
        <Typography className={styles.label}>
          {t('profile.electrocard_orders.order.date')} <span className={styles.value}>{date}</span>
        </Typography>
        <Typography className={styles.label}>
          {t('profile.electrocard_orders.order.status')}{' '}
          <span
            className={tw({
              [styles.value]: true,
              [styles.status.completed]: orderStatus === EjnEjnCardOrderStatusChoices.Completed,
              [styles.status.cancelled]: orderStatus === EjnEjnCardOrderStatusChoices.Cancelled,
            })}
          >
            {status}
          </span>
        </Typography>
      </div>
    </Card>
  )
}
