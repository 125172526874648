import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { SidebarNavIcon } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'
import { MyEVsPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { MyEVsIcon } from '@electro/consumersite/src/icons'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  navIcon: 'w-full p-0.5',
}

export const MyEVsSidebarIcon = () => (
  <SidebarNavIcon panelName={SidebarPanels.MY_EVS}>
    <MyEVsIcon className={styles.navIcon} />
  </SidebarNavIcon>
)

export const MyEVsPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer panelType={SidebarPanels.MY_EVS} label={t('map.my_evs.title')}>
      <MyEVsPanelContent />
    </SidebarPanelContainer>
  )
}
