import { FC, useMemo } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useFeatureFlag } from '@electro/consumersite/src/hooks'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import useTranslation from 'next-translate/useTranslation'
import { DebtWarningStatus, useUserQuery } from '@electro/consumersite/generated/graphql'
import { isActiveAccountFleetDriver } from '@electro/consumersite/src/utils/isActiveAccountFleetDriver'

interface NavItem {
  key: string
  label: string
  withIcon?: boolean
  isHidden?: boolean
  pathname: string
  regex: RegExp
  className?: string
}

const styles = {
  nav: {
    root: 'flex flex-col pr-6',
  },
  menuItem: {
    link: 'cursor-pointer no-underline text-base text-white hover:text-tertiary my-2 focus:ring-0 flex w-full',
    active: 'md:font-medium md:text-white hover:text-white cursor-default',
    userDebtWarning: {
      escalated: 'ml-2 w-5 h-5 bg-escalated-warning',
      issued: 'ml-2 w-5 h-5 bg-issued-warning',
    },
  },
}

interface Props {
  items?: NavItem[]
}

export const AccountManageNavigation: FC<Props> = () => {
  const { t } = useTranslation('common')
  const { pathname } = useRouter()
  const { setStep } = useAccountManageMobileStep()
  const { data: userData, loading: userLoading } = useUserQuery()
  const showDebtWarningFeature = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DEBT_PREVENTION === 'on',
  )

  /**
   * Only when device is mobile it open the account content
   * when the nav item pathname is equal with page pathname.
   */
  const handleMobileNavigationClick = (item: NavItem) => {
    const activePathname = item.pathname === pathname

    if (activePathname) {
      setStep(AccountManageMobileStepsEnum.ACCOUNT_MANAGE_CONTENT)
    }
  }

  const userHasIssuedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Issued,
    [userData?.me?.financialInfo?.debtStatus],
  )
  const userHasEscalatedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Escalated,
    [userData?.me?.financialInfo?.debtStatus],
  )

  const activeUserIsAFleetDriver = useMemo(
    () => isActiveAccountFleetDriver(userData) && !userLoading,
    [userData, userLoading],
  )

  const ACCOUNT_NAV: NavItem[] = useMemo(() => {
    const NAV_ITEMS = [
      {
        key: 'general',
        label: 'profile.navigation.account_general',
        pathname: '/user/account',
        regex: /^\/user\/account$/,
      },
      {
        key: 'vehicles',
        label: 'profile.navigation.evs_option',
        pathname: '/user/account/vehicles',
        regex: /\/user\/account\/vehicles[a-z]{0,}.*/,
      },
      {
        key: 'electrocards',
        label: 'profile.navigation.electrocards',
        pathname: '/user/account/electrocards',
        regex: /\/user\/account\/electrocards[a-z]{0,}.*/,
      },
      {
        key: 'payment',
        label: 'profile.navigation.payment_methods',
        pathname: '/user/account/payment-methods',
        regex: /\/user\/account\/payment[a-z]{0,}.*/,
        withIcon: (userHasEscalatedDebt || userHasIssuedDebt) && showDebtWarningFeature,
      },
      {
        key: 'vouchers',
        label: 'profile.navigation.vouchers_and_credit',
        pathname: '/user/account/vouchers-and-credit',
        regex: /\/user\/account\/vouchers[a-z]{0,}.*/,
        isHidden: activeUserIsAFleetDriver,
      },
      {
        key: 'close',
        label: 'profile.navigation.danger_zone',
        pathname: '/user/account/close',
        regex: /\/user\/account\/close[a-z]{0,}.*/,
        className: 'mt-6 border-secondary/50 border-t-2 pt-6',
        isHidden: activeUserIsAFleetDriver,
      },
    ]
    const filteredList = NAV_ITEMS.filter((item: NavItem) => !item.isHidden)
    return filteredList
  }, [userHasEscalatedDebt, userHasIssuedDebt, activeUserIsAFleetDriver, showDebtWarningFeature])

  return (
    <div className="w-66">
      <div className="flex flex-col justify-between gap-12">
        <nav
          className={tw({
            [styles.nav.root]: true,
          })}
        >
          {ACCOUNT_NAV.map((item) => (
            <Link
              key={item.key}
              href={item.pathname}
              onClick={() => handleMobileNavigationClick(item)}
              className={tw({
                [styles.menuItem.link]: true,
                [styles.menuItem.active]: item.regex.test(pathname),
                [item.className]: item.className,
              })}
            >
              {t(item.label)}
              {item.withIcon ? (
                <span
                  data-testid="list-item-warning-icon"
                  className={tw({
                    [styles.menuItem.userDebtWarning.escalated]: userHasEscalatedDebt,
                    [styles.menuItem.userDebtWarning.issued]: userHasIssuedDebt,
                  })}
                />
              ) : null}
            </Link>
          ))}
        </nav>
      </div>
    </div>
  )
}
