export const USER_ID_PARAM = 'user_id'
export const SHORT_LIVED_TOKEN_PARAM = 'short_lived_token'
/**
 * Runs a quick check to see if we have the necessary params to
 * authenticate via the cross platform quickAuth endpoint.
 * Doing so allows us to auth a user inside a web view in the mobile apps.
 * @returns boolean
 */
export default function urlHasQuickAuthParams(): boolean {
  const urlParams = new URLSearchParams(window?.location?.search)
  const userId = urlParams.get(USER_ID_PARAM)
  const shortLivedToken = urlParams.get(SHORT_LIVED_TOKEN_PARAM)
  return !!userId && !!shortLivedToken
}
