import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  root: 'my-auto',
  img: 'mx-auto w-36',
  text: 'mx-auto px-4 mt-4 text-center',
}

export const RouteOverviewNoRoute = () => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.root}>
      <Image
        src="/images/zapman-confused.svg"
        alt="Zapman is a bit confused"
        className={styles.img}
        height={780}
        width={432}
      />
      <h3 className={styles.text}>{t('map.route_overview.no_route')}</h3>
    </div>
  )
}
