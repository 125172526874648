import { UTCDate } from '@date-fns/utc'
import { Card } from '@electro/shared-ui-components'
import Image from 'next/image'
import { useEffect } from 'react'
import { useAuth } from '@electro/consumersite/src/hooks'

/**
 * Wrap this Component around a page or component you want to hide when the
 * Back End GraphQl endpoint is down for maintenance.
 *
 * @param showMaintenanceScreen
 * Will show a full screen maintenance message that sits underneath the top navigation.
 */
export const HideWhenSiteUnderMaintenance = ({ children, showMaintenanceScreen = false }) => {
  const [{ session }, { logout }] = useAuth()

  /**
   * Scheduled downtime strings must be set in the .env file to a valid UTC date string.
   * If the time strings are invalid the build will fail.
   */
  const maintenanceFlagSet = process.env.NEXT_PUBLIC_SITE_UNDER_MAINTENANCE === 'true'
  const scheduledDowntimeStart = process.env.NEXT_PUBLIC_SCHEDULED_DOWNTIME_START_UTC
  const scheduledDowntimeEnd = process.env.NEXT_PUBLIC_SCHEDULED_DOWNTIME_END_UTC
  const timeNowInUtc = new UTCDate(new Date()).toUTCString()

  const hasScheduledDowntime = scheduledDowntimeStart && scheduledDowntimeEnd

  const utcTimeNowIsBetweenScheduledDowntime = hasScheduledDowntime
    ? new UTCDate(timeNowInUtc) >= new UTCDate(scheduledDowntimeStart) &&
      new UTCDate(timeNowInUtc) <= new UTCDate(scheduledDowntimeEnd)
    : false

  /**
   * If the user is logged in we want to log them out when the site is under maintenance
   * This is so they can't make calls to the api via the sections of the UI that are
   * available site wide. E.g Vehicle management & user login.
   */
  useEffect(() => {
    if (maintenanceFlagSet && session) logout()
  }, [logout, session, maintenanceFlagSet])

  const siteCurrentlyUnderMaintenance = maintenanceFlagSet || utcTimeNowIsBetweenScheduledDowntime

  return siteCurrentlyUnderMaintenance
    ? showMaintenanceScreen && (
        <div className="flex items-center justify-center w-screen h-screen fixed z-30 bg-tertiary-shade flex-col">
          <Card className="text-center">
            <Image
              className="m-auto"
              src="/images/electrician-zap.png"
              width={190}
              height={269}
              alt=""
            />
            <h1 className="mt-8">This page is under planned maintenance!</h1>
            <p>Check back soon and it will be up and running.</p>
          </Card>
        </div>
      )
    : children
}
