import { Modal } from '@electro/shared-ui-components'
import {
  PaymentDetailsModalScreen,
  PaymentProcessingModalScreen,
  PaymentSuccessModalScreen,
  PaymentFailModalScreen,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/components'
import { ModalScreensEnum } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/ManageOutstandingPayments.types'
import { useManageOutstandingPayments } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/hooks'

interface ModalScreenProps {
  content: JSX.Element
}

export const OutstandingPaymentModal = () => {
  const { activeModalScreen, errorMessage, closeModalScreen } = useManageOutstandingPayments()

  const getModalOptions = (screen: ModalScreensEnum): ModalScreenProps => {
    const map: Record<ModalScreensEnum, ModalScreenProps> = {
      [ModalScreensEnum.PAYMENT_DETAILS_MODAL_SCREEN]: {
        content: <PaymentDetailsModalScreen />,
      },
      [ModalScreensEnum.PAYMENT_PROCESSING_MODAL_SCREEN]: {
        content: <PaymentProcessingModalScreen />,
      },
      [ModalScreensEnum.PAYMENT_SUCCESS_MODAL_SCREEN]: {
        content: <PaymentSuccessModalScreen />,
      },
      [ModalScreensEnum.PAYMENT_FAIL_MODAL_SCREEN]: {
        content: <PaymentFailModalScreen error={errorMessage} />,
      },
    }

    return map[screen] || null
  }

  const elem = getModalOptions(activeModalScreen)

  return (
    <Modal
      data-testid="outstanding-payment-modal"
      size="md"
      open={!!activeModalScreen}
      onClose={closeModalScreen}
    >
      <Modal.CloseButton />
      {elem?.content}
    </Modal>
  )
}
