import { Locale } from '@electro/shared/types/locales'
import { SearchBoxSuggestionResponse } from '@mapbox/search-js-core'

const MAPBOX_SEARCHBOX_API_ENDPOINT = 'https://api.mapbox.com/search/searchbox/v1/suggest?'

interface FetchPlaceSuggestionsArgs {
  /**
   * @searchQuery is the text query to search for.
   */
  searchQuery: string
  /**
   * @sessionToken is a unique identifier for the session.
   * Required for rate limiting and billing purposes. UUIDv4 recommended.
   */
  sessionToken: string

  /**
   * @locale is requested language format. e.g. en-GB
   */
  locale?: Locale | string
  /**
   * @country is a comma-separated list of ISO 3166 alpha 2 country codes.
   * Results will be more relevant for the countries provided.
   */
  country?: string
  /**
   * @types is a comma separated list of types to filter results by.
   * e.g. 'country,region,postcode,district,place,city,locality,neighborhood,street,address,poi,category'
   */
  types?: string
  /**
   * @limit is the maximum number of results to return, up to 10.
   */
  limit?: string
  /**
   * @proximity uses 'lng,lat' coordinates to bias the search results.
   * Results are biased to the user IP address if none provided.
   */
  proximity?: string
  /**
   * @origin uses 'lng,lat' coordinates to limit search results to drivable locations.
   */
  origin?: string
}

/** https://docs.mapbox.com/api/search/search-box/#interactive-search */
export const fetchPlaceSuggestions = async ({
  searchQuery,
  sessionToken,
  locale,
  country,
  types = 'country,region,postcode,district,place,city,locality,neighborhood,street,address,poi',
  limit = '5',
  proximity,
  origin,
}: FetchPlaceSuggestionsArgs): Promise<SearchBoxSuggestionResponse> => {
  if (!process.env.NEXT_PUBLIC_MAPBOX_API_KEY) return { url: '', suggestions: [] }

  const params = {
    q: encodeURIComponent(searchQuery),
    access_token: process.env.NEXT_PUBLIC_MAPBOX_API_KEY,
    session_token: sessionToken,

    ...(locale ? { language: locale.slice(0, 2) } : {}),
    ...(country ? { country } : {}),
    ...(types ? { types } : {}),
    ...(limit ? { limit } : {}),
    ...(proximity ? { proximity } : {}),
    ...(origin ? { origin, navigation_profile: 'driving' } : {}),
  }

  const requestURL = `${MAPBOX_SEARCHBOX_API_ENDPOINT}${new URLSearchParams(params)}`
  const response = await fetch(requestURL, { method: 'GET' })

  return response.json()
}
