import { client } from '@electro/consumersite/src/client/client'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useLocalStorage } from 'react-use'
import { UserAccountButton } from '@electro/consumersite/src/components'
import { Typography } from '@electro/shared-ui-components'
import { useUserQuery, AccountType } from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { ACCOUNT_ID } from '@electro/consumersite/src/constants/localStorage'

interface UserAccountSelectProps {
  isLoginFlow?: boolean
  onAccountSelect?: (account: number) => void
}

const styles = {
  root: 'flex flex-col mx-2',
  rootLogin: 'mx-8 md:mx-10 mt-6 md:mt-8',
  header: 'uppercase text-base-dark/60 text-xs mb-2',
  container: 'overflow-y-auto h-32',
  containerLogin: 'h-44',
}

export const UserAccountSelect = ({
  isLoginFlow = false,
  onAccountSelect,
}: UserAccountSelectProps) => {
  const { t } = useTranslation('common')
  const { data: userData } = useUserQuery()
  const [activeAccount, setActiveAccount] = useLocalStorage(ACCOUNT_ID)

  const handleClick = async (account: AccountType) => {
    setActiveAccount(account.id)

    if (onAccountSelect) {
      onAccountSelect(account.id)
    }
    await client.resetStore()
    if (!isLoginFlow) {
      window.location.reload()
    }
  }

  return userData?.me?.accounts?.length > 1 ? (
    <div
      className={tw({
        [styles.root]: true,
        [styles.rootLogin]: isLoginFlow,
      })}
    >
      {!isLoginFlow && (
        <Typography variant="small" as="div" className={styles.header}>
          {t('user.menu.select_account')}
        </Typography>
      )}
      <div
        className={tw({
          [styles.container]: true,
          [styles.containerLogin]: isLoginFlow,
        })}
      >
        {userData?.me?.accounts?.map((account) => (
          <UserAccountButton
            account={account}
            key={account.id}
            onClick={handleClick}
            isSelected={account.id === activeAccount}
            isLoginFlow={isLoginFlow}
          />
        ))}
      </div>
    </div>
  ) : null
}
