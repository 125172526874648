import { RouteDictionaryType } from '@electro/consumersite/src/components/Map/types'

export enum RouteTitlesEnum {
  FASTEST_ROUTE = 'map.route_overview.summary.title.fastest_route',
  SHORTEST_CHARGE = 'map.route_overview.summary.title.shortest_charge',
  SHORTEST_ROUTE = 'map.route_overview.summary.title.shortest_route',
}

export type RouteTitlesType = `${RouteTitlesEnum}`
const RouteTitlesOrder = Object.values(RouteTitlesEnum)

/** Compares routes to give them a special title if they meet certain conditions.
 *  A route can only have 1 title, so they are assigned in order of priority. */
export const getRouteSummaryTitles = (
  routes: RouteDictionaryType['routes'],
): RouteDictionaryType['routes'] => {
  const updatedRoutes = routes

  if (routes.length === 1) {
    // FASTEST ROUTE - If there is only 1 route, it is the fastest
    updatedRoutes[0].summary.title = RouteTitlesEnum.FASTEST_ROUTE
  } else if (routes.length > 1) {
    // FASTEST ROUTE
    const fastestRouteIndex = routes.reduce((prev, curr) =>
      prev.summary.driveDurationSeconds < curr.summary.driveDurationSeconds ? prev : curr,
    ).id
    if (!updatedRoutes[fastestRouteIndex].summary.title) {
      updatedRoutes[fastestRouteIndex].summary.title = RouteTitlesEnum.FASTEST_ROUTE
    }

    // SHORTEST CHARGE - We don't assign this if there are no charge stops
    if (!routes.some((route) => route.summary.chargingStopsCount === 0)) {
      const shortestChargeIndex = routes.reduce((prev, curr) =>
        prev.summary.chargeDurationSeconds < curr.summary.chargeDurationSeconds ? prev : curr,
      ).id

      if (!updatedRoutes[shortestChargeIndex].summary.title) {
        updatedRoutes[shortestChargeIndex].summary.title = RouteTitlesEnum.SHORTEST_CHARGE
      }
    }

    // SHORTEST ROUTE
    const shortestRouteIndex = routes.reduce((prev, curr) =>
      prev.summary.distanceMetres < curr.summary.distanceMetres ? prev : curr,
    ).id
    if (!updatedRoutes[shortestRouteIndex].summary.title) {
      updatedRoutes[shortestRouteIndex].summary.title = RouteTitlesEnum.SHORTEST_ROUTE
    }

    // Sort routes into title enum order, with untitled routes at the end
    const sortedRoutes = updatedRoutes.sort((a, b) => {
      if (!a.summary.title) return 1
      if (!b.summary.title) return -1
      return (
        RouteTitlesOrder.indexOf(a.summary.title as RouteTitlesEnum) -
        RouteTitlesOrder.indexOf(b.summary.title as RouteTitlesEnum)
      )
    })

    // This makes it easier to reference the route by index after sorting them
    const updateRouteIndexes = sortedRoutes.map((route, index) => ({ ...route, id: index }))

    return updateRouteIndexes
  }

  return updatedRoutes
}
