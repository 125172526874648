import { v1 as uuid } from 'uuid'
import { devOrPreviewOrTestEnv } from '@electro/shared/utils/isEnv'
import { RoutePlanMutation } from '@electro/consumersite/generated/graphql'
import {
  RouteDictionaryRouteType,
  RouteDictionaryType,
} from '@electro/consumersite/src/components/Map/types'
import {
  getMarkerIconFromRouteStep,
  getRouteSummaryTitles,
} from '@electro/consumersite/src/components/Map/helpers'

type IternioRoutesType = RoutePlanMutation['routePlan']['planResponse']['result']['routes']

/** Creates simplified route objects with unique titles, and sorts them */
export const buildRoutesDictionary = (
  iternioRoutes: IternioRoutesType,
): RouteDictionaryType['routes'] => {
  const routesDictionary = iternioRoutes.map((route, index) => ({
    id: index,
    summary: buildRouteSummary(route),
    markers: buildRouteMarkers(route, { routeID: index }),
    polyline: buildRoutePolyline(route),
  }))

  return getRouteSummaryTitles(routesDictionary)
}

/** Collects useful data to quickly compare the suggested routes */
const buildRouteSummary = (
  route: IternioRoutesType[number],
): RouteDictionaryRouteType['summary'] => ({
  distanceMetres: route.totalDist,
  energyConsumedKWh: route.totalEnergyUsed,
  driveDurationSeconds: route.totalDriveDuration,
  chargeDurationSeconds: route.totalChargeDuration,
  chargingStopsCount: route.steps.filter((step) => step.isCharger).length,
})

/** Creates a marker object for each waypoint/charger for a more detailed view of the route */
const buildRouteMarkers = (
  route: IternioRoutesType[number],
  { routeID }: { routeID: number },
): RouteDictionaryRouteType['markers'] =>
  route.steps.map((step, index) => {
    const nextStepDistanceToRouteEnd = route.steps[index + 1]?.arrivalDist ?? 0

    const markerInfo = {
      id: `marker-${step.id}-${uuid()}`,
      address: step?.name,
      coordinates: { lat: step?.lat, lng: step?.lon },
      icon: getMarkerIconFromRouteStep({ routeID, step }),
      departureBatteryPercentage: step.departurePerc,
      arrivalBatteryPercentage: step.arrivalPerc,
      driveDuration: step.driveDuration,
      driveDistance: step.arrivalDist - nextStepDistanceToRouteEnd,
      isStationStart: step.isStation,
      isStationEnd: step.isEndStation,
    }

    if (step.isCharger) {
      const isEjnLocation = Boolean(step?.charger?.ejnChargingLocationId)
      const chargerID = isEjnLocation
        ? step?.charger?.ejnChargingLocationId
        : step?.charger?.ecoChargingLocationId

      // On staging, default to a specific charger due to Iternio returning production locations
      const fallbackID = chargerID ?? (devOrPreviewOrTestEnv && '256886')

      return {
        ...markerInfo,
        charger: { id: fallbackID, isEjnLocation, chargeDuration: step.chargeDuration },
      }
    }

    return markerInfo
  })

/** Defines the route line to display on the map, with battery estimations included */
const buildRoutePolyline = (
  route: IternioRoutesType[number],
): RouteDictionaryRouteType['polyline'] =>
  route.steps.map((step, index) => ({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          // Route steps at stations (e.g. train, ferry) have identical IDs
          // for departure and arrival. We use UUID to differentiate them
          id: `line-${step.id}-${uuid()}`,
          lineStartBatteryPercentage: step.departurePerc,
          lineEndBatteryPercentage: route.steps[index + 1]?.arrivalPerc,
        },
        geometry: {
          type: 'LineString',
          coordinates: step?.path?.map(([lng, lat]) => [lat, lng]),
        },
      },
    ],
  }))
