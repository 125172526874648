import { useMemo } from 'react'
import { Alert, Button, Modal, Typography } from '@electro/shared-ui-components'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import useTranslation from 'next-translate/useTranslation'
import { useManageOutstandingPayments } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/hooks'
import { StripeFailureReason } from '@electro/consumersite/generated/graphql'

export const PaymentFailModalScreen = ({ error }) => {
  const { t } = useTranslation('common')
  const { closeModalScreen } = useManageOutstandingPayments()

  const latestFailureReason = useMemo(() => {
    if (
      error === StripeFailureReason.PaymentDeclined ||
      error === StripeFailureReason.CardDeclined ||
      error === StripeFailureReason.DeclineRateLimitExceeded
    ) {
      return t('profile.outstanding_payments.payment_failure_reason.payment_failed_try_again')
    }
    if (
      error === StripeFailureReason.DoNotHonor ||
      error === StripeFailureReason.Fraudulent ||
      error === StripeFailureReason.InvalidAccount ||
      error === StripeFailureReason.TransactionNotAllowed ||
      error === StripeFailureReason.RevocationOfAuthorization ||
      error === StripeFailureReason.StolenCard
    ) {
      return t('profile.outstanding_payments.payment_failure_reason.payment_failed_contact_bank')
    }
    if (error === StripeFailureReason.InsufficientFunds) {
      return t('profile.outstanding_payments.payment_failure_reason.insufficient_funds')
    }
    if (error === StripeFailureReason.CardExpired) {
      return t('profile.outstanding_payments.payment_failure_reason.card_expired')
    }
    if (error === StripeFailureReason.IncorrectNumber) {
      return t('profile.outstanding_payments.payment_failure_reason.incorrect_number')
    }
    if (error === StripeFailureReason.TryAgainLater) {
      return t('profile.outstanding_payments.payment_failure_reason.try_again_later')
    }
    if (
      error === StripeFailureReason.AmountTooSmall ||
      error === StripeFailureReason.InvalidRequest ||
      error === 'UNKNOWN'
    ) {
      return t('profile.outstanding_payments.payment_failure_reason.payment_failed')
    }
    return t('profile.outstanding_payments.payment_failure_reason.payment_not_found')
  }, [error, t])

  return (
    <>
      <Modal.Body>
        <Typography variant="h3" as="h1" className="text-center mb-0">
          {t('profile.outstanding_payments.resolve_payment_modal.payment_fail_screen.title')}
        </Typography>
        {error ? (
          <div className="flex justify-center">
            <Alert variant="warn" className="mt-4 bg-action-danger">
              {latestFailureReason}
            </Alert>
          </div>
        ) : null}
        <AnimatedComponent
          name="payment-fail-animation"
          lottieProps={{ className: 'h-[170px]', loop: false }}
          getAnimationData={ANIMATIONS.paymentFail}
        />
        <Typography className="text-center mt-2">
          {t('profile.outstanding_payments.resolve_payment_modal.payment_fail_screen.description')}
        </Typography>
      </Modal.Body>
      <Modal.Actions center>
        <Button onClick={() => closeModalScreen()}>
          {t(
            'profile.outstanding_payments.resolve_payment_modal.cta_buttons.go_to_payment_methods',
          )}
        </Button>
      </Modal.Actions>
    </>
  )
}
