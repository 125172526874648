import { ReactNode, useMemo } from 'react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { EjnEjnTokenStatusChoices } from '@electro/consumersite/generated/graphql'
import { Card, Typography } from '@electro/shared-ui-components'

// TODO: add props.designOption when ready for this change
interface CardItemProps {
  number: string
  nameOnCard?: string
  cardStatus: EjnEjnTokenStatusChoices | string
  children: ReactNode | ReactNode[]
}

const styles = {
  root: 'flex mb-2 last:mb-0 rounded-md',
  content: 'disabled:opacity-30 flex-1 flex items-stretch', // IS THIS DISABLED REALLY HAPPENING? if not the div can be removed
  detailsWrapper: 'ml-4 xs:ml-8 flex w-full justify-between items-center',
  label: 'text-xs xs:text-sm text-wrap text-white',
  value: 'text-inherit font-medium text-nowrap',
}

export const CardItem = ({ nameOnCard, number, cardStatus, children }: CardItemProps) => {
  const { t } = useTranslation('common')

  const status = useMemo((): EjnEjnTokenStatusChoices | string => {
    switch (cardStatus) {
      case EjnEjnTokenStatusChoices.Pending:
        return t('profile.electrocards.electrocard.electrocard_status.pending')
      case EjnEjnTokenStatusChoices.Active:
        return t('profile.electrocards.electrocard.electrocard_status.active')
      case EjnEjnTokenStatusChoices.Cancelled:
        return t('profile.electrocards.electrocard.electrocard_status.cancelled')
      case EjnEjnTokenStatusChoices.Unassigned:
        return t('profile.electrocards.electrocard.electrocard_status.unassigned')
      default:
        return t('profile.electrocards.electrocard.electrocard_status.pending')
    }
  }, [cardStatus, t])

  return (
    <Card density="high" transparent className={styles.root}>
      <div className={styles.content}>
        <Image src="/images/cards/electrocard.svg" alt="Electrocard" width={50} height={70} />
        <div className={styles.detailsWrapper}>
          <div>
            <Typography className={styles.label}>{nameOnCard}</Typography>
            <Typography className={styles.label}>
              {t('profile.electrocards.electrocard.number')}{' '}
              <span className={styles.value}>{number}</span>
            </Typography>
            <Typography className={styles.label}>
              {t('profile.electrocards.electrocard.status')}{' '}
              <span className={styles.value}>{status}</span>
            </Typography>
          </div>
        </div>
      </div>
      {children}
    </Card>
  )
}
