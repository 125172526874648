import { HTMLAttributes, ReactNode } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { CheckIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { Typography } from '@electro/shared-ui-components'
import { StepProvider } from './StepContext'
import { useStepper } from '../Stepper/StepperContext'

const styles = {
  root: 'flex flex-1 items-center',
  last: 'flex-initial',
  avatar: {
    wrapper: 'self-stretch flex flex-col',
    circle:
      'bg-secondary flex items-center justify-center h-8 w-8 rounded-full shrink-0 ring-1 ring-inset ring-secondary ',
    icon: 'h-5 w-5 text-white',
    disabled: 'bg-transparent step-disabled',
  },
  content: {
    wrapper: 'flex-1 min-w-0 flex flex-row self-stretch py-1',
    children: 'flex flex-col',
    wrapperWithChildren: 'ml-4',
  },
  vertical: {
    root: 'my-2 relative flex justify-center h-full min-h-[24px] w-full self-stretch',
    connector: 'absolute w-[2px] h-full bg-secondary',
  },
  horizontal: {
    root: 'relative flex flex-1 mx-2 self-stretch',
    connector: 'mt-3 flex-1 mx-2 self-start h-[2px] min-w-[24px] bg-secondary',
  },
  hidden: 'hidden',
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
  active?: boolean
  completed?: boolean
  disabled?: boolean
  last?: boolean
  index?: number
  blank?: boolean
}

export const Step = ({
  active,
  completed,
  index,
  disabled,
  last,
  children,
  blank,
  ...rest
}: Props) => {
  const { activeStep, orientation } = useStepper()
  const isActive = Boolean(active !== undefined ? active : activeStep === index)
  const isCompleted = Boolean(activeStep > index || completed)
  const isUpcoming = Boolean(activeStep < index)
  const isDisabled = (!isActive && !isCompleted && isUpcoming) || disabled

  return (
    <StepProvider active={isActive}>
      <div
        className={tw({
          [styles.root]: true,
          [styles.last]: last && orientation === 'horizontal',
        })}
        data-disabled={isDisabled}
        data-testid="step"
        data-index={index}
        data-last={last}
        data-active={isActive}
        {...rest}
      >
        <div className={styles.avatar.wrapper}>
          <span
            className={clsx({
              [styles.avatar.circle]: true,
              [styles.avatar.disabled]: isDisabled,
            })}
          >
            {isCompleted ? (
              <CheckIcon
                data-testid="completed-icon"
                className={styles.avatar.icon}
                aria-hidden="true"
              />
            ) : null}

            {!isCompleted && !blank ? <Typography>{index + 1}</Typography> : null}
          </span>

          <div
            data-testid="vertical-connector"
            className={clsx({
              [styles.vertical.root]: true,
              [styles.hidden]: last || orientation === 'horizontal',
            })}
          >
            <div className={styles.vertical.connector} />
          </div>
        </div>

        <span
          className={tw({
            [styles.content.wrapper]: true,
            [styles.content.wrapperWithChildren]: !!children,
          })}
        >
          {children ? <div className={styles.content.children}>{children}</div> : null}

          <div
            data-testid="horizontal-connector"
            className={tw({
              [styles.horizontal.root]: true,
              [styles.hidden]: last || orientation === 'vertical',
            })}
          >
            <div className={styles.horizontal.connector} />
          </div>
        </span>
      </div>
    </StepProvider>
  )
}
