import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import KRAKEN_ACCOUNTS_QUERY from '@electro/consumersite/src/graphql/krakenAccountsQuery.graphql'

import { Query } from '@electro/consumersite/generated/graphql'

export type FetchDriver = Pick<Query, 'krakenAccounts'>
type UseFetchDriver = QueryResult<FetchDriver>

export function useFetchKrakenAccounts(options?: QueryHookOptions<FetchDriver>): UseFetchDriver {
  const KrakenAccountsQuery = useQuery<FetchDriver>(KRAKEN_ACCOUNTS_QUERY, options)

  return KrakenAccountsQuery
}
