import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline'
import { ChangeEvent } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Input } from '@electro/shared-ui-components'
import { useDataGrid } from '../../hooks'

const styles = {
  root: 'relative',
  icons: {
    magnifyGlassIcon: 'w-6 h-6 text-white absolute left-3 top-1/2 -translate-y-1/2',
    xIcon: 'w-6 h-6 text-white cursor-pointer absolute right-3 top-1/2 -translate-y-1/2',
  },
  input: 'rounded-full pl-10 pr-10',
}

export const SearchToolbar = () => {
  const { t } = useTranslation('common')
  const [{ searchText }, { handleSearchText }] = useDataGrid()

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    handleSearchText(event.currentTarget.value)

  return (
    <div className={styles.root} data-testid="data-grid-search-toolbar">
      <MagnifyingGlassIcon className={styles.icons.magnifyGlassIcon} />

      <Input
        fullWidth
        name={t('common.button.search')}
        placeholder={t('search_toolbar.placeholder')}
        className={styles.input}
        value={searchText}
        onChange={handleOnChange}
      />

      {searchText && (
        <XCircleIcon className={styles.icons.xIcon} onClick={() => handleSearchText('')} />
      )}
    </div>
  )
}
