import {
  ChangeEvent,
  useEffect,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useCallback,
} from 'react'

import { useFormik } from 'formik'
import useTranslation from 'next-translate/useTranslation'
import { Input, Button, Typography } from '@electro/shared-ui-components'
import scrollToFirstErrorInDocument from '@electro/consumersite/src/helpers/scrollToFormikValidationErrors'
import { formatAlphaNumericAllCaps } from '@electro/shared/utils/formatters'
import { CountrySelect } from '@electro/consumersite/src/components/CountrySelect'

import {
  OrderCardFormFields,
  OrderCardFormFieldNames,
  keysOfOrderCardFormFields,
} from '@electro/consumersite/src/components/Signup/types'
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
  MAX_ADDRESS_LENGTH,
} from '@electro/consumersite/src/components/Signup/constants/orderCardFormConstants'

export interface OrderCardFormProps {
  formFields: Partial<OrderCardFormFields>
  onSubmit?: (values: OrderCardFormFields) => void
  children: ReactNode | ReactNode[]
}

const { FIRST_NAME, LAST_NAME, ADDRESS1, ADDRESS_CITY, ADDRESS_REGION, POSTCODE, COUNTRY_CODE } =
  OrderCardFormFieldNames

const OrderCardFormContext = createContext(null)

const OrderCardForm = ({ children, onSubmit, formFields }: OrderCardFormProps) => {
  const formik = useFormik({
    initialValues: { ...INITIAL_VALUES, ...formFields },
    validationSchema: VALIDATION_SCHEMA,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit,
  })

  const handlePostcode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const formattedPostcode = formatAlphaNumericAllCaps(e.target.value)

      formik.setFieldValue(POSTCODE, formattedPostcode)
    },
    [formik],
  )

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  useEffect(() => {
    if (!formik.isValid) {
      scrollToFirstErrorInDocument<OrderCardFormFields, keysOfOrderCardFormFields>(formik.errors)
    }
  }, [formik.errors, formik.isValid, formik.submitCount])

  const context = useMemo(
    () => ({ formik, handlePostcode, validateOnBlur }),
    [formik, handlePostcode, validateOnBlur],
  )

  return <OrderCardFormContext.Provider value={context}>{children}</OrderCardFormContext.Provider>
}

const Fields = () => {
  const { t } = useTranslation('common')
  const { formik, handlePostcode, validateOnBlur, disabledAddressFields } =
    useContext(OrderCardFormContext)

  const getErrorMessage = (fieldName: keysOfOrderCardFormFields) => {
    const error = formik?.errors?.[fieldName]
    if (error === 'form.validation.address_max_length_error') {
      return t(error, { maxLength: MAX_ADDRESS_LENGTH })
    }
    return t(error as string)
  }

  return (
    <form
      aria-label="order-card-form"
      id="order-card-form"
      onBlur={validateOnBlur}
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="h3" as="h2">
        {t('form.title.delivery_address')}
      </Typography>

      <CountrySelect
        queryVariables={{ isValidDeliveryLocation: true }}
        onSelect={(country) => {
          formik.setFieldValue(COUNTRY_CODE, country.alpha3)
        }}
        errorMessage={getErrorMessage(COUNTRY_CODE)}
      />
      <Input
        fullWidth
        disabled={disabledAddressFields}
        name={FIRST_NAME}
        label={t('form.field.first_name')}
        value={formik.values[FIRST_NAME]}
        errorMessage={getErrorMessage(FIRST_NAME)}
        onChange={formik.handleChange}
        required
      />
      <Input
        fullWidth
        disabled={disabledAddressFields}
        name={LAST_NAME}
        label={t('form.field.last_name')}
        value={formik.values[LAST_NAME]}
        errorMessage={getErrorMessage(LAST_NAME)}
        onChange={formik.handleChange}
        required
      />
      <Input
        fullWidth
        disabled={disabledAddressFields}
        name={ADDRESS1}
        label={t('form.field.address')}
        placeholder={t('form.help_text.address')}
        value={formik.values[ADDRESS1]}
        errorMessage={getErrorMessage(ADDRESS1)}
        onChange={formik.handleChange}
        required
      />
      <Input
        fullWidth
        disabled={disabledAddressFields}
        name={ADDRESS_CITY}
        label={t('form.field.address_city')}
        value={formik.values[ADDRESS_CITY]}
        errorMessage={getErrorMessage(ADDRESS_CITY)}
        onChange={formik.handleChange}
        required
      />
      <Input
        fullWidth
        disabled={disabledAddressFields}
        name={ADDRESS_REGION}
        label={t('form.field.address_region')}
        value={formik.values[ADDRESS_REGION]}
        errorMessage={getErrorMessage(ADDRESS_REGION)}
        onChange={formik.handleChange}
      />
      <Input
        fullWidth
        disabled={disabledAddressFields}
        name={POSTCODE}
        label={t('form.field.address_postcode')}
        value={formik.values[POSTCODE]}
        errorMessage={getErrorMessage(POSTCODE)}
        onChange={handlePostcode}
        required
      />
    </form>
  )
}

export const SubmitButton = ({
  buttonText = 'Create account',
  loading = false,
}: {
  buttonText?: string
  loading?: boolean
}) => (
  <Button form="order-card-form" data-testid="Submit" fullWidth type="submit" loading={loading}>
    {buttonText}
  </Button>
)

OrderCardForm.Fields = Fields
OrderCardForm.SubmitButton = SubmitButton

export { OrderCardForm }
