/**
 * To authorize users with Apple, we have setup a Service ID in the Apple Developer Console.
 * This Service ID has a redirect URI that points to the `/sign-up/magic/apple` route for production and
 * preview environments.
 *
 * IMPORTANT NOTE: you cannot enter localhost to the Apple Developer Console. This means the redirect url will not work
 * in development. You will need to test this in a staging or preview environment.
 *
 * See:
 * https://developer.apple.com/documentation/sign_in_with_apple/request_an_authorization_to_the_sign_in_with_apple_server
 */
import { useScript } from '@electro/shared/hooks'
import { AppleIcon } from '@electro/icons/24/logos'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export const AppleSignIn = () => {
  const { t } = useTranslation('common')
  const { locale } = useRouter()
  const snakeCaseLocale = locale.replace('-', '_')
  const { loaded } = useScript(
    `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${snakeCaseLocale}/appleid.auth.js`,
  )

  const onClick = () => {
    const urlParams = new URLSearchParams({
      response_type: 'code id_token',
      response_mode: 'fragment', // gives a fragment (hash #) in the redirect URI
      client_id: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
      redirect_uri: `${window.location.origin}/sign-up/magic/apple`,
      state: 'origin:web',
      nonce: String(Date.now()),
      scope: '',
    })

    window.location.href = `https://appleid.apple.com/auth/authorize?${urlParams}`
  }

  return loaded ? (
    <button
      onClick={onClick}
      className="signin-button h-10 mb-4 w-full black bg-white rounded-full flex items-center justify-center text-center"
    >
      <AppleIcon className="w-6 h-6 -mt-1 flex-shrink-0 ml-2.5" />
      <div className="flex-grow pr-3">{t('oauth.login.continue_with_apple')}</div>
    </button>
  ) : null
}
