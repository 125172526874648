import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { Toast } from '@electro/shared-ui-components'
import { ToastNotificationContext } from './ToastNotificationProvider'

interface Props {
  children: ReactNode
}

export const ToastNotificationContainer = ({ children }: Props) => {
  const { notification, setNotification } = useContext(ToastNotificationContext)
  const [showToast, setShowToast] = useState(false)
  const [timeoutId, setTimeoutId] = useState(0)

  /**
   * This function handle toast notification timeout. When
   * new notification is created, makes sure to clear
   * the old notification timeout before set a new timeout
   */
  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      setTimeoutId(0)
    }

    if (notification?.timeout) {
      const timerId = window.setTimeout(() => setShowToast(false), notification?.timeout)
      setTimeoutId(timerId)
    }

    setShowToast(!!notification)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification])

  return (
    <>
      {children}
      <Toast
        display={showToast}
        heading={notification?.heading}
        onClose={() => setNotification(null)}
        body={notification?.body}
        variant={notification?.variant}
        position={notification?.position}
      />
    </>
  )
}
