export const LightingIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 23 23">
    <path
      d="M20.1244 0H2.87498C1.28724 0 0 1.28724 0 2.87498V20.125C0 21.7081 1.28259 22.9953 2.86568 23H20.125C21.7128 23 23 21.7128 23 20.125L22.9997 2.87498C22.9997 1.28698 21.7128 0 20.1248 0H20.1244ZM21.5619 20.1248C21.5619 20.9187 20.9183 21.5623 20.1244 21.5623H2.87498C2.08111 21.5623 1.43746 20.9187 1.43746 20.1248V2.87545C1.43746 2.08158 2.08108 1.43792 2.87498 1.43792H10.7811V6.46909H10.6449C10.49 6.46909 10.3444 6.53008 10.2361 6.63831C10.1282 6.74627 10.0669 6.89679 10.0669 7.04705V8.86946C8.39454 9.46143 7.19194 11.0585 7.19194 12.9375C7.19194 15.3191 9.12277 17.25 11.5045 17.25C13.8861 17.25 15.817 15.3191 15.817 12.9375C15.817 11.0632 14.6143 9.46115 12.942 8.86946V7.04679C12.942 6.89185 12.881 6.74626 12.7728 6.63804C12.6648 6.53008 12.5143 6.46883 12.364 6.46883H12.2278L12.228 1.43792H20.1342C20.928 1.43792 21.5717 2.08154 21.5717 2.87545V20.1248H21.5619ZM11.4998 10.0627C13.0875 10.0627 14.3748 11.35 14.3748 12.9377C14.3748 14.5255 13.0875 15.8127 11.4998 15.8127C9.91206 15.8127 8.62482 14.5255 8.62482 12.9377C8.62482 11.35 9.91206 10.0627 11.4998 10.0627Z"
      fill="currentColor"
    />
    <path
      d="M17.9631 13.6557C17.5686 13.651 17.2491 13.3316 17.2491 12.9371C17.2491 12.5427 17.5733 12.2185 17.9677 12.2185H19.4099C19.8044 12.2232 20.1239 12.5427 20.1239 12.9371C20.1239 13.3316 19.7997 13.6557 19.4053 13.6557H17.9631Z"
      fill="currentColor"
    />
    <path
      d="M3.58846 13.6557C3.19398 13.651 2.87451 13.3316 2.87451 12.9371C2.87451 12.5427 3.19866 12.2185 3.59311 12.2185H5.03528C5.42976 12.2232 5.74923 12.5427 5.74923 12.9371C5.74923 13.3316 5.42508 13.6557 5.03063 13.6557H3.58846Z"
      fill="currentColor"
    />
    <path
      d="M16.5806 8.87413C16.2982 9.14974 15.8464 9.14974 15.5674 8.87075C15.2884 8.59176 15.2884 8.13342 15.5674 7.85418L16.5874 6.83422C16.8697 6.55861 17.3216 6.55861 17.6005 6.83759C17.8795 7.11658 17.8795 7.57493 17.6005 7.85417L16.5806 8.87413Z"
      fill="currentColor"
    />
    <path
      d="M6.41553 19.0386C6.13317 19.3142 5.68133 19.3142 5.40235 19.0352C5.12338 18.7562 5.12336 18.2978 5.40235 18.0186L6.42231 16.9986C6.70467 16.723 7.15652 16.723 7.43549 17.002C7.71448 17.281 7.71448 17.7394 7.43549 18.0186L6.41553 19.0386Z"
      fill="currentColor"
    />
    <path
      d="M15.5641 18.0154C15.2885 17.733 15.2885 17.2812 15.5675 17.0022C15.8464 16.7232 16.3048 16.7232 16.584 17.0022L17.604 18.0222C17.8796 18.3045 17.8796 18.7564 17.6006 19.0354C17.3216 19.3143 16.8633 19.3143 16.584 19.0354L15.5641 18.0154Z"
      fill="currentColor"
    />
    <path
      d="M5.39892 7.85102C5.1233 7.56866 5.1233 7.11681 5.40229 6.83784C5.68128 6.55886 6.13962 6.55885 6.41886 6.83784L7.43882 7.8578C7.71444 8.14016 7.71444 8.59201 7.43545 8.87098C7.15646 9.14997 6.69812 9.14997 6.41888 8.87098L5.39892 7.85102Z"
      fill="currentColor"
    />
  </svg>
)
