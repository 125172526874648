import {
  BuildingOffice2Icon,
  BuildingStorefrontIcon,
  FlagIcon,
  MapPinIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline'

// https://docs.mapbox.com/api/search/search-box/#administrative-unit-types
enum MapBoxFeatureTypesEnum {
  COUNTRY = 'country',
  REGION = 'region',
  POSTCODE = 'postcode',
  DISTRICT = 'district',
  PLACE = 'place',
  CITY = 'city',
  LOCALITY = 'locality',
  NEIGHBORHOOD = 'neighborhood',
  STREET = 'street',
  ADDRESS = 'address',
  POI = 'poi',
  CATEGORY = 'category',
  OCTOPUS_HQ = 'octopus_hq',
}

type MapBoxFeatureTypes = `${MapBoxFeatureTypesEnum}`

const {
  POI,
  COUNTRY,
  REGION,
  POSTCODE,
  DISTRICT,
  PLACE,
  CITY,
  LOCALITY,
  NEIGHBORHOOD,
  STREET,
  ADDRESS,
  CATEGORY,
  OCTOPUS_HQ,
} = MapBoxFeatureTypesEnum

const ICON = {
  [POI]: <MapPinIcon />,
  [COUNTRY]: <FlagIcon />,
  [REGION]: <BuildingOffice2Icon />,
  [POSTCODE]: <MapPinIcon />,
  [DISTRICT]: <BuildingOffice2Icon />,
  [PLACE]: <BuildingOffice2Icon />,
  [CITY]: <BuildingOffice2Icon />,
  [LOCALITY]: <MapPinIcon />,
  [NEIGHBORHOOD]: <MapPinIcon />,
  [STREET]: <MapPinIcon />,
  [ADDRESS]: <MapPinIcon />,
  [CATEGORY]: <RectangleStackIcon />,
  [OCTOPUS_HQ]: <BuildingStorefrontIcon />,
}

/** Display icons in search results according to their MapBox feature type */
export const mapFeatureTypeToIcon = (featureType: string) =>
  ICON[featureType as MapBoxFeatureTypes] || <MapPinIcon />
