import { Menu } from '@headlessui/react'
import { useCallback, useEffect, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { PlaceDetails } from '@electro/consumersite/src/components/Map/helpers'
import { useCurrentLocation, useMapSearch } from '@electro/consumersite/src/components/Map/hooks'
import { menuItemsStyles } from '@electro/consumersite/src/components/Map/components/MapLocationSearch/components'

const styles = {
  buttonSpacing: 'my-1',
  deniedText: 'text-red-400',
  paperAirplaneIcon:
    'w-5 h-5 max-w-5 -rotate-45 ml-px -mr-px p-1 pl-[5px] pr-[3px] rounded-full text-white bg-secondary', // Hardcoded values for a weirdly shaped icon
}

interface CurrentLocationMenuItemProps {
  /** Keeps the logic mounted after the searchbar is closed. */
  show: boolean
}

/** Allows the user to view their location on the map. */
export const CurrentLocationMenuItem = ({ show }: CurrentLocationMenuItemProps) => {
  const { t } = useTranslation('common')
  const [, { handleLocationSearchPlaceSelection }] = useMapSearch()
  const [{ geoControlRef, geolocationPermission, currentLocationDetails }] = useCurrentLocation()
  const [onFirstTrigger, setOnFirstTrigger] = useState<boolean>(false)

  /** Sends the location place details to the map, identifying the search result type */
  const onSelectCurrentLocation = useCallback(
    (place: PlaceDetails) =>
      handleLocationSearchPlaceSelection({ place, searchType: 'currentLocation' }),
    [handleLocationSearchPlaceSelection],
  )

  /** Fly to their location if we have it. Else trigger the geolocation control to request it instead */
  const selectCurrentLocation = () => {
    if (!currentLocationDetails) {
      geoControlRef.current.trigger()
      setOnFirstTrigger(true)
    } else onSelectCurrentLocation(currentLocationDetails)
  }

  /** Fly to their location after receiving a response from the geolocation control */
  useEffect(() => {
    if (currentLocationDetails && onFirstTrigger) {
      onSelectCurrentLocation(currentLocationDetails)
      setOnFirstTrigger(false)
    }
  }, [currentLocationDetails, onSelectCurrentLocation, onFirstTrigger])

  return show ? (
    <Menu.Item key="currentLocation">
      {({ active }) => (
        <button
          data-testid="currentLocation-menu-item"
          onClick={selectCurrentLocation}
          onKeyDown={selectCurrentLocation}
          className={tw({
            [menuItemsStyles.button.root]: true,
            [menuItemsStyles.button.buttonActive]: active,
            [styles.buttonSpacing]: true,
          })}
        >
          <PaperAirplaneIcon className={styles.paperAirplaneIcon} />
          <div className={menuItemsStyles.button.text}>
            <div>{t('map.search.current_location')}</div>

            {currentLocationDetails ? (
              <div className={menuItemsStyles.button.subtext}>{currentLocationDetails.subtext}</div>
            ) : null}

            {!currentLocationDetails && onFirstTrigger ? (
              <div className={menuItemsStyles.button.subtext}>
                {t('map.search.retrieving_location')}
              </div>
            ) : null}

            {geolocationPermission === 'denied' ? (
              <div className={tw(menuItemsStyles.button.subtext, styles.deniedText)}>
                {t('map.search.location_denied')}
              </div>
            ) : null}
          </div>
        </button>
      )}
    </Menu.Item>
  ) : null
}
