import {
  Alert,
  Button,
  Card,
  Modal,
  Skeleton,
  Typography,
  IconButton,
} from '@electro/shared-ui-components'
import {
  DebtWarningStatus,
  useDeactivateAccountMutation,
  useUserQuery,
} from '@electro/consumersite/generated/graphql'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import { XCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import { useCallback, useMemo, useState } from 'react'
import { useAuth } from '@electro/consumersite/src/hooks'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { DisableContentWrapper } from '@electro/consumersite/src/components'
import { isActiveAccountFleetDriver } from '@electro/consumersite/src/utils/isActiveAccountFleetDriver'
import { mapLocaleToCountryDetails } from '@electro/shared/utils/mapLocaleToCountryDetails'

export const AccountClose = () => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const { setStep } = useAccountManageMobileStep()

  const router = useRouter()
  const [, { logout }] = useAuth()
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useUserQuery({
    fetchPolicy: 'network-only',
    onError(err) {
      Sentry.captureException(err)
    },
  })
  const [deactivateAccount, { loading: deactivating, error: deactivationError }] =
    useDeactivateAccountMutation({
      onError(err) {
        Sentry.captureException(err)
      },
    })

  const handleCloseAccount = useCallback(async () => {
    const { data: deactivationData } = await deactivateAccount()
    if (deactivationData?.deactivateAccount?.success) {
      logout({ revokeRefreshToken: false })
    }
  }, [deactivateAccount, logout])

  const today = new Date().toLocaleDateString(router.locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })

  useErrorNotificationEffect({ error: userError, message: userError?.message })

  /**
   * We only want to show the debt warning if the user has an account issue.
   * We also don't want them closing an account if they have unresolved payments.
   */
  const userHasUnresolvedDebt = useMemo(
    () =>
      [DebtWarningStatus.Escalated, DebtWarningStatus.Issued].some(
        (status) => status === userData?.me?.financialInfo?.debtStatus,
      ),
    [userData?.me?.financialInfo?.debtStatus],
  )

  /**
   * We need the following check in order to prevent fleet drivers from closing their accounts.
   */

  const activeUserIsAFleetDriver = useMemo(
    () => isActiveAccountFleetDriver(userData) && !userLoading,
    [userData, userLoading],
  )

  return (
    <>
      {userLoading ? (
        <div
          className="flex flex-col gap-4 sm:basis-[450px] opacity-40"
          data-testid="loading-rows-placeholder"
        >
          <Skeleton className="w-1/2 mb-4" />
          <Skeleton className="w-full h-52 mb-4" />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <Skeleton className="h-12" variant="circular" />
          </div>
        </div>
      ) : null}

      {!userLoading && userData?.me ? (
        <div>
          <div className="flex justify-between">
            <IconButton
              className="block md:hidden"
              onClick={() => setStep(AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU)}
            >
              <ArrowLeftIcon className="w-6 h-6" />
            </IconButton>
            <Typography variant="h3" as="h1" data-testid="account-close-page-title">
              {t('profile.navigation.danger_zone')}
            </Typography>
            <div />
          </div>
          <DisableContentWrapper
            condition={activeUserIsAFleetDriver}
            message={t('fleet_driver_account.alert_messages.close_account')}
          >
            <Card frosted variant="error" className="max-w-xl">
              <Typography variant="h3" as="h2">
                {t('profile.close_account.actions.close_account')}
              </Typography>
              <Typography as="p">
                <Trans
                  i18nKey="common:profile.close_account.instructions"
                  values={{
                    supportEmail: mapLocaleToCountryDetails(locale).supportEmail,
                  }}
                />
              </Typography>
              {userHasUnresolvedDebt ? (
                <Alert variant="error">{t('profile.close_account.unresolved_payments')}</Alert>
              ) : null}

              <Button
                disabled={userHasUnresolvedDebt}
                onClick={() => setConfirmationModalOpen(true)}
                variant="error"
                className="mt-6"
              >
                {t('profile.close_account.actions.close_account')}
              </Button>
            </Card>
          </DisableContentWrapper>
        </div>
      ) : null}

      <Modal
        variant="error"
        size="lg"
        onClose={() => setConfirmationModalOpen(false)}
        open={confirmationModalOpen}
      >
        {!deactivating ? <Modal.CloseButton /> : null}
        <Modal.Header>
          <div className="text-center">{t('profile.close_account.leaving_so_soon')}</div>
        </Modal.Header>
        <Modal.Body>
          <Image
            src="/images/sad-consty.png"
            width={73}
            height={90}
            alt="sad pink constantine"
            className="mx-auto my-4"
          />
          <Typography as="p">
            <Trans
              i18nKey="common:profile.close_account.description"
              values={{
                today,
              }}
            />
          </Typography>
          <Typography>Email: {userData?.me?.email}</Typography>
          <Typography>Account ID: {userData?.me?.id}</Typography>

          <Typography variant="body" as="p" className="font-bold mt-4">
            {t('profile.close_account.are_you_sure')}
          </Typography>
          {deactivationError ? (
            <Alert variant="error">{t('profile.close_account.deactivation_error')}</Alert>
          ) : null}
        </Modal.Body>
        <Modal.Actions stacked>
          <Button loading={deactivating} onClick={handleCloseAccount} variant="error" fullWidth>
            <XCircleIcon className="w-5 h-5 mr-2" />
            {t('profile.close_account.actions.close_account')}
          </Button>
          <Button
            disabled={deactivating}
            fullWidth
            variant="outline"
            onClick={() => setConfirmationModalOpen(false)}
          >
            {t('common.button.cancel')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}
