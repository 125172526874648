import { tw } from '@electro/shared/utils/tailwind-merge'
import { useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { SidebarNavLabelsPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'

const styles = {
  icon: {
    root: tw(
      'w-7 h-7 p-1 rounded-lg cursor-pointer mt-auto mb-1',
      'text-white/70 bg-slate-600/30 hover:bg-slate-600/50 ring-1 ring-white/30',
    ),
    active: 'text-primary ring-primary/50 stroke-2',
  },
  container: tw(
    'bg-base rounded-xl border-secondary border-2',
    'text-xl [&>p]:h-11 [&>p]:mb-0 first:[&_p]:-mt-2.5',
  ),
}

export const SidebarNavLabelsIcon = () => {
  const [{ visiblePanel }, { showPanel, hidePanel }] = useMapSidebar()

  const isActive = visiblePanel === SidebarPanels.ICON_NAV_LABELS

  return (
    <QuestionMarkCircleIcon
      className={tw({
        [styles.icon.root]: true,
        [styles.icon.active]: isActive,
      })}
      onClick={() => (isActive ? hidePanel() : showPanel(SidebarPanels.ICON_NAV_LABELS))}
    />
  )
}

export const SidebarNavLabelsPanel = () => (
  <SidebarPanelContainer panelType={SidebarPanels.ICON_NAV_LABELS} className={styles.container}>
    <SidebarNavLabelsPanelContent />
  </SidebarPanelContainer>
)
