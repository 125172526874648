import { tw } from '@electro/shared/utils/tailwind-merge'
import { Status as StatusEnum } from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'

type AvailabilityProps = { status: StatusEnum }

type StatusNames = `${StatusEnum}`

const {
  Available,
  Blocked,
  Charging,
  Inoperative,
  Outoforder,
  Planned,
  Removed,
  Reserved,
  Unknown,
} = StatusEnum

type statusDict = { [key in StatusNames]: { name: string; className: string } }

const DEFAULT_DISPLAY_STATUS = {
  name: '',
  className: '',
}

const statusMap: statusDict = {
  [Available]: {
    name: 'map.location.connector_status.available',
    className: 'text-action-success',
  },
  [Blocked]: {
    name: 'map.location.connector_status.blocked',
    className: 'text-action-warning',
  },
  [Charging]: {
    name: 'map.location.connector_status.occupied',
    className: 'text-action-warning',
  },
  [Inoperative]: {
    name: 'map.location.connector_status.out_of_order',
    className: 'text-white',
  },
  [Outoforder]: {
    name: 'map.location.connector_status.out_of_order',
    className: 'text-white',
  },
  [Planned]: {
    name: 'map.location.connector_status.planned',
    className: 'text-white',
  },
  [Removed]: {
    name: 'map.location.connector_status.removed',
    className: 'text-action-warning',
  },
  [Reserved]: {
    name: 'map.location.connector_status.reserved',
    className: 'text-action-warning',
  },
  [Unknown]: {
    ...DEFAULT_DISPLAY_STATUS,
  },
}

export const Availability = ({ status }: AvailabilityProps) => {
  const displayStatus = statusMap[status] || DEFAULT_DISPLAY_STATUS
  const { t } = useTranslation('common')
  return (
    <span
      data-testid="availability"
      className={tw('capitalize break-normal float-right', displayStatus.className)}
    >
      {t(displayStatus.name)}
    </span>
  )
}
