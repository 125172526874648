export enum DrawerPanels {
  LOCATION_DETAILS = 'location-details',
}
export type DrawerPanelsType = `${DrawerPanels}`

export interface DefaultDrawerPanelsType {
  visible: DrawerPanelsType
  disabled: DrawerPanelsType[]
}

export const DEFAULT_DRAWER_PANELS: DefaultDrawerPanelsType = {
  visible: undefined,
  disabled: [],
}
