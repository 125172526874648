import { useEffect, useState, useContext, createContext, useCallback, useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import { differenceInMilliseconds, fromUnixTime } from 'date-fns'
import * as Sentry from '@sentry/nextjs'

import { useErrorNotificationEffect } from '@electro/shared/hooks'
import { getSessionFromJwt, sessionIsValid, UserSession } from '@electro/shared/utils/jwtSession'
import { EJN_REFRESH_TOKEN_KEY, EJN_AUTH_TOKEN_KEY } from '@electro/shared/constants'

import {
  MutationLogInArgs,
  MutationQuickAuthArgs,
  useLogInMutation,
  useQuickAuthMutation,
} from '@electro/consumersite/generated/graphql'
import {
  setRefreshTokenToLocalStorage,
  setAccessTokenToLocalStorage,
} from '@electro/shared/utils/asynclLocalStorage'
import { useFetchUser, useFetchAuthTokens } from '@electro/consumersite/src/services'
import { useRouter } from 'next/router'
import { v1 as uuid } from 'uuid'
import urlHasQuickAuthParams, {
  USER_ID_PARAM,
  SHORT_LIVED_TOKEN_PARAM,
} from '@electro/consumersite/src/utils/urlHasQuickAuthParams'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { logoutAndReload } from '@electro/consumersite/src/utils/logoutAndReload'

interface AuthTokens {
  token: string
  refreshToken: string
}

interface LoginWithJwt extends AuthTokens {}

interface State {
  sessionLoading: boolean
  error: Partial<ApolloError>
  session: UserSession
  sessionToken: string
}

export interface LogoutArgs {
  revokeRefreshToken?: boolean
}

interface Handlers {
  loginV2: (args: MutationLogInArgs) => Promise<UserSession>
  loginWithJwt: ({ token }: LoginWithJwt) => void
  loginWithQuickAuth: ({ user, shortLivedToken }: MutationQuickAuthArgs) => Promise<UserSession>
  logout: ({ revokeRefreshToken }?: LogoutArgs) => void
}

export type UseAuth = [State, Handlers]

const EjnAuthContext = createContext<UseAuth>(null)

const millisecondsToExpiry = ({ expiresIn }: { expiresIn: number }) => {
  if (!expiresIn) return null
  return differenceInMilliseconds(fromUnixTime(expiresIn), new Date())
}

export const useAuth = (): UseAuth => {
  const context = useContext(EjnAuthContext)
  if (!context) {
    throw new Error(`useAuth() cannot be used outside the context of <EjnAuthProvider/>`)
  }
  return context
}

export function useProvideEjnAuth(): UseAuth {
  const router = useRouter()
  const [session, setSession] = useState<UserSession>(null)
  const [sessionLoading, setSessionLoading] = useState<boolean>(true)
  const [error, setError] = useState<Partial<ApolloError>>(null)
  const [fetchUser, userQuery] = useFetchUser({ options: { fetchPolicy: 'cache-first' } })
  const [quickAuthMutation] = useQuickAuthMutation()
  const { revokeRefreshTokenMutation, refreshTokenMutation } = useFetchAuthTokens()

  const [userLogIn] = useLogInMutation()

  const { errorNotification } = useErrorNotificationEffect({
    error: userQuery.error,
    message: 'Could not log you in!',
  })

  const sessionToken = useMemo(() => uuid(), [])

  const logout = useCallback(
    async ({ revokeRefreshToken = true } = {}) => {
      const refreshToken = localStorage.getItem(EJN_REFRESH_TOKEN_KEY)
      setSessionLoading(() => false)
      try {
        if (revokeRefreshToken) await revokeRefreshTokenMutation({ variables: { refreshToken } })
        setSession(() => null)
      } catch (err) {
        Sentry.captureException(err)
      } finally {
        /**
         * Make sure we remove any tokens from local storage and memory
         * even if the revokeRefreshTokenMutation fails.
         * This prevents expired tokens from persisting in local storage.
         */
        logoutAndReload()
      }
    },
    [revokeRefreshTokenMutation],
  )

  const applySession = useCallback(
    async ({ token, refreshToken }: AuthTokens): Promise<UserSession> => {
      await setAccessTokenToLocalStorage(token)
      await setRefreshTokenToLocalStorage(refreshToken)
      try {
        const { data } = await fetchUser()
        const decodedSession = getSessionFromJwt(token)
        const nextSession = {
          ...decodedSession,
          user: {
            ...decodedSession.user,
            id: data?.me?.id,
          },
        }
        setSession(() => nextSession)
        GTM.userAuthenticated({ userId: data?.me?.id })
        return nextSession
      } catch (err) {
        logout()
        errorNotification()
        Sentry.captureException(err)
        return null
      } finally {
        setSessionLoading(() => false)
      }
    },
    [errorNotification, fetchUser, logout],
  )

  /**
   * Will authenticate via the `quickAuth` endpoint.
   * Useful for logging a user in with a magic link after they have signed up.
   */
  const loginWithQuickAuth = useCallback(
    async ({ user, shortLivedToken }) => {
      setSessionLoading(() => true)
      try {
        const { data } = await quickAuthMutation({
          variables: {
            user,
            shortLivedToken,
          },
        })
        const nextSession = await applySession({
          token: data.quickAuth.token,
          refreshToken: data.quickAuth.refreshToken,
        })
        return nextSession
      } catch (err) {
        setError(() => err)
        Sentry.captureException(err)
        return null
      } finally {
        setSessionLoading(() => false)
      }
    },
    [applySession, quickAuthMutation],
  )

  /**
   * In some instances we already have a token and simply need to
   * decode it and turn it into a user session.
   * This is useful if you need to defer the actual 'logged in' state
   * of the UI which checks for the `session` key returned by useAuth().
   */
  const loginWithJwt = useCallback(
    async ({ token, refreshToken }: LoginWithJwt) => {
      if (!token || !refreshToken) {
        throw new Error('You must provide both { token, refreshToken } args to loginWithJwt()')
      }
      await applySession({ token, refreshToken })
    },
    [applySession],
  )

  /**
   * loginV2() is used to authenticate a user who has already signed up.
   * This is used as an alternative to allow users to login without
   * having to open their emails and click on a magic link.
   */
  const loginV2 = useCallback(
    async ({ email, password }: MutationLogInArgs): Promise<UserSession> => {
      setSessionLoading(() => true)
      setError(() => null)
      try {
        const {
          data: {
            logIn: { token, refreshToken },
          },
        } = await userLogIn({
          variables: {
            email,
            password,
          },
        })
        const nextSession = await applySession({
          token,
          refreshToken,
        })
        return nextSession
      } catch (err) {
        Sentry.captureException(err)
        setError(() => err)
        return null
      } finally {
        setSessionLoading(() => false)
      }
    },
    [applySession, userLogIn],
  )

  const refreshAccessToken = useCallback(async () => {
    const oldRefreshToken = localStorage.getItem(EJN_REFRESH_TOKEN_KEY)
    if (!oldRefreshToken) return setSessionLoading(() => false)
    try {
      const {
        data: {
          refreshToken: { token, refreshToken: nextRefreshToken },
        },
      } = await refreshTokenMutation({
        variables: {
          refreshToken: oldRefreshToken,
        },
      })
      applySession({ token, refreshToken: nextRefreshToken })
    } catch (err) {
      /**
       * If we get an error here we're going to assume it's either an
       * expired/revoked refresh token or someone messing around
       * with tokens. Either way They're getting quietly logged out!
       */
      logout()
      Sentry.captureException(err)
    }
    return null
  }, [applySession, logout, refreshTokenMutation])

  /**
   * We want to remove these search params to prevent a page reload
   * calling the `quickAuth` Endpoint with an expired/claimed token!
   */
  const removeCrossPlatformAuthUrlParams = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete(USER_ID_PARAM)
    searchParams.delete(SHORT_LIVED_TOKEN_PARAM)
    router.replace(`${router.pathname}`, { search: searchParams.toString() })
  }, [router])

  /**
   * Will authenticate via the cross platform `quickAuth` endpoint.
   * Doing so allows us to auth a user inside a web view in the mobile apps.
   */
  const runCrossPlatformAuth = useCallback(async () => {
    const urlParams = new URLSearchParams(window?.location?.search)
    const user = parseInt(urlParams.get(USER_ID_PARAM), 10)
    const shortLivedToken = urlParams.get(SHORT_LIVED_TOKEN_PARAM)

    try {
      const { data } = await quickAuthMutation({
        variables: {
          user,
          shortLivedToken,
        },
      })
      await applySession({
        token: data.quickAuth.token,
        refreshToken: data.quickAuth.refreshToken,
      })
    } catch (err) {
      errorNotification()
    } finally {
      setSessionLoading(false)
      removeCrossPlatformAuthUrlParams()
    }
  }, [applySession, errorNotification, quickAuthMutation, removeCrossPlatformAuthUrlParams])

  /**
   * refresh access token just before it expires
   */
  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (session) refreshAccessToken()
      },
      millisecondsToExpiry({ expiresIn: session?.expiresIn }) - 60_000,
    )
    return () => clearTimeout(timer)
  }, [refreshAccessToken, session])

  /**
   * Subscribe to user on mount
   * Because this sets state in the callback it will cause any
   * component that utilises this hook to re-render with the
   * latest auth object.
   */
  useEffect(() => {
    const checkForSession = () => {
      /**
       * If we have quick auth params in the URL use them to login and overwrite any existing login.
       * This is to prevent us from having any lingering authentication credo
       * pushing users out of sync.
       */
      const token = localStorage.getItem(EJN_AUTH_TOKEN_KEY)
      const refreshToken = localStorage.getItem(EJN_REFRESH_TOKEN_KEY)
      const jwtSession = getSessionFromJwt(token)

      if (urlHasQuickAuthParams()) {
        runCrossPlatformAuth()
      } else if (sessionIsValid(jwtSession)) {
        applySession({ token, refreshToken })
      } else {
        refreshAccessToken()
      }
    }

    checkForSession()
    return () => checkForSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => () => setSessionLoading(() => false), [])

  const state = useMemo(
    () => ({
      session,
      sessionLoading,
      error,
      sessionToken,
    }),
    [error, session, sessionLoading, sessionToken],
  )

  const handlers = useMemo(
    () => ({
      loginWithJwt,
      loginWithQuickAuth,
      loginV2,
      logout,
    }),
    [loginV2, loginWithJwt, loginWithQuickAuth, logout],
  )

  return [state, handlers]
}

export const EjnAuthProvider = ({ children }) => {
  const auth = useProvideEjnAuth()
  return <EjnAuthContext.Provider value={auth}>{children}</EjnAuthContext.Provider>
}
