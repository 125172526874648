import { useMemo } from 'react'
import { ClockIcon, GlobeAltIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { mapCapabilityToIcon } from '@electro/consumersite/src/components/Map/helpers'
import { Facilities } from '@electro/consumersite/src/components/Map/components/MapLocationDetailsPanel/components'
import { Capabilities, GenericLocationInterface } from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { Typography } from '@electro/shared-ui-components'

interface LocationInformationProps {
  chargingLocation: GenericLocationInterface
}

export const LocationInformation = ({ chargingLocation }: LocationInformationProps) => {
  const hasSupportInformation = useMemo(
    () =>
      chargingLocation.operator.supportPhoneNumber ||
      chargingLocation.operator.supportEmail ||
      chargingLocation.operator.supportWebsite,
    [chargingLocation],
  )
  const hasAccessInformation = useMemo(
    () => chargingLocation.capabilities.length > 0,
    [chargingLocation],
  )
  const { t } = useTranslation('common')

  return (
    <div data-testid="location-details-information">
      {chargingLocation.openingHours?.twentyFourSeven && (
        <section>
          <h3 className="mt-4 mb-2">{t('map.location.information_title')}</h3>
          <div className="flex items-center">
            <ClockIcon className="w-5 h-5 mr-2 text-white" />
            <Typography as="span">{t('map.location.info.always_open')}</Typography>
          </div>
        </section>
      )}
      {hasAccessInformation && (
        <section>
          <h3 className="mt-4 mb-2">{t('map.location.access_title')}</h3>
          <div>
            {chargingLocation.capabilities.map((capability) => {
              const { icon, label } = mapCapabilityToIcon(capability.name as Capabilities)

              return (
                <span
                  key={capability.name}
                  className="inline-flex items-center mr-4 mb-2 last:mr-0"
                >
                  {icon}
                  <Typography as="span">{t(label)}</Typography>
                </span>
              )
            })}
          </div>
        </section>
      )}
      <Facilities facilities={chargingLocation.facilities} />
      {hasSupportInformation && (
        <section>
          <h3 className="mt-4 mb-2">{t('map.location.operator_support_title')}</h3>
          {chargingLocation.operator.supportPhoneNumber && (
            <div className="flex items-center mb-2 text-white">
              <PhoneIcon className="w-5 h-5 mr-2" />
              <Typography as="span">{chargingLocation.operator.supportPhoneNumber}</Typography>
            </div>
          )}
          {chargingLocation.operator.supportEmail && (
            <div className="flex items-center mb-2 text-white">
              <EnvelopeIcon className="w-5 h-5 mr-2" />{' '}
              <Typography as="span">{chargingLocation.operator.supportEmail}</Typography>
            </div>
          )}
          {chargingLocation.operator.supportWebsite && (
            <div className="flex items-center mb-2 text-white">
              <GlobeAltIcon className="w-5 h-5 mr-2" />
              <Typography as="span">
                <a target="_blank" rel="noreferrer" href={chargingLocation.operator.supportWebsite}>
                  {chargingLocation.operator.supportWebsite}
                </a>
              </Typography>
            </div>
          )}
        </section>
      )}
    </div>
  )
}
