import { ReactNode, useMemo } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { AccountType } from '@electro/consumersite/generated/graphql'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

interface UserAccountButtonProps {
  onClick?: (account: AccountType) => void
  account: AccountType
  isSelected?: boolean
  isLoginFlow?: boolean
}

interface AccountButtonData {
  label: string
  icon: ReactNode
}

const styles = {
  button:
    'flex flex-row justify-between items-center w-full md:max-w-xs mb-2 px-2 py-2 border-2 border-solid border-secondary-light/20 rounded-lg text-base-dark/80 hover:bg-secondary-light/20 text-xs',
  buttonLogin: 'text-white tracking-wide text-sm font-normal border-[3px]',
  buttonSelected: 'border-secondary',
  container: 'flex flex-row items-center w-full',
  leftIcon: 'mr-2',
  rightIcon: 'self-center',
}

export const UserAccountButton = ({
  account,
  onClick,
  isSelected,
  isLoginFlow,
}: UserAccountButtonProps) => {
  const { t } = useTranslation('common')
  const { entityName, entityDomain, isFleetDriver } = account

  const handleClick = () => {
    onClick(account)
  }

  const buttonData: AccountButtonData = useMemo(() => {
    if (entityName === 'OEU') {
      return {
        label: t('user.menu.personal_account'),
        icon: (
          <Image
            src="/images/personal-account-icon.svg"
            alt="personal-account-icon"
            width={isLoginFlow ? 58 : 36}
            height={isLoginFlow ? 58 : 36}
            className={styles.leftIcon}
            data-testid="personal-account-icon"
          />
        ),
      }
    }
    if (entityDomain === 'FLEETS' && isFleetDriver === true) {
      return {
        label: entityName,
        icon: (
          <Image
            src="/images/driver-account-icon.svg"
            alt="driver-account-icon"
            width={isLoginFlow ? 58 : 36}
            height={isLoginFlow ? 58 : 36}
            className={styles.leftIcon}
            data-testid="fleets-driver-account-icon"
          />
        ),
      }
    }

    return {
      label: '',
      icon: null,
    }
  }, [isLoginFlow, entityName, entityDomain, isFleetDriver, t])

  return (
    <button
      className={tw({
        [styles.button]: true,
        [styles.buttonLogin]: isLoginFlow,
        [styles.buttonSelected]: isSelected,
      })}
      onClick={handleClick}
    >
      <div className={styles.container}>
        {buttonData.icon} {buttonData.label}
      </div>
      <span className={styles.rightIcon}>
        {isSelected && (
          <Image
            src="/images/selected-icon.svg"
            alt="selected-icon"
            width={isLoginFlow ? 24 : 16}
            height={isLoginFlow ? 16 : 10}
            data-testid="selected-account-icon"
          />
        )}
      </span>
    </button>
  )
}
