import useTranslation from 'next-translate/useTranslation'
import { NumberInput, Slider } from '@electro/shared-ui-components'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersEnum } from '@electro/consumersite/src/components/Map/types'

const styles = {
  heading: 'mt-0.5 -mb-6',
  rangeSlider: {
    root: 'px-1',
    details: {
      root: 'flex flex-row justify-end items-end',
      numberInput: 'mr-2 w-8',
    },
  },
}

const { MIN_CHARGE_POINTS } = MapFiltersEnum

const SLIDER_MIN_VALUE = 0
const SLIDER_MAX_VALUE = 6

/** Slider for filtering by minimum number of connectors per charging location */
export const FiltersMinimumChargePoints = () => {
  const { t } = useTranslation('common')
  const [{ currentFilters }, { updateFilters }] = useMapFilters()

  /** Update filters if minimum charge points within defined bounds */
  const onSliderValuesChange = (values: number[]) => {
    const updatedValue = values?.[0]
    if (updatedValue > SLIDER_MAX_VALUE || updatedValue < SLIDER_MIN_VALUE) return

    updateFilters({ [MIN_CHARGE_POINTS]: updatedValue })
  }

  return (
    <div data-testid="filters-minimum-charge-points">
      <h4 className={styles.heading}>{t('map.filters.subtitles.min_charge_points')}</h4>

      <Slider
        initialValues={[currentFilters[MIN_CHARGE_POINTS]]}
        onChange={onSliderValuesChange}
        name="minimum-charge-points"
        max={SLIDER_MAX_VALUE}
        min={SLIDER_MIN_VALUE}
      >
        {({ values, setSliderValues }) => (
          <div className={styles.rangeSlider.root}>
            <div className={styles.rangeSlider.details.root}>
              <NumberInput
                textCentre
                value={values[0]}
                onChange={(e) => setSliderValues([parseInt(e.target.value, 10)])}
                className={styles.rangeSlider.details.numberInput}
                max={SLIDER_MAX_VALUE}
                min={SLIDER_MIN_VALUE}
              />
            </div>

            <Slider.Rail />
          </div>
        )}
      </Slider>
    </div>
  )
}
