import { AddAnEvForm } from '@electro/consumersite/src/components/Map/components'
import { Modal, Button, Typography } from '@electro/shared-ui-components'

import getRandomItemFromArray from '@electro/shared/utils/getRandomItemFromArray'
import { iceErrors } from '@electro/shared/constants'
import { useAccountVehicleManagerModal } from '@electro/consumersite/src/components/AccountVehicleManager/hooks'
import useTranslation from 'next-translate/useTranslation'

// NOTE: UK driving license registration lookup was removed due to API cost. Revert this commit to re-enable it

export const AddAnEvModal = () => {
  const { showModal, setShowModal } = useAccountVehicleManagerModal()
  const { t } = useTranslation('common')

  return (
    <Modal size="md" open={showModal} onClose={setShowModal(false)}>
      <Modal.CloseButton />

      <AddAnEvForm>
        <Modal.Header>{t('profile_add_ev_option')}</Modal.Header>
        <Modal.Body>
          <AddAnEvForm.ManualVehicleSearch />
          <AddAnEvForm.VehicleRegLookupSelectVehicle />
          <AddAnEvForm.NotAnEv>
            <div className="text-action-danger text-center">
              <div className="font-medium">{getRandomItemFromArray(iceErrors)}</div>
              That doesn&apos;t look like an EV!
            </div>
          </AddAnEvForm.NotAnEv>
          <AddAnEvForm.Success>
            <div className="text-center">
              {t('select_ev_success_subtitle')
                .split('\\n')
                .map((line) => (
                  <Typography key={line} variant="body" as="p">
                    {line}
                  </Typography>
                ))}
            </div>
          </AddAnEvForm.Success>
        </Modal.Body>
        <Modal.Actions stacked>
          <AddAnEvForm.Success>
            <Button fullWidth onClick={setShowModal(false)}>
              {t('common_button_word_done')}
            </Button>
          </AddAnEvForm.Success>
          <AddAnEvForm.AddVehicleButton />
        </Modal.Actions>
      </AddAnEvForm>
    </Modal>
  )
}
