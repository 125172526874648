import { ChangeEvent, forwardRef, useEffect, useRef } from 'react'
import { MinusIcon, PlusIcon } from '@heroicons/react/16/solid'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  root: 'flex items-center gap-x-0.5 override-number-input',
  inputBox: {
    root: 'relative flex items-center text-sm border-2 border-secondary rounded-lg cursor-text',
    inputField: {
      root: 'w-full p-1 outline-none bg-transparent',
      textCentre: 'text-center',
      isPercentage: 'w-7 pr-0 text-end',
    },
    percentageIcon: 'pr-1 text-[10px] text-gray-400 select-none',
    disabled: 'absolute w-full h-full bg-black/40 rounded-lg',
  },
  incrementButtons: {
    root: 'flex flex-col gap-y-0.5',
    buttonTop: 'w-3.5 bg-secondary rounded-t',
    buttonBottom: 'w-3.5 bg-secondary rounded-b',
    buttonDisabled: 'bg-secondary-dark text-gray-500',
  },
}

const RefInput = forwardRef<HTMLInputElement, any>((props, ref) => <input {...props} ref={ref} />)

export interface NumberInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  showIncrementButtons?: boolean
  isPercentage?: boolean
  textCentre?: boolean
  value?: number
  max?: number
  min?: number
}

/** A themed number input component with additional usability features */
export const NumberInput = ({
  showIncrementButtons,
  isPercentage,
  textCentre,
  ...props
}: NumberInputProps) => {
  let interval: NodeJS.Timeout
  const inputRef = useRef<HTMLInputElement>()
  const isValidNumber = inputRef.current?.validity.valid ?? true

  useEffect(() => {
    // Define onChange when component loads for the dispatch event to work
    inputRef.current.onchange = (e) =>
      props.onChange?.(e as unknown as ChangeEvent<HTMLInputElement>)
  }, [props])

  /** Loops a timeout to update the value by 1 until stopped (e.g. releasing the mouse click) */
  const handleIncrement = (direction: 'up' | 'down') => {
    incrementValue(direction)
    interval = setInterval(() => incrementValue(direction), 150)
  }

  /** Updates the value by 1 in either direction, with restrictions on max/min */
  const incrementValue = (direction: 'up' | 'down') => {
    if (inputRef.current?.value) {
      const updatedValue = parseInt(inputRef.current.value, 10) + (direction === 'up' ? 1 : -1)
      if (updatedValue > props.max || updatedValue < props.min) return

      inputRef.current.value = updatedValue.toString()
      inputRef.current.dispatchEvent(new Event('change'))
    }
  }

  /** Sanitise the input to the bounds of max/min, or 0 if neither are specified */
  const handleBlur = (value: number) => {
    let sanitisedValue = value
    if (value > props.max) sanitisedValue = props.max
    else if (value < props.min) sanitisedValue = props.min
    else if (Number.isNaN(value)) sanitisedValue = props.max ?? props.min ?? 0

    inputRef.current.value = sanitisedValue.toString()
    inputRef.current.dispatchEvent(new Event('change'))
  }

  return (
    <div className={tw(styles.root, props.className)}>
      <div
        className={tw(styles.inputBox.root, !isValidNumber && 'border-action-danger')}
        onClick={() => inputRef.current.focus()}
        aria-hidden="true"
      >
        <RefInput
          {...props}
          type="number"
          ref={inputRef}
          className={tw({
            [styles.inputBox.inputField.root]: true,
            [styles.inputBox.inputField.textCentre]: textCentre,
            [styles.inputBox.inputField.isPercentage]: isPercentage,
          })}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => handleBlur(parseInt(e.target.value, 10))}
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            const numString = isPercentage ? e.target.value.substring(0, 3) : e.target.value
            inputRef.current.value = numString
          }}
          onKeyDown={(e: KeyboardEvent) => {
            if (e.key === 'Enter') inputRef.current.blur()
          }}
        />

        {isPercentage ? <span className={styles.inputBox.percentageIcon}>%</span> : null}

        {props.disabled ? (
          <span data-testid="number-input-disabled" className={styles.inputBox.disabled} />
        ) : null}
      </div>

      {showIncrementButtons ? (
        <div className={styles.incrementButtons.root}>
          <button
            disabled={props.disabled}
            data-testid="increment-number-input-button"
            onMouseDown={() => handleIncrement('up')}
            onMouseUp={() => clearInterval(interval)}
            className={tw({
              [styles.incrementButtons.buttonTop]: true,
              [styles.incrementButtons.buttonDisabled]: props.disabled,
            })}
          >
            <PlusIcon />
          </button>

          <button
            disabled={props.disabled}
            data-testid="decrement-number-input-button"
            onMouseDown={() => handleIncrement('down')}
            onMouseUp={() => clearInterval(interval)}
            className={tw({
              [styles.incrementButtons.buttonBottom]: true,
              [styles.incrementButtons.buttonDisabled]: props.disabled,
            })}
          >
            <MinusIcon />
          </button>
        </div>
      ) : null}
    </div>
  )
}
