import { FormikProps, useFormik } from 'formik'
import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react'
import { ActivateElectrocardFormFields } from '@electro/consumersite/src/components/AccountElectrocardsManager/components/ActivateElectrocardForm'
import scrollToFirstErrorInDocument from '@electro/consumersite/src/helpers/scrollToFormikValidationErrors'
import * as Yup from 'yup'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import {
  useAssignElectrocardMutation,
  ElectrocardsDocument,
} from '@electro/consumersite/generated/graphql'

type ActivateElectrocardContextType = {
  formik: FormikProps<ActivateElectrocardFormFields>
  validateOnBlur: (e: React.FocusEvent<any>) => void
  loading: boolean
  success: boolean
}

const cardNumberRegex = /^[a-fA-F0-9]*$/

const activateElectrocardFormValidationSchema = Yup.object().shape({
  number: Yup.string()
    .required('Card number is required.')
    .min(14, 'Card number should be at least 14 characters.')
    .matches(cardNumberRegex, 'Card number should be only numbers and letters between A-F.'),
})

const ActivateElectrocardContext = createContext(null)

const useActivateElectrocardProvider = (formFields): ActivateElectrocardContextType => {
  const [success, setSuccess] = useState(false)
  const [assignElectrocard, { loading, error }] = useAssignElectrocardMutation({
    errorPolicy: 'all',
    onCompleted: ({ assignEjnCard }) => {
      if (assignEjnCard?.success) setSuccess(true)
    },
    refetchQueries: [ElectrocardsDocument],
  })

  const submitHandler = (values) => {
    assignElectrocard({
      variables: {
        hexCode: values.number,
      },
    })
  }

  useErrorNotificationEffect({
    error,
    message: error?.message,
  })

  const formik = useFormik({
    initialValues: {
      ...formFields,
    },
    validationSchema: activateElectrocardFormValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: submitHandler,
  })

  const validateOnBlur = formik.submitCount > 0 ? formik.handleBlur : null

  useEffect(() => {
    if (!formik.isValid) {
      scrollToFirstErrorInDocument<
        ActivateElectrocardFormFields,
        keyof ActivateElectrocardFormFields
      >(formik.errors)
    }
  }, [formik.errors, formik.isValid, formik.submitCount])

  return { formik, validateOnBlur, loading, success }
}

interface Props {
  formFields?: Partial<ActivateElectrocardFormFields>
  children: ReactNode | ReactNode[]
}

export const ActivateElectrocardProvider = ({ formFields, children }: Props) => {
  const value = useActivateElectrocardProvider(formFields)

  return (
    <ActivateElectrocardContext.Provider value={value}>
      {children}
    </ActivateElectrocardContext.Provider>
  )
}

export const useActivateElectrocard = (): ActivateElectrocardContextType => {
  const context = useContext(ActivateElectrocardContext)

  if (!context)
    throw new Error(
      'useActivateElectrocard() cannot be used outside of <ActivateElectrocardProvider/>',
    )
  return context
}
