import { tw } from '@electro/shared/utils/tailwind-merge'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'

type LoadingBoltSize = 'sm' | 'md'

interface LoadingBoltProps {
  size?: LoadingBoltSize
  subtitle?: string
  className?: string
}

const styles = {
  root: 'flex flex-col flex-grow items-center justify-center',
  size: {
    sm: '[&>div]:w-24',
    md: '[&>div]:w-36',
  },
  subtitle: {
    root: 'text-primary text-center mt-2',
    size: {
      sm: '',
      md: 'font-medium text-xl',
    },
  },
}

export const LoadingBolt = ({ size = 'md', subtitle, className }: LoadingBoltProps) => (
  <div
    className={tw(styles.root, styles.size[size], className)}
    data-testid="loading-bolt-animation"
    aria-label="loading"
  >
    <AnimatedComponent name="loading-bolt" getAnimationData={ANIMATIONS.loadingBolt} />
    {subtitle ? (
      <p className={tw(styles.subtitle.root, styles.subtitle.size[size])}>{subtitle}</p>
    ) : null}
  </div>
)
