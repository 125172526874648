import { tw } from '@electro/shared/utils/tailwind-merge'

interface Props {
  className?: string
}

export const RadioUncheckedIcon = ({ className = '' }: Props) => (
  <svg
    className={tw('fill-current absolute top-0 right-0', className)}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="radio-unchecked-icon"
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
  </svg>
)

export default RadioUncheckedIcon
