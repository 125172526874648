/**
 * This component is a strong candidate for a refactor!
 * There is a fair amount of spaghetti tangled into state management.
 * TODO: add a state machine (xstate) to control state in a more predictable way!
 */

import { createContext, useContext } from 'react'
import { Button, LoadingBolt, Typography } from '@electro/shared-ui-components'

import {
  VehicleRegLookup,
  REGISTRATION_LOOKUP_FORM_ID,
  VehicleOptionsSelect,
} from '@electro/shared/components'
import { ManualVehicleLookup } from '@electro/consumersite/src/components/Map/components/AddAnEvForm/components'

import useTranslation from 'next-translate/useTranslation'
import { useAddAnEv, IUseAddAnEv, AddAnEvFormStagesEnum } from './hooks'

const AddAnEvFormContext = createContext(null)

interface Props {
  children: any
}

const AddAnEvForm = ({ children }: Props) => {
  const context = useAddAnEv()

  return (
    <AddAnEvFormContext.Provider value={context}>
      {(typeof children === 'object' && children) ||
        children({ formStage: context.formStage, setFormStage: context.setFormStage })}
    </AddAnEvFormContext.Provider>
  )
}

const useAddAnEvFormContext = () => {
  const context = useContext<IUseAddAnEv>(AddAnEvFormContext)
  if (!context) {
    throw new Error(
      `"Add an EV" compound components cannot be rendered outside the <AddAnEvForm/> component`,
    )
  }
  return context
}

const VehicleRegLookupFields = () => {
  const {
    handleLookupSubmit,
    lookupError,
    lookupLoading,
    isElectric,
    addVehicleLoading,
    isFormStage,
    regNumber,
  } = useAddAnEvFormContext()

  return (
    <>
      <div className="text-center">
        <Typography variant="body" as="p" className="mb-0">
          Registration number (number plate)
        </Typography>
        <Typography variant="small" as="p">
          For example, OC70 PUS
        </Typography>
      </div>

      {isFormStage(AddAnEvFormStagesEnum.ENTER_REGISTRATION) && (
        <VehicleRegLookup
          isElectric={isElectric}
          loading={lookupLoading}
          error={lookupError}
          onSubmit={handleLookupSubmit}
          initialRegNumber={regNumber}
        />
      )}
      {addVehicleLoading && <LoadingBolt size="sm" subtitle="Adding EV..." className="my-4" />}
    </>
  )
}

const VehicleRegLookupSelectVehicle = () => {
  const { isFormStage, vehicleOptions, handleSelectVehicleOption } = useAddAnEvFormContext()

  return isFormStage(AddAnEvFormStagesEnum.SELECT_VEHICLE) ? (
    <>
      <Typography variant="h3" data-testid="vehicleRegLookupSelectVehicle">
        {vehicleOptions[0]?.vehicleMake}
      </Typography>
      <Typography className="mb-3">Select your model</Typography>
      <VehicleOptionsSelect options={vehicleOptions} onSelect={handleSelectVehicleOption} />
    </>
  ) : null
}

const VehicleRegLookupSearchButton = () => {
  const { lookupLoading, isFormStage } = useAddAnEvFormContext()
  // This button exists outside the form
  // we are binding it via the id -> form html attributes relationship
  return isFormStage(AddAnEvFormStagesEnum.ENTER_REGISTRATION) ? (
    <Button form={REGISTRATION_LOOKUP_FORM_ID} loading={lookupLoading} type="submit" fullWidth>
      Search
    </Button>
  ) : null
}

const ManualVehicleSearch = () => {
  const { isFormStage, setVehicle, resetVehicle } = useAddAnEvFormContext()
  return isFormStage(AddAnEvFormStagesEnum.ADD_BY_MAKE_AND_MODEL) ? (
    <ManualVehicleLookup
      onComplete={(vehicle) => setVehicle(vehicle)}
      resetVehicle={resetVehicle}
    />
  ) : null
}

const SearchTypeToggle = () => {
  const { toggleManualVehicleSearchEnabled, vehicle, isFormStage } = useAddAnEvFormContext()

  return !isFormStage(AddAnEvFormStagesEnum.VEHICLE_ADDED) ? (
    <Button
      variant={
        vehicle || isFormStage(AddAnEvFormStagesEnum.ENTER_REGISTRATION) ? 'outline' : 'default'
      }
      onClick={toggleManualVehicleSearchEnabled}
      fullWidth
    >
      {isFormStage(AddAnEvFormStagesEnum.ADD_BY_MAKE_AND_MODEL)
        ? 'Search by reg'
        : 'Add vehicle manually'}
    </Button>
  ) : null
}

const AddVehicleButton = () => {
  const { t } = useTranslation('common')
  const { vehicle, vehicleAdded, addVehicleLoading, handleAddVehicle, isFormStage } =
    useAddAnEvFormContext()
  const showButton =
    (isFormStage(AddAnEvFormStagesEnum.SELECT_VEHICLE) ||
      isFormStage(AddAnEvFormStagesEnum.ADD_BY_MAKE_AND_MODEL)) &&
    vehicle &&
    !vehicleAdded &&
    !addVehicleLoading

  return showButton ? (
    <Button disabled={!vehicle || addVehicleLoading} onClick={handleAddVehicle} fullWidth>
      {t('add_ev_button_text')}
    </Button>
  ) : null
}

const Success = ({ children }) => {
  const { isFormStage } = useAddAnEvFormContext()
  return isFormStage(AddAnEvFormStagesEnum.VEHICLE_ADDED) ? children : null
}

const NotAnEv = ({ children }) => {
  const { isElectric, vehicleOptions, lookupLoading } = useAddAnEvFormContext()
  return !isElectric && vehicleOptions && !lookupLoading ? children : null
}

AddAnEvForm.VehicleRegLookupSelectVehicle = VehicleRegLookupSelectVehicle
AddAnEvForm.VehicleRegLookupSearchButton = VehicleRegLookupSearchButton
AddAnEvForm.VehicleRegLookupFields = VehicleRegLookupFields
AddAnEvForm.ManualVehicleSearch = ManualVehicleSearch
AddAnEvForm.SearchTypeToggle = SearchTypeToggle
AddAnEvForm.AddVehicleButton = AddVehicleButton
AddAnEvForm.Success = Success
AddAnEvForm.NotAnEv = NotAnEv

export { AddAnEvForm }
