import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { RouteBreakdownPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import useTranslation from 'next-translate/useTranslation'

export const RouteBreakdownPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.ROUTE_BREAKDOWN}
      label={t('map.route_breakdown.title')}
      showStickyButtons
    >
      <RouteBreakdownPanelContent />
    </SidebarPanelContainer>
  )
}
