import getYearFromPartialDateString from '@electro/shared/utils/getYearFromPartialDateString'
import React, { useMemo, useCallback, useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Typography } from '@electro/shared-ui-components'

interface VehicleType {
  __typename?: 'VehicleType'
  availabilityDateFrom?: string
  availabilityDateTo?: string
  batteryCapacityFull: number
  batteryCapacityUseable: number
  chargePlug: string
  chargePlugLocation?: string
  chargeStandardChargeSpeed?: number
  chargeStandardChargeTime?: number
  chargeStandardPhase?: number
  chargeStandardPower: number
  efficiencyReal: number
  fastchargeChargeSpeed?: number
  fastchargeChargeTime?: number
  fastchargeOptional?: boolean
  fastchargePlug?: string
  fastchargePlugLocation?: string
  fastchargePowerAvg?: number
  fastchargePowerMax?: number
  id: string
  isHybrid: boolean
  octoEvDbId: number
  pk?: number
  rangeReal: number
  rangeRealMiles?: number
  vehicleMake: string
  vehicleModel: string
  vehicleModelVersion?: string
}

interface VehicleOptionsSelectProps {
  options: VehicleType[]
  onSelect: (vehicle: VehicleType) => void
}

const styles = {
  root: tw(
    'rounded-xl w-full text-left px-4 py-3 mb-1.5 border',
    'border-secondary hover:text-white hover:bg-base/20 hover:border-secondary hover:ring-tertiary',
  ),
  selected: 'ring-1 border-secondary bg-secondary ring-secondary',
}

interface VehicleOption extends VehicleType {
  name: string
  year: string
}

export const VehicleOptionsSelect = ({ options, onSelect }: VehicleOptionsSelectProps) => {
  const [selectedItemId, setselectedItemId] = useState<number | null>(null)
  const vehicles: VehicleOption[] = useMemo(() => {
    if (options?.length === 0) return []

    return options
      ?.map((option) => ({
        ...option,
        year: `${getYearFromPartialDateString(option.availabilityDateFrom)}${
          option.availabilityDateTo
            ? `-${getYearFromPartialDateString(option.availabilityDateTo)}`
            : ''
        }`,
        name: `${option.vehicleModel} ${
          option.vehicleModelVersion ? option.vehicleModelVersion : ''
        }`,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [options])

  const handleSelect = useCallback(
    (vehicle: VehicleOption) => () => {
      const { octoEvDbId } = vehicle
      const selectedVehicle = vehicles.find((i) => i.octoEvDbId === octoEvDbId)

      if (selectedVehicle) {
        setselectedItemId(selectedVehicle.octoEvDbId)
        onSelect(selectedVehicle)
      }
    },
    [onSelect, vehicles],
  )

  if (!options) return null

  return (
    <div className="pt-2 pb-5 px-0.5" data-testid="vehicle-options-select">
      {vehicles.map((vehicle) => (
        <button
          className={tw({
            [styles.root]: true,
            [styles.selected]: selectedItemId === vehicle?.octoEvDbId,
          })}
          data-testid="select-box-option"
          key={vehicle.octoEvDbId}
          value={vehicle.octoEvDbId}
          onClick={handleSelect(vehicle)}
        >
          <Typography>{vehicle.name}</Typography>
          <Typography>{vehicle.year}</Typography>
        </button>
      ))}
    </div>
  )
}
