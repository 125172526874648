export const filtersChargeSpeedSliderStops = [
  {
    value: 0,
    label: '0',
    activeStepColor: '#ffffff',
  },
  {
    value: 7,
    label: '7',
    activeStepColor: '#ffffff',
  },
  {
    value: 22,
    label: '22',
    activeStepColor: '#ffffff',
  },
  {
    value: 50,
    label: '50',
    activeStepColor: '#FFC632',
  },
  {
    value: 75,
    label: '75',
    activeStepColor: '#FFC632',
  },
  {
    value: 100,
    label: '100',
    activeStepColor: '#FFC632',
  },
  {
    value: 150,
    label: '150',
    activeStepColor: '#FF0074',
  },
  {
    value: 350,
    label: '350+',
    activeStepColor: '#FF0074',
  },
]
