import { fireGtmEvent } from '@electro/shared/utils/fireGtmEvent'

enum GTM_EVENTS {
  SIGN_UP_COMPLETED = 'WEB::SIGN_UP_COMPLETED',
  PAYMENT_METHOD_ADDED = 'WEB::PAYMENT_METHOD_ADDED',
  USER_AUTHENTICATED = 'WEB::USER_AUTHENTICATED',
  PNC_SIGN_UP_FORM_INITIALIZED = 'WEB::PNC_SIGN_UP_FORM_INITIALIZED',
  PNC_SIGN_UP_COMPLETE = 'WEB::PNC_SIGN_UP_COMPLETE',
  PNC_SIGN_UP_INCOMPATIBLE_VEHICLE = 'WEB::PNC_SIGN_UP_INCOMPATIBLE_VEHICLE',
  PNC_SIGN_UP_ERROR = 'WEB::PNC_SIGN_UP_ERROR',
  ELECTROCARD_ORDERED = 'WEB::ELECTROCARD_ORDERED',
  AFFILIATE_CAMPAIGN_CODE_PRESENT = 'WEB::AFFILIATE_CAMPAIGN_CODE_PRESENT',
}

// User completes order Electrocard form (conversion)
// User completes magic link sign up flow (conversion)
export const signupConversion = ({ userId }: { userId?: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.SIGN_UP_COMPLETED,
    userId,
  })

/** User adds a card for future payments. */
export const paymentMethodAdded = () =>
  fireGtmEvent({
    event: GTM_EVENTS.PAYMENT_METHOD_ADDED,
  })

/**
 * User is logged in & authenticated.
 * This event is important to Amplitude analytics, the user ID is how we track user behavior across different platforms.
 */
export const userAuthenticated = ({ userId }: { userId: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.USER_AUTHENTICATED,
    userId,
  })

/** Plug and Charge sign up form initialized */
export const pncSignupFormInit = ({ userId }) =>
  fireGtmEvent({
    event: GTM_EVENTS.PNC_SIGN_UP_FORM_INITIALIZED,
    userId,
  })

/** Plug and Charge sign up form completed */
export const pncSignupCompleted = ({ userId }) =>
  fireGtmEvent({
    event: GTM_EVENTS.PNC_SIGN_UP_COMPLETE,
    userId,
  })

/** Plug and Charge sign up, incompatible reg plate submitted */
export const pncSignupIncompatibleVehicle = ({ userId, isElectric, isPncCompatible }) =>
  fireGtmEvent({
    event: GTM_EVENTS.PNC_SIGN_UP_INCOMPATIBLE_VEHICLE,
    isElectric,
    isPncCompatible,
    userId,
  })

/** Plug and Charge sign up form error */
export const pncSignupError = ({ errorCode, userId }) =>
  fireGtmEvent({
    event: GTM_EVENTS.PNC_SIGN_UP_ERROR,
    errorCode,
    userId,
  })

export const electrocardOrdered = () =>
  fireGtmEvent({
    event: GTM_EVENTS.ELECTROCARD_ORDERED,
  })

/**
 * User has an affiliate campaign code that we want to send to Amplitude via GTM
 * so we can track them all the way up to the point they signup.
 * This will also let us segment any future events by specific affiliate codes in Amplitude */
export const userWithAffiliateCampaignCode = ({ campaignCode }: { campaignCode: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.AFFILIATE_CAMPAIGN_CODE_PRESENT,
    campaignCode,
  })
