import { ACCOUNT_ID } from '@electro/consumersite/src/constants/localStorage'
import {
  EJN_REFRESH_TOKEN_KEY,
  FLEETS_REFRESH_TOKEN_KEY,
  FLEETS_AUTH_TOKEN_KEY,
  KRAKEN_OAUTH_CHALLENGE_CODE,
  EJN_AUTH_TOKEN_KEY,
} from '@electro/shared/constants/localStorage'

export function setRefreshTokenToLocalStorage(token: string) {
  return Promise.resolve().then(() => {
    localStorage.setItem(EJN_REFRESH_TOKEN_KEY, token)
  })
}

export function removeRefreshTokenFromLocalStorage() {
  return Promise.resolve().then(() => {
    localStorage.removeItem(EJN_REFRESH_TOKEN_KEY)
  })
}

export function setAccessTokenToLocalStorage(token: string) {
  return Promise.resolve().then(() => {
    localStorage.setItem(EJN_AUTH_TOKEN_KEY, token)
  })
}

export function removeAccessTokenFromLocalStorage() {
  return Promise.resolve().then(() => {
    localStorage.removeItem(EJN_AUTH_TOKEN_KEY)
  })
}

export function setFleetsAccessTokenToLocalStorage(token: string) {
  return Promise.resolve().then(() => {
    localStorage.setItem(FLEETS_AUTH_TOKEN_KEY, token)
  })
}

export function removeFleetsAccessTokenFromLocalStorage() {
  return Promise.resolve().then(() => {
    localStorage.removeItem(FLEETS_AUTH_TOKEN_KEY)
  })
}

export function setFleetsRefreshTokenToLocalStorage(token: string) {
  return Promise.resolve().then(() => {
    localStorage.setItem(FLEETS_REFRESH_TOKEN_KEY, token)
  })
}

export function removeFleetsRefreshTokenFromLocalStorage() {
  return Promise.resolve().then(() => {
    localStorage.removeItem(FLEETS_REFRESH_TOKEN_KEY)
  })
}

export function setChallengeCodeToLocalStorage(token: string) {
  return Promise.resolve().then(() => {
    localStorage.setItem(KRAKEN_OAUTH_CHALLENGE_CODE, token)
  })
}

export function removeChallengeCodeFromLocalStorage() {
  return Promise.resolve().then(() => {
    localStorage.removeItem(KRAKEN_OAUTH_CHALLENGE_CODE)
  })
}

export function removeAccountIdFromLocalStorage() {
  return Promise.resolve().then(() => {
    localStorage.removeItem(ACCOUNT_ID)
  })
}
