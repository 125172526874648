import CREATE_PLUG_AND_CHARGE_CONTRACT_MUTATION from '@electro/consumersite/src/graphql/createPlugAndChargeContractMutation.graphql'
import { MutationHookOptions, MutationResult, useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import {
  Mutation,
  MutationCreatePlugAndChargeContractArgs,
} from '@electro/consumersite/generated/graphql'

type CreatePlugAndChargeContractMutation = Pick<Mutation, 'createPlugAndChargeContract'>

type UseCreatePlugAndChargeContract = [
  ApolloMutationFunction<CreatePlugAndChargeContractMutation>,
  MutationResult<CreatePlugAndChargeContractMutation>,
]

type UseCreatePlugAndChargeContractArgs = MutationHookOptions<
  CreatePlugAndChargeContractMutation,
  MutationCreatePlugAndChargeContractArgs
>

export function useCreatePlugAndChargeContract(
  options?: UseCreatePlugAndChargeContractArgs,
): UseCreatePlugAndChargeContract {
  const [createPlugAndChargeContractMutation, createPlugAndChargeContractResult] =
    useMutation<CreatePlugAndChargeContractMutation>(
      CREATE_PLUG_AND_CHARGE_CONTRACT_MUTATION,
      options,
    )

  return [createPlugAndChargeContractMutation, createPlugAndChargeContractResult]
}
