export const FoodDrinkIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 22 23">
    <path
      d="M0.898456 20.9831H1.03988H12.377H12.5184L12.4713 21.1164C12.3652 21.4164 12.1687 21.676 11.9091 21.8596L11.9091 21.8596C11.6493 22.0432 11.3389 22.1417 11.0209 22.1415M0.898456 20.9831L11.021 22.0415M0.898456 20.9831L0.945597 21.1164C1.05165 21.4164 1.24812 21.676 1.50791 21.8596L1.50791 21.8596C1.7677 22.0432 2.07797 22.1417 2.3961 22.1415M0.898456 20.9831L2.39604 22.0415M11.0209 22.1415L11.021 22.0415M11.0209 22.1415H11.021V22.0415M11.0209 22.1415H2.3961M11.021 22.0415H2.39604M2.3961 22.1415L2.39604 22.0415M2.3961 22.1415H2.39604V22.0415M12.377 21.0831V21.1831H12.3373C12.3518 21.1503 12.365 21.117 12.377 21.0831ZM12.377 21.0831L12.2827 21.0498C12.2787 21.0609 12.2746 21.072 12.2704 21.0831H12.377ZM1.03988 21.0831L1.13416 21.0498C1.13811 21.0609 1.14221 21.072 1.14644 21.0831H1.03988ZM1.03988 21.0831V21.1831H1.0795C1.06509 21.1503 1.05186 21.117 1.03988 21.0831ZM0.772575 22.2273L0.772573 22.2273C0.342087 21.7969 0.100122 21.2128 0.100122 20.604C0.100122 20.3946 0.269991 20.2248 0.479339 20.2248H12.9374C13.038 20.2248 13.1345 20.2648 13.2056 20.3359C13.2767 20.407 13.3166 20.5035 13.3166 20.604C13.3166 21.2129 13.0748 21.7969 12.6442 22.2273L12.6442 22.2274C12.2137 22.6579 11.6298 22.8998 11.021 22.8998C11.0209 22.8998 11.0209 22.8998 11.0209 22.8998H2.39604C1.78707 22.8998 1.20318 22.658 0.772575 22.2273Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M13.0731 18.258L13.0731 18.258C13.3978 18.8202 13.3978 19.5127 13.0731 20.0747L13.0731 20.0747C12.7486 20.6368 12.1489 20.983 11.4999 20.983H1.91674C1.26773 20.983 0.668001 20.6368 0.343497 20.0747C0.0190004 19.5127 0.018994 18.8202 0.343498 18.258C0.667997 17.696 1.26773 17.3498 1.91674 17.3498C2.12609 17.3498 2.29596 17.5195 2.29596 17.729C2.29596 17.9383 2.12611 18.108 1.91674 18.108C1.53864 18.108 1.18926 18.3098 1.00022 18.6372C0.811168 18.9647 0.811168 19.3681 1.00022 19.6955C1.18926 20.0229 1.53863 20.2247 1.91674 20.2247H11.4999C11.878 20.2247 12.2273 20.0229 12.4164 19.6955C12.6054 19.3681 12.6054 18.9647 12.4164 18.6372L12.3298 18.6872L12.4164 18.6372C12.2273 18.3098 11.878 18.108 11.4999 18.108C11.2905 18.108 11.1208 17.9383 11.1208 17.729C11.1208 17.5195 11.2906 17.3498 11.4999 17.3498C12.1489 17.3498 12.7486 17.696 13.0731 18.258Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M8.62418 19.5455C8.52352 19.5462 8.42678 19.5067 8.35535 19.4358C8.35528 19.4358 8.35522 19.4357 8.35515 19.4356L7.06179 18.1375L7.03248 18.1081H6.99095H1.91662C1.26761 18.1081 0.667879 17.7618 0.343374 17.1998C0.0188783 16.6378 0.0188719 15.9453 0.343376 15.3831C0.667875 14.821 1.26761 14.4748 1.91662 14.4748H11.4997C12.1487 14.4748 12.7485 14.8211 13.073 15.3831L13.073 15.3831C13.3976 15.9453 13.3976 16.6377 13.073 17.1998L13.073 17.1998C12.7485 17.7618 12.1487 18.1081 11.4997 18.1081H10.2588H10.2173L10.1881 18.1374L8.88977 19.4356C8.88971 19.4357 8.88965 19.4358 8.88959 19.4358C8.81906 19.5057 8.72383 19.5452 8.62437 19.5455L8.62446 19.5906L8.62418 19.5455ZM7.18732 17.3498L7.18795 17.3498C7.28858 17.3491 7.38535 17.3886 7.45698 17.4595C7.45705 17.4596 7.45713 17.4597 7.45721 17.4597L8.55395 18.5613L8.62466 18.6323L8.69553 18.5614L9.76345 17.4935H9.76418L9.79359 17.4635C9.86457 17.3912 9.96136 17.3502 10.0625 17.3498H11.4998C11.8779 17.3498 12.2273 17.148 12.4163 16.8206C12.6054 16.4931 12.6054 16.0897 12.4163 15.7623L12.3297 15.8123L12.4163 15.7623C12.2273 15.4349 11.8779 15.2331 11.4998 15.2331L1.91667 15.2331C1.53856 15.2331 1.18918 15.4349 1.00014 15.7623C0.811095 16.0897 0.811096 16.4931 1.00014 16.8206C1.18918 17.148 1.53856 17.3498 1.91667 17.3498L7.18732 17.3498Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M13.2054 15.1222C13.1349 15.1928 13.0392 15.2326 12.9393 15.2331H12.9373H0.479217C0.269846 15.2331 0.1 15.0633 0.1 14.854C0.1 13.4825 0.644866 12.1674 1.61457 11.1977C2.58428 10.228 3.89941 9.68313 5.2709 9.68313H8.14587C9.5172 9.68313 10.8324 10.228 11.802 11.1977L11.802 11.1977C12.7719 12.1674 13.3166 13.4825 13.3166 14.854C13.3166 14.9545 13.2766 15.0509 13.2054 15.1222ZM12.4297 14.4748H12.5415L12.529 14.3637C12.4086 13.2854 11.8949 12.2893 11.0865 11.5659C10.2778 10.8423 9.23104 10.4421 8.14612 10.4416H8.14608H5.2711L5.27106 10.4416C4.18602 10.4421 3.13921 10.8423 2.33071 11.5659C1.5221 12.2893 1.00845 13.2854 0.887967 14.3637L0.987266 14.3748V14.3748V14.4748H0.987348H1.0768H12.4297Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M11.0209 16.6706H2.39597C2.18646 16.6706 2.01675 16.5008 2.01675 16.2915C2.01675 16.082 2.18648 15.9123 2.39597 15.9123H11.0209C11.2302 15.9123 11.3999 16.082 11.3999 16.2915C11.3999 16.5009 11.2302 16.6706 11.0209 16.6706Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M3.35427 13.3167H2.87505C2.66577 13.3167 2.496 13.1468 2.496 12.9375C2.496 12.7281 2.66577 12.5583 2.87505 12.5583H3.35427C3.56359 12.5583 3.73348 12.7281 3.73348 12.9375C3.73348 13.1468 3.56359 13.3167 3.35427 13.3167Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M5.7499 12.3581H5.27084C5.06133 12.3581 4.89163 12.1883 4.89163 11.979C4.89163 11.7695 5.06136 11.5998 5.27084 11.5998H5.7499C5.95939 11.5998 6.12912 11.7695 6.12912 11.979C6.12912 12.1883 5.95941 12.3581 5.7499 12.3581H5.7499Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M8.14593 12.3581H7.66672C7.45735 12.3581 7.2875 12.1883 7.2875 11.979C7.2875 11.7696 7.45737 11.5998 7.66672 11.5998H8.14593C8.35524 11.5998 8.52499 11.7695 8.52499 11.979C8.52499 12.1884 8.35526 12.3581 8.14593 12.3581Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M10.5416 13.3167H10.0624C9.85314 13.3167 9.68337 13.1468 9.68337 12.9375C9.68337 12.7281 9.85315 12.5583 10.0624 12.5583H10.5416C10.751 12.5583 10.9209 12.7281 10.9209 12.9375C10.9209 13.1468 10.751 13.3167 10.5416 13.3167Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M18.7009 22.8998H18.6872H11.0209C10.8114 22.8998 10.6417 22.7301 10.6417 22.5206C10.6417 22.3113 10.8114 22.1415 11.0209 22.1415H18.2371H18.3309L18.3369 22.048L19.2329 8.1524L19.2398 8.04597L19.1331 8.04597L10.5751 8.04581L10.4684 8.04581L10.4753 8.15227L10.6287 10.5193L10.6287 10.5193C10.6423 10.729 10.4835 10.9098 10.274 10.9234L10.2739 10.9234C10.0645 10.937 9.88358 10.7782 9.86986 10.5687L9.68988 7.80031H9.69058L9.68309 7.69332C9.67572 7.58814 9.71229 7.48482 9.78427 7.40775L9.78428 7.40775C9.85607 7.33088 9.95688 7.28731 10.0621 7.28756V7.28756H10.0624L19.6459 7.28756L19.646 7.28756C19.7507 7.28744 19.8508 7.33049 19.9226 7.40657C19.9943 7.48275 20.0314 7.58492 20.0252 7.68917C20.0252 7.68926 20.0252 7.68934 20.0251 7.68943L19.0669 22.5432L19.0669 22.5437C19.0551 22.7397 18.8958 22.8935 18.7009 22.8998Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M20.6041 8.0456H9.10422C8.89485 8.0456 8.725 7.87583 8.725 7.66655V5.74985C8.725 5.54052 8.89489 5.37063 9.10422 5.37063H20.6041C20.7046 5.37063 20.8011 5.41062 20.8722 5.48172C20.9433 5.55282 20.9833 5.64932 20.9833 5.74985V7.66655C20.9833 7.767 20.9434 7.86336 20.8722 7.93468C20.8016 8.00532 20.7058 8.04516 20.6058 8.0456H20.6041ZM9.48352 7.18733V7.28733H9.58352H20.1248H20.2248V7.18733V6.22906V6.12906H20.1248H9.58352H9.48352V6.22906V7.18733Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M17.0605 1.92871L17.0605 1.92871C18.4257 2.71698 19.2667 4.17368 19.2667 5.75007C19.2667 5.95942 19.4364 6.12929 19.6459 6.12929H19.6461C19.7465 6.12929 19.8429 6.08935 19.9142 6.01819C19.9142 6.01817 19.9142 6.01815 19.9143 6.01813M17.0605 1.92871L12.698 2.01531C11.3637 2.78572 10.5418 4.20941 10.5418 5.75007C10.5418 6.01462 10.3273 6.22929 10.0625 6.22929C9.79799 6.22929 9.5835 6.01462 9.5835 5.75007C9.5835 3.86704 10.588 2.12698 12.2188 1.18543C13.8496 0.243875 15.8589 0.243834 17.4898 1.18543C19.1208 2.12702 20.1251 3.86708 20.1251 5.75007C20.1251 5.87713 20.0747 5.99906 19.9849 6.0889L19.9143 6.01813M17.0605 1.92871C15.6952 1.1406 14.0133 1.1406 12.648 1.92871L12.648 1.92871C11.2827 2.71698 10.4418 4.17368 10.4418 5.75007C10.4418 5.95942 10.272 6.12929 10.0625 6.12929C9.85327 6.12929 9.6835 5.95944 9.6835 5.75007C9.6835 3.90276 10.6689 2.19572 12.2688 1.27203C13.8686 0.348341 15.8398 0.348299 17.4398 1.27203C19.0399 2.19576 20.0251 3.9028 20.0251 5.75007C20.0251 5.85065 19.9853 5.9471 19.9143 6.01813M17.0605 1.92871L19.9143 6.01813"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <mask id="path-13-inside-1_4039_11031" fill="white">
      <path d="M11.9792 4.31246C11.7146 4.31246 11.5 4.09796 11.5 3.83325V1.43749C11.5 1.31042 11.4496 1.18849 11.3597 1.09865C11.2697 1.00881 11.148 0.95827 11.0209 0.95827H7.66672C7.40216 0.95827 7.1875 0.743768 7.1875 0.479217C7.1875 0.214502 7.40216 0 7.66672 0H11.0209C11.4021 0 11.7677 0.15145 12.0373 0.420978C12.3069 0.690668 12.4584 1.05631 12.4584 1.43749V3.83325C12.4584 3.96031 12.4079 4.08224 12.318 4.17208C12.2282 4.26192 12.1062 4.31246 11.9792 4.31246H11.9792Z" />
    </mask>
    <path
      d="M11.9792 4.31246C11.7146 4.31246 11.5 4.09796 11.5 3.83325V1.43749C11.5 1.31042 11.4496 1.18849 11.3597 1.09865C11.2697 1.00881 11.148 0.95827 11.0209 0.95827H7.66672C7.40216 0.95827 7.1875 0.743768 7.1875 0.479217C7.1875 0.214502 7.40216 0 7.66672 0H11.0209C11.4021 0 11.7677 0.15145 12.0373 0.420978C12.3069 0.690668 12.4584 1.05631 12.4584 1.43749V3.83325C12.4584 3.96031 12.4079 4.08224 12.318 4.17208C12.2282 4.26192 12.1062 4.31246 11.9792 4.31246H11.9792Z"
      fill="currentColor"
    />
    <path
      d="M11.3597 1.09865L11.5012 0.95723L11.501 0.957104L11.3597 1.09865ZM12.0373 0.420978L11.8958 0.562394L11.8958 0.5624L12.0373 0.420978ZM11.9792 4.11246C11.825 4.11246 11.7 3.98746 11.7 3.83325H11.3C11.3 4.20846 11.6042 4.51246 11.9792 4.51246V4.11246ZM11.7 3.83325V1.43749H11.3V3.83325H11.7ZM11.7 1.43749C11.7 1.25752 11.6286 1.08468 11.5012 0.95723L11.2183 1.24007C11.2706 1.29231 11.3 1.36333 11.3 1.43749H11.7ZM11.501 0.957104C11.3737 0.829971 11.2012 0.75827 11.0209 0.75827V1.15827C11.0948 1.15827 11.1658 1.18765 11.2185 1.2402L11.501 0.957104ZM11.0209 0.75827H7.66672V1.15827H11.0209V0.75827ZM7.66672 0.75827C7.51253 0.75827 7.3875 0.633223 7.3875 0.479217H6.9875C6.9875 0.854313 7.29179 1.15827 7.66672 1.15827V0.75827ZM7.3875 0.479217C7.3875 0.325002 7.51258 0.2 7.66672 0.2V-0.2C7.29175 -0.2 6.9875 0.104001 6.9875 0.479217H7.3875ZM7.66672 0.2H11.0209V-0.2H7.66672V0.2ZM11.0209 0.2C11.3491 0.2 11.6638 0.330382 11.8958 0.562394L12.1787 0.279562C11.8717 -0.0274824 11.4551 -0.2 11.0209 -0.2V0.2ZM11.8958 0.5624C12.128 0.794593 12.2584 1.10937 12.2584 1.43749H12.6584C12.6584 1.00325 12.4859 0.586742 12.1787 0.279557L11.8958 0.5624ZM12.2584 1.43749V3.83325H12.6584V1.43749H12.2584ZM12.2584 3.83325C12.2584 3.90723 12.229 3.9783 12.1766 4.03066L12.4594 4.3135C12.5868 4.18618 12.6584 4.01339 12.6584 3.83325H12.2584ZM12.1766 4.03066C12.1242 4.08302 12.0532 4.11246 11.9792 4.11246V4.51246C12.1593 4.51246 12.3321 4.44083 12.4594 4.3135L12.1766 4.03066ZM11.9792 4.51246H11.9792V4.11246H11.9792V4.51246Z"
      fill="currentColor"
      mask="url(#path-13-inside-1_4039_11031)"
    />
    <path
      d="M18.5873 19.0665V19.0666H15.3332C15.1238 19.0666 14.954 18.8968 14.954 18.6874V11.5C14.954 11.2906 15.1238 11.1208 15.3332 11.1208H19.1665C19.3758 11.1208 19.5457 11.2906 19.5457 11.5C19.5457 11.7093 19.3758 11.8792 19.1665 11.8792H15.8123H15.7123V11.9792V18.208V18.308H15.8123H18.6873C18.8967 18.308 19.0665 18.4779 19.0665 18.6872C19.0665 18.8966 18.8967 19.0665 18.6873 19.0665H18.5873Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M17.7291 6.12922C17.5197 6.12922 17.3499 5.95933 17.3499 5.75001C17.3499 5.08801 17.087 4.45324 16.6188 3.98529C16.1508 3.51716 15.5161 3.25425 14.8541 3.25425C14.6448 3.25425 14.4749 3.08436 14.4749 2.87503C14.4749 2.6657 14.6448 2.49581 14.8541 2.49581C15.7172 2.49581 16.5448 2.83871 17.1551 3.44902C17.7654 4.05934 18.1083 4.88694 18.1083 5.75001C18.1083 5.85053 18.0683 5.94703 17.9972 6.01813C17.9261 6.08923 17.8296 6.12922 17.7291 6.12922Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
)
