import { useEffect } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

import { Typography } from '@electro/shared-ui-components'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import { UserVehicleType } from '@electro/consumersite/generated/graphql'
import {
  ConnectorsHumanNameType,
  retrieveSocketTypeDetails,
} from '@electro/consumersite/src/components/Map/utils'

const styles = {
  root: 'mb-4 px-1',
  listItem: {
    root: tw('flex items-center mb-2 p-1 last:mb-0 w-full rounded-xl group text-sm xs:text-base'),
    inactive: 'bg-base/30 border-secondary border hover:border-tertiary',
    active:
      'pointer-events-none bg-secondary border border-secondary hover:ring-0 hover:border-secondary',
    selectEvButton: 'flex-grow flex items-center disabled:opacity-30',
    connector: {
      root: tw(
        'mr-2 flex items-center p-2 rounded-lg w-20 sm:w-auto justify-center',
        'border group-hover:border-tertiary',
      ),
      inactive: 'border-secondary hover:border-tertiary',
      active: 'border-secondary bg-base/30',
    },
    label: {
      root: 'text-left flex-grow text-xs sm:text-base text-white hover:text-tertiary',
      regNumber: 'text-xs xs:text-sm text-white',
    },
  },
}

interface UserEvsListProps {
  onSelect: ({ vehicle }: { vehicle: UserVehicleType }) => () => void
}

export const UserEvsList = ({ onSelect }: UserEvsListProps) => {
  const [{ activeVehicle, userVehicles }, { updateActiveVehicle }] = useUserVehicles()

  const handleSelectVehicle = (vehicle: UserVehicleType) => () => {
    if (vehicle.pk === activeVehicle?.pk) return
    onSelect({ vehicle })()
    updateActiveVehicle(vehicle)
  }

  useEffect(() => {
    if (activeVehicle) onSelect({ vehicle: activeVehicle })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVehicle])

  return (
    <div className={styles.root}>
      {userVehicles
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((vehicle: UserVehicleType) => {
          const { chargePlug, fastchargePlug } = vehicle.vehicle
          const plug = retrieveSocketTypeDetails(chargePlug as ConnectorsHumanNameType)
          const fastPlug = retrieveSocketTypeDetails(fastchargePlug as ConnectorsHumanNameType)

          const selected = activeVehicle?.pk === vehicle.pk

          return (
            <div
              key={vehicle.id}
              data-testid={selected ? `${vehicle.regNumber} selected` : vehicle.regNumber}
              className={tw({
                [styles.listItem.root]: true,
                [styles.listItem.inactive]: !selected,
                [styles.listItem.active]: selected,
              })}
            >
              <button
                value={vehicle.vehicle.chargePlug}
                className={styles.listItem.selectEvButton}
                onClick={handleSelectVehicle(vehicle)}
                type="button"
              >
                <div
                  className={tw({
                    [styles.listItem.connector.root]: true,
                    [styles.listItem.connector.inactive]: !selected,
                    [styles.listItem.connector.active]: selected,
                  })}
                >
                  {chargePlug && (
                    <img
                      src={plug.iconURL}
                      alt={chargePlug}
                      className="w-6 h-6 sm:w-auto sm:h-auto"
                    />
                  )}
                  {fastchargePlug && (
                    <img
                      className="ml-2 w-6 h-6 sm:w-auto sm:h-auto"
                      src={fastPlug.iconURL}
                      alt={fastchargePlug}
                    />
                  )}
                </div>
                <div className="text-left">
                  <Typography variant="body">{vehicle.name}</Typography>
                  <Typography variant="body">{vehicle.regNumber}</Typography>
                </div>
              </button>
            </div>
          )
        })}
    </div>
  )
}
