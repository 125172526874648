/** Currently this list includes fr-FR for fleets. We will need to handle other countries here when we expand fleets. */
export enum Locales {
  EN = 'en-GB',
  FR = 'fr',
  FR_FR = 'fr-FR',
  ES = 'es',
  IT = 'it',
  DE = 'de',
  PT_PT = 'pt-PT',
}

export type Locale = `${Locales}`

export const LOCALES_USING_MILES_UNIT = [Locales.EN]
