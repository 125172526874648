import { Bbox } from '@mapbox/mapbox-gl-geocoder'
import { SearchBoxFeatureProperties } from '@mapbox/search-js-core/dist/searchbox/types'

export interface PlaceDetails {
  id: string
  name: string
  subtext?: string
  type: string

  // Position data only exists on Feature properties
  coordinates?: { lng: number; lat: number }
  bbox?: Bbox

  // Custom fields
  hasCustomMarker?: boolean
  maxZoom?: number
}

/** Converts both MapBox Suggestions and Feature properties to a simple PlaceDetails type */
export const formatMapboxSearchToPlaceDetails = (
  featureProperties: Partial<SearchBoxFeatureProperties>[],
): PlaceDetails[] =>
  featureProperties.map((property) => ({
    id: property.mapbox_id,
    name: property.name_preferred || property.name,
    subtext: property.full_address || property.place_formatted || undefined,
    type: property.feature_type,

    ...(property.coordinates
      ? { coordinates: { lng: property.coordinates.longitude, lat: property.coordinates.latitude } }
      : {}),
    ...(property.bbox ? { bbox: property.bbox } : {}),
  }))
