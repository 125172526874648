import { OperationVariables, QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client'
import LOCATION_QUERY from '@electro/consumersite/src/graphql/locationQuery.graphql'

import { Query, QueryChargingLocationArgs } from '@electro/consumersite/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchChargingLocation = Pick<Query, 'chargingLocation'>

type UseFetchChargingLocation = [
  ApolloLazyQueryFunction<FetchChargingLocation>,
  QueryResult<FetchChargingLocation, OperationVariables>,
]

type FetchChargingLocationArgs = QueryHookOptions<FetchChargingLocation, QueryChargingLocationArgs>

export function useFetchChargingLocation(
  options?: FetchChargingLocationArgs,
): UseFetchChargingLocation {
  const [lazyFetchChargingLocation, ChargingLocationResult] = useLazyQuery<FetchChargingLocation>(
    LOCATION_QUERY,
    options,
  )

  return [lazyFetchChargingLocation, ChargingLocationResult]
}
