import { convertBatteryPercentageToHex } from '@electro/consumersite/src/components/Map/utils'

interface BatteryIconProps extends React.ComponentProps<'svg'> {
  /** Define the battery percentage to fill the bar to that amount */
  percentage: number
}

export const BatteryIcon = ({ percentage, ...props }: BatteryIconProps) => (
  <svg
    width="155"
    height="72"
    viewBox="0 0 155 72"
    xmlns="http://www.w3.org/2000/svg"
    fill={convertBatteryPercentageToHex(percentage, { type: 'icon' })}
    {...props}
  >
    <path d="M28.457 71.5781H109.176C117.566 71.5781 125.306 70.8626 130.836 65.399C136.298 59.8703 136.95 52.1302 136.95 43.8046V28.5845C136.95 20.259 136.298 12.5189 130.836 7.05519C125.306 1.5265 117.566 0.811035 109.176 0.811035H28.457C20.1315 0.811035 12.3264 1.5265 6.79771 7.05519C1.33402 12.5189 0.683594 20.259 0.683594 28.5845V43.8046C0.683594 52.1302 1.33402 59.8703 6.79771 65.399C12.3264 70.8626 20.0665 71.5781 28.457 71.5781ZM27.0261 62.2118C22.278 62.2118 16.6842 61.6265 13.6272 58.5695C10.5702 55.5123 10.0499 49.9838 10.0499 45.2356V27.2186C10.0499 22.4053 10.5702 16.9418 13.6272 13.8197C16.6842 10.7626 22.278 10.2423 27.0911 10.2423H110.607C115.355 10.2423 120.949 10.7626 124.006 13.8197C127.063 16.9418 127.583 22.4053 127.583 27.2186V45.2356C127.583 49.9838 127.063 55.5123 124.006 58.5695C120.949 61.6265 115.355 62.2118 110.607 62.2118H27.0261ZM144.559 49.8536C148.722 49.5935 154.316 44.2599 154.316 36.1946C154.316 28.1292 148.722 22.7956 144.559 22.5355V49.8536Z" />
    <rect x="18.3743" y="18.502" width={percentage} height="35.3835" rx="7.07671" />
  </svg>
)
