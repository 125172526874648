import React from 'react'

/** Creates a button ripple effect centered on mouse position within an element.
 *
 * Note: Some css can distort the position of the effect. For example, filter and transform are common issues
 * with frosted glass effects. In some cases, even position changes on parents like `top-20` or `my-10` will
 * also affect it. To debug, remove the onClick effect and play around in the inspector with moving/deleting
 * elements/classes until the effect renders correctly.
 */
export const rippleEffect = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  const button = event.currentTarget
  const ripple = document.createElement('span')

  const diameter = Math.max(button.clientWidth, button.clientHeight)
  const radius = diameter / 2

  ripple.style.height = `${diameter}px`
  ripple.style.width = `${diameter}px`
  ripple.style.left = `${event.clientX - (button.offsetLeft + radius)}px`
  ripple.style.top = `${event.clientY - (button.offsetTop + radius)}px`
  ripple.className = 'absolute rounded-full scale-0 bg-white/70 animate-buttonRipple'

  const previousRipple = button.getElementsByClassName('animate-buttonRipple')[0]
  if (previousRipple) previousRipple.remove()

  button.appendChild(ripple)
}
