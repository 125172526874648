import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { SidebarNavIcon } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'
import { RoutePlannerPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import useTranslation from 'next-translate/useTranslation'
import { RoutePlannerIcon } from '@electro/icons/24'

const styles = {
  navIcon: 'size-6',
}

export const RoutePlannerSidebarIcon = () => (
  <SidebarNavIcon panelName={SidebarPanels.ROUTE_PLANNER}>
    <RoutePlannerIcon className={styles.navIcon} />
  </SidebarNavIcon>
)

export const RoutePlannerPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.ROUTE_PLANNER}
      label={t('map.route_planner.title')}
    >
      <RoutePlannerPanelContent />
    </SidebarPanelContainer>
  )
}
