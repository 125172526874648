import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { ReactNode, useState } from 'react'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

interface DetailsProps {
  children: ReactNode | string
  className?: string
  onClick?: () => void
}

const styles = {
  details:
    'flex flex-col gap-y-4 text-white [&_ul]:list-disc [&_ul]:pl-4 [&_ol]:list-decimal [&_ol]:pl-4',
  title: tw(
    'text-lg md:text-xl',
    'flex items-center justify-between m-4',
    'cursor-pointer list-none [&::-webkit-details-marker]:hidden',
  ),
  content: {
    root: 'p-4 pt-0 [&_b]:text-primary',
    border: 'my-4 border-secondary',
  },
  item: 'group rounded-4xl shadow-2xl border-2 border-secondary',
  chevron: {
    root: 'w-8 h-8 flex-shrink-0 ml-4',
    open: 'group-open:inline-block hidden',
    closed: 'group-open:hidden inline-block',
  },
}

const Details = ({ children, className, ...restProps }: DetailsProps) => (
  <div {...restProps} className={tw(styles.details, className)} data-testid="details">
    {children}
  </div>
)

const Title = ({ children, className, onClick, ...restProps }: DetailsProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setOpen(!open)
      if (open && !window.location.hostname.startsWith('fleets')) {
        GTM.openFAQcomponent({ title: children.toString() })
      }
    }
  }

  return (
    <summary
      tabIndex={0}
      className={tw(styles.title, className)}
      role="button"
      onClick={() => {
        setOpen(!open)
        onClick?.()
      }}
      onKeyDown={handleKeyDown}
      {...restProps}
    >
      {children}
      <ChevronDownIcon className={tw(styles.chevron.root, styles.chevron.closed)} />
      <ChevronUpIcon className={tw(styles.chevron.root, styles.chevron.open)} />
    </summary>
  )
}

const Content = ({ children, className, ...restProps }: DetailsProps) => (
  <div className={tw(styles.content.root, className)} {...restProps}>
    <hr className={styles.content.border} />
    {children}
  </div>
)

const Item = ({ children, className, ...restProps }: DetailsProps) => (
  <details className={tw(styles.item, className)} {...restProps} data-testid="detail-item">
    {children}
  </details>
)

Details.Item = Item
Details.Title = Title
Details.Content = Content

export { Details }
