import { useCallback, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { PrimaryNav } from '@electro/consumersite/src/components/TopNavigation/components/DesktopNavigation/components'
import {
  UserMenu,
  LoginButton,
  SignUpButton,
  DownloadAppQRButton,
} from '@electro/consumersite/src/components/TopNavigation/components'
import { HideWhenSiteUnderMaintenance, LanguageSelect } from '@electro/consumersite/src/components'
import { DesktopNavigationProvider } from '@electro/consumersite/src/components/TopNavigation/components/DesktopNavigation/hooks'
import {
  useDeviceInformation,
  OperatingSystemEnum,
} from '@electro/consumersite/src/hooks/useDeviceInformation'
import { useAuth } from '@electro/consumersite/src/hooks'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { getScrollbarVisible } from '@electro/consumersite/src/utils/getScrollbarVisible'
import { useMount } from 'react-use'
import ElectroverseLogoWeb from '@electro/consumersite/public/images/electroverse-logo-dark-web.svg'
import ElectroverseLogoIsolated from '@electro/consumersite/public/images/electroverse-logo-dark-isolated.svg'

const styles = {
  root: 'flex items-center justify-between fixed top-0 z-40 h-18 w-full backdrop-blur-lg px-8 py-4',
  solidBackground:
    'bg-gradient-to-r from-[#1b014d]/90 via-[#350c77]/90 via-65% to-[#5014ac]/90 shadow-md', // Hardcoded values mimic the homepage gradient mesh
  mapBackground:
    'bg-base ring-secondary ring-2 rounded-xl px-3 w-[calc(100%-40px)] mx-5 py-1 my-2 h-14', // Hardcode value to mimic the map sidebar styles
  scrollbarVisible: 'pr-[21px] [@supports_not_(scrollbar-width:thin)]:pr-[17px]',
}

const DesktopNavigation = () => {
  const { asPath, push } = useRouter()
  const { deviceType } = useDeviceInformation()
  const [{ session, sessionLoading }] = useAuth()
  const isMapRoute = asPath.includes('/map')
  const isBusinessRoute = asPath.includes('/business')

  const handleSignUp = useCallback(() => {
    GTM.clickSignUpOnNav()
    push('/sign-up/magic')
  }, [push])

  const handleLogin = useCallback(() => {
    GTM.clickSignUpOnNav()
    push('/log-in')
  }, [push])

  const [scrollbarVisible, setScrollbarVisible] = useState<boolean>(false)

  /** Adjust padding on panel based on the scrollbar visibility */
  useMount(() => setScrollbarVisible(getScrollbarVisible()))

  return (
    <nav
      className={tw({
        [styles.root]: true,
        [styles.solidBackground]: !isMapRoute,
        [styles.mapBackground]: isMapRoute,
        [styles.scrollbarVisible]: scrollbarVisible && !isMapRoute,
      })}
      aria-label="navigation"
      data-testid="desktop-navigation"
    >
      <div className="flex w-full shrink-0 lg:max-w-screen-xs xl:max-w-screen-md 2xl:max-w-screen-lg h-14 items-center lg:gap-6 xl:gap-16 2xl:gap-32">
        <Link
          href="/"
          className="cursor-pointer self-center rounded-full focus:ring-0 -ml-3 mr-1"
          aria-label="Go to homepage"
          draggable="false"
        >
          <ElectroverseLogoWeb className="h-16 p-[3px] hidden sm:block" />
          <ElectroverseLogoIsolated className="h-16 p-[3px] block sm:hidden" />
        </Link>
        <PrimaryNav />
      </div>
      <div className="flex items-center flex-shrink-0 gap-x-4">
        <HideWhenSiteUnderMaintenance>
          {sessionLoading || !session ? (
            <div
              className={tw(
                'flex flex-shrink w-full items-center gap-x-4',

                isBusinessRoute ? 'opacity-0 pointer-events-none' : 'opacity-100',
              )}
            >
              <SignUpButton onClick={handleSignUp} />
              <LoginButton onClick={handleLogin} />
              {deviceType === OperatingSystemEnum.DESKTOP ? <DownloadAppQRButton /> : null}
            </div>
          ) : (
            <UserMenu />
          )}
        </HideWhenSiteUnderMaintenance>
        <LanguageSelect />
      </div>
    </nav>
  )
}

const DesktopNavigationContainer = () => (
  <DesktopNavigationProvider>
    <DesktopNavigation />
  </DesktopNavigationProvider>
)

export { DesktopNavigationContainer as DesktopNavigation }
