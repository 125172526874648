import Image from 'next/image'
import { useMemo } from 'react'
import { v1 as uuid } from 'uuid'

import {
  OperatingSystemEnum,
  useDeviceInformation,
} from '@electro/consumersite/src/hooks/useDeviceInformation'
import { GTM } from '../../utils/event-triggers'

const buttonData = [
  {
    id: uuid(),
    os: OperatingSystemEnum.IOS,
    href: 'https://apps.apple.com/gb/app/octopus-electric-universe/id1535444463',
    img: {
      alt: 'Download on the app store',
      src: '/images/download-app-store.png',
    },
    onClick: () => GTM.openAppleStore(),
  },
  {
    id: uuid(),
    os: OperatingSystemEnum.ANDROID,
    href: 'https://play.google.com/store/apps/details?id=energy.octopus.electricjuice.android',
    img: {
      alt: 'Download on the google play store',
      src: '/images/download-google-play.png',
    },
    onClick: () => GTM.openGooglePlayStore(),
  },
]

export const AppDownloadButton = () => {
  const { userAgent } = useDeviceInformation()

  const buttonsFilteredByUserAgent = useMemo(() => {
    const buttonHasOsCondition = (button) => userAgent?.deviceOS?.includes(button.os)
    if (buttonData.some(buttonHasOsCondition)) {
      return buttonData.filter(buttonHasOsCondition)
    }
    return buttonData
  }, [userAgent])

  return (
    <div className="inline-flex justify-around flex-col sm:flex-row items-center gap-4">
      {buttonsFilteredByUserAgent.map((button) => (
        <a
          onClick={button.onClick}
          key={button.id}
          target="blank"
          href={button.href}
          className="mb-6 mt-2"
        >
          <Image src={button.img.src} alt={button.img.alt} width="203" height="60" />
        </a>
      ))}
    </div>
  )
}
