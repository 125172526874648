import { ForwardedRef, ReactNode, TableHTMLAttributes, forwardRef, useMemo } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { TableProvider } from './TableContext'

interface Props extends TableHTMLAttributes<HTMLTableElement> {
  loading?: boolean
  loadingMessage?: ReactNode
  error?: boolean
  errorMessage?: ReactNode
  emptyTable?: boolean
  emptyTableMessage?: ReactNode
  children: ReactNode | ReactNode[]
  variant?: 'dark' | 'light'
}

const styles = {
  root: tw(
    'border-separate border-spacing-0 p-0 overflow-auto table w-full',
    'border-[2.5px] border-secondary rounded-2xl text-white',

    // TableHeader styles
    '[&_thead]:font-medium',
    '[&_th]:p-4 [&_th]:whitespace-nowrap',
    '[&_th]:lg:text-left [&_th]:text-center [&_th]:font-medium [&_th]:lg:text-[1rem] [&_th]:text-sm',
    '[&_th]:border-b-[2.5px] [&_th]:border-secondary',

    // TableCell styles
    '[&_td]:lg:p-4 [&_td]:p-2 [&_td]:whitespace-nowrap',
    '[&_td]:border-b-[2.5px] [&_td]:border-secondary',
    '[&_td]:lg:text-[1rem] [&_td]:text-sm',

    // TableBody styles
    '[&_tbody>tr:last-child>td]:border-none',
  ),
  light: tw(
    'bg-white text-base-dark border-base-dark/30',
    '[&_th]:border-base-dark/30',
    '[&_td]:border-base-dark/30',
    '[&_tr]:hover:bg-transparent',
  ),
}

export const Table = forwardRef(
  (
    {
      variant = 'dark',
      loading,
      loadingMessage = 'Loading...',
      error,
      errorMessage = 'Failed to load data',
      emptyTable,
      emptyTableMessage = 'No data',
      className,
      children,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLTableElement>,
  ) => {
    const tableProps = useMemo(
      () => ({ loading, loadingMessage, error, errorMessage, emptyTable, emptyTableMessage }),
      [loading, loadingMessage, error, errorMessage, emptyTable, emptyTableMessage],
    )

    return (
      <TableProvider {...tableProps}>
        <table
          ref={ref}
          className={tw({
            [styles.root]: true,
            [className]: !!className,
            [styles.light]: variant === 'light',
          })}
          {...rest}
        >
          {children}
        </table>
      </TableProvider>
    )
  },
)
