import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { SidebarNavIcon } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { InfoKeyIcon } from '@electro/consumersite/src/icons'
import useTranslation from 'next-translate/useTranslation'
import { KeyPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'

const styles = {
  navIcon: 'w-full p-[5px]',
}

export const KeySidebarIcon = () => (
  <SidebarNavIcon panelName={SidebarPanels.KEY}>
    <InfoKeyIcon className={styles.navIcon} />
  </SidebarNavIcon>
)

export const KeyPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer panelType={SidebarPanels.KEY} label={t('map.key.title')}>
      <KeyPanelContent />
    </SidebarPanelContainer>
  )
}
