import { ReactNode } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useDesktopNavigation } from '@electro/consumersite/src/components/TopNavigation/components/DesktopNavigation/hooks'

export const SecondaryNavContainer = ({
  index,
  children,
}: {
  index: number
  children: ReactNode
}) => {
  const { subNavIndex } = useDesktopNavigation()
  return (
    <div
      className={tw(
        'absolute w-full transition-all duration-200',
        subNavIndex === index ? 'opacity-100' : 'opacity-0 pointer-events-none',
        subNavIndex === index || subNavIndex === null ? 'transform-none' : '',
        subNavIndex > index ? '-translate-x-24' : 'translate-x-24',
      )}
    >
      {children}
    </div>
  )
}
