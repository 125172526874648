import { ReactNode } from 'react'
import { IconButton, LoadingSpinner } from '@electro/shared-ui-components'

interface DataGridActionItemProps {
  label: string
  icon?: ReactNode
  showInMenu?: boolean
  disabled?: boolean
  loading?: boolean
  onClick?: () => void
}

export const DataGridActionItem = ({
  label,
  icon,
  showInMenu,
  disabled,
  loading,
  onClick,
}: DataGridActionItemProps) => {
  const handleClick = () => onClick?.()

  if (showInMenu) {
    return (
      <div className="flex items-center">
        {loading && (
          <LoadingSpinner
            data-testid={`data-grid-action-button-${label}-loading-spinner`}
            className="w-6 h-6 mr-3"
          />
        )}

        {icon && !loading && <span className="h-6 w-6 mr-3">{icon}</span>}

        {label}
      </div>
    )
  }

  return (
    <IconButton
      disabled={disabled || loading}
      className="[&_svg]:h-5 [&_svg]:w-5 z-0"
      size="sm"
      onClick={handleClick}
      data-testid={`data-grid-action-button-${label}`}
    >
      {loading ? (
        <LoadingSpinner data-testid={`data-grid-action-button-${label}-loading-spinner`} />
      ) : (
        icon
      )}
    </IconButton>
  )
}
