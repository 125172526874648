import { ReactNode } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Alert } from '@electro/shared-ui-components'

interface DisableContentWrapperProps {
  children: ReactNode
  message?: string
  showContent?: boolean
  condition?: boolean
}

const styles = {
  message: 'mb-6',
  contentWrapper: 'pointer-events-none opacity-50 select-none',
}

export const DisableContentWrapper = ({
  message = '',
  children,
  showContent = true,
  condition = false,
}: DisableContentWrapperProps) => {
  if (!condition) {
    return children
  }

  return (
    <>
      {message && (
        <Alert className={styles.message} variant="info">
          {message}
        </Alert>
      )}
      {showContent && (
        <div
          style={{ pointerEvents: 'none' }}
          className={tw({
            [styles.contentWrapper]: true,
          })}
        >
          {children}
        </div>
      )}
    </>
  )
}
