import { useRouter } from 'next/router'
import { Transition } from '@headlessui/react'
import { HTMLAttributes, useMemo } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { RouteTitlesEnum } from '@electro/consumersite/src/components/Map/helpers/getRouteSummaryTitles'
import { useMapSidebar, useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { Button } from '@electro/shared-ui-components'
import { RouteFastestIcon, RouteShortestChargeIcon, RouteShortestIcon } from '@electro/icons/20'
import {
  RouteBoltIcon,
  RouteChargerIcon,
  RouteDistanceIcon,
  RouteWaypointIcon,
} from '@electro/icons/16'
import {
  formatKilometresToLocalisedDistance,
  formatSecondsToLocalisedDuration,
} from '@electro/shared/utils/formatters'
import {
  RouteDictionaryRouteType,
  SidebarPanels,
} from '@electro/consumersite/src/components/Map/types'

const styles = {
  container: {
    root: 'relative w-full h-min flex flex-col cursor-default mt-2',
    inactive: 'group cursor-pointer',
    hasSummaryTitle: 'mt-6',
  },
  summaryTitle: tw(
    'absolute -top-5 flex items-center gap-x-1 w-fit px-1.5 ml-4',
    'bg-secondary rounded-t-md',
    'text-sm [&>svg]:size-[18px]',
  ),
  routeDuration: {
    root: tw(
      'flex items-center justify-between w-full h-10 pt-1 px-4',
      'bg-base/90 group-hover:bg-base-dark/90',
      'border-2 border-b-0 border-secondary',
      'font-semibold rounded-t-lg',
    ),
    chevronIcon: 'w-8 h-8 -m-1',
  },
  routeSummary: {
    centreSlice:
      'z-10 !flex w-full bg-base/90 group-hover:bg-base-dark/90 border-x-2 border-secondary',
    bottomSlice: tw(
      'absolute top-8 !flex w-full h-4',
      'bg-base/90 group-hover:bg-base-dark/90',
      'rounded-b-lg border-2 border-t-0 border-secondary',
    ),
    content: {
      root: 'z-20 absolute top-11 left-3 w-11/12',
      infoRow: {
        root: 'flex justify-between mx-1 text-sm [&>h4]:font-semibold',
        description: 'flex items-center gap-x-2 [&>svg]:size-4 [&>p]:mb-0',
      },
      button: 'text-sm mt-2 py-0.5',
    },
  },
}

const RouteSummaryTitleIcons = {
  [RouteTitlesEnum.FASTEST_ROUTE]: <RouteFastestIcon />,
  [RouteTitlesEnum.SHORTEST_CHARGE]: <RouteShortestChargeIcon />,
  [RouteTitlesEnum.SHORTEST_ROUTE]: <RouteShortestIcon />,
}

interface RouteOverviewSelectRouteButtonProps extends HTMLAttributes<HTMLButtonElement> {
  route: RouteDictionaryRouteType
  hideButton?: boolean
}

export const RouteOverviewSelectRouteButton = ({
  route,
  className,
  hideButton = false,
}: RouteOverviewSelectRouteButtonProps) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const [, { showPanel }] = useMapSidebar()
  const [{ activeRouteID }, { updateActiveRouteID }] = useRoutePlanner()

  const isActive = useMemo(() => route.id === activeRouteID, [route.id, activeRouteID])

  /** Format the summary data into readable stats, memoised to save re-calculating it on switching routes */
  const summaryInfo = useMemo(() => {
    const { driveDurationSeconds, distanceMetres, energyConsumedKWh, chargeDurationSeconds } =
      route.summary

    const driveDuration = formatSecondsToLocalisedDuration(driveDurationSeconds, { locale })
    const driveDistance = formatKilometresToLocalisedDistance(distanceMetres / 1000, { locale })
    const energyConsumed = `${Math.round(energyConsumedKWh)} kWh`
    const chargeDuration = formatSecondsToLocalisedDuration(chargeDurationSeconds, { locale })

    return { driveDuration, driveDistance, energyConsumed, chargeDuration }
  }, [route.summary, locale])

  /** Switches the currently active route, if not active already */
  const updateActiveRoute = () => {
    if (!isActive) updateActiveRouteID(route.id)
  }

  return (
    <div
      tabIndex={0}
      role="button" // Prevents accessibility warnings about buttons within buttons
      onClick={updateActiveRoute}
      onKeyDown={updateActiveRoute}
      className={tw({
        [styles.container.root]: true,
        [styles.container.inactive]: !isActive,
        [styles.container.hasSummaryTitle]: Boolean(route.summary.title),
        [className]: Boolean(className),
      })}
    >
      {route.summary.title ? (
        <div className={styles.summaryTitle}>
          {RouteSummaryTitleIcons[route.summary.title]} {t(route.summary.title)}
        </div>
      ) : null}

      <div className={styles.routeDuration.root}>
        <h4>{summaryInfo.driveDuration}</h4>

        {!isActive ? <ChevronDownIcon className={styles.routeDuration.chevronIcon} /> : null}
      </div>

      <Transition
        appear
        show={isActive}
        className={styles.routeSummary.centreSlice}
        enter="transition-[height] origin-top linear duration-500"
        enterFrom="h-0"
        enterTo={hideButton ? 'h-22' : 'h-32'}
        leave="transition-[height] origin-top linear duration-500"
        leaveFrom={hideButton ? 'h-22' : 'h-32'}
        leaveTo="h-0"
      />

      <Transition
        appear
        show={isActive}
        unmount={false}
        className={styles.routeSummary.bottomSlice}
        enter="transition-transform origin-top linear duration-500 delay-100" // The delay is required to prevent a weird gap forming during the animation
        enterFrom="translate-y-0"
        enterTo={hideButton ? 'translate-y-[5.5rem]' : 'translate-y-[8rem]'}
        leave="transition-transform origin-top linear duration-500"
        leaveFrom={hideButton ? 'translate-y-[5.5rem]' : 'translate-y-[8rem]'}
        leaveTo="translate-y-0"
      />

      <Transition
        show={isActive}
        unmount={false}
        className={styles.routeSummary.content.root}
        enter="transition-opacity duration-200 delay-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <span className={styles.routeSummary.content.infoRow.root}>
          <div className={styles.routeSummary.content.infoRow.description}>
            <RouteDistanceIcon />
            <p>{t('map.route_overview.summary.distance')}</p>
          </div>
          <h4>{summaryInfo.driveDistance}</h4>
        </span>

        <span className={styles.routeSummary.content.infoRow.root}>
          <div className={styles.routeSummary.content.infoRow.description}>
            <RouteBoltIcon />
            <p>{t('map.route_overview.summary.consumption')}</p>
          </div>
          <h4>{summaryInfo.energyConsumed}</h4>
        </span>

        <span className={styles.routeSummary.content.infoRow.root}>
          <div className={styles.routeSummary.content.infoRow.description}>
            <RouteChargerIcon />
            <p>{t('map.route_overview.summary.charge_duration')}</p>
          </div>
          <h4>{summaryInfo.chargeDuration}</h4>
        </span>

        <span className={styles.routeSummary.content.infoRow.root}>
          <div className={styles.routeSummary.content.infoRow.description}>
            <RouteWaypointIcon />
            <p>{t('map.route_overview.summary.charge_stops')}</p>
          </div>
          <h4>
            {route.summary.chargingStopsCount} {t('map.route_overview.summary.stops')}
          </h4>
        </span>

        {!hideButton ? (
          <Button
            fullWidth
            size="2xs"
            className={styles.routeSummary.content.button}
            onClick={() => showPanel(SidebarPanels.ROUTE_BREAKDOWN)}
          >
            {t('map.route_overview.summary.view_details')}
          </Button>
        ) : null}
      </Transition>
    </div>
  )
}
