import * as React from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { CheckMarkSvg } from './index'

type changeValue = string | number

export interface CheckboxProps extends React.ComponentPropsWithoutRef<'input'> {
  onCheckboxChange: (value?: changeValue) => typeof value | any // TODO support formik types
  className?: string
  fullWidth?: boolean
  disabled?: boolean
  label?: string | React.ReactNode
  name?: string
  value?: changeValue
  checked?: boolean
  errorMessage?: string
}

export const Checkbox = ({
  disabled = false,
  name,
  checked,
  onCheckboxChange,
  value,
  label,
  errorMessage,
  ...rest
}: CheckboxProps) => {
  const styles = {
    root: 'mt-2 relative',
    rootInput: tw(
      'opacity-0 absolute h-6 w-4 appearance-none border-black ',
      'disabled:cursor-not-allowed disabled:opacity-50',
    ),
    displayInput: tw(
      'w-4 h-4 flex shrink-0',
      'bg-transparent border rounded-sm justify-center items-center mr-2 border-tertiary',
    ),
    label: 'select-none flex items-center text-sm text-white',
    errorMessage: 'text-sm text-action-danger border-action-danger mt-2',
  }

  function changeHandler() {
    onCheckboxChange(value)
  }

  return (
    <div className={styles.root}>
      <label htmlFor={name} className={styles.label}>
        <input
          aria-label={name}
          onChange={changeHandler}
          disabled={disabled}
          type="checkbox"
          id={name}
          name={name}
          value={value}
          className={styles.rootInput}
          checked={checked}
          data-hj-allow
          {...rest}
        />
        <div className={styles.displayInput}>
          <CheckMarkSvg />
        </div>
        {label}
      </label>
      {!!errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
