import { BatteryIcon } from '@electro/consumersite/src/icons'
import { useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import RouteWaypointIcon from '@electro/consumersite/public/images/markers/route-waypoint.svg'

const styles = {
  root: 'relative bg-base rounded-lg p-2',
  dottedLine: 'absolute w-0.5 h-full py-6 -mt-1 ml-[11px] vertical-dashed-line bg-clip-content',
  stop: {
    root: 'flex items-center gap-x-1.5 mb-2 last:mb-1',
    icon: 'shrink-0 z-10 py-px',
    address: 'mb-0 text-sm font-semibold line-clamp-2 w-full',
    battery: {
      root: 'min-w-7 ml-auto mr-1 flex flex-col items-center text-xs font-mono font-semibold',
      icon: 'w-6 h-6 -mb-1.5 ml-0.5',
    },
  },
}

export const RouteOverviewWaypoints = () => {
  const [{ routeDetails }] = useRoutePlanner()

  return (
    <div className={styles.root}>
      <div className={styles.dottedLine} />

      <div>
        {routeDetails?.destinations?.map(({ address }, index) => (
          <span className={styles.stop.root} key={address}>
            <RouteWaypointIcon className={styles.stop.icon} />

            <p className={styles.stop.address}>{address}</p>

            <span className={styles.stop.battery.root}>
              {index === 0 ? (
                <>
                  <BatteryIcon
                    percentage={routeDetails.startCharge}
                    className={styles.stop.battery.icon}
                  />
                  {routeDetails.startCharge}%
                </>
              ) : null}

              {index === routeDetails.destinations.length - 1 ? (
                <>
                  <BatteryIcon
                    percentage={routeDetails.endCharge}
                    className={styles.stop.battery.icon}
                  />
                  {routeDetails.endCharge}%
                </>
              ) : null}
            </span>
          </span>
        ))}
      </div>
    </div>
  )
}
