// These constants define standard options for various map controls.
// Specifically, they're useful in orienting the camera for map animations.

export const SPEED = 0.5

export const LOCATION_MAX_ZOOM = 12
export const COUNTRY_MAX_ZOOM = 5

const DEFAULT_PADDING_MOBILE = { top: 220, left: 70, right: 70, bottom: 70 }
const DEFAULT_PADDING_DESKTOP = { top: 180, left: 70, right: 70, bottom: 70 }

/** The padding to apply at various zoom levels, impacted by other visible components */
export const PADDING = {
  sidebarAndDrawer: {
    DEFAULT: DEFAULT_PADDING_MOBILE,
    sm: DEFAULT_PADDING_MOBILE,
    md: { ...DEFAULT_PADDING_DESKTOP, left: 470, right: 470 },
    lg: { ...DEFAULT_PADDING_DESKTOP, left: 470, right: 470 },
    xl: { ...DEFAULT_PADDING_DESKTOP, left: 470, right: 470 },
    '2xl': DEFAULT_PADDING_DESKTOP,
  },
  sidebarOnly: {
    DEFAULT: DEFAULT_PADDING_MOBILE,
    sm: DEFAULT_PADDING_MOBILE,
    md: { ...DEFAULT_PADDING_DESKTOP, left: 470 },
    lg: { ...DEFAULT_PADDING_DESKTOP, left: 470 },
    xl: { ...DEFAULT_PADDING_DESKTOP, left: 470 },
    '2xl': DEFAULT_PADDING_DESKTOP,
  },
  drawerOnly: {
    DEFAULT: DEFAULT_PADDING_MOBILE,
    sm: DEFAULT_PADDING_MOBILE,
    md: { ...DEFAULT_PADDING_DESKTOP, right: 470 },
    lg: { ...DEFAULT_PADDING_DESKTOP, right: 470 },
    xl: { ...DEFAULT_PADDING_DESKTOP, right: 470 },
    '2xl': DEFAULT_PADDING_DESKTOP,
  },
  none: {
    DEFAULT: DEFAULT_PADDING_MOBILE,
    sm: DEFAULT_PADDING_MOBILE,
    md: DEFAULT_PADDING_DESKTOP,
    lg: DEFAULT_PADDING_DESKTOP,
    xl: DEFAULT_PADDING_DESKTOP,
    '2xl': DEFAULT_PADDING_DESKTOP,
  },
}
