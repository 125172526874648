import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  useRef,
} from 'react'
import { useMap } from 'react-map-gl'
import * as Sentry from '@sentry/nextjs'
import { useUpdateEffect } from 'react-use'
import { testEnv } from '@electro/shared/utils/isEnv'
import { LngLatBoundsLike, LngLatLike } from 'mapbox-gl'
import useTranslation from 'next-translate/useTranslation'
import { useToastNotification } from '@electro/shared-ui-components'
import { bbox, featureCollection, point, Position } from '@turf/turf'
import { RouteFormFieldsType } from '@electro/consumersite/src/components/Map/constants'
import { useMapSidebar } from '@electro/consumersite/src/components/Map/hooks'
import { getErrorMessage } from '@electro/shared/utils/getErrorMessage'
import {
  buildRoutesDictionary,
  getCameraAnimationOptions,
} from '@electro/consumersite/src/components/Map/helpers'
import {
  RoutePlanResponseFragment,
  SavedRouteMetadata,
  useRefreshRoutePlanMutation,
  useRoutePlanMutation,
} from '@electro/consumersite/generated/graphql'
import {
  RouteDictionaryType,
  RouteStatus,
  SidebarPanels,
} from '@electro/consumersite/src/components/Map/types'

const { ROUTE_OVERVIEW, ROUTE_BREAKDOWN, ALL_PANELS_HIDDEN } = SidebarPanels

/** Sidebar panels we permit to show route planner results on.
 *  Leaving any of these will clear the results from state. */
const ALLOWED_PANELS = [ROUTE_OVERVIEW, ROUTE_BREAKDOWN, ALL_PANELS_HIDDEN]

const ROUTE_PLAN_DEFAULT_CONFIG = {
  pathSteps: true,
  allowBorder: true,
  allowFerry: true,
  allowMotorway: true,
  findAlts: true,
}

interface SubmitRoutePlanType {
  formFields?: RouteFormFieldsType
  savedPlan?: SavedRouteMetadata
}

interface State {
  routeDetails: RouteDictionaryType
  routeLoading: boolean
  activeRouteID: number
}

interface Handlers {
  setRouteDetails: Dispatch<SetStateAction<RouteDictionaryType>>
  submitRoutePlan: ({ formFields, savedPlan }: SubmitRoutePlanType) => Promise<void>
  clearRoutePlan: () => void
  updateActiveRouteID: Dispatch<SetStateAction<number>>
}

type UseRoutePlanner = [state: State, handlers: Handlers]
const UseRoutePlannerContext = createContext<UseRoutePlanner>(null)

function useRoutePlannerProvider(): UseRoutePlanner {
  const { baseMap } = useMap()
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()
  const [{ visiblePanel }, { showPanel }] = useMapSidebar()

  const [routePlanMutation] = useRoutePlanMutation()
  const [refreshRoutePlanMutation] = useRefreshRoutePlanMutation()

  const [routeDetails, setRouteDetails] = useState<RouteDictionaryType>()
  const [routeLoading, setRouteLoading] = useState<boolean>(false)
  const [activeRouteID, updateActiveRouteID] = useState<number>(0)

  const visiblePanelRef = useRef<SidebarPanels>(visiblePanel as SidebarPanels)

  /** Reset the route planner to default state */
  const clearRoutePlan = useCallback(() => {
    setRouteDetails(undefined)
    setRouteLoading(false)
    updateActiveRouteID(0)
  }, [])

  /** Watch for changes to the visible panel, and clear the route if they navigate away */
  useUpdateEffect(() => {
    visiblePanelRef.current = visiblePanel as SidebarPanels

    if (!ALLOWED_PANELS.includes(visiblePanel as SidebarPanels) && !testEnv) {
      setTimeout(() => clearRoutePlan(), 500)
    }
  }, [visiblePanel, clearRoutePlan])

  /** Sends the route plan request, formats the response and stores it in state */
  const submitRoutePlan = useCallback(
    async ({ formFields, savedPlan }: SubmitRoutePlanType) => {
      if (!(formFields || savedPlan)) return

      clearRoutePlan()
      setRouteLoading(true)

      try {
        let routePlanResponse: RoutePlanResponseFragment

        if (formFields) {
          const { activeVehicle, destinations, startCharge, endCharge } = formFields
          setRouteDetails({ vehicle: activeVehicle.vehicle, destinations, startCharge, endCharge })
          setTimeout(() => showPanel(SidebarPanels.ROUTE_OVERVIEW), 500)

          const { data } = await routePlanMutation({
            variables: {
              routeParams: {
                vehicleId: activeVehicle.vehicle.pk,
                destinations,
                initialSocPerc: startCharge,
                arrivalSocPerc: endCharge,
                excludeNonEjnLocations: formFields.electroverseOnly,
                allowToll: !formFields.avoidTolls,
                ...ROUTE_PLAN_DEFAULT_CONFIG,
              },
            },
          })

          routePlanResponse = data?.routePlan?.planResponse
        } else if (savedPlan) {
          const {
            planUuid: uuid,
            vehicle,
            waypointNames,
            initialSocPerc: startCharge,
            arrivalSocPerc: endCharge,
          } = savedPlan
          const destinations = waypointNames.map((name) => ({ address: name }))

          setRouteDetails({ uuid, vehicle, destinations, startCharge, endCharge })
          setTimeout(() => showPanel(SidebarPanels.ROUTE_OVERVIEW), 500)

          const { data } = await refreshRoutePlanMutation({
            variables: { routeParams: { planUuid: uuid } },
          })

          const lngLatArray = data.refreshRoutePlan.planResponse.result.routes.flatMap(
            ({ steps }) => steps.map(({ lat, lon }) => [lon, lat] as LngLatLike),
          )

          if (lngLatArray.length > 1) {
            // Move the map to fit the route bounds
            const turfPointArray = lngLatArray.map((lngLat) => point(lngLat as Position))
            const routeFeatureCollection = featureCollection(turfPointArray)
            const routeBBox = bbox(routeFeatureCollection) as LngLatBoundsLike

            baseMap?.fitBounds(
              routeBBox,
              getCameraAnimationOptions({ type: 'fitBounds', isSidebarOpen: true }),
            )
          }

          routePlanResponse = data?.refreshRoutePlan?.planResponse
        }

        if (!ALLOWED_PANELS.includes(visiblePanelRef.current) && !testEnv) {
          clearRoutePlan()
          return
        }

        if (routePlanResponse.status === 'ok' && routePlanResponse.result?.routes.length > 0) {
          const routeDictionary = {
            uuid: routePlanResponse.result.planUuid,
            status: routePlanResponse.status as RouteStatus,
            routes: buildRoutesDictionary(routePlanResponse.result.routes),
          }

          setRouteDetails((existingDetails) => ({ ...existingDetails, ...routeDictionary }))
          setRouteLoading(false)
        } else {
          clearRoutePlan()

          // TODO: Map the errors to something localised and user friendly
          showToastNotification({
            heading: t('utility.something_went_wrong.title'),
            body: routePlanResponse.status,
            variant: 'error',
          })
          Sentry.captureMessage(
            `RoutePlanner, no route returned. Status => ${routePlanResponse.status}`,
            (scope) => scope.setExtras(formFields as any),
          )
        }
      } catch (error) {
        clearRoutePlan()
        const errorMessage = getErrorMessage(error)

        showToastNotification({
          heading: t('utility.something_went_wrong.title'),
          body: errorMessage,
          variant: 'error',
        })
        Sentry.captureMessage(
          `RoutePlanner, no route returned. Error => ${errorMessage}`,
          (scope) => scope.setExtras(formFields as any),
        )
      }
    },
    [
      t,
      clearRoutePlan,
      setRouteDetails,
      showPanel,
      routePlanMutation,
      refreshRoutePlanMutation,
      baseMap,
      showToastNotification,
    ],
  )

  const state = useMemo(
    () => ({ routeDetails, routeLoading, activeRouteID }),
    [routeDetails, routeLoading, activeRouteID],
  )

  const handlers = useMemo(
    () => ({ setRouteDetails, submitRoutePlan, clearRoutePlan, updateActiveRouteID }),
    [setRouteDetails, submitRoutePlan, clearRoutePlan, updateActiveRouteID],
  )

  return [state, handlers]
}

export const UseRoutePlannerProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useRoutePlannerProvider()
  return <UseRoutePlannerContext.Provider value={ctx}>{children}</UseRoutePlannerContext.Provider>
}

export const useRoutePlanner = (): UseRoutePlanner => {
  const context = useContext(UseRoutePlannerContext)
  if (!context)
    throw new Error('useRoutePlanner() cannot be used outside of <UseRoutePlannerProvider/>')
  return context
}
