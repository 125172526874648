import { DrawerContainer as Drawer } from '@electro/consumersite/src/components/Map/components/MapDrawer/components/DrawerContainer'
import { LocationDetailsPanel } from '@electro/consumersite/src/components/Map/components/MapDrawer/panels'

const styles = {
  root: '',
}

export const MapDrawer = () => (
  <Drawer className={styles.root}>
    <LocationDetailsPanel />
  </Drawer>
)
