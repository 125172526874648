import { useState, useEffect } from 'react'
import UAParser from 'ua-parser-js'

export enum OperatingSystemEnum {
  IOS = 'iOS',
  ANDROID = 'Android',
  DESKTOP = 'Desktop',
}

type UserAgent = {
  deviceBrowser: string //  E.g. "Chrome 115.0.0.0 Blink"
  deviceOS: string // E.g. "Mac OS"
  deviceOSAndVersion: string // E.g. "Mac OS 10.15.7"
  deviceName: string // E.g. "Apple Macintosh undefined"
}

interface DeviceInformation {
  deviceType: OperatingSystemEnum
  userAgent: UserAgent
}

type UseDeviceInformation = DeviceInformation

/**
 * Unfortunately us-parser-js does not support 'desktop' as a possible device type. 
  See https://github.com/faisalman/ua-parser-js/issues/182 for a discussion.
  Identifies iOS or Android devices specifically. Assumes any other device OS 
  is a desktop computer, rather than a smart appliance.
 */
function matchUserAgentToOperatingSystemEnum(userAgentString): OperatingSystemEnum {
  const enumMemberIndex = Object.values(OperatingSystemEnum).indexOf(userAgentString)
  return enumMemberIndex > 0 ? OperatingSystemEnum[enumMemberIndex] : OperatingSystemEnum.DESKTOP
}

export function useDeviceInformation(): UseDeviceInformation {
  const [deviceType, setDeviceType] = useState<OperatingSystemEnum>()
  const [userAgent, setUserAgent] = useState<UserAgent>()

  useEffect(() => {
    const ua = new UAParser(window.navigator.userAgent)

    setDeviceType(matchUserAgentToOperatingSystemEnum(ua.getOS().name))

    const deviceBrowser = `${ua.getBrowser().name} ${ua.getBrowser().version} ${
      ua.getEngine().name
    }`
    const deviceOS = `${ua.getOS().name}`
    const deviceOSAndVersion = `${ua.getOS().name} ${ua.getOS().version}`
    const deviceName = `${ua.getDevice().vendor} ${ua.getDevice().model} ${ua.getDevice().type}`

    setUserAgent({ deviceBrowser, deviceOS, deviceOSAndVersion, deviceName })
  }, [])
  return { deviceType, userAgent }
}
