import { HTMLAttributes } from 'react'
import { Transition } from '@headlessui/react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useMapDrawer } from '@electro/consumersite/src/components/Map/hooks'
import { DrawerPanelsType } from '@electro/consumersite/src/components/Map/types'

const styles = {
  root: tw(
    'z-30 absolute right-0 mt-1 xs:mr-1.5 lg:mt-4 lg:mr-0',
    'w-full max-w-sm bg-base rounded-xl border-2 border-secondary overflow-hidden',
    'h-[calc(100dvh-8rem)] xs:h-[calc(100dvh-10rem)] md:h-[calc(100dvh-12rem)] lg:h-[calc(100dvh-11rem)]',
  ),
  content: {
    root: 'w-full max-w-sm p-2 h-[calc(100dvh-10rem)] xs:h-[calc(100dvh-12rem)] md:h-[calc(100dvh-14rem)] lg:h-[calc(100dvh-13rem)]',
  },
}

interface DrawerPanelContainerProps extends HTMLAttributes<HTMLDivElement> {
  panelType?: DrawerPanelsType
}

export const DrawerPanelContainer = ({
  panelType,
  className,
  children,
}: DrawerPanelContainerProps) => {
  const [{ visibleDrawer }] = useMapDrawer()

  return (
    <Transition
      unmount={false}
      show={visibleDrawer === panelType}
      className={styles.root}
      enter="ease-in-out duration-500 delay-100"
      enterFrom="origin-right translate-x-104"
      enterTo="origin-right translate-x-0"
      leave="ease-in-out duration-500 delay-100"
      leaveFrom="origin-right translate-x-0"
      leaveTo="origin-right translate-x-104"
    >
      <Transition
        show={visibleDrawer === panelType}
        className={tw({
          [styles.content.root]: true,
          [className]: !!className,
        })}
        enter="transition-opacity ease-in-out duration-300 delay-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {children}
      </Transition>
    </Transition>
  )
}
