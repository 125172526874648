import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { SavedRoutesPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import useTranslation from 'next-translate/useTranslation'

export const SavedRoutesPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.SAVED_ROUTES}
      label={t('map.saved_routes.title')}
    >
      <SavedRoutesPanelContent />
    </SidebarPanelContainer>
  )
}
