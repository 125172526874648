import { useEffect, useState } from 'react'

// Keeps track of already loaded scripts to avoid redundant loading
const cachedScripts: any = []

/**
 *  useScript hook to load an external script dynamically
 */
export const useScript = (src: string) => {
  // State to track whether the script is loaded or if there's an error
  const [state, setState] = useState({
    loaded: false,
    error: false,
  })

  useEffect(() => {
    // If the script has already been loaded (exists in cache), update state to loaded
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      })
      return () => {}
    }

    cachedScripts.push(src)

    // Create a new script element
    const script = document.createElement('script')
    script.src = src
    script.async = true

    const onScriptLoad = () => {
      setState({
        loaded: true,
        error: false,
      })
    }

    // Handle script loading error and remove it from the DOM and cache
    const onScriptError = () => {
      const index = cachedScripts.indexOf(src)
      if (index >= 0) cachedScripts.splice(index, 1)
      script.remove()

      setState({
        loaded: true,
        error: true,
      })
    }

    script.addEventListener('load', onScriptLoad)
    script.addEventListener('error', onScriptError)

    document.body.appendChild(script)

    // Cleanup function to remove event listeners when the component unmounts
    return () => {
      script.removeEventListener('load', onScriptLoad)
      script.removeEventListener('error', onScriptError)
    }
  }, [src])

  return { loaded: state.loaded, error: state.error }
}
