export const FreeParkingIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 23 23">
    <path
      d="M19.825 0H3.19922C1.42987 0 0 1.42987 0 3.19922V19.8008C0 21.57 1.42987 23 3.19922 23H19.8008C21.57 23 23 21.5701 23 19.8008L22.9998 3.19922C23.024 1.42987 21.5942 0 19.8248 0H19.825ZM12.0211 13.4509H9.79137V18.904H7.22224V4.1201H12.0209C14.5899 4.1201 16.6985 6.20438 16.6985 8.79762C16.6986 11.3909 14.5902 13.4509 12.0214 13.4509H12.0211Z"
      fill="currentColor"
    />
    <path
      d="M12.0211 6.66484H9.79138V10.8819H12.0211C13.1844 10.8819 14.1296 9.93672 14.1296 8.77344C14.1296 7.60999 13.1844 6.66479 12.0211 6.66479V6.66484Z"
      fill="currentColor"
    />
  </svg>
)
