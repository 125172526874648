import { Fragment, useCallback, useState, useRef } from 'react'
import { ApolloError } from '@apollo/client'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, A11y, Scrollbar, Keyboard } from 'swiper'
import { Transition, Dialog as DialogHeadless } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useDraggable } from 'react-use-draggable-scroll'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import { IconButton, LazyImage, Typography } from '@electro/shared-ui-components'

import { ChargingLocationMetadataType, Query } from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'

interface LocationImageGalleryProps {
  chargingLocation: ChargingLocationMetadataType
  data: Pick<Query, 'locationPhotos'>
  error: ApolloError
}

const styles = {
  thumbnails: {
    root: 'flex w-full overflow-x-auto gap-3 pt-1 pb-3 pl-0.5 pr-0.5',
    imageButton: 'w-28 h-28 relative flex-shrink-0 rounded-md hover:ring-2 hover:ring-secondary',
  },
  modal: {
    overlay: 'bg-base-dark bg-opacity-95 h-screen w-screen absolute z-40 top-0 left-0 pt-4 px-4',
    panel: 'top-0 left-0 absolute w-screen h-screen z-50 sm:py-4',
  },
  gallery: {
    slide: 'w-full h-[calc(93vh-theme(spacing.8))] relative mt-12',
    closeButton: 'absolute z-50 p-2 top-1.5 right-2',
  },
  galleryBanner: {
    root: 'ej-gradient-full shadow-md dark p-1 fixed top-0 w-full z-10 opacity-95',
    text: 'text-base lg:text-xl text-center my-1',
  },
}

export const LocationImageGallery = ({
  chargingLocation,
  data,
  error,
}: LocationImageGalleryProps) => {
  const { t } = useTranslation('common')
  const [galleryModalOpen, setGalleryModalOpen] = useState<boolean>(false)
  const [initialSlide, setInitialSlide] = useState<number>(0)
  const closeButtonRef = useRef(null)
  const galleryRef = useRef(null)
  const { events: draggableEvents } = useDraggable(galleryRef, {
    applyRubberBandEffect: true,
  })

  const handleClickPhoto = useCallback(
    (index: number) => () => {
      setInitialSlide(index)
      setGalleryModalOpen(true)
    },
    [],
  )

  const handleCloseGalleryModal = useCallback(() => setGalleryModalOpen(false), [])

  /**
   * Silently failing on fetch so that we don't trigger any scary error messages.
   * If only the images fail to load the user may still be able to access the
   * rest of the location info.
   */
  if (error) return null
  return (
    <>
      <div
        data-testid="location-gallery-thumbnails"
        className={styles.thumbnails.root}
        ref={galleryRef}
        {...draggableEvents}
      >
        {data?.locationPhotos?.map((locationPhoto, index) => (
          <button
            key={locationPhoto.pk}
            onClick={handleClickPhoto(index)}
            className={styles.thumbnails.imageButton}
            data-testid="location-gallery-thumbnail-button"
          >
            <LazyImage
              className="object-cover"
              fill
              src={locationPhoto.photo}
              alt={`image ${index + 1}/${data.locationPhotos.length} of ${
                chargingLocation.operator.name
              } charge point at ${chargingLocation.address} ${chargingLocation.postalCode}`}
            />
          </button>
        ))}
      </div>
      <Transition appear show={galleryModalOpen}>
        <DialogHeadless initialFocus={closeButtonRef} as="div" onClose={handleCloseGalleryModal}>
          <div data-testid="location-gallery-modal">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogHeadless.Overlay
                data-testid="location-gallery-modal-overlay"
                className={styles.modal.overlay}
              />
            </Transition.Child>
            <Transition.Child
              as="div"
              enter="ease-out duration-0"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <DialogHeadless.Panel className={styles.modal.panel}>
                <div className={styles.galleryBanner.root}>
                  <Typography as="h1" className={styles.galleryBanner.text}>
                    {t('map.photo_gallery.submit_photos')} <br className="block lg:hidden" />
                    {t('map.photo_gallery.photo_rewards')}
                  </Typography>
                </div>
                <Swiper
                  modules={[Navigation, Pagination, A11y, Scrollbar, Keyboard]}
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation
                  pagination={{ clickable: true }}
                  scrollbar={{ draggable: true }}
                  keyboard={{ enabled: true }}
                  initialSlide={initialSlide}
                >
                  {data?.locationPhotos?.map((locationPhoto, index) => (
                    <SwiperSlide key={locationPhoto.pk}>
                      <div
                        data-testid="location-gallery-modal-slide"
                        className={styles.gallery.slide}
                      >
                        <LazyImage
                          fill
                          src={locationPhoto.photo}
                          className="object-contain"
                          alt={`Full screen image of ${index + 1}/${
                            data.locationPhotos.length
                          } of ${chargingLocation.operator.name} charge point at ${
                            chargingLocation.address
                          } ${chargingLocation.postalCode}`}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </DialogHeadless.Panel>
              <IconButton
                data-testid="location-gallery-modal-close-button"
                aria-label="close image gallery"
                ref={closeButtonRef}
                tabIndex={0}
                onClick={() => setGalleryModalOpen(false)}
                className={styles.gallery.closeButton}
              >
                <XMarkIcon className="w-10 h-10 scroll" />
              </IconButton>
            </Transition.Child>
          </div>
        </DialogHeadless>
      </Transition>
    </>
  )
}
