import {
  removeAccessTokenFromLocalStorage,
  removeAccountIdFromLocalStorage,
  removeRefreshTokenFromLocalStorage,
} from '@electro/shared/utils/asynclLocalStorage'
import { client } from '@electro/consumersite/src/client/client'

interface Args {
  /**
   * Query params to append to the URL when reloading the page.
   */
  urlParams?: Record<string, string>
}

/**
 * Clears all tokens from local storage and memory and reloads the page.
 * We are reloading the users current page because we want to remove the session,
 * which is set to memory in react. We can't do that outside of the react
 * lifecycle for example in the apollo client.
 */
export const logoutAndReload = async ({ urlParams }: Args = {}) => {
  await removeRefreshTokenFromLocalStorage()
  await removeAccessTokenFromLocalStorage()
  await removeAccountIdFromLocalStorage()

  // https://www.apollographql.com/docs/react/caching/advanced-topics/#resetting-the-cache
  await client.clearStore()
  if (urlParams) {
    const url = new URL(window.location.href)
    Object.keys(urlParams).forEach((key) => {
      url.searchParams.set(key, urlParams[key])
    })

    /**
     * Using window.location.href instead of Router.reload()/Router.push()
     * because we want to reload the page with the new query params to
     * clear the session.
     */
    window.location.href = url.toString()
    return
  }
  window.location.reload()
}
