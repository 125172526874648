import Image from 'next/image'
import { useRouter } from 'next/router'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { Button, ButtonProps } from '@electro/shared-ui-components'
import { VehicleType } from '@electro/consumersite/generated/graphql'
import { PlusCircleIcon, EyeSlashIcon } from '@heroicons/react/16/solid'
import { formatKilometresToLocalisedDistance } from '@electro/shared/utils/formatters'
import { EVBatteryIcon, EVMaxChargeSpeedIcon, EVRangeIcon } from '@electro/icons/16'
import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid'
import { MyEVsIcon } from '@electro/consumersite/src/icons'

const styles = {
  button: {
    root: 'flex flex-row justify-between shrink-0 py-1 px-4 [&>span]:-ml-4 rounded-lg hover:bg-secondary/10',
    showInfo: 'py-2 px-3 bg-base',
  },
  vehicle: {
    root: 'flex gap-x-2 items-center text-xs text-left leading-4',
    image: {
      root: 'w-10',
      noVehicle: 'hidden',
    },
    text: {
      root: 'w-48 [&>*]:truncate [&>p]:mb-0',
      showVehicleStats: 'w-24 2xs:w-32',
      title: 'text-primary-light font-semibold',
    },
  },
  icon: {
    standard: 'w-6 text-white/90',
    plusIcon: 'absolute w-3 text-primary top-2 right-3',
  },
  vehicleStats: {
    root: 'flex gap-x-2 w-20',
    lists: {
      root: 'flex flex-col justify-evenly items-start [&>svg]:size-4',
      evBatteryIcon: 'ml-px',
      text: 'text-xs font-mono [&>p]:mb-0',
    },
  },
}

interface MyEVsVehicleButtonProps extends ButtonProps {
  vehicle: VehicleType
  icon?: 'arrowsRightLeft' | 'eyeSlash'
}

export const MyEVsVehicleButton = ({
  vehicle,
  icon,
  className,
  ...props
}: MyEVsVehicleButtonProps) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')

  let title = t('map.my_evs.no_vehicles.none_selected')
  let subtext = t('map.my_evs.no_vehicles.click_here_to_manage')
  const vehicleStats = { battery: undefined, range: undefined, maxChargeSpeed: undefined }

  if (vehicle?.vehicleMake) {
    title = `${vehicle?.vehicleMake} ${vehicle?.vehicleModel}`
    subtext = vehicle?.vehicleModelVersion

    const { rangeReal, fastchargePowerMax, batteryCapacityFull } = vehicle

    if (rangeReal) {
      const localisedRange = formatKilometresToLocalisedDistance(rangeReal, { locale })
      vehicleStats.range = localisedRange.padStart(6, '⠀')
    }
    if (fastchargePowerMax) {
      vehicleStats.maxChargeSpeed = `${fastchargePowerMax.toString().split('.')[0].padStart(3, '⠀')} kW`
    }
    if (batteryCapacityFull) {
      vehicleStats.battery = `${batteryCapacityFull.toString().split('.')[0].padStart(3, '⠀')} kWh`
    }
  }

  return (
    <Button
      {...props}
      fullWidth
      variant="outline"
      className={tw({
        [styles.button.root]: true,
        [styles.button.showInfo]: !icon,
        [className]: !!className,
      })}
    >
      <div className={styles.vehicle.root}>
        <Image
          src="/images/electric-car.svg"
          alt="Electric Car Illustration"
          className={tw({
            [styles.vehicle.image.root]: true,
            [styles.vehicle.image.noVehicle]: !vehicle,
          })}
          height={30}
          width={63}
        />

        <div
          className={tw({
            [styles.vehicle.text.root]: true,
            [styles.vehicle.text.showVehicleStats]: !icon && vehicle,
          })}
        >
          <h4 className={styles.vehicle.text.title}>{title}</h4>
          <p>{subtext}</p>
        </div>
      </div>

      {/* User has no vehicle. Display 'add a vehicle' icon */}
      {!vehicle ? (
        <span>
          <MyEVsIcon className={styles.icon.standard} />
          <PlusCircleIcon className={styles.icon.plusIcon} />
        </span>
      ) : null}

      {/* Action button icon indicators (e.g. swap vehicle, hidden, etc.) */}
      {icon && vehicle ? (
        <>
          {icon === 'arrowsRightLeft' && <ArrowsRightLeftIcon className={styles.icon.standard} />}
          {icon === 'eyeSlash' && <EyeSlashIcon className={styles.icon.standard} />}
        </>
      ) : null}

      {/* Vehicle stats */}
      {!icon && vehicle ? (
        <div className={styles.vehicleStats.root}>
          <div className={styles.vehicleStats.lists.root}>
            <EVRangeIcon />
            <EVMaxChargeSpeedIcon />
            <EVBatteryIcon className={styles.vehicleStats.lists.evBatteryIcon} />
          </div>

          <div className={tw(styles.vehicleStats.lists.root, styles.vehicleStats.lists.text)}>
            <p>{vehicleStats.range}</p>
            <p>{vehicleStats.maxChargeSpeed}</p>
            <p>{vehicleStats.battery}</p>
          </div>
        </div>
      ) : null}
    </Button>
  )
}
