import { useCallback, useMemo, createContext, useContext, ReactNode, useState } from 'react'
import { FormikErrors, useFormik } from 'formik'
import { LngLatBoundsLike, LngLatLike, useMap } from 'react-map-gl'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'
import { useMarkers, useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import {
  PlaceDetails,
  getCameraAnimationOptions,
} from '@electro/consumersite/src/components/Map/helpers'
import {
  INITIAL_VALUES,
  RouteFormFieldsType,
  RouteFormFieldsTypeKeys,
  RouteFormFieldsTypeValues,
  VALIDATION_SCHEMA,
} from '@electro/consumersite/src/components/Map/constants'
import {
  Feature,
  LineString,
  Position,
  bbox,
  featureCollection,
  lineString,
  point,
} from '@turf/turf'

interface State {
  fieldValues: RouteFormFieldsType
  destinationsRecord: Record<string, PlaceDetails>
  dashedRouteLine: Feature<LineString, { [name: string]: any }>
  errors: FormikErrors<RouteFormFieldsType>
}

interface Handlers {
  submitForm: () => void
  updateFormField: (field: RouteFormFieldsTypeKeys, value: RouteFormFieldsTypeValues) => void
  updateDestinationsRecord: (value: State['destinationsRecord']) => void
}

type UseRoutePlannerForm = [state: State, handlers: Handlers]
const UseRoutePlannerFormContext = createContext<UseRoutePlannerForm>(null)

function useRoutePlannerFormProvider(): UseRoutePlannerForm {
  const { baseMap } = useMap()
  const [, { submitRoutePlan }] = useRoutePlanner()
  const [, { clearActiveMarker, toggleLocationDetailsPanel }] = useMarkers()

  const [destinationsRecord, setDestinationsRecord] = useState<State['destinationsRecord']>({})
  const [dashedRouteLine, setDashedRouteLine] = useState<State['dashedRouteLine']>()
  const [previousLngLatArray, setPreviousLngLatArray] = useState<LngLatLike[]>([])

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (formFields) => {
      await submitRoutePlan({ formFields })
      sendRouteSubmitAnalytics(formFields)
    },
  })

  /** Updates a single field on the route planner form  */
  const updateFormField = useCallback(
    (field: RouteFormFieldsTypeKeys, value: RouteFormFieldsTypeValues) =>
      formik.setFieldValue(field, value),
    [formik],
  )

  /** Updates all location fields and performs some map functions (e.g. zoom to area, show dashed route line) */
  const updateDestinationsRecord = useCallback(
    (value: State['destinationsRecord']) => {
      setDestinationsRecord(value)

      const { start, end, ...waypoints } = value
      const waypointsArray = Object.values(waypoints)
      const placeDetailsArray = [start, ...waypointsArray, end].filter(Boolean) as PlaceDetails[]

      const destinationsArray = placeDetailsArray.map(({ coordinates, name, subtext }) => ({
        lat: coordinates?.lat,
        lon: coordinates?.lng,
        address: [name, subtext].filter(Boolean).join(', '),
      }))

      updateFormField('destinations', destinationsArray)

      toggleLocationDetailsPanel({ open: false })
      clearActiveMarker()

      const sanitisedCoordinates = placeDetailsArray.map((p) => p?.coordinates).filter(Boolean)
      const lngLatArray = sanitisedCoordinates.map(({ lng, lat }) => [lng, lat] as LngLatLike)

      setPreviousLngLatArray(lngLatArray)

      if (JSON.stringify(lngLatArray) !== JSON.stringify(previousLngLatArray)) {
        if (lngLatArray.length > 0) {
          const turfPointArray = lngLatArray.map((lngLat) => point(lngLat as Position))
          const routeFeatureCollection = featureCollection(turfPointArray)
          const routeBBox = bbox(routeFeatureCollection) as LngLatBoundsLike

          if (lngLatArray.length > 1) {
            setDashedRouteLine(lineString(lngLatArray as Position[]))
            baseMap?.fitBounds(
              routeBBox,
              getCameraAnimationOptions({ type: 'fitBounds', isSidebarOpen: true }),
            )
          } else {
            setDashedRouteLine(null)
            baseMap?.fitBounds(routeBBox, {
              ...getCameraAnimationOptions({ type: 'fitBounds', isSidebarOpen: true }),
              maxZoom: 6,
              speed: 0.25,
            })
          }
        } else setDashedRouteLine(null)
      }
    },
    [updateFormField, toggleLocationDetailsPanel, clearActiveMarker, previousLngLatArray, baseMap],
  )

  /** Send details of route planner usage to analytics */
  const sendRouteSubmitAnalytics = useCallback((formFields: RouteFormFieldsType) => {
    const splitStartLocation = formFields.destinations[0].address.split(',')
    const startLocationCountry = splitStartLocation[splitStartLocation.length - 1].trim()

    const splitEndLocation =
      formFields.destinations[formFields.destinations.length - 1].address.split(',')
    const endLocationCountry = splitEndLocation[splitEndLocation.length - 1].trim()

    const isInternationalRoute = startLocationCountry !== endLocationCountry
    const waypointCounter = Object.keys(formFields.destinations).length - 2
    GTM.submitRoutePlanner({ ...formFields, isInternationalRoute, waypointCounter })
  }, [])

  const state = useMemo(
    () => ({
      fieldValues: formik.values,
      destinationsRecord,
      dashedRouteLine,
      errors: formik.errors,
    }),
    [formik.values, destinationsRecord, dashedRouteLine, formik.errors],
  )

  const handlers = useMemo(
    () => ({ submitForm: formik.submitForm, updateFormField, updateDestinationsRecord }),
    [formik.submitForm, updateFormField, updateDestinationsRecord],
  )

  return [state, handlers]
}

interface UseFormProviderProps {
  children: ReactNode | ReactNode[]
}

export const UseRoutePlannerFormProvider = ({ children }: UseFormProviderProps) => {
  const ctx = useRoutePlannerFormProvider()
  return (
    <UseRoutePlannerFormContext.Provider value={ctx}>
      {children}
    </UseRoutePlannerFormContext.Provider>
  )
}

interface UseFormProps {
  /** Allows this hook to exist in a file without its provider.
   *
   * Due to the rules of hooks, the value cannot be changed after component mount */
  skip?: boolean
}

export const useRoutePlannerForm = ({ skip = false }: UseFormProps = {}): UseRoutePlannerForm => {
  if (skip)
    return [
      null,
      { submitForm: () => {}, updateFormField: () => {}, updateDestinationsRecord: () => {} },
    ]

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(UseRoutePlannerFormContext)
  if (!context)
    throw new Error(
      'useRoutePlannerForm() cannot be used outside of <UseRoutePlannerFormProvider/>',
    )
  return context
}
