import { ForwardedRef, HTMLAttributes, ReactNode, forwardRef } from 'react'

interface Props extends HTMLAttributes<HTMLTableSectionElement> {
  children: ReactNode | ReactNode[]
}

export const TableHead = forwardRef(
  ({ className, children, ...rest }: Props, ref: ForwardedRef<HTMLTableSectionElement>) => (
    <thead ref={ref} {...rest}>
      {children}
    </thead>
  ),
)
