import {
  ConsumptionRate,
  GenericEvseConnectionTypeEdge,
  PriceComponent,
  TimeRate,
  GenericConnectorConnectionTypeEdge,
} from '@electro/consumersite/generated/graphql'

interface LowestRates {
  consumptionRate?: PriceComponentFragment
  timeRate?: PriceComponentFragment
  parkingTimeRate?: PriceComponentFragment
  connectionFee?: PriceComponentFragment
}

type PriceComponentFragment = ConsumptionRate | TimeRate

function getLowestUnitAmountByType({
  uniquePriceComponents,
  priceComponentTypeName,
}): PriceComponentFragment {
  if (!uniquePriceComponents) return null
  const priceComponentsWithMatchingType: PriceComponentFragment[] = uniquePriceComponents.filter(
    (priceComponent: PriceComponentFragment) => {
      // eslint-disable-next-line no-underscore-dangle
      const typeName = priceComponent.__typename
      if (typeName) return typeName === priceComponentTypeName
      return []
    },
  )

  if (priceComponentsWithMatchingType.length === 0) return null

  const cheapestPriceComponent: PriceComponentFragment = priceComponentsWithMatchingType?.reduce(
    (prev: PriceComponentFragment, curr: PriceComponentFragment) =>
      prev.unitAmount < curr.unitAmount ? prev : curr,
  )

  return cheapestPriceComponent
}

function flattenPriceComponents(evseEdges: GenericEvseConnectionTypeEdge[]): PriceComponent[] {
  return evseEdges
    .flatMap(({ node }: GenericEvseConnectionTypeEdge) =>
      node.connectors.edges.map(
        (connector: GenericConnectorConnectionTypeEdge) => connector.node.priceComponents,
      ),
    )
    .flat()
}

function deDuplicatePriceComponents(flattenedPriceComponents: PriceComponent[]): PriceComponent[] {
  return flattenedPriceComponents.filter(
    (price: PriceComponent, index) => flattenedPriceComponents.indexOf(price) === index,
  )
}

function removeZeroUnitAmounts(deDuplicatedPriceComponents: PriceComponent[]): PriceComponent[] {
  return deDuplicatedPriceComponents.filter(
    (priceComponent: PriceComponent & { unitAmount?: number }) => priceComponent.unitAmount !== 0,
  )
}

export default function getLowestRatesfromEvses(
  evseEdges: GenericEvseConnectionTypeEdge[],
): LowestRates {
  if (!evseEdges || evseEdges.length === 0) return null

  const flattenedPriceComponents = flattenPriceComponents(evseEdges)
  const deDuplicatedPriceComponents = deDuplicatePriceComponents(flattenedPriceComponents)
  const uniquePriceComponents = removeZeroUnitAmounts(deDuplicatedPriceComponents)

  const timeRate = getLowestUnitAmountByType({
    uniquePriceComponents,
    priceComponentTypeName: 'TimeRate',
  })

  const consumptionRate = getLowestUnitAmountByType({
    uniquePriceComponents,
    priceComponentTypeName: 'ConsumptionRate',
  })

  const parkingTimeRate = getLowestUnitAmountByType({
    uniquePriceComponents,
    priceComponentTypeName: 'ParkingTimeRate',
  })

  const connectionFee = getLowestUnitAmountByType({
    uniquePriceComponents,
    priceComponentTypeName: 'ConnectionFee',
  })

  return {
    consumptionRate, // kWh
    timeRate, // per minute
    parkingTimeRate,
    connectionFee, // one off connection charge
  }
}
