export const LightBulbIcon = (props: React.ComponentProps<'svg'>) => (
  <svg viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
    <path
      d="M9.1705 17.0653H14.199M11.6847 2V3.07609M18.533 4.83662L17.7721 5.59753M21.3696 11.6847H20.2936M3.07609 11.6847H2M5.59747 5.59753L4.83657 4.83662M7.8802 15.4894C5.77901 13.3882 5.77901 9.98148 7.8802 7.88029C9.98137 5.77909 13.3882 5.77909 15.4893 7.88029C17.5905 9.98148 17.5905 13.3882 15.4893 15.4894L14.9006 16.0782C14.2196 16.7591 13.8369 17.6828 13.8369 18.6461V19.2175C13.8369 20.4061 12.8734 21.3696 11.6847 21.3696C10.4962 21.3696 9.53259 20.4061 9.53259 19.2175V18.6461C9.53259 17.6828 9.14998 16.7591 8.46893 16.0782L7.8802 15.4894Z"
      stroke="currentColor"
      strokeWidth="2.15218"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
