import { Children, cloneElement, ReactElement, ReactNode, HTMLAttributes } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Step } from '../Step'
import { StepContent } from '../StepContent'
import { StepperProvider } from './StepperContext'

const styles = {
  root: 'text-white',
  orientation: {
    vertical: 'flex flex-col',
    horizontal: 'flex flex-row',
  },
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  orientation?: 'vertical' | 'horizontal'
  activeStep?: number
}

export const Stepper = ({
  children,
  orientation = 'horizontal',
  activeStep = 0,
  ...rest
}: Props) => {
  const childrenArray = Children.toArray(children).filter(Boolean)
  const steps = childrenArray.map((step: ReactElement<any>, index) =>
    cloneElement(step, {
      index,
      last: index + 1 === childrenArray.length,
      ...step.props,
    }),
  )

  return (
    <StepperProvider activeStep={activeStep} orientation={orientation}>
      <div
        data-testid="stepper"
        className={tw({
          [styles.root]: true,
          [styles.orientation.horizontal]: orientation === 'horizontal',
          [styles.orientation.vertical]: orientation === 'vertical',
        })}
        {...rest}
      >
        {steps}
      </div>
    </StepperProvider>
  )
}

Stepper.Step = Step
Stepper.StepContent = StepContent
