import { useCallback, useMemo } from 'react'
import * as Sentry from '@sentry/nextjs'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Alert, Button, Typography } from '@electro/shared-ui-components'
import { CountryCodes } from '@electro/shared/types/countries'
import {
  useUserQuery,
  DebtWarningStatus,
  useAllPaymentMethodsQuery,
  useCancelPaymentMethodMutation,
  useMakePaymentMethodPreferredMutation,
  AllPaymentMethodsDocument,
  PaymentMethodType,
} from '@electro/consumersite/generated/graphql'

import { useFeatureFlag } from '@electro/consumersite/src/hooks'
import { useUserCountry } from '@electro/consumersite/src/hooks/useUserCountry'
import { RequestKrakenOauthLinkButton } from '@electro/consumersite/src/components'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import {
  NoPaymentMethodSetupNotice,
  PaymentMethodItem,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManagePaymentMethods/components/'
import {
  PaymentTypeEnum,
  PaymentMethodStatus,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManagePaymentMethods/types'

const styles = {
  root: 'mb-2 px-1 max-w-lg',
  errorMessage: {
    root: 'mb-4',
    escalated: 'bg-action-danger',
    issued: 'bg-action-warning',
  },
  paymentsContainer: {
    root: 'mb-8',
    title: 'mb-4',
  },
  buttonContainer: 'flex md:flex-row flex-col items-center gap-4',
  addPaymentMethodButton: 'w-full md:w-auto',
}

export const ManagePaymentMethods = () => {
  const { t } = useTranslation('common')
  const { push } = useRouter()
  const { data: userData } = useUserQuery({ fetchPolicy: 'network-only' })
  const { restrictToCountries } = useUserCountry()
  const showPayWithOeFeatureFlag = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_SHOW_PAY_WITH_OE === 'on',
  )
  const {
    data,
    error,
    loading: loadingPaymentMethods,
  } = useAllPaymentMethodsQuery({
    fetchPolicy: 'network-only',
  })
  const [cancelPaymentMethod, { loading: cancelling, error: errorCancelling }] =
    useCancelPaymentMethodMutation({
      refetchQueries: [AllPaymentMethodsDocument],
    })
  const [
    setPaymentMethodPreferred,
    { loading: loadingSetPreferred, error: errorSettingPreferred },
  ] = useMakePaymentMethodPreferredMutation({
    refetchQueries: [AllPaymentMethodsDocument],
  })

  const userHasIssuedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Issued,
    [userData?.me?.financialInfo?.debtStatus],
  )

  const userHasEscalatedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Escalated,
    [userData?.me?.financialInfo?.debtStatus],
  )

  const handleSetPreferredPaymentMethod = useCallback(
    (paymentMethodPk: number) => async () => {
      try {
        await setPaymentMethodPreferred({
          variables: {
            paymentMethodId: paymentMethodPk,
          },
        })
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    [setPaymentMethodPreferred],
  )

  const handleCancelPaymentMethod = useCallback(
    (paymentMethodPk: number) => async () => {
      try {
        await cancelPaymentMethod({
          variables: {
            paymentMethodId: paymentMethodPk,
          },
        })
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    [cancelPaymentMethod],
  )

  const waiting = useMemo(
    () => loadingSetPreferred || cancelling || loadingPaymentMethods,
    [cancelling, loadingSetPreferred, loadingPaymentMethods],
  )

  const updateError = useMemo(
    () => errorSettingPreferred || errorCancelling,
    [errorCancelling, errorSettingPreferred],
  )

  const hasPaymentSetup = useMemo(
    () => !loadingPaymentMethods && data?.activePaymentMethods?.length > 0,
    [data?.activePaymentMethods, loadingPaymentMethods],
  )

  const hasActivePayments = useMemo(
    () => hasPaymentSetup && data?.activePaymentMethods.some(({ isPreferred }) => isPreferred),
    [hasPaymentSetup, data?.activePaymentMethods],
  )

  const hasAvailablePayments = useMemo(
    () =>
      hasPaymentSetup &&
      data?.activePaymentMethods.some(
        ({ isPreferred, isActivated }) => !isPreferred && isActivated,
      ),
    [hasPaymentSetup, data?.activePaymentMethods],
  )

  const hasPendingPayments = useMemo(
    () =>
      hasPaymentSetup &&
      data?.activePaymentMethods.some(
        ({ isPreferred, isActivated }) => !isPreferred && !isActivated,
      ),
    [hasPaymentSetup, data?.activePaymentMethods],
  )

  const showPayWithOctopusOption = useMemo(
    () =>
      !data?.activePaymentMethods.some(
        (method) => method.paymentMethodType === PaymentTypeEnum.KRAKEN,
      ) && restrictToCountries([CountryCodes.GBR]),
    [data?.activePaymentMethods, restrictToCountries],
  )

  useErrorNotificationEffect({
    error: updateError,
    message: t('profile.payment_methods.errors.could_not_update_payment_method'),
  })

  const activePayments = useMemo(
    () =>
      data?.activePaymentMethods.filter(
        ({ isPreferred, isActivated }) => isPreferred && isActivated,
      ),
    [data?.activePaymentMethods],
  )

  const availablePayments = useMemo(
    () =>
      data?.activePaymentMethods.filter(
        ({ isPreferred, isActivated }) => !isPreferred && isActivated,
      ),
    [data?.activePaymentMethods],
  )

  const pendingPayments = useMemo(
    () =>
      data?.activePaymentMethods.filter(
        ({ isPreferred, isActivated }) => !isPreferred && !isActivated,
      ),
    [data?.activePaymentMethods],
  )

  return (
    <div className={styles.root}>
      {error && (
        <Alert variant="error" className={styles.errorMessage.root}>
          <strong>{t('common_error_title')}.</strong> {t('common_error_subtitle')}
        </Alert>
      )}

      {userHasEscalatedDebt ? (
        <Alert
          variant="warn"
          className={tw(styles.errorMessage.root, styles.errorMessage.escalated)}
        >
          {t('profile.payment_methods.alert_messages.debt_status_escalated')}
        </Alert>
      ) : null}

      {userHasIssuedDebt ? (
        <Alert variant="warn" className={tw(styles.errorMessage.root, styles.errorMessage.issued)}>
          {t('profile.payment_methods.alert_messages.debt_status_issued')}
        </Alert>
      ) : null}

      {!hasPaymentSetup && <NoPaymentMethodSetupNotice />}
      {hasActivePayments ? (
        <div className={styles.paymentsContainer.root}>
          <Typography variant="h3" className={styles.paymentsContainer.title}>
            {t('profile.payment_methods.status.active')}
          </Typography>
          {activePayments.map((paymentMethod) => (
            <PaymentMethodItem
              key={paymentMethod.pk}
              pk={paymentMethod.pk}
              paymentMethod={paymentMethod as PaymentMethodType}
              status={PaymentMethodStatus.ACTIVE}
            />
          ))}
        </div>
      ) : null}
      {hasAvailablePayments ? (
        <div className={styles.paymentsContainer.root}>
          <Typography variant="h3" className={styles.paymentsContainer.title}>
            {t('profile.payment_methods.status.available')}
          </Typography>
          {availablePayments.map((paymentMethod) => (
            <PaymentMethodItem
              key={paymentMethod.pk}
              pk={paymentMethod.pk}
              paymentMethod={paymentMethod as PaymentMethodType}
              status={PaymentMethodStatus.AVAILABLE}
              loading={waiting}
              onSelect={handleSetPreferredPaymentMethod(paymentMethod.pk)}
              onCancel={handleCancelPaymentMethod(paymentMethod.pk)}
            />
          ))}
        </div>
      ) : null}
      {hasPendingPayments ? (
        <div className={styles.paymentsContainer.root}>
          <Typography variant="h3" className={styles.paymentsContainer.title}>
            {t('profile.payment_methods.status.pending')}
          </Typography>
          {pendingPayments.map((paymentMethod) => (
            <PaymentMethodItem
              key={paymentMethod.pk}
              pk={paymentMethod.pk}
              paymentMethod={paymentMethod as PaymentMethodType}
              status={PaymentMethodStatus.PENDING}
            />
          ))}
        </div>
      ) : null}

      <div className={styles.buttonContainer}>
        <Button
          className={styles.addPaymentMethodButton}
          onClick={() => push('/user/account/payment-methods/add-payment')}
          variant={restrictToCountries([CountryCodes.GBR]) ? 'outline' : 'default'}
        >
          {t('profile.payment_methods.payment_method_actions.add_payment_method')}
        </Button>
        {showPayWithOeFeatureFlag && showPayWithOctopusOption ? (
          <RequestKrakenOauthLinkButton />
        ) : null}
      </div>
    </div>
  )
}
