import { Locale } from '@electro/shared/types/locales'
import { SearchBoxRetrieveResponse } from '@mapbox/search-js-core'

const MAPBOX_SEARCHBOX_API_ENDPOINT = 'https://api.mapbox.com/search/searchbox/v1/retrieve/'

export interface FetchPlaceFeatureArgs {
  /**
   * @id is the mapbox_id of the suggestion to retrieve.
   */
  id: string
  /**
   * @sessionToken is a unique identifier for the session.
   * Required for rate limiting and billing purposes. UUIDv4 recommended.
   */
  sessionToken: string

  /**
   * @locale is requested language format. e.g. en-GB
   */
  locale?: Locale | string
  /**
   * @attributeSets is a comma-separated list of metadata to be included in the result.
   * e.g. 'basic, photos, venue, visit'
   */
  attributeSets?: string
}

/** https://docs.mapbox.com/api/search/search-box/#retrieve-a-suggested-feature */
export const fetchPlaceFeature = async ({
  id,
  sessionToken,
  locale,
  attributeSets,
}: FetchPlaceFeatureArgs): Promise<SearchBoxRetrieveResponse> => {
  if (!process.env.NEXT_PUBLIC_MAPBOX_API_KEY) {
    return { url: '', type: 'FeatureCollection', features: [] }
  }

  const params = {
    access_token: process.env.NEXT_PUBLIC_MAPBOX_API_KEY,
    session_token: sessionToken,

    ...(locale ? { language: locale.slice(0, 2) } : {}),
    ...(attributeSets ? { attribute_sets: attributeSets } : {}),
  }

  const requestURL = `${MAPBOX_SEARCHBOX_API_ENDPOINT}${id}?${new URLSearchParams(params)}`
  const response = await fetch(requestURL, { method: 'GET' })

  return response.json()
}
