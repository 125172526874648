import Image from 'next/image'
import { useMemo } from 'react'
import { Typography, Card, Tag, Menu, LoadingSpinner } from '@electro/shared-ui-components'
import {
  PaymentCardType,
  PaymentMethodType,
  DirectDebitType,
} from '@electro/consumersite/generated/graphql'
import { TrashIcon } from '@heroicons/react/24/outline'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import {
  PaymentTypeEnum,
  PaymentMethodStatus,
} from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManagePaymentMethods/types'

const paymentImageStyles = {
  root: 'w-16 h-12 relative flex flex-col justify-center ml-1 flex-shrink-0',
  image: 'rounded-lg object-contain',
}

const paymentDetailStyles = {
  root: 'break-all',
  label: 'text-xs opacity-70 text-wrap',
  accountHolder: 'text-xs pr-16',
}

const PaymentNetworkImage = ({ paymentMethod }: { paymentMethod: PaymentMethodType }) => {
  const commonNetworksMap: Record<string, string> = {
    visa: '/images/visa.png',
    mastercard: '/images/mastercard.png',
    discover: '/images/discover.png',
    diners: '/images/diners.png',
    amex: '/images/amex.png',
    paypal: '/images/paypal-white.png',
    direct_debit: '/images/direct-debit.png',
    pay_with_octopus: '/images/pay-with-octopus.png',
    bacs_debit: '/images/direct-debit.png',
    fallback: '/images/credit-card.png',
  }

  const getImage = (paymentTypeKey: string) =>
    commonNetworksMap[paymentTypeKey] || commonNetworksMap.fallback

  const paymentKey: string = useMemo(() => {
    if (paymentMethod.paymentMethodType === PaymentTypeEnum.KRAKEN) return 'pay_with_octopus'

    return (
      (paymentMethod.paymentMethodDetails as PaymentCardType)?.paymentNetwork ||
      paymentMethod.paymentMethodType
    ).toLowerCase()
  }, [paymentMethod])

  const altTag = paymentKey.split('_').join(' ')

  return (
    <div className={paymentImageStyles.root}>
      <Image
        className={paymentImageStyles.image}
        width={200}
        height={200}
        src={getImage(paymentKey)}
        alt={altTag}
      />
    </div>
  )
}

const PaymentMethodDetails = ({ paymentMethod }: { paymentMethod: PaymentMethodType }) => {
  const { t } = useTranslation('common')

  const paymentDetailComponentMap = {
    [PaymentTypeEnum.KRAKEN]: (
      <div data-testid="direct-debit-payment-method-identifier">
        {(paymentMethod.paymentMethodDetails as DirectDebitType)?.accountHolder ? (
          <div className={paymentDetailStyles.accountHolder}>
            {(paymentMethod.paymentMethodDetails as DirectDebitType).accountHolder}
          </div>
        ) : (
          <div className={paymentDetailStyles.label}>
            {t('profile.payment_methods.pending_kraken_account.title')}
          </div>
        )}
        {paymentMethod?.paymentMethodDetails?.identifier ? (
          <div className={paymentDetailStyles.label}>
            {t('profile.payment_methods.account_number')}:{' '}
            {paymentMethod?.paymentMethodDetails?.identifier}
          </div>
        ) : (
          <div className={paymentDetailStyles.label}>
            {t('profile.payment_methods.pending_kraken_account.description')}
          </div>
        )}
      </div>
    ),

    [PaymentTypeEnum.PAYPAL]: (
      <span data-testid="paypal-payment-method-identifier">
        {paymentMethod.paymentMethodDetails?.identifier}
      </span>
    ),

    [PaymentTypeEnum.CARD_PAYMENT]: (
      <>
        <span data-testid="card-payment-method-identifier">{`**** **** **** ${paymentMethod.paymentMethodDetails?.identifier}`}</span>
        {(paymentMethod.paymentMethodDetails as PaymentCardType)?.expiryMonth &&
        (paymentMethod.paymentMethodDetails as PaymentCardType)?.expiryYear ? (
          <div
            className={paymentDetailStyles.label}
          >{`${t('profile.payment_methods.expiry_date')}: ${
            (paymentMethod.paymentMethodDetails as PaymentCardType).expiryMonth
          }/${(paymentMethod.paymentMethodDetails as PaymentCardType).expiryYear}`}</div>
        ) : null}
      </>
    ),
  }
  return paymentDetailComponentMap?.[paymentMethod.paymentMethodType] ? (
    <Typography variant="small" className={paymentDetailStyles.root} data-testid="payment-type">
      {paymentDetailComponentMap[paymentMethod.paymentMethodType]}
    </Typography>
  ) : null
}

const styles = {
  root: 'mb-4 first:mb-8 last:mb-0 rounded-xl border flex items-center gap-2 sm:gap-4 relative',
  active: 'border-tertiary ring-tertiary ring-1',
  tagContainer: 'flex-grow',
  tag: 'inline-block ml-2 text-xs absolute top-0 right-0',
  menu: {
    root: 'flex-grow-0 flex-shrink-0',
    loadingIcon: 'size-6',
    icon: 'w-7 h-8',
    divider: 'my-1 mx-2',
    deleteIcon: 'size-5 mr-2',
  },
}

interface PaymentMethodItemProps extends PaymentMethodType {
  paymentMethod: PaymentMethodType
  status: PaymentMethodStatus
  loading?: boolean
  onSelect?: () => void
  onCancel?: () => void
}

export const PaymentMethodItem = ({
  paymentMethod,
  status,
  loading,
  onSelect,
  onCancel,
}: PaymentMethodItemProps) => {
  const { t } = useTranslation('common')

  return (
    <Card
      data-testid={`payment-method-card-${paymentMethod?.paymentMethodDetails?.identifier}${
        status === PaymentMethodStatus.ACTIVE ? '-active' : ''
      }`}
      density="high"
      className={tw({
        [styles.root]: true,
        [styles.active]: status === PaymentMethodStatus.ACTIVE,
      })}
    >
      <PaymentNetworkImage paymentMethod={paymentMethod} />
      <div className={styles.tagContainer}>
        {status === PaymentMethodStatus.ACTIVE ? (
          <Tag
            data-testid="active-payment-tag"
            label={t('profile.payment_methods.status.active')}
            variant="filled"
            colour="tertiary"
            className={styles.tag}
          />
        ) : null}
        <PaymentMethodDetails paymentMethod={paymentMethod} />
      </div>
      {status === PaymentMethodStatus.AVAILABLE ? (
        <div data-testid="payment-method-options-menu" className={styles.menu.root}>
          <Menu>
            <Menu.Button aria-label="payment-menu" chevron={false} disabled={loading}>
              {loading ? <LoadingSpinner className={styles.menu.loadingIcon} /> : null}
              {!loading ? <EllipsisHorizontalIcon className={styles.menu.icon} /> : null}
            </Menu.Button>
            <Menu.MenuItemList>
              <Menu.MenuItem onSelect={onSelect}>
                {t('profile.payment_methods.payment_method_actions.set_preferred')}
              </Menu.MenuItem>
              <hr className={styles.menu.divider} />
              <Menu.MenuItem onSelect={onCancel}>
                <TrashIcon className={styles.menu.deleteIcon} />
                {t('profile.payment_methods.payment_method_actions.remove')}
              </Menu.MenuItem>
            </Menu.MenuItemList>
          </Menu>
        </div>
      ) : null}
    </Card>
  )
}
