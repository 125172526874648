import { v1 as uuid } from 'uuid'

interface LoadingDoubleRowPlaceholderProps {
  rows: number
}

/**
 * @deperecated please use <Skeleton/> component
 */
export const LoadingDoubleRowPlaceholder = ({ rows = 1 }: LoadingDoubleRowPlaceholderProps) => (
  <>
    {Array.from(Array(rows)).map(() => (
      <div key={uuid()} data-testid="loading-rows-placeholder">
        <div className="cp-doubleLine" />
      </div>
    ))}
  </>
)
