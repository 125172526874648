import { UserQuery } from '@electro/consumersite/generated/graphql'
import { SHOW_USER_ONBOARDING_MODAL_TRIGGER_PARAM } from '@electro/shared/constants'
import { mapAlpha3CountryCodeToLocale } from '@electro/shared/utils/mapLocaleToCountryDetails'
import { NextRouter } from 'next/router'
import { ACCOUNT_ID } from '@electro/consumersite/src/constants/localStorage'

/**
 * This handler is used to redirect the user to the correct page after they have logged in,
 * based on their account status.
 *
 * The URL param SHOW_USER_ONBOARDING_MODAL_TRIGGER_PARAM is used to trigger the onboarding
 * modal when they hit the map page.
 *
 * We also set the account ID in local storage so that we can attach it to requests to BE
 */
export function handlePostLoginRedirect({
  userData,
  router,
}: {
  userData: UserQuery
  router: NextRouter
}): Promise<void> {
  if (!userData?.me) {
    /**
     * Failing silently here as this is a non-critical operation
     * and we don't want to break the login flow, instead we want to
     * show errors returned from the login mutation
     */
    return null
  }
  const shouldRedirectToMap = userData.me.accounts.length === 1
  const shouldRedirectToChooseUserAccount = userData.me.accounts.length > 1
  const shouldShowOnboardingModal =
    !userData.me.hasElectricJuiceCard || !userData.me.hasValidPaymentInstruction

  const locale = mapAlpha3CountryCodeToLocale(userData?.me?.countryCode)

  if (shouldRedirectToChooseUserAccount) {
    router.push('/log-in/choose-user-account', '/log-in/choose-user-account', { locale })
    return null
  }

  if (shouldRedirectToMap) {
    localStorage.setItem(ACCOUNT_ID, userData.me.activeAccount.id.toString())

    router.push(
      `/map${shouldShowOnboardingModal ? `?${SHOW_USER_ONBOARDING_MODAL_TRIGGER_PARAM}=${shouldShowOnboardingModal}` : ''}`,
      undefined,
      { locale },
    )
  }
  return null
}
