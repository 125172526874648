import { Slider } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { MapFiltersEnum } from '@electro/consumersite/src/components/Map/types'
import { filtersChargeSpeedSliderStops } from '@electro/consumersite/src/components/Map/constants'

const styles = {
  heading: 'mb-2',
  slider: 'mb-6 ml-2 mr-3',
}

const { CHARGE_POINT_SPEEDS } = MapFiltersEnum

/** Dual-sided slider for customising speed bounds of charging locations */
export const FiltersChargeSpeedSlider = () => {
  const { t } = useTranslation('common')
  const [{ currentFilters }, { updateFilters }] = useMapFilters()

  return (
    <div data-testid="filters-charge-speed-slider">
      <h4 className={styles.heading}>{t('map.filters.subtitles.charge_point_speeds')}</h4>

      <div className={styles.slider}>
        <Slider
          name="charge-point-speed-range-slider"
          nonLinearSteps={filtersChargeSpeedSliderStops}
          initialValues={[
            currentFilters[CHARGE_POINT_SPEEDS][0],
            currentFilters[CHARGE_POINT_SPEEDS][1],
          ]}
          onChange={([min, max]) => updateFilters({ [CHARGE_POINT_SPEEDS]: [min, max] })}
        >
          <Slider.Rail />
        </Slider>
      </div>
    </div>
  )
}
