import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  useEffect,
} from 'react'
import { FormikHelpers } from 'formik'
import useTranslation from 'next-translate/useTranslation'

import { ErrorCode, VehicleInfo, useUserQuery } from '@electro/consumersite/generated/graphql'

import { useAuth } from '@electro/consumersite/src/hooks'
import {
  useCreatePlugAndChargeContract,
  useVinLookupFromRegNumber,
} from '@electro/consumersite/src/services'
import { getMessageFromErrorCode } from '@electro/consumersite/src/utils/getMessageFromErrorCode'

import {
  PncStage,
  PncStagesEnum,
  Vehicle,
  VinFormFields,
} from '@electro/consumersite/src/components/PlugAndChargeSignup/types'

import { GTM } from '@electro/consumersite/src/utils/event-triggers'

import { CountryCodes } from '@electro/shared/types/countries'

interface UsePlugAndChargeSignupProps {
  children: ReactNode | ReactNode[]
}

interface State {
  activeStage: PncStage
  errorMessage: string
  vehicle: Vehicle
  loading: boolean
}

interface Handlers {
  /**
   * Takes the regNumber and handles the submission of the reg number form to the backend
   * @param param0
   * @returns
   */
  handleSubmitRegNumber: ({ regNumber }: { regNumber: string }) => Promise<void>
  /**
   * Takes formik values and actions and handles the submission of the VIN form
   * @param values
   * @param formikActions
   * @returns
   */
  handleSubmitVin: (
    values: VinFormFields,
    formikActions: FormikHelpers<VinFormFields>,
  ) => Promise<void>
  /**
   * Takes the pcid and handles the submission of the vehicles vin number to the backend
   * @param param0
   * @returns
   */
  handleConfirmVehicle: () => Promise<void>
  setActiveStage: (stage: PncStage) => void
}

type UsePlugAndChargeSignup = [state: State, handlers: Handlers]

const UsePlugAndChargeSignupContext = createContext<UsePlugAndChargeSignup>(null)

function usePlugAndChargeSignupProvider(): UsePlugAndChargeSignup {
  const { t } = useTranslation('common')
  const [activeStage, setActiveStage] = useState<PncStage>()
  const [vehicle, setVehicle] = useState<Vehicle>(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)

  const { data: userData } = useUserQuery()
  const [lookupVinWithRegNumber, regLookup] = useVinLookupFromRegNumber()
  const [createContract, pncContract] = useCreatePlugAndChargeContract()

  const [{ session }] = useAuth()

  useEffect(() => {
    const initialActiveStage =
      userData?.me?.countryCode === CountryCodes.GBR
        ? PncStagesEnum.VEHICLE_REG_LOOKUP
        : PncStagesEnum.ENTER_VEHICLE_VIN
    setActiveStage(initialActiveStage)
  }, [userData])

  const resetErrorMessage = useCallback(() => {
    setErrorMessage(null)
  }, [])

  const isVehicleIncompatible = (vehicleInfo: VehicleInfo) =>
    !vehicleInfo.isElectric || !vehicleInfo.isPncCompatible

  const handleSubmitRegNumber = useCallback(
    async ({ regNumber }: { regNumber: string }) => {
      try {
        const { data } = await lookupVinWithRegNumber({ variables: { regNumber } })
        setVehicle({
          regNumber,
          info: data.vinLookupFromRegNumber.vehicleInfo,
        })

        if (isVehicleIncompatible(data?.vinLookupFromRegNumber?.vehicleInfo)) {
          GTM.pncSignupIncompatibleVehicle({
            userId: session.user.id,
            isElectric: data?.vinLookupFromRegNumber?.vehicleInfo?.isElectric,
            isPncCompatible: data?.vinLookupFromRegNumber?.vehicleInfo?.isPncCompatible,
          })
          setActiveStage(PncStagesEnum.INCOMPATIBLE_VEHICLE)
        } else {
          setActiveStage(PncStagesEnum.CONFIRM_VEHICLE_DETAILS)
        }
      } catch (err) {
        const [firstError] = err.graphQLErrors
        setErrorMessage(t(getMessageFromErrorCode(firstError?.errorCode)))
        GTM.pncSignupError({ errorCode: firstError?.errorCode, userId: session.user.id })
      }
    },
    [lookupVinWithRegNumber, session, t],
  )

  const handleSubmitVin = useCallback(
    async (values: VinFormFields, formikActions: FormikHelpers<VinFormFields>) => {
      try {
        resetErrorMessage()
        formikActions.setErrors({})
        await createContract({
          variables: { pcid: values.pcid },
        })
        setActiveStage(PncStagesEnum.COMPLETE)
      } catch (err) {
        const [firstError] = err.graphQLErrors
        if (firstError?.errorCode === ErrorCode.PncProviderError) {
          setActiveStage(PncStagesEnum.CONTRACT_CREATION_ERROR)
        } else {
          formikActions.setErrors({ pcid: t(getMessageFromErrorCode(firstError?.errorCode)) })
          GTM.pncSignupError({ errorCode: firstError?.errorCode, userId: session.user.id })
        }
      } finally {
        formikActions.setSubmitting(false)
      }
    },
    [createContract, resetErrorMessage, session, t],
  )

  const handleConfirmVehicle = useCallback(async () => {
    try {
      resetErrorMessage()
      await createContract({
        variables: { pcid: vehicle.info.vin },
      })
      setActiveStage(PncStagesEnum.COMPLETE)
    } catch (err) {
      const [firstError] = err.graphQLErrors

      if (firstError?.errorCode === ErrorCode.PncProviderError) {
        setActiveStage(PncStagesEnum.CONTRACT_CREATION_ERROR)
      } else {
        setErrorMessage(t(getMessageFromErrorCode(firstError?.errorCode)))
        GTM.pncSignupError({ errorCode: firstError?.errorCode, userId: session.user.id })
      }
    }
  }, [createContract, resetErrorMessage, session, vehicle, t])

  useEffect(() => {
    if (session) GTM.pncSignupFormInit({ userId: session.user.id })
  }, [session])

  /**
   * Track when the user completes the PNC signup flow.
   * All successful pnc sign ups will be tracked as a single event.
   */
  useEffect(() => {
    resetErrorMessage()
    if (activeStage === PncStagesEnum.COMPLETE) {
      GTM.pncSignupCompleted({ userId: session.user.id })
    }
  }, [activeStage, resetErrorMessage, session])

  const loading = useMemo(
    () => pncContract.loading || regLookup.loading,
    [pncContract.loading, regLookup.loading],
  )

  const state = useMemo(
    () => ({
      activeStage,
      vehicle,
      loading,
      errorMessage,
    }),
    [activeStage, errorMessage, loading, vehicle],
  )

  const handlers = useMemo(
    () => ({
      setActiveStage,
      handleSubmitRegNumber,
      handleSubmitVin,
      handleConfirmVehicle,
    }),
    [handleConfirmVehicle, handleSubmitRegNumber, handleSubmitVin],
  )

  return [state, handlers]
}

export const PlugAndChargeSignupProvider = ({ children }: UsePlugAndChargeSignupProps) => {
  const ctx = usePlugAndChargeSignupProvider()
  return (
    <UsePlugAndChargeSignupContext.Provider value={ctx}>
      {children}
    </UsePlugAndChargeSignupContext.Provider>
  )
}

export const usePlugAndChargeSignup = (): UsePlugAndChargeSignup => {
  const context = useContext(UsePlugAndChargeSignupContext)
  if (!context)
    throw new Error(
      'usePlugAndChargeSignup() cannot be used outside of <PlugAndChargeSignupProvider/>',
    )
  return context
}
