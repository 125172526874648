import { ReactNode } from 'react'

import {
  ToastNotificationContainer,
  ToastNotificationProvider,
} from '@electro/shared-ui-components'

interface Props {
  children: ReactNode
}

export const ToastNotifications = ({ children }: Props) => (
  <ToastNotificationProvider>
    <ToastNotificationContainer>{children}</ToastNotificationContainer>
  </ToastNotificationProvider>
)
