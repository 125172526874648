import { ForwardedRef, forwardRef } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Menu } from '@headlessui/react'
import { useMapSearch } from '@electro/consumersite/src/components/Map/hooks'
import { PlaceDetails } from '@electro/consumersite/src/components/Map/helpers'
import { menuItemsStyles } from '@electro/consumersite/src/components/Map/components/MapLocationSearch/components'
import { mapFeatureTypeToIcon } from '@electro/consumersite/src/components/Map/utils/mapFeatureTypeToIcon'

export const SearchMenuItems = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const [{ showSearchResults, placeSuggestions }, { handleLocationSearchPlaceSelection }] =
    useMapSearch()

  /** Sends the location place details to the map, identifying the search result type */
  const onSelectResult = (place: PlaceDetails) => handleLocationSearchPlaceSelection({ place })

  return (
    <Menu.Items
      ref={ref}
      className={tw(menuItemsStyles.menuItems, 'mt-2')}
      data-testid="search-menu-items"
    >
      {showSearchResults
        ? placeSuggestions.map((item: PlaceDetails) => (
            <Menu.Item key={item.id}>
              {({ active }) => (
                <button
                  data-testid="search-menu-item"
                  onClick={() => onSelectResult(item)}
                  onKeyDown={() => onSelectResult(item)}
                  className={tw({
                    [menuItemsStyles.button.root]: true,
                    [menuItemsStyles.button.buttonActive]: active,
                  })}
                >
                  <span className={menuItemsStyles.button.featureTypeIcon}>
                    {mapFeatureTypeToIcon(item.type)}
                  </span>

                  <div className={menuItemsStyles.button.text}>
                    <div>{item.name}</div>
                    <div className={menuItemsStyles.button.subtext}>{item.subtext}</div>
                  </div>
                </button>
              )}
            </Menu.Item>
          ))
        : null}
    </Menu.Items>
  )
})
