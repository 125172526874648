import { useMemo } from 'react'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import Image from 'next/image'
import Link from 'next/link'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import { useRouter } from 'next/router'
import {
  Button,
  Card,
  IconButton,
  Skeleton,
  Typography,
  useToastNotification,
} from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { formatPriceToLocalisedValue } from '@electro/shared/utils/formatters'
import { mapAlpha3CountryCodeToLocale } from '@electro/shared/utils/mapLocaleToCountryDetails'
import { DisableContentWrapper } from '@electro/consumersite/src/components'
import { useUserQuery, useUserReferralTotalQuery } from '@electro/consumersite/generated/graphql'
import { isActiveAccountFleetDriver } from '@electro/consumersite/src/utils/isActiveAccountFleetDriver'
import Trans from 'next-translate/Trans'

export const AccountVouchersAndCreditManager = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { setStep } = useAccountManageMobileStep()
  const { showToastNotification } = useToastNotification()
  const { data: userData, loading: userLoading } = useUserQuery({ fetchPolicy: 'network-only' })
  const { data: userReferralData } = useUserReferralTotalQuery({ variables: { completed: true } })

  const activeUserIsAFleetDriver = useMemo(
    () => isActiveAccountFleetDriver(userData) && !userLoading,
    [userData, userLoading],
  )

  const locale = useMemo(
    () => mapAlpha3CountryCodeToLocale(userData?.me?.countryCode),
    [userData?.me?.countryCode],
  )

  const creditValue = useMemo(
    () =>
      formatPriceToLocalisedValue({
        price: userData?.me?.referralInfo?.totalAmount,
        currency: userData?.me?.referralInfo?.currency,
        locale,
        isFloat: false,
      }),
    [userData?.me?.referralInfo, locale],
  )

  const handleShare = () => {
    navigator.clipboard.writeText(userData?.me?.referralInfo?.url)
    showToastNotification({
      variant: 'success',
      heading: t('profile.vouchers_and_credit.refer_a_friend.qr_code.toast_heading'),
      body: t('profile.vouchers_and_credit.refer_a_friend.qr_code.toast_message'),
      position: 'topRight',
      timeout: 4000,
    })
  }

  if (userLoading && !activeUserIsAFleetDriver)
    return (
      <div
        className="flex flex-col gap-4 sm:basis-[550px] opacity-40"
        data-testid="loading-rows-placeholder"
      >
        <Skeleton className="w-1/2 h-6 mb-4" />
        <Skeleton className="max-w-xs h-48 mb-4" variant="rounded" />
        <Skeleton className="h-112 mt-8" variant="rounded" />
      </div>
    )

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col mt-6 justify-center">
        <div className="flex">
          <div className="grow sm:grow-0 sm:basis-[550px]">
            <DisableContentWrapper
              condition={activeUserIsAFleetDriver}
              showContent={!activeUserIsAFleetDriver}
            >
              <div className="flex justify-between">
                <IconButton
                  className="block md:hidden"
                  onClick={() => setStep(AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU)}
                >
                  <ArrowLeftIcon className="w-6 h-6" />
                </IconButton>
                <Typography variant="h3" as="h1" data-testid="vouchers-and-credit-page-title">
                  {t('profile.navigation.vouchers_and_credit')}
                </Typography>
                <div />
              </div>
              <div className="flex flex-col justify-self-start">
                <div className="flex flex-col justify-between max-w-xs h-48 rounded-lg mb-4 p-6 bg-electrocard bg-center bg-cover aspect-video border-2 border-secondary">
                  <Typography variant="h3" as="h2">
                    {t('profile.vouchers_and_credit.electroverse_credits')}
                  </Typography>
                  <Typography className="mb-0" variant="h3" as="h2" data-testid="current-balance">
                    {formatPriceToLocalisedValue({
                      price: userData?.me?.currentBalance.amount,
                      currency: userData?.me?.currentBalance.currency,
                      locale: router.locale,
                      isFloat: true,
                    })}
                  </Typography>
                </div>

                <Card className="mt-8" density="low" data-testid="refer-a-friend">
                  <div className="flex flex-col items-center mb-8">
                    <Typography variant="h1" className="xs:text-2xl" data-testid="referral-value">
                      {formatPriceToLocalisedValue({
                        price: userReferralData?.userReferralTotal?.value,
                        currency: userReferralData?.userReferralTotal?.currency,
                        locale,
                        isFloat: true,
                      })}
                    </Typography>
                    <Typography>
                      {t('profile.vouchers_and_credit.refer_a_friend.earned_so_far')}
                    </Typography>
                  </div>

                  <div>
                    <ul className="list-disc pl-6 my-4 text-white">
                      <li>
                        <Typography as="h6" data-testid="credit-info">
                          <Trans
                            i18nKey="common:profile.vouchers_and_credit.refer_a_friend.credit_value"
                            values={{
                              creditValue,
                            }}
                          />
                        </Typography>
                      </li>
                      <li>
                        <Typography as="h6">
                          {t('profile.vouchers_and_credit.refer_a_friend.complete_a_charge')}
                        </Typography>
                      </li>
                    </ul>
                  </div>

                  <div className="mt-8 flex flex-col items-center">
                    <Link href="/faqs" locale={locale} target="_blank">
                      {t('profile.vouchers_and_credit.refer_a_friend.faq_link')}
                    </Link>
                    <Button
                      className="w-full md:w-1/2 my-8"
                      onClick={handleShare}
                      variant="default"
                    >
                      {t('profile.vouchers_and_credit.refer_a_friend.share_cta')}
                    </Button>
                    <Typography className="text-center" variant="h6">
                      {t('profile.vouchers_and_credit.refer_a_friend.qr_code.description')}
                    </Typography>

                    <Image
                      src={userData?.me?.referralInfo?.qrCode}
                      width={100}
                      height={100}
                      className="rounded-md mt-8"
                      alt={t('profile.vouchers_and_credit.refer_a_friend.qr_code.alt_text')}
                    />
                  </div>
                </Card>
              </div>
            </DisableContentWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}
