const defaultTheme = require('tailwindcss/defaultTheme')

module.exports = {
  ...defaultTheme,
  fontFamily: {
    sans: ['Rubik', 'Arial', 'Helvetica'],
    display: ['Rubik', 'Arial', 'Helvetica'],
    body: ['Rubik', 'Arial', 'Helvetica'],
    mono: ['Fragment Mono'],
  },
  fontWeight: {
    ...defaultTheme.fontWeight,
    light: 300,
    normal: 400,
    medium: 600,
    bold: 800,
  },
  screens: {
    ...defaultTheme.screens,
    '2xs': '359px', // Adds a layer between 320px devices and other popular device widths (e.g. 360px, 375px, 390px)
    xs: '400px',
  },
  rotate: {
    ...defaultTheme.rotate,
    18: '18deg',
    '-14': '-14deg',
  },
  spacing: {
    ...defaultTheme.spacing,
    18: '4.5rem',
    22: '5.5rem',
    66: '16.5rem',
    104: '27rem',
    112: '30rem',
    120: '33rem',
    128: '36rem',
    136: '39rem',
    144: '42rem',
    152: '45rem',
    184: '57rem',
    274: '90rem',
  },
  backgroundImage: {
    ...defaultTheme.backgroundImage,
    static: "url('/images/static.png')",
    'info-icon-lg': "url('/images/info-icon-bg.svg')",
    'quote-marks': "url('/images/quotes.svg')",
    'detail-chevron-up': "url('/images/detail-chevron-up.svg')",
    'detail-chevron-down': "url('/images/detail-chevron-down.svg')",
    'electric-wave': "url('/images/ej-wave.svg')",
    'electric-wave-sm': "url('/images/ej-wave-sm.svg')",
    'list-star': "url('/images/list-star.png')",
    'list-pink-zap': "url('/images/pink-flash.png')",
    'constantine-silhouette-cropped': "url('/images/constantine-silhouette-cropped.svg')",
    'logo-cloud': "url('/images/logo-cloud-bg.svg')",
    'gradient-radial': 'radial-gradient(var(--tw-gradient-stops))',
    'lightning-sm-light': "url('/images/lightning-sm-light.png')",
    'lightning-sm-dark': "url('/images/lightning-sm-dark.png')",
    electrocard: "url('/images/cards/bg-credit.svg')",
    'progress-bar': 'linear-gradient(90deg, #5840FF 0%, #EE9CF9 100%)',
    'escalated-warning': "url('/images/escalated-warning-icon.svg')",
    'issued-warning': "url('/images/issued-warning-icon.svg')",
  },
  keyframes: {
    ...defaultTheme.keyframes,
    toastEnterTop: {
      '0%': {
        transform: 'translateY(-100%)',
        opacity: 0,
      },
      '100%': {
        transform: 'translateY(0%)',
        opacity: 1,
      },
    },
    toastEnterBottom: {
      '0%': {
        transform: 'translateY(100%)',
        opacity: 0,
      },
      '100%': {
        transform: 'translateY(0%)',
        opacity: 1,
      },
    },
    buttonRipple: {
      '0%': { transform: 'scale(0)', opacity: 0.7 },
      '100%': { transform: 'scale(2)', opacity: 0 },
    },
    ellipsisLoading: {
      '0%': { content: "''" },
      '25%': { content: "'.'" },
      '50%': { content: "'..'" },
      '75%': { content: "'...'" },
      '100%': { content: "''" },
    },
  },
  borderRadius: {
    ...defaultTheme.borderRadius,
    '4xl': '2rem',
  },
  animation: {
    ...defaultTheme.animation,
    toastEnterTop: 'toastEnterTop 0.7s cubic-bezier(0,.83,.22,.97)',
    toastEnterBottom: 'toastEnterBottom 0.7s cubic-bezier(0,.83,.22,.97)',
    buttonRipple: 'buttonRipple 0.6s linear',
  },
  fontSize: {
    ...defaultTheme.fontSize,
    '2xs': ['0.6em', '1em'],
  },

  extend: {
    zIndex: {
      85: '85',
      90: '90',
      95: '95',
      100: '100',
    },
    flex: {
      1: '1 1 0%',
      2: '2 1 0%',
      3: '3 1 0%',
      4: '4 1 0%',
      5: '5 1 0%',
    },
    colors: {
      /**
       * Theme colors for the redesign
       */
      base: {
        DEFAULT: '#180048', // Hemocyanin
        dark: '#100030', // Siphon
      },
      primary: {
        DEFAULT: '#F050F8', // Soho Lights
        light: '#FA98FF', // Akoya
        dark: '#600E6B', // Plum
      },
      secondary: {
        DEFAULT: '#5840FF', // Ink
        light: '#6675F6', // Blue Tang
        dark: '#2D1A83', // Blueberry
      },
      tertiary: {
        DEFAULT: '#60F0F8', // Voltage
        light: '#BDF2FF', // Aquamarine
        shade: '#D9DDFF', // Dusky Sky
        lavender: '#A59CE6', // Lavender
      },
      action: {
        danger: '#FF3E68', // Red
        warning: '#FFA26B', // Orange
        success: '#18F0B8', // Green
      },
      info: {
        light: '#EEE2FF', // Shades of purple for beta badges etc...
        dark: '#780F93', // Shades of purple for beta badges etc...
      },
      'octo-primary-pink': {
        DEFAULT: '#FF00C7', // Octopus Energy brand primary pink
      },
      transitionProperty: {
        width: 'width',
      },
    },
  },
}
