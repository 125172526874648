import { Typography } from '@electro/shared-ui-components'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

export const TermsLabel = () => {
  const { t } = useTranslation('common')

  return (
    <Typography variant="small" as="p">
      <Trans
        i18nKey="common:common.terms_and_conditions"
        components={{
          termsLink: <Link href="/legal/terms/">{t('common.terms')}</Link>,
          privacy: <Link href="/legal/privacy/">{t('common.privacy_policy')}</Link>,
        }}
      />
    </Typography>
  )
}
