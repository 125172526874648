import { useEffect, useState } from 'react'
import { Button, Modal } from '@electro/shared-ui-components'
import { LightBulbIcon } from '@electro/consumersite/src/icons'
import { useAuth, useDeviceInformation } from '@electro/consumersite/src/hooks'

const styles = {
  root: 'mx-4 mt-1 mb-2 text-sm text-center',
  subtext: 'font-semibold',
  lightBulbIcon: 'w-5 mr-1',
  modal: {
    root: 'sm:max-w-[calc(100vw-theme(spacing.12))] backdrop-blur bg-base/70',
    iframe: 'w-full h-full min-h-[calc(100dvh-theme(spacing.32))]',
  },
}

const FEEDBACK_URL =
  'https://octopus.typeform.com/to/HOEOlIL6?typeform-embed-id=7781823332456582&typeform-embed=embed-widget&embed-hide-headers=true&embed-opacity=0&disable-tracking=true&typeform-embed-auto-resize=true&typeform-embed-disable-scroll=true'

/** Embeds a typeform within a modal to retrieve feedback regarding the map */
export const FeedbackTypeform = () => {
  const [{ session }] = useAuth()
  const { userAgent } = useDeviceInformation()

  const [feedbackURL, setFeedbackURL] = useState<string>(FEEDBACK_URL)
  const [showTypeform, setShowTypeform] = useState<boolean>(false)

  /** Include user and browser information in the form submission */
  useEffect(() => {
    if (!userAgent) return

    const user = session?.user?.email || 'unknown'
    const { deviceBrowser: browser, deviceOS: os } = userAgent
    setFeedbackURL(`${FEEDBACK_URL}&${new URLSearchParams({ user, browser, os })}`)
  }, [userAgent, session?.user?.email])

  return (
    <div className={styles.root}>
      <p>
        Got a bright idea to help us improve the Electroverse?{' '}
        <span className={styles.subtext}>Share it with us!</span>
      </p>

      <Button fullWidth onClick={() => setShowTypeform(true)}>
        <LightBulbIcon className={styles.lightBulbIcon} /> Share my idea
      </Button>

      <Modal
        open={showTypeform}
        onClose={() => setShowTypeform(false)}
        dialogClassName={styles.modal.root}
      >
        <Modal.CloseButton />
        <Modal.Body>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            src={feedbackURL}
            className={styles.modal.iframe}
            data-testid="map-feedback-typeform"
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}
