import { fireGtmEvent } from '@electro/shared/utils/fireGtmEvent'

enum GTM_EVENTS {
  SHOW_APP_QR_CODE = 'WEB::SHOW_APP_QR_CODE',
  OPEN_APPLE_STORE = 'WEB::OPEN_APPLE_STORE',
  OPEN_GOOGLE_PLAY_STORE = 'WEB::OPEN_GOOGLE_PLAY_STORE',
  SHARE_ON_SOCIALS = 'WEB::SHARE_ON_SOCIALS',
  CLICK_LOGIN_ON_NAV = 'WEB::CLICK_LOGIN_ON_NAV',
  CLICK_SIGNUP_ON_NAV = 'WEB::CLICK_SIGNUP_ON_NAV',
  OPEN_FAQ_COMPONENT = 'WEB::OPEN_FAQ_COMPONENT',
  CONTENT_SEARCH = 'WEB::CONTENT_SEARCH',
  THIRD_PARTY_LOGIN_SUCCESSFUL = 'WEB::THIRD_PARTY_LOGIN_SUCCESSFUL',
}

export const showAppQRCode = () =>
  fireGtmEvent({
    event: GTM_EVENTS.SHOW_APP_QR_CODE,
  })

export const openAppleStore = () =>
  fireGtmEvent({
    event: GTM_EVENTS.OPEN_APPLE_STORE,
  })

export const openGooglePlayStore = () =>
  fireGtmEvent({
    event: GTM_EVENTS.OPEN_GOOGLE_PLAY_STORE,
  })

export const contentSearchSubmitted = ({ searchString }: { searchString: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.CONTENT_SEARCH,
    searchString,
  })

export const thirdPartyLoginSuccessful = ({ authProvider }: { authProvider: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.THIRD_PARTY_LOGIN_SUCCESSFUL,
    authProvider,
  })

export const shareOnSocials = ({ platform, label }: { platform: string; label?: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.SHARE_ON_SOCIALS,
    platform,
    label,
  })

export const clickLoginOnNav = () =>
  fireGtmEvent({
    event: GTM_EVENTS.CLICK_LOGIN_ON_NAV,
  })

export const clickSignUpOnNav = () =>
  fireGtmEvent({
    event: GTM_EVENTS.CLICK_SIGNUP_ON_NAV,
  })

export const openFAQcomponent = ({ title }: { title?: string }) =>
  fireGtmEvent({
    event: GTM_EVENTS.OPEN_FAQ_COMPONENT,
    title,
  })
