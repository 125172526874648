import { TagColours, VibrantTagColours } from '@electro/shared-ui-components'

export const tagColourGenerator = () => {
  const tagColourGeneratorInstance = (function* vibrantTagColourGenerator() {
    let i = -1
    while (true) yield VibrantTagColours[(i += 1) % VibrantTagColours.length]
  })()

  return () => tagColourGeneratorInstance.next().value as TagColours
}
