import { useState, useCallback, useMemo, createContext, useContext, ReactNode } from 'react'
import {
  DefaultDrawerPanelsType,
  DrawerPanelsType,
} from '@electro/consumersite/src/components/Map/types'

interface State {
  visibleDrawer: DrawerPanelsType
}

interface Handlers {
  updateVisibleDrawer: (key: DrawerPanelsType) => void
}

type UseMapDrawer = [state: State, handlers: Handlers]
const UseMapDrawerContext = createContext<UseMapDrawer>(null)

function useMapDrawerProvider({ panels }: { panels: DefaultDrawerPanelsType }): UseMapDrawer {
  const [visibleDrawer, setVisibleDrawer] = useState<DrawerPanelsType>(panels?.visible ?? undefined)

  /** Set the visible map drawer, on the condition it has not been disabled for that page */
  const updateVisibleDrawer = useCallback(
    (key: DrawerPanelsType) => {
      if (!panels?.disabled.includes(key)) setVisibleDrawer(key)
    },
    [panels?.disabled],
  )

  const state = useMemo(() => ({ visibleDrawer }), [visibleDrawer])

  const handlers = useMemo(() => ({ updateVisibleDrawer }), [updateVisibleDrawer])

  return [state, handlers]
}

interface UseMapDrawerProps {
  children: ReactNode | ReactNode[]
  panels?: DefaultDrawerPanelsType
}

export const UseMapDrawerProvider = ({ children, panels }: UseMapDrawerProps) => {
  const ctx = useMapDrawerProvider({ panels })
  return <UseMapDrawerContext.Provider value={ctx}>{children}</UseMapDrawerContext.Provider>
}

export const useMapDrawer = (): UseMapDrawer => {
  const context = useContext(UseMapDrawerContext)
  if (!context) throw new Error('useMapDrawer() cannot be used outside of <UseMapDrawerProvider/>')
  return context
}
