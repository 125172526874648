import { useState, useEffect, useMemo } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useFeatureFlag, useAuth } from '@electro/consumersite/src/hooks'
import useTranslation from 'next-translate/useTranslation'
import { Button, Modal, Typography, LoadingBolt, Alert } from '@electro/shared-ui-components'
import { DebtWarningStatus, useUserQuery } from '@electro/consumersite/generated/graphql'

type ModalContentProps = {
  title: string
  srcImage: string
  altImage: string
  description: string
}

const ModalContent = ({ title, srcImage, altImage, description }: ModalContentProps) => (
  <>
    <Modal.Header>
      <Typography variant="h2" className="text-center mt-4">
        {title}
      </Typography>
    </Modal.Header>
    <Modal.Body>
      <Image src={srcImage} width={235} height={210} alt={altImage} className="mx-auto mb-4" />

      <Typography className="text-center mb-4">{description}</Typography>
    </Modal.Body>
  </>
)

export const DebtStatusModal = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [show, setShow] = useState(false)
  const { data: userData, loading, error } = useUserQuery()

  const [{ session }] = useAuth()
  const showDebtWarningFeature = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DEBT_PREVENTION === 'on',
  )

  const handleResolveDebt = async () => {
    await router.push('/user/account/payment-methods/')
    setShow(false)
  }

  const userHasIssuedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Issued,
    [userData],
  )
  const userHasEscalatedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Escalated,
    [userData],
  )

  /**
   * We only want to show this if user is:
   * logged in
   * has unresolved debt (issued or escalated)
   * has reached the Payment Methods page already
   */

  useEffect(() => {
    if (
      session &&
      (userHasIssuedDebt || userHasEscalatedDebt) &&
      !router.asPath.includes('payment-methods')
    ) {
      setShow(true)
    }
  }, [session, userHasIssuedDebt, userHasEscalatedDebt, router.asPath])

  if (show && userHasEscalatedDebt && showDebtWarningFeature) {
    return (
      <Modal data-testid="escalated" variant="error" size="md" onClose={() => setShow(false)} open>
        <Modal.CloseButton />
        {loading ? <LoadingBolt subtitle={t('common_loading')} /> : null}
        {error && !loading ? <Alert variant="error">{error.message}</Alert> : null}
        {!loading && !error ? (
          <>
            <ModalContent
              title={t('debt_status_modal.escalated.title')}
              srcImage="/images/escalated-debt-status.png"
              altImage={t('debt_status_modal.escalated.title')}
              description={t('debt_status_modal.escalated.description')}
            />
            <Modal.Actions center>
              <Button onClick={handleResolveDebt}>
                {t('debt_status_modal.review_payments_cta')}
              </Button>
            </Modal.Actions>
          </>
        ) : null}
      </Modal>
    )
  }

  if (show && userHasIssuedDebt && showDebtWarningFeature) {
    return (
      <Modal data-testid="issued" variant="warning" size="md" onClose={() => setShow(false)} open>
        <Modal.CloseButton />
        {loading ? <LoadingBolt subtitle={t('common_loading')} /> : null}
        {error && !loading ? <Alert variant="error">{error.message}</Alert> : null}
        {!loading && !error ? (
          <>
            <ModalContent
              title={t('debt_status_modal.issued.title')}
              srcImage="/images/issued-debt-status.png"
              altImage={t('debt_status_modal.issued.title')}
              description={t('debt_status_modal.issued.description')}
            />
            <Modal.Actions center>
              <Button onClick={handleResolveDebt}>
                {t('debt_status_modal.review_payments_cta')}
              </Button>
            </Modal.Actions>
          </>
        ) : null}
      </Modal>
    )
  }

  return null
}
