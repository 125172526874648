import useTranslation from 'next-translate/useTranslation'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { OperatorListPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'

export const OperatorListPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.OPERATOR_LIST}
      label={t('map.operator_list.title')}
    >
      <OperatorListPanelContent />
    </SidebarPanelContainer>
  )
}
