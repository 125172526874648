import { Locales, Locale } from '@electro/shared/types/locales'

export enum BlogTitleEnum {
  PLUG_AND_CHARGE_BLOG = 'plug_and_charge_blog',
  EASTER_EGG_HUNT_2024 = 'easter_egg_hunt_2024',
}

export interface BlogLinks {
  plug_and_charge_blog?: string
  easter_egg_hunt_2024?: string
}

export const localisedBlogMap: Record<string, BlogLinks> = {
  [Locales.EN]: {
    plug_and_charge_blog: '/community/electroverse-features/what-is-plug-and-charge',
    easter_egg_hunt_2024: '/community/news-and-competitions/electroverse-easter-zap-hunt',
  },
  [Locales.IT]: {
    plug_and_charge_blog: '/it/community/electroverse-features/che-cos-e-il-plug-charge',
    easter_egg_hunt_2024:
      '/it/community/news-and-competitions/la-caccia-del-tesoro-pasquale-con-electroverse',
  },
  [Locales.FR]: {
    plug_and_charge_blog: '/fr/community/electroverse-features/qu-est-ce-que-le-plug-charge',
    easter_egg_hunt_2024:
      '/fr/community/news-and-competitions/la-chasse-aux-zaps-de-p%C3%A2ques-delectroverse',
  },
  [Locales.ES]: {
    plug_and_charge_blog: '/es/community/electroverse-features/que-es-plug-charge',
    easter_egg_hunt_2024:
      '/es/community/news-and-competitions/b%C3%BAsqueda-del-zap-de-pascua-de-electroverse',
  },
  [Locales.DE]: {
    plug_and_charge_blog: '/de/community/electroverse-features/was-ist-plug-charge',
    easter_egg_hunt_2024:
      '/de/community/news-and-competitions/die-zap-ostereiersuche-von-electroverse',
  },
}

export const selectRegionalisedBlogLink = (locale: Locale, blogTitle: BlogTitleEnum) =>
  localisedBlogMap[locale]?.[blogTitle] ?? localisedBlogMap[Locales.EN][blogTitle]
