import { Modal, Typography, Button } from '@electro/shared-ui-components'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import useTranslation from 'next-translate/useTranslation'
import { useManageOutstandingPayments } from '@electro/consumersite/src/components/AccountPaymentsManager/components/ManageOutstandingPayments/hooks'

export const PaymentSuccessModalScreen = () => {
  const { t } = useTranslation('common')
  const { closeModalScreen } = useManageOutstandingPayments()

  return (
    <>
      <Modal.Body>
        <Typography variant="h3" as="h1" className="text-center mb-0">
          {t('profile.outstanding_payments.resolve_payment_modal.payment_success_screen.title')}
        </Typography>
        <AnimatedComponent
          name="payment-success-animation"
          lottieProps={{ className: 'h-[170px]', loop: false }}
          getAnimationData={ANIMATIONS.paymentSuccess}
        />
        <Typography className="text-center mt-2">
          {t(
            'profile.outstanding_payments.resolve_payment_modal.payment_success_screen.description',
          )}
        </Typography>
      </Modal.Body>
      <Modal.Actions center>
        <Button onClick={() => closeModalScreen()}>
          {t('profile.outstanding_payments.resolve_payment_modal.cta_buttons.done')}
        </Button>
      </Modal.Actions>
    </>
  )
}
