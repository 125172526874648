import useTranslation from 'next-translate/useTranslation'
import { Slider, NumberInput } from '@electro/shared-ui-components'
import { useRoutePlannerForm } from '@electro/consumersite/src/components/Map/hooks'

const styles = {
  root: 'flex flex-col gap-y-3',
  rangeSlider: {
    root: 'px-1',
    details: {
      root: 'flex flex-row justify-end items-end',
      numberInput: 'mr-2',
    },
  },
}

export const RoutePlannerChargeSliders = () => {
  const { t } = useTranslation('common')
  const [{ fieldValues }, { updateFormField }] = useRoutePlannerForm()

  return (
    <div className={styles.root}>
      <RangeSlider
        initialValue={fieldValues.startCharge}
        label={t('map.route_planner.sliders.start_charge')}
        onChange={(value) => updateFormField('startCharge', value)}
      />
      <RangeSlider
        initialValue={fieldValues.endCharge}
        label={t('map.route_planner.sliders.end_charge')}
        onChange={(value) => updateFormField('endCharge', value)}
      />
    </div>
  )
}

type RangeSliderProps = {
  initialValue?: number
  onChange: (value: number) => void
  label?: string
  maxValue?: number
  minValue?: number
}

const RangeSlider = ({
  initialValue = 80,
  onChange,
  label,
  maxValue = 100,
  minValue = 10,
}: RangeSliderProps) => (
  <Slider
    max={maxValue}
    min={minValue}
    name={label}
    initialValues={[initialValue]}
    onChange={(values) => onChange(values?.[0])}
  >
    {({ values, setSliderValues }) => (
      <div className={styles.rangeSlider.root}>
        <div className={styles.rangeSlider.details.root}>
          <Slider.Label>{label}</Slider.Label>
          <NumberInput
            value={values[0]}
            onChange={(e) => setSliderValues([parseInt(e.target.value, 10)])}
            className={styles.rangeSlider.details.numberInput}
            data-testid={`input-${label}`}
            isPercentage
            max={maxValue}
            min={minValue}
          />
        </div>
        <Slider.Rail />
      </div>
    )}
  </Slider>
)
