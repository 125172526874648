import { Typography } from '@electro/shared-ui-components'
import { GenericLocationInterface } from '@electro/consumersite/generated/graphql'
import Image from 'next/image'

interface OperatorLogoProps {
  chargingLocation: GenericLocationInterface
}

export const OperatorLogo = ({ chargingLocation }: OperatorLogoProps) => (
  <>
    {chargingLocation.operator.logoDark && (
      <div className="mb-4 -mt-6" data-testid="location-details-operator-logo">
        <span className="p-4 bg-white inline-block rounded-xl border-2 border-secondary drop-shadow-xl">
          <Image
            src={chargingLocation.operator.logoDark}
            alt={`${chargingLocation.operator.name} Logo`}
            width={70}
            height={70}
          />
        </span>
      </div>
    )}
    {!chargingLocation.operator.logoDark && (
      <Typography
        data-testid="location-details-operator-no-logo-placeholder"
        className="mb-2 -mt-6 w-full"
      >
        {`operator: ${chargingLocation.operator.name}`}
      </Typography>
    )}
  </>
)
