import { tw } from '@electro/shared/utils/tailwind-merge'
import { useMapConfig } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarContainer as Sidebar } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarContainer'

const styles = {
  root: 'mt-1 xs:ml-1.5 lg:mt-4 lg:ml-0',
  container: {
    // This root style is shared to a number of components for consistency
    root: {
      all: 'absolute max-w-sm w-full h-[calc(100dvh-4rem)] sm:h-[calc(100dvh-6rem)] md:h-[calc(100dvh-8rem)] lg:h-[calc(100dvh-7rem)]',
      withPadding:
        'h-[calc(100dvh-8rem)] sm:h-[calc(100dvh-10rem)] md:h-[calc(100dvh-12rem)] lg:h-[calc(100dvh-11rem)]',
    },
  },
}
export const mapSidebarContainerStyling = styles.container.root

export const MapSidebar = () => {
  const [{ showNavbar }] = useMapConfig()

  return (
    <Sidebar
      className={tw({
        [styles.container.root.all]: true,
        [styles.container.root.withPadding]: showNavbar,
        [styles.root]: true,
      })}
    >
      <Sidebar.SidebarNav>
        <Sidebar.MapIcon />
        <Sidebar.FiltersIcon />
        <Sidebar.RoutePlannerIcon />
        <Sidebar.MyEVsIcon />
        <Sidebar.ElectroverseFeaturesIcon />
        <Sidebar.FeedbackIcon />
        <Sidebar.KeyIcon />

        <Sidebar.SidebarNavLabelsIcon />
      </Sidebar.SidebarNav>

      {/* The order dictates which panels appear above/below each other */}

      {/* VISIBLE */}
      <Sidebar.FiltersPanel />
      <Sidebar.RoutePlannerPanel />
      <Sidebar.MyEVsPanel />
      <Sidebar.FeedbackPanel />
      <Sidebar.KeyPanel />

      {/* HIDDEN */}
      <Sidebar.ElectroverseFeatures />
      <Sidebar.OperatorListPanel />
      <Sidebar.RouteOverviewPanel />
      <Sidebar.RouteBreakdownPanel />
      <Sidebar.SavedRoutesPanel />
      <Sidebar.SidebarNavLabelsPanel />
    </Sidebar>
  )
}
