import Image from 'next/image'
import { Locales } from '@electro/shared/types/locales'
import useTranslation from 'next-translate/useTranslation'
import { RestrictToLocales } from '@electro/consumersite/src/components'
import {
  FeedbackMoodLevel,
  FeedbackSupportEmail,
  FeedbackTypeform,
} from '@electro/consumersite/src/components/Map/components/MapSidebar/panels/Feedback/components'

const styles = {
  header: {
    root: 'flex flex-col items-center mx-4',
    img: 'w-32 my-4',
    text: 'text-center text-sm [&>span]:font-semibold',
  },
  breakpoint: 'border-secondary',
}

export const FeedbackPanelContent = () => {
  const { t } = useTranslation('common')

  return (
    <>
      <div className={styles.header.root}>
        <Image
          alt="Zapman with a megaphone"
          src="/images/zapman-megaphone.svg"
          className={styles.header.img}
          width={137}
          height={119}
        />
        <p className={styles.header.text}>
          <span>{t('map.feedback.header.hey_there')}</span>{' '}
          {t('map.feedback.header.share_feedback')}
        </p>
      </div>

      <FeedbackMoodLevel />

      <RestrictToLocales locales={[Locales.EN]}>
        <hr className={styles.breakpoint} />
        <FeedbackTypeform />
      </RestrictToLocales>

      <hr className={styles.breakpoint} />
      <FeedbackSupportEmail />
    </>
  )
}
