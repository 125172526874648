import QUICK_AUTH_MUTATION from '@electro/consumersite/src/graphql/quickAuthTokenMutation.graphql'
import REFRESH_TOKEN_MUTATION from '@electro/consumersite/src/graphql/refreshTokenMutation.graphql'
import REVOKE_REFRESH_TOKEN_MUTATION from '@electro/consumersite/src/graphql/revokeRefreshTokenMutation.graphql'

import { useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'

import {
  Mutation,
  MutationQuickAuthArgs,
  MutationRefreshTokenArgs,
  MutationRevokeTokenArgs,
} from '@electro/consumersite/generated/graphql'

type RevokeRefreshTokenMutation = Pick<Mutation, 'revokeToken'>
type RefreshTokenMutation = Pick<Mutation, 'refreshToken'>
type QuickAuthMutation = Pick<Mutation, 'quickAuth'>

interface UseAcceptTermsAndConditions {
  revokeRefreshTokenMutation: ApolloMutationFunction<RevokeRefreshTokenMutation>
  refreshTokenMutation: ApolloMutationFunction<RefreshTokenMutation>
  quickAuthMutation: ApolloMutationFunction<QuickAuthMutation>
}

export function useFetchAuthTokens(): UseAcceptTermsAndConditions {
  const [revokeRefreshTokenMutation] = useMutation<
    RevokeRefreshTokenMutation,
    MutationRevokeTokenArgs
  >(REVOKE_REFRESH_TOKEN_MUTATION)

  const [refreshTokenMutation] = useMutation<RefreshTokenMutation, MutationRefreshTokenArgs>(
    REFRESH_TOKEN_MUTATION,
  )

  const [quickAuthMutation] = useMutation<QuickAuthMutation, MutationQuickAuthArgs>(
    QUICK_AUTH_MUTATION,
  )

  return {
    revokeRefreshTokenMutation,
    refreshTokenMutation,
    quickAuthMutation,
  }
}
