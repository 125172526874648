import { useCallback } from 'react'
import {
  PlugAndChargeSignupProvider,
  usePlugAndChargeSignup,
} from '@electro/consumersite/src/components/PlugAndChargeSignup/hooks'
import {
  PncStage,
  PncStagesEnum,
} from '@electro/consumersite/src/components/PlugAndChargeSignup/types'
import {
  ConfirmVehicleDetailsStage,
  RegLookupStage,
  IncompatibleVehicleStage,
  SuccessStage,
  VinNumberStage,
  ContractCreationErrorStage,
} from '@electro/consumersite/src/components/PlugAndChargeSignup/components'

const PlugAndChargeSignup = () => {
  const [{ activeStage }] = usePlugAndChargeSignup()

  const mapActiveStageToComponent = useCallback((stage: PncStage) => {
    const componentMap = {
      [PncStagesEnum.VEHICLE_REG_LOOKUP]: <RegLookupStage />,
      [PncStagesEnum.ENTER_VEHICLE_VIN]: <VinNumberStage />,
      [PncStagesEnum.INCOMPATIBLE_VEHICLE]: <IncompatibleVehicleStage />,
      [PncStagesEnum.CONFIRM_VEHICLE_DETAILS]: <ConfirmVehicleDetailsStage />,
      [PncStagesEnum.CONTRACT_CREATION_ERROR]: <ContractCreationErrorStage />,
      [PncStagesEnum.COMPLETE]: <SuccessStage />,
    }
    return (
      componentMap[stage] || <div className=" text-action-danger">{stage} stage does not exist</div>
    )
  }, [])

  return mapActiveStageToComponent(activeStage)
}

const PlugAndChargeSignupWithProvider = () => (
  <PlugAndChargeSignupProvider>
    <PlugAndChargeSignup />
  </PlugAndChargeSignupProvider>
)

export { PlugAndChargeSignupWithProvider as PlugAndChargeSignup }
