import { useMemo } from 'react'
import { Card } from '@electro/shared-ui-components'
import { Switch } from '@headlessui/react'
import useTranslation from 'next-translate/useTranslation'
import { tw } from '@electro/shared/utils/tailwind-merge'

import MARKETING_PREFERENCES_QUERY from '@electro/consumersite/src/graphql/marketingPreferencesQuery.graphql'
import UPDATE_MARKETING_PREFERENCES_MUTATION from '@electro/consumersite/src/graphql/updateMarketingPreferencesMutation.graphql'

import { useMutation, useQuery } from '@apollo/client'
import { useErrorNotificationEffect } from '@electro/shared/hooks'

import {
  Mutation,
  MutationUpdateMarketingPreferencesArgs,
  Query,
  QueryMarketingPreferencesArgs,
} from '@electro/consumersite/generated/graphql'

interface MarketingPreferencesProps {
  token: string
}

export const MarketingPreferences = ({ token }: MarketingPreferencesProps) => {
  const { t } = useTranslation('common')
  const [updateMarketingPreferencesMutation, updateMarketingPreferences] = useMutation<
    Pick<Mutation, 'updateMarketingPreferences'>,
    MutationUpdateMarketingPreferencesArgs
  >(UPDATE_MARKETING_PREFERENCES_MUTATION, {
    refetchQueries: [{ query: MARKETING_PREFERENCES_QUERY, variables: { token } }],
  })

  const marketingPreferences = useQuery<
    Pick<Query, 'marketingPreferences'>,
    QueryMarketingPreferencesArgs
  >(MARKETING_PREFERENCES_QUERY, {
    variables: {
      token,
    },
  })

  useErrorNotificationEffect({
    error: marketingPreferences.error,
    message: t('profile.settings.marketing.errors.marketing_preferences'),
  })
  useErrorNotificationEffect({
    error: updateMarketingPreferences.error,
    message: t('profile.settings.marketing.errors.update_marketing_preferences'),
  })

  function handleToggleEmail(subscribe: boolean) {
    const communityFeedback = marketingPreferences.data?.marketingPreferences.communityFeedback
    updateMarketingPreferencesMutation({
      variables: {
        communityFeedback,
        subscribe,
        token,
      },
    })
  }

  function handleToggleCommunityFeedback(communityFeedback: boolean) {
    const subscribe = marketingPreferences.data?.marketingPreferences.subscribed
    updateMarketingPreferencesMutation({
      variables: {
        subscribe,
        communityFeedback,
        token,
      },
    })
  }

  const emailEnabled = useMemo(
    () => marketingPreferences.data?.marketingPreferences.subscribed,
    [marketingPreferences.data],
  )

  const communityFeedbackEnabled = useMemo(
    () => marketingPreferences.data?.marketingPreferences.communityFeedback,
    [marketingPreferences.data],
  )
  const loadingPreferences = updateMarketingPreferences.loading || marketingPreferences.loading

  return (
    <div className="flex flex-grow items-center justify-center p-4">
      <Card className="w-full max-w-lg min-w-min">
        <h1>{t('profile.settings.marketing.email_preferences')}</h1>
        <h3>{t('profile.settings.marketing.marketing_emails.title')}</h3>
        <p className="text-sm">{t('profile.settings.marketing.marketing_emails.description')}</p>
        {!!marketingPreferences.data && (
          <div className="flex justify-between items-center">
            <div className="pr-2 font-medium text-white">
              {emailEnabled && t('profile.settings.marketing.subscribed')}
              {!emailEnabled && t('profile.settings.marketing.unsubscribed')}
            </div>
            <Switch
              data-testid="email-marketing-toggle"
              disabled={loadingPreferences}
              checked={emailEnabled}
              onChange={handleToggleEmail}
              className={tw({
                'ej-gradient-full': emailEnabled,
                'bg-tertiary/30': !emailEnabled,
                'cursor-not-allowed opacity-40': loadingPreferences,
                'relative inline-flex items-center h-10 rounded-full w-20': true,
              })}
            >
              <span className="sr-only">
                {emailEnabled
                  ? t('profile.settings.marketing.marketing_emails.screen_reader.unsubscribe')
                  : t('profile.settings.marketing.marketing_emails.screen_reader.subscribe')}
              </span>
              <span
                className={tw({
                  'translate-x-11': emailEnabled,
                  'translate-x-1': !emailEnabled,
                  'w-8 h-8 bg-white rounded-full inline-flex items-center justify-center': true,
                  'transition duration-200 ease-in-out': true,
                })}
              />
            </Switch>
          </div>
        )}
        <hr className="border border-tertiary-shade mt-8 mb-8" />
        <h3>{t('profile.settings.marketing.community_feedback.title')}</h3>
        <p className="text-sm">{t('profile.settings.marketing.community_feedback.description')}</p>
        {!!marketingPreferences.data && (
          <div className="flex justify-between items-center">
            <div className="pr-2 font-medium text-white">
              {communityFeedbackEnabled && t('profile.settings.marketing.subscribed')}
              {!communityFeedbackEnabled && t('profile.settings.marketing.unsubscribed')}
            </div>
            <Switch
              data-testid="community-feedback-toggle"
              disabled={loadingPreferences}
              checked={communityFeedbackEnabled}
              onChange={handleToggleCommunityFeedback}
              className={tw({
                'ej-gradient-full': communityFeedbackEnabled,
                'bg-tertiary/30': !communityFeedbackEnabled,
                'cursor-not-allowed opacity-40': loadingPreferences,
                'relative inline-flex items-center h-10 rounded-full w-20': true,
              })}
            >
              <span className="sr-only">
                {communityFeedbackEnabled
                  ? t('profile.settings.marketing.community_feedback.screen_reader.unsubscribe')
                  : t('profile.settings.marketing.community_feedback.screen_reader.subscribe')}
              </span>
              <span
                className={tw({
                  'translate-x-11': communityFeedbackEnabled,
                  'translate-x-1': !communityFeedbackEnabled,
                  'w-8 h-8 bg-white rounded-full inline-flex items-center justify-center': true,
                })}
              />
            </Switch>
          </div>
        )}
      </Card>
    </div>
  )
}
