import { Formik } from 'formik'
import * as Yup from 'yup'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import Link from 'next/link'
import { useRouter } from 'next/router'

import {
  selectRegionalisedBlogLink,
  BlogTitleEnum,
} from '@electro/consumersite/src/helpers/selectLocalisedBlog'
import { Locale } from '@electro/shared/types/locales'
import { Button, Input, Typography } from '@electro/shared-ui-components'
import { usePlugAndChargeSignup } from '@electro/consumersite/src/components/PlugAndChargeSignup/hooks'
import { formatAlphaNumericAllCaps } from '@electro/shared/utils/formatters'
import { CountryCodes } from '@electro/shared/types/countries'
import { RestrictToCountries } from '@electro/consumersite/src/components/RestrictToCountries'
import { PncStagesEnum } from '../../types'

export const VinNumberStage = () => {
  const [, { handleSubmitVin, setActiveStage }] = usePlugAndChargeSignup()
  const router = useRouter()
  const { t } = useTranslation('common')

  const validationSchema = Yup.object().shape({
    pcid: Yup.string()
      .required(t('plug_and_charge.vin_number_form.pcid_required'))
      .min(17, t('plug_and_charge.vin_number_form.pcid_length'))
      .nullable(),
  })

  const pcidInputLabel = t('plug_and_charge.vin_number_form.pcid_input_label')

  const handleBack = () => {
    setActiveStage(PncStagesEnum.VEHICLE_REG_LOOKUP)
  }

  return (
    <Formik
      initialValues={{ pcid: '' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmitVin}
    >
      {({ handleBlur, values, errors, handleSubmit, isSubmitting, setFieldValue }) => {
        const handlePcidChange = (e) => {
          const formattedPostcode = formatAlphaNumericAllCaps(e.target.value.toUpperCase())
          setFieldValue('pcid', formattedPostcode)
        }
        return (
          <form onSubmit={handleSubmit}>
            <Typography variant="h1" as="h1">
              {t('plug_and_charge.vin_number_form.title')}
            </Typography>
            <Typography variant="small" as="p" className="mb-8">
              {t('plug_and_charge.vin_number_form.pcid_instructions')}
            </Typography>
            <Typography variant="small" as="p" className="mb-8">
              {t('plug_and_charge.vin_number_form.need_help')}{' '}
              <Trans
                i18nKey="common:plug_and_charge.vin_number_form.read_our_guide"
                components={{
                  guideLink: (
                    <Link
                      href={selectRegionalisedBlogLink(
                        router.locale as Locale,
                        BlogTitleEnum.PLUG_AND_CHARGE_BLOG,
                      )}
                    >
                      {t('utility.guide')}
                    </Link>
                  ),
                }}
              />
              .
            </Typography>
            <Input
              mono
              placeholder="1OCTO41PUSS123456"
              disabled={isSubmitting}
              fullWidth
              label={pcidInputLabel}
              type="text"
              onChange={handlePcidChange}
              onBlur={handleBlur}
              value={values.pcid}
              errorMessage={errors.pcid}
              name="pcid"
              maxLength={18}
            />
            <Button fullWidth loading={isSubmitting} type="submit">
              {t('utility.submit')}
            </Button>
            <RestrictToCountries countries={[CountryCodes.GBR]}>
              <Button
                className="mt-4"
                variant="naked"
                fullWidth
                onClick={handleBack}
                loading={isSubmitting}
              >
                {t('plug_and_charge.vin_number_form.search_reg')}
              </Button>
            </RestrictToCountries>
            <RestrictToCountries countries={[CountryCodes.ITA, CountryCodes.ESP, CountryCodes.FRA]}>
              <Link
                href={{ pathname: `/` }}
                className="no-underline hover:text-base-dark text-base-dark inline-block w-full"
              >
                <Button variant="naked" className="mt-2" fullWidth loading={isSubmitting}>
                  {t('utility.exit')}
                </Button>
              </Link>
            </RestrictToCountries>
          </form>
        )
      }}
    </Formik>
  )
}
