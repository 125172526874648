import { useState, useEffect } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import Image from 'next/image'

interface ProgressBarProps {
  className?: string
  barClassName?: string
  hasIcon?: boolean
}

const styles = {
  root: 'h-4 w-full fixed top-0 left-0 z-30', // z-index value here should be always be less than the one passed in the Desktop SecondaryNav
  bar: 'h-3 w-4 bg-progress-bar relative rounded-r-sm',
  icon: 'absolute bottom-[-5px] right-0 max-w-none',
}

export const ProgressBar = ({
  className = '',
  barClassName = '',
  hasIcon = false,
}: ProgressBarProps) => {
  const [scrollTop, setScrollTop] = useState(0)

  const handleScroll = () => {
    const { documentElement } = document

    const scrolledFromTopValue = documentElement.scrollTop
    const contentPageHeight = documentElement.scrollHeight - documentElement.clientHeight
    const scrolledPercentage = (scrolledFromTopValue / contentPageHeight) * 100

    setScrollTop(scrolledPercentage)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollTop])

  return (
    <div className={tw(styles.root, className)}>
      <div className={tw(styles.bar, barClassName)} style={{ width: `${scrollTop}%` }}>
        {hasIcon ? (
          <Image
            alt="Progress bar icon"
            src="/images/progress-bar-icon.svg"
            height={28}
            width={28}
            className={styles.icon}
          />
        ) : null}
      </div>
    </div>
  )
}
