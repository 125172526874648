import Image from 'next/image'
import dynamic from 'next/dynamic'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { AvatarOptions } from '@electro/consumersite/generated/graphql'
import {
  getActiveHolidaySeason,
  getSeasonalImagePath,
} from '@electro/consumersite/src/helpers/seasonalRendering'

import CO2_SAVED_1000KG from './LottieAnimations/1000kg_co2_saved.json'
import CHARGED_5000KWH from './LottieAnimations/5000kwh_charged.json'
import BARRY_THE_BATTERY from './LottieAnimations/barry_the_battery.json'
import MAGICIAN_ZAP from './LottieAnimations/magician_zap.json'
import SHELL_COFFEE from './LottieAnimations/shell_coffee.json'

const Lottie = dynamic(() => import('lottie-react'), { ssr: false })

const avatarMap: Record<AvatarOptions, string | unknown> = {
  [AvatarOptions.FriendlyFace]: '/images/avatars/FRIENDLY_FACE.svg',
  [AvatarOptions.Constantine]: '/images/avatars/CONSTANTINE.svg',
  [AvatarOptions.Electroverse]: '/images/avatars/ELECTROVERSE.svg',
  [AvatarOptions.ElectricWheels]: '/images/avatars/ELECTRIC_WHEELS.svg',
  [AvatarOptions.BringThePower]: '/images/avatars/BRING_THE_POWER.svg',
  [AvatarOptions.Co2Saved_50Kg]: '/images/avatars/CO2_SAVED_50KG.svg',
  [AvatarOptions.Co2Saved_250Kg]: '/images/avatars/CO2_SAVED_250KG.svg',
  [AvatarOptions.Co2Saved_1000Kg]: CO2_SAVED_1000KG,
  [AvatarOptions.Charged_250Kwh]: '/images/avatars/CHARGED_250KWH.svg',
  [AvatarOptions.Charged_1000Kwh]: '/images/avatars/CHARGED_1000KWH.svg',
  [AvatarOptions.Charged_5000Kwh]: CHARGED_5000KWH,
  [AvatarOptions.Experimenter]: '/images/avatars/EXPERIMENTER.svg',
  [AvatarOptions.Explorer]: '/images/avatars/EXPLORER.svg',
  [AvatarOptions.Adventurer]: '/images/avatars/ADVENTURER.svg',
  [AvatarOptions.Connoisseur]: '/images/avatars/CONNOISSEUR.svg',
  [AvatarOptions.Battery]: BARRY_THE_BATTERY,
  [AvatarOptions.Magician]: MAGICIAN_ZAP,
  [AvatarOptions.ShellCoffee]: SHELL_COFFEE,
}

const styles = {
  root: 'w-11 h-11 flex items-center justify-center',
  avatar: 'rounded-2xl overflow-hidden [&>*]:w-[43px] [&>*]:h-[43px]',
  seasonalHat: 'absolute w-14 ml-8 mb-11',
  status: {
    root: 'absolute ml-8 mb-8 size-5 z-10', // z-10 ensures this sits above any seasonal decorations
    warning: 'bg-issued-warning',
    error: 'bg-escalated-warning',
  },
}

const matchAvatarEnumToImage = (avatarName: AvatarOptions) =>
  avatarMap[avatarName] || avatarMap.FRIENDLY_FACE

interface ProfileAvatarProps {
  avatar?: AvatarOptions
  status?: 'warning' | 'error'
}

/** Profile icons with seasonal decorations and status effects */
export const ProfileAvatar = ({ avatar, status }: ProfileAvatarProps) => {
  const avatarPathOrJson = matchAvatarEnumToImage(avatar || AvatarOptions.FriendlyFace)
  const activeHolidaySeason = getActiveHolidaySeason()

  return (
    <div className={styles.root}>
      <div className={styles.avatar}>
        {typeof avatarPathOrJson === 'string' ? (
          <img src={avatarPathOrJson} width={51} height={51} alt="" />
        ) : (
          <Lottie animationData={avatarPathOrJson} />
        )}
      </div>

      {activeHolidaySeason ? (
        <Image
          src={getSeasonalImagePath('profile-hat', activeHolidaySeason)}
          data-testid={`${activeHolidaySeason}-profile-hat`}
          alt="Seasonal profile icon hat!"
          className={styles.seasonalHat}
          height={373}
          width={403}
        />
      ) : null}

      {status ? (
        <div
          data-testid={`${status}-profile-status-icon`}
          className={tw(styles.status.root, styles.status[status])}
        />
      ) : null}
    </div>
  )
}
