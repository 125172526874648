import { forwardRef, ButtonHTMLAttributes, useId, useState, ForwardedRef } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { LoadingSpinner } from '@electro/shared-ui-components'
import { rippleEffect } from './buttonRippleEffect'

// prettier-ignore
export type ButtonVariantType = 'outline' | 'naked' | 'default' | 'raised' | 'shadow' | 'error' | 'warning'
export type ButtonSizeType = '2xs' | 'xs' | 'sm' | 'md' | 'lg'
export type ButtonType = 'button' | 'submit'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariantType
  size?: ButtonSizeType
  type?: ButtonType
  fullWidth?: boolean
  disabled?: boolean
  loading?: boolean
  overrideDisabledInteractivity?: boolean
  disableClickFeedback?: boolean
}

const styles = {
  button: {
    root: tw(
      'flex items-center justify-center w-fit relative',
      'border-2 border-transparent rounded-full overflow-hidden shadow-md',
      'text-sm font-medium normal-case tracking-wide text-white',
      'disabled:cursor-not-allowed',
    ),
    fullWidth: 'w-full',
    variant: {
      // Hardcoded colour values here mimic opacity effects on bg-base (e.g. hover:bg-secondary/90)
      default: 'bg-secondary hover:bg-[#5139ed]',
      outline: 'border-secondary hover:bg-secondary-dark',
      naked: 'shadow-none hover:bg-secondary-dark',
      shadow: 'shadow-none hover:shadow-lg hover:border-black/10 hover:text-primary-light',
      raised: 'bg-base/90 hover:bg-base-dark/90',
      error: 'bg-action-danger hover:bg-[#d32349]',
      warning: 'bg-action-warning hover:bg-[#EC9567] text-base-dark',
    },
    size: {
      '2xs': 'px-1 py-0 text-xs',
      xs: 'p-2 text-xs',
      sm: 'px-8 py-2',
      md: 'px-12 py-2',
      lg: 'px-12 py-5 text-base',
    },
    clickFeedback: {
      // For each button size, add an equivalent with 1px less padding and a 1px margin on x-axis
      '2xs': 'px-[calc(4px-1px)] mx-px',
      xs: 'px-[calc(8px-1px)] mx-px',
      sm: 'px-[calc(32px-1px)] mx-px',
      md: 'px-[calc(48px-1px)] mx-px',
      lg: 'px-[calc(48px-1px)] mx-px',
    },
  },
  loading: {
    spinner: {
      root: 'absolute mx-auto z-10',
      size: {
        // For each button size, add a 1px margin and set the bolt width so that the button doesn't change size
        '2xs': 'w-6 -my-1',
        xs: 'w-6 -my-1',
        sm: 'w-6 -my-1',
        md: 'w-7 -my-1',
        lg: 'w-14 -my-4',
      },
    },
    children: tw(
      'text-transparent disabled:cursor-default',
      '[&>*]:opacity-0 [&>*:nth-last-child(1)]:opacity-100 [&>*:nth-last-child(2)]:opacity-100',
    ),
  },
  disabled: 'absolute w-full h-full bg-black/40',
}

/** Electroverse Button */
export const Button = forwardRef(
  (
    {
      children,
      className,
      variant = 'default',
      type = 'button',
      onClick = () => {}, // Required for Formik
      size = 'md',
      fullWidth = false,
      disabled = false,
      loading = false,
      overrideDisabledInteractivity = false,
      disableClickFeedback = false,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const id = useId()
    const [showClickFeedback, setShowClickFeedback] = useState<boolean>(false)

    return (
      <button
        id={id}
        ref={ref}
        onClick={(e) => {
          onClick(e)
          if (disableClickFeedback) return
          rippleEffect(e)
          setShowClickFeedback(true)
          setTimeout(() => setShowClickFeedback(false), 50)
        }}
        disabled={!overrideDisabledInteractivity && (disabled || loading)}
        type={type}
        className={tw({
          [styles.button.root]: true,
          [styles.button.fullWidth]: fullWidth,
          [styles.button.variant[variant]]: true,
          [styles.button.size[size]]: true,
          [styles.button.clickFeedback[size]]: showClickFeedback,
          [className]: !!className,
          [styles.loading.children]: loading,
        })}
        {...rest}
      >
        {children}

        {loading ? (
          <div
            data-testid="button-loading"
            className={tw({
              [styles.loading.spinner.root]: true,
              [styles.loading.spinner.size[size]]: true,
            })}
          >
            <LoadingSpinner />
          </div>
        ) : null}

        {disabled || loading ? (
          <span data-testid="button-disabled" className={styles.disabled} />
        ) : null}
      </button>
    )
  },
)
