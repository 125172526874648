interface User {
  id?: string
  email: string
  firstName: string
  lastName: string
}

export interface UserSession {
  user: User
  expiresIn: number
  timeCreated: number
}

export function getSessionFromJwt(token: string): UserSession | null {
  if (!token) return null
  const base64User = token.split('.')[1]

  const decodedSession = JSON.parse(Buffer.from(base64User, 'base64').toString())
  const session = {
    user: {
      email: decodedSession.email,
      firstName: decodedSession.ejn_first_name,
      lastName: decodedSession.ejn_last_name,
    },
    expiresIn: decodedSession.exp,
    timeCreated: decodedSession.origIat,
  }

  return session
}

export function sessionIsValid(jwtSession: UserSession) {
  if (!jwtSession) return false
  const timeNow = parseInt((new Date().getTime() / 1000).toFixed(0), 10)
  return timeNow < jwtSession.expiresIn
}
