import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { RouteOverviewPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import useTranslation from 'next-translate/useTranslation'

export const RouteOverviewPanel = () => {
  const { t } = useTranslation('common')
  const [, { clearRoutePlan }] = useRoutePlanner()

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.ROUTE_OVERVIEW}
      label={t('map.route_overview.title')}
      onBackArrowClick={() => setTimeout(() => clearRoutePlan(), 500)}
      showStickyButtons
    >
      <RouteOverviewPanelContent />
    </SidebarPanelContainer>
  )
}
