export const LodgingIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 22 20">
    <path
      d="M21.1657 12.0983V12.0681L21.154 12.0403L19.0121 6.93308V5.96939C19.0121 3.15333 16.7088 0.85 13.8927 0.85L8.15032 0.850193C5.33427 0.850193 3.03094 3.15352 3.03094 5.96958V6.93308L0.889208 12.0403L0.877538 12.0681V12.0983V12.1043C0.874133 12.1112 0.868528 12.1228 0.863808 12.1354C0.857765 12.1516 0.85 12.1772 0.85 12.2086V12.319V18.4479C0.85 18.8344 1.16558 19.15 1.5521 19.15C1.93863 19.15 2.25421 18.8344 2.25421 18.4479V16.8863H19.844V18.4479C19.844 18.8344 20.1596 19.15 20.5461 19.15C20.9326 19.15 21.2482 18.8344 21.2482 18.4479V16.3773V16.267V16.1566V12.2916V12.2295L21.2079 12.1892C21.2072 12.1879 21.2063 12.186 21.2049 12.1833L21.2039 12.1814C21.1998 12.173 21.1929 12.1591 21.1821 12.143C21.1812 12.1403 21.1803 12.1378 21.1794 12.1354C21.1747 12.1228 21.1691 12.1112 21.1657 12.1043V12.0983ZM7.90189 5.45672H10.3195L10.3195 8.11064H7.90208C7.68123 8.11064 7.49998 7.92938 7.49998 7.70854V7.70852L7.49978 5.85882C7.49978 5.85882 7.49978 5.85881 7.49978 5.85881C7.49979 5.63796 7.68105 5.45672 7.90189 5.45672ZM4.43537 5.96928V5.9692C4.43537 3.92621 6.10755 2.25422 8.15035 2.25422H13.8928C15.9357 2.25422 17.6077 3.9264 17.6077 5.9692V6.64748H15.9475L15.9477 5.8589V5.85887C15.9477 4.86498 15.1351 4.05231 14.1411 4.05231H7.90178C6.90789 4.05231 6.09522 4.86492 6.09522 5.85887V6.61993H4.43505L4.43537 5.96928ZM2.57915 11.6169L4.08126 8.05189H6.11522C6.26636 8.8925 7.01898 9.5151 7.90218 9.5151H14.1415C15.0297 9.5151 15.7529 8.88773 15.9247 8.05189H17.9624L19.4648 11.6169H2.57915ZM14.1413 5.45672C14.3622 5.45672 14.5434 5.63798 14.5434 5.85882V7.70854C14.5434 7.92939 14.3622 8.11064 14.1413 8.11064H11.7237V5.45672H14.1413ZM2.22671 13.0211H19.8165V15.4819H2.22671V13.0211Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.3"
    />
  </svg>
)
