import { create } from 'zustand'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { PlaceDetails } from '@electro/consumersite/src/components/Map/helpers'
import { MAP_SEARCH_HISTORY } from '@electro/shared/constants'

interface State {
  searchHistory: PlaceDetails[]
  setSearchHistory: (searchHistory: PlaceDetails[]) => void
}

/**
 * Persist the search history across each searchbar given their separate providers.
 *
 * Avoids the need to repeatedly fetch the information from local storage.
 * */
export const useSearchHistoryStore = create<State>((set) => ({
  searchHistory: [],
  setSearchHistory: (searchHistory: PlaceDetails[]) => set({ searchHistory }),
}))

/** Only use this in _app.tsx or root. It acts as a provider */
export const useSearchHistoryInitialiser = () => {
  const [localStorage] = useLocalStorage<PlaceDetails[]>(MAP_SEARCH_HISTORY)
  const setSearchHistory = useSearchHistoryStore((state) => state.setSearchHistory)

  useEffect(() => {
    // Send search history persisted across sessions to the store
    if (localStorage?.length > 0) setSearchHistory(localStorage)
  }, [localStorage, setSearchHistory])
}
