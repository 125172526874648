import EVBatteryIcon from './ev-battery.svg'
import EVMaxChargeSpeedIcon from './ev-max-charge-speed.svg'
import EVRangeIcon from './ev-range.svg'
import RouteBoltIcon from './route-bolt.svg'
import RouteChargerIcon from './route-charger.svg'
import RouteDistanceIcon from './route-distance.svg'
import RouteWaypointIcon from './route-waypoint.svg'

export {
  EVBatteryIcon,
  EVMaxChargeSpeedIcon,
  EVRangeIcon,
  RouteBoltIcon,
  RouteChargerIcon,
  RouteDistanceIcon,
  RouteWaypointIcon,
}
