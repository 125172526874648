import { useState } from 'react'
import DayPicker, { DateUtils, DayModifiers } from 'react-day-picker'
import { Button } from '@electro/shared-ui-components'
import {
  format,
  Locale as DateFnsLocale,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
} from 'date-fns'

type DateRange = {
  startDate: Date
  endDate: Date
}

interface Props extends Partial<DateRange> {
  onApply: ({ startDate, endDate }: DateRange) => void
  fromDate?: Date
  toDate?: Date
  dateFnsLocale: DateFnsLocale
  buttonTextReset: string
  buttonTextApply: string
}

export const DateRangePicker = ({
  onApply,
  startDate,
  endDate,
  fromDate,
  toDate,
  dateFnsLocale,
  buttonTextReset,
  buttonTextApply,
}: Props) => {
  const [from, setFrom] = useState<Date>(startDate)
  const [to, setTo] = useState<Date>(endDate)
  const [enteredTo, setEnteredTo] = useState(null)

  function isSelectingFirstDay(fromDay: Date, toDay: Date, day: Date) {
    const isBeforeFirstDay = fromDay && DateUtils.isDayBefore(day, fromDay)
    const isRangeSelected = fromDay && toDay
    return !fromDay || isBeforeFirstDay || isRangeSelected
  }
  function handleResetClick() {
    setEnteredTo(null)
    setTo(null)
    setFrom(null)
  }

  function handleDayClick(day: Date, modifiers: DayModifiers) {
    if (modifiers.disabled) {
      return
    }
    if (from && to && day >= from && day <= to) {
      handleResetClick()
      return
    }
    if (isSelectingFirstDay(from, to, day)) {
      setEnteredTo(null)
      setTo(null)
      setFrom(day)
    } else {
      setEnteredTo(day)
      setTo(day)
    }
  }

  function handleDayMouseEnter(day: Date) {
    if (!isSelectingFirstDay(from, to, day)) {
      setEnteredTo(day)
    }
  }

  function handleApplyClick() {
    onApply({ startDate: from, endDate: to })
  }

  const modifiers = { start: from, end: enteredTo || to }
  const disabledDays = { after: new Date() }
  const selectedDays = [from, { from, to: enteredTo || to }]

  const months = Array.from({ length: 12 }, (_, i) =>
    format(new Date(2020, i), 'MMMM', { locale: dateFnsLocale }),
  )

  const weekdays = eachDayOfInterval({
    start: startOfWeek(new Date(), { locale: dateFnsLocale, weekStartsOn: 0 }),
    end: endOfWeek(new Date(), { locale: dateFnsLocale, weekStartsOn: 0 }),
  }).map((day) => format(day, 'EEEE', { locale: dateFnsLocale }))

  const shortWeekdays = eachDayOfInterval({
    start: startOfWeek(new Date(), { locale: dateFnsLocale, weekStartsOn: 0 }),
    end: endOfWeek(new Date(), { locale: dateFnsLocale, weekStartsOn: 0 }),
  }).map((day) => format(day, 'EEE', { locale: dateFnsLocale }))

  return (
    <div data-testid="date-range-picker">
      <DayPicker
        months={months}
        weekdaysLong={weekdays}
        weekdaysShort={shortWeekdays}
        className="Range"
        numberOfMonths={2}
        initialMonth={startDate}
        selectedDays={selectedDays}
        disabledDays={disabledDays}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
        fromMonth={fromDate || null}
        toMonth={toDate || null}
      />
      <div>
        {from && to && (
          <div className="grid grid-cols-2 gap-6">
            <Button fullWidth variant="naked" className="link" onClick={handleResetClick}>
              {buttonTextReset}
            </Button>
            <Button fullWidth className="link" onClick={handleApplyClick}>
              {buttonTextApply}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
