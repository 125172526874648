import { useMemo, createContext, useContext, ReactNode } from 'react'
import { MapConfigType } from '@electro/consumersite/src/components/Map/types'

interface State {
  showNavbar: boolean
  allowUserLogin: boolean
}

type UseMapConfig = [state: State]

const UseMapConfigContext = createContext<UseMapConfig>(null)

function useMapConfigProvider({
  config = { showNavbar: true, allowUserLogin: true },
}: {
  config?: MapConfigType
}): UseMapConfig {
  const state = useMemo(() => config, [config])

  return [state]
}

interface UseMapConfigProps {
  children: ReactNode | ReactNode[]
  config?: MapConfigType
}

export const UseMapConfigProvider = ({ children, config }: UseMapConfigProps) => {
  const ctx = useMapConfigProvider({ config })
  return <UseMapConfigContext.Provider value={ctx}>{children}</UseMapConfigContext.Provider>
}

export const useMapConfig = (): UseMapConfig => {
  const context = useContext(UseMapConfigContext)
  if (!context) throw new Error('useMapConfig() cannot be used outside of <UseMapConfigProvider/>')
  return context
}
