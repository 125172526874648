export const ElectroversePartnerBoltIcon = (props: React.ComponentProps<'svg'>) => (
  <svg width="13" height="20" viewBox="0 0 13 20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 11.0774C0 11.4763 0.322918 11.7802 0.740793 11.7802H5.67944L3.05817 18.8462C2.67826 19.872 3.76095 20.4228 4.44475 19.606L12.4605 9.53883C12.6314 9.34891 12.7264 9.12095 12.7264 8.91202C12.7264 8.51312 12.3845 8.20923 11.9856 8.20923H7.02802L9.64929 1.16219C10.0482 0.136485 8.9465 -0.433338 8.28168 0.402412L0.246944 10.4506C0.0949966 10.6405 0 10.8685 0 11.0774Z"
      fill="url(#paint0_linear_2726_3094)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2726_3094"
        x1="-8.65689"
        y1="10.3404"
        x2="21.6859"
        y2="10.3404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F050F8" />
        <stop offset="1" stopColor="#5840FF" />
      </linearGradient>
    </defs>
  </svg>
)
