import { useRouter } from 'next/router'
import {
  useState,
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
  FC,
  useCallback,
} from 'react'

export enum AccountManageMobileStepsEnum {
  ACCOUNT_MANAGE_MENU = 'ACCOUNT_MANAGE_MENU',
  ACCOUNT_MANAGE_CONTENT = 'ACCOUNT_MANAGE_CONTENT',
}

type AccountManageMobileStepContextType = {
  step: AccountManageMobileStepsEnum
  setStep: Dispatch<SetStateAction<AccountManageMobileStepsEnum>>
}

const AccountManageMobileStepContext = createContext<AccountManageMobileStepContextType>(null)

function useAccountManageMobileStepProvider(): AccountManageMobileStepContextType {
  const router = useRouter()
  const { menu = false } = router.query

  const [step, setStep] = useState<AccountManageMobileStepsEnum>(
    menu
      ? AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU
      : AccountManageMobileStepsEnum.ACCOUNT_MANAGE_CONTENT,
  )

  const setStepHandler = useCallback((nextStep) => {
    setStep(nextStep)
  }, [])

  return { step, setStep: setStepHandler }
}

interface Props {
  children: ReactNode | ReactNode[]
}

export const AccountManageMobileStepProvider: FC<Props> = ({ children }) => {
  const value = useAccountManageMobileStepProvider()

  return (
    <AccountManageMobileStepContext.Provider value={value}>
      {children}
    </AccountManageMobileStepContext.Provider>
  )
}

export const useAccountManageMobileStep = () => {
  const context = useContext(AccountManageMobileStepContext)

  if (!context)
    throw new Error(
      'useAccountManageMobileStep() cannot be used outside of <AccountManageMobileStepProvider/>',
    )
  return context
}
