/* eslint-disable import/no-duplicates */
import { CountryCode, CountryCodes } from '@electro/shared/types/countries'
import { Locale, Locales } from '@electro/shared/types/locales'
import { de, enGB, es, fr, it, pt } from 'date-fns/locale'
import { Locale as DateLocale } from 'date-fns'
import countries from 'i18n-iso-countries'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

interface LocaleMapResult {
  alpha3: CountryCode
  alpha2: string
  dateFormat: DateLocale
  supportEmail: string
}

const localeMap: Record<string, LocaleMapResult> = {
  [Locales.EN]: {
    alpha3: CountryCodes.GBR,
    alpha2: 'GB',
    dateFormat: enGB,
    supportEmail: 'hello@electroverse.com',
  },
  [Locales.FR]: {
    alpha3: CountryCodes.FRA,
    alpha2: 'FR',
    dateFormat: fr,
    supportEmail: 'bonjour@electroverse.com',
  },
  [Locales.FR_FR]: {
    alpha3: CountryCodes.FRA,
    alpha2: 'FR',
    dateFormat: fr,
    supportEmail: 'bonjour@electroverse.com',
  },
  [Locales.ES]: {
    alpha3: CountryCodes.ESP,
    alpha2: 'ES',
    dateFormat: es,
    supportEmail: 'hola@electroverse.com',
  },
  [Locales.IT]: {
    alpha3: CountryCodes.ITA,
    alpha2: 'IT',
    dateFormat: it,
    supportEmail: 'ciao@electroverse.com',
  },
  [Locales.DE]: {
    alpha3: CountryCodes.DEU,
    alpha2: 'DE',
    dateFormat: de,
    supportEmail: 'hallo@electroverse.com',
  },
  [Locales.PT_PT]: {
    alpha3: CountryCodes.PRT,
    alpha2: 'PT',
    dateFormat: pt,
    supportEmail: 'ola@electroverse.com',
  },
}

const countryCodeMap: { [key: string]: { locale: Locales } } = {
  [CountryCodes.GBR]: { locale: Locales.EN },
  [CountryCodes.FRA]: { locale: Locales.FR },
  [CountryCodes.ESP]: { locale: Locales.ES },
  [CountryCodes.ITA]: { locale: Locales.IT },
  [CountryCodes.DEU]: { locale: Locales.DE },
  [CountryCodes.PRT]: { locale: Locales.PT_PT },
}

export const mapLocaleToCountryDetails = (locale: Locale | string): LocaleMapResult =>
  localeMap[locale] || localeMap[Locales.EN]

export const mapAlpha3CountryCodeToLocale = (countryCode: string): Locale =>
  countryCodeMap[countryCode]?.locale || countryCodeMap[CountryCodes.GBR].locale

export const mapAlpha3CountryCodeToAlpha2 = (countryCode: string) =>
  countries.alpha3ToAlpha2(countryCode)
