import { FormikErrors } from 'formik'

interface ErrorYPosition<U> {
  yPosition: number
  fieldName: U
}

function getErrorYPositions<T, U>(errors: FormikErrors<T>): ErrorYPosition<U>[] {
  const errorPositions = []
  const hasErrors = errors && Object.keys(errors).length > 0
  if (hasErrors) {
    const fieldsWithErrors = Object.keys(errors)
    fieldsWithErrors.forEach((fieldName) => {
      /**
       * To make this a little easier for users to read.
       * We want to scroll to the Y position of the label of
       * the invalid field, not the input field itself.
       * */
      const elem = document.querySelector(`[for="${fieldName}"]`)
      const yPosition = elem ? window.pageYOffset + elem.getBoundingClientRect().top : 0

      errorPositions.push({
        yPosition,
        fieldName,
      })
    })
  }
  return errorPositions
}

export default function scrollToFirstErrorInDocument<T, U>(errors: FormikErrors<T>): void {
  const yPositionOfFirstErrorOnPage = Math.min(
    ...getErrorYPositions<T, U>(errors).map((error: ErrorYPosition<U>) => error.yPosition),
  )
  const hasErrors = errors && Object.keys(errors).length > 0
  if (hasErrors) {
    window.scroll({
      top: yPositionOfFirstErrorOnPage,
      behavior: 'smooth',
    })
  }
}
