import { useState } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { useMapFilters } from '@electro/consumersite/src/components/Map/hooks'
import { ShareLinkDrawer } from '@electro/consumersite/src/components/Map/components/ShareLinkDrawer'
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/16/solid'
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { Button } from '@electro/shared-ui-components'

const styles = {
  root: 'bg-base/10 backdrop-blur absolute left-0 bottom-0.5 w-full rounded-br-md pl-3',
  breakpoint: 'border-secondary',
  buttonGroup: {
    root: 'flex gap-x-2 mx-5',
    button: 'basis-1/2 py-1 my-3',
    icon: 'shrink-0 w-4 h-4 mr-1',
  },
}

/** Various buttons to quickly perform general actions on the filters */
export const FiltersStickyButtons = () => {
  const { t } = useTranslation('common')
  const [{ filtersSearchParams, filtersActive }, { resetFilters }] = useMapFilters()

  const [shareLink, setShareLink] = useState<string>()

  /** Opens either the device native link sharing function or the ShareLinkDrawer component */
  const onShareLink = async () => {
    const { origin, pathname, hash } = new URL(window.location.href)
    const nonEmbedPathname = pathname.replace('/embed', '')
    const updatedURL = `${origin}${nonEmbedPathname}${filtersSearchParams}${hash}`

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
    if (navigator.share && navigator.canShare && isMobile) {
      navigator.share({ url: updatedURL }).catch((e) => {
        if (e.name !== 'AbortError') console.error(e)
      })
    } else if (!shareLink) setShareLink(updatedURL)
  }

  return (
    <>
      <div className={styles.root}>
        <hr className={styles.breakpoint} />

        <div className={styles.buttonGroup.root}>
          <Button
            onClick={resetFilters}
            disabled={!filtersActive}
            className={styles.buttonGroup.button}
          >
            <ArrowPathIcon className={styles.buttonGroup.icon} />
            {t('map.common.buttons.reset')}
          </Button>

          <Button
            variant={shareLink ? 'raised' : 'outline'}
            onClick={onShareLink}
            className={styles.buttonGroup.button}
          >
            {shareLink ? (
              <>
                <XMarkIcon className={tw(styles.buttonGroup.icon, 'w-5 h-5 mr-0.5')} />
                {t('map.common.buttons.close')}
              </>
            ) : (
              <>
                <ArrowUpOnSquareIcon className={styles.buttonGroup.icon} />
                {t('map.common.buttons.share')}
              </>
            )}
          </Button>
        </div>
      </div>

      <ShareLinkDrawer link={shareLink} onClose={() => setShareLink(undefined)} />
    </>
  )
}
