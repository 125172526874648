export const StopClockIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="none" viewBox="0 0 18 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18.49A7.364 7.364 0 1 0 9 3.764a7.364 7.364 0 0 0 0 14.728Zm0 1.637a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      fill="currentColor"
    />
    <path
      d="M6.54.818c0-.452.367-.818.818-.818h3.273a.818.818 0 0 1 0 1.636H7.358A.818.818 0 0 1 6.54.818Z"
      fill="currentColor"
    />
    <path d="M7.525 1.472h2.945v1.636H7.525V1.472Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.486 5.727v4.91H7.849v-4.91h1.637ZM13.78 15.314l-4.398-2.182.728-1.465 4.397 2.181-.727 1.466Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.668 12.764a.982.982 0 1 0 0-1.964.982.982 0 0 0 0 1.964Zm0 .655a1.636 1.636 0 1 0 0-3.273 1.636 1.636 0 0 0 0 3.272Z"
      fill="currentColor"
    />
  </svg>
)
