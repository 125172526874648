import { ForwardedRef, HTMLAttributes, forwardRef } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'

interface Props extends HTMLAttributes<HTMLDivElement> {
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular'
  width?: number | string
  height?: number | string
}

export const styles = {
  root: 'animate-pulse w-full h-3 bg-tertiary-shade/40',
  text: 'rounded',
  rectangular: 'h-5',
  rounded: 'rounded h-5',
  circular: 'rounded-full',
}

export const Skeleton = forwardRef(
  (
    { className = '', variant = 'text', width, height, ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <div
      ref={ref}
      data-testid="skeleton"
      className={tw(styles.root, styles[variant], className)}
      style={{
        ...(width ? { width } : {}),
        ...(height ? { height } : {}),
      }}
      {...rest}
    />
  ),
)
