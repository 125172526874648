import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  CSSProperties,
  HTMLAttributes,
} from 'react'
import { usePopper } from 'react-popper'

interface Object<T> {
  [key: string]: T
}

interface MenuContextReturnType {
  setButtonRef: Dispatch<SetStateAction<Element>>
  setPortalRef: Dispatch<SetStateAction<Element>>
  portalStyles: Object<CSSProperties>
  portalAttributes: Object<HTMLAttributes<HTMLElement>>
}

const MenuContext = createContext<MenuContextReturnType>(null)

const useMenuProvider = (): MenuContextReturnType => {
  const [buttonRef, setButtonRef] = useState<Element>(null)
  const [portalRef, setPortalRef] = useState<HTMLElement>(null)
  const { styles: portalStyles, attributes: portalAttributes } = usePopper(buttonRef, portalRef, {
    placement: 'bottom-end',
  })

  return { setButtonRef, setPortalRef, portalStyles, portalAttributes }
}

export const MenuProvider = ({ children }) => {
  const value = useMenuProvider()

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

export const useMenu = () => {
  const context = useContext(MenuContext)

  return context
}
