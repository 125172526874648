import { HTMLAttributes, ReactNode } from 'react'
import { useStep } from '../Step/StepContext'

const styles = {
  root: 'transition ease-in-out duration-1000',
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const StepContent = ({ children, ...rest }: Props) => {
  const { active } = useStep()

  if (!active) return null

  return (
    <div className={styles.root} {...rest}>
      {children}
    </div>
  )
}
