import Link from 'next/link'
import { useMemo } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { useRoutePlanner } from '@electro/consumersite/src/components/Map/hooks'
import { GoogleMapsIcon } from '@electro/icons/24/logos'
import { Button } from '@electro/shared-ui-components'

const GOOGLE_MAPS_URL =
  'https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&origin='

const styles = {
  root: 'w-full flex justify-center mt-2.5 mb-1',
  link: {
    root: 'no-underline',
    button: 'px-4 py-0.5',
    icon: 'size-6 p-px mr-0.5',
  },
}

export const RouteBreakdownActionButtons = () => {
  const { t } = useTranslation('common')
  const [{ routeDetails, activeRouteID }] = useRoutePlanner()

  /** Formats a Google Maps Directions URL with the waypoints/chargers as stops */
  const googleMapsLink = useMemo(() => {
    const { markers } = routeDetails.routes[activeRouteID]

    const waypoints = markers.map(({ coordinates: { lat, lng } }) => `${lat},${lng}`)
    const origin = waypoints.shift()
    const destination = waypoints.pop()

    return `${GOOGLE_MAPS_URL}${origin}&destination=${destination}&waypoints=${waypoints.join('|')}`
  }, [routeDetails.routes, activeRouteID])

  return (
    <div className={styles.root}>
      <Link href={googleMapsLink} target="_blank" className={styles.link.root}>
        <Button size="2xs" className={styles.link.button}>
          <GoogleMapsIcon className={styles.link.icon} /> {t('map.route_breakdown.action_open')}{' '}
          Google Maps
        </Button>
      </Link>
    </div>
  )
}
