import { memo, useCallback } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { EjAuthorised } from '@electro/consumersite/src/components'
import { Button } from '@electro/shared-ui-components'

const styles = {
  root: 'uppercase tracking-widest shrink-0 focus:ring-0',
}

/* 
wrapping the component in React.memo and passing the onClick in the useCallback 
is to make sure that the component does not re-render when navigating across
the different pages
*/
export const SignUpButton = memo(
  ({ fullWidth = false, onClick }: { fullWidth?: boolean; onClick: () => void }) => {
    const { t } = useTranslation('common')

    const handleClick = useCallback(() => {
      onClick()
    }, [onClick])

    return (
      <EjAuthorised>
        <EjAuthorised.LoggedOut>
          <Button
            size="sm"
            className={styles.root}
            onClick={handleClick}
            data-testid="sign-up-button"
            fullWidth={fullWidth}
          >
            {t('user.sign_up')}
          </Button>
        </EjAuthorised.LoggedOut>
      </EjAuthorised>
    )
  },
)
