import { FC, ReactNode, useMemo } from 'react'
import {
  useAccountManageMobileStep,
  AccountManageMobileStepProvider,
  AccountManageMobileStepsEnum,
} from '@electro/consumersite/src/components/AccountManageLayout/hooks'
import { AccountManageNavigation } from '@electro/consumersite/src/components/AccountManageLayout/components'
import {
  Card,
  LoadingDoubleRowPlaceholder,
  LoadingOverlay,
  ProfileAvatar,
  Typography,
} from '@electro/shared-ui-components'
import { useFeatureFlag } from '@electro/consumersite/src/hooks'
import { useMedia } from 'react-use'
import electroTheme from '@electro/shared/theme/electro'
import {
  EjAuthorised,
  HideWhenSiteUnderMaintenance,
  TopNavigation,
} from '@electro/consumersite/src/components'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { DebtWarningStatus, useUserQuery } from '@electro/consumersite/generated/graphql'

const styles = {
  bg: {
    root: 'flex flex-col pt-16 lg:pt-20 min-h-screen bg-gradient-mesh',
  },
  container:
    'flex-1 flex flex-col max-w-6xl mx-2 lg:mx-0 px-4 py-4 sm:px-16 sm:py-8 lg:rounded-2xl shadow-md',
  userDebtWarning: {
    escalated: 'bg-escalated-warning',
    issued: 'bg-issued-warning',
  },
}

interface Props {
  children: ReactNode
}

const UserInfo = () => {
  const { t } = useTranslation('common')
  const { data, loading } = useUserQuery()
  return (
    <div className="flex flex-col mx-4" data-testid="account-user-info">
      {loading ? (
        <div className="min-w-[150px] h-10 mt-2">
          <LoadingDoubleRowPlaceholder rows={1} />
        </div>
      ) : (
        <>
          <Typography variant="h3">{`${data?.me?.firstName || ''} ${
            data?.me?.lastName || ''
          }`}</Typography>
          <Typography variant="small">{t('user.menu.manage_account')}</Typography>
        </>
      )}
    </div>
  )
}

const AccountManageLayout: FC<Props> = ({ children }) => {
  const mdScreenMatch = useMedia(`(min-width: ${electroTheme.screens.md})`)
  const router = useRouter()
  const { step } = useAccountManageMobileStep()
  const { data: userData } = useUserQuery()
  const showDebtWarningFeature = useFeatureFlag(
    process.env.NEXT_PUBLIC_FEATURE_FLAG_DEBT_PREVENTION === 'on',
  )
  const handleUnauthorised = () => router.push('/')

  const userHasIssuedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Issued,
    [userData?.me?.financialInfo?.debtStatus],
  )
  const userHasEscalatedDebt = useMemo(
    () => userData?.me?.financialInfo?.debtStatus === DebtWarningStatus.Escalated,
    [userData?.me?.financialInfo?.debtStatus],
  )

  let debtStatus: 'warning' | 'error' | undefined
  if (showDebtWarningFeature) {
    if (userHasIssuedDebt) debtStatus = 'warning'
    else if (userHasEscalatedDebt) debtStatus = 'error'
  }

  return (
    <div className={styles.bg.root}>
      <div className="flex flex-1">
        <TopNavigation />
        <HideWhenSiteUnderMaintenance showMaintenanceScreen>
          <main className="flex-1 flex justify-center lg:px-2 lg:pb-2">
            <EjAuthorised onUnauthorised={handleUnauthorised}>
              <EjAuthorised.Loading>
                <LoadingOverlay />
              </EjAuthorised.Loading>

              <EjAuthorised.LoggedIn>
                <Card frosted className={styles.container}>
                  <div>
                    <div className="flex flex-row">
                      <div>
                        <ProfileAvatar
                          avatar={userData?.me?.customisationOptions?.avatar}
                          status={debtStatus}
                        />
                      </div>
                      <UserInfo />
                    </div>

                    <div className="flex flex-col mt-12">
                      {mdScreenMatch ? (
                        <div className="flex flex-row">
                          <AccountManageNavigation />
                          {children}
                        </div>
                      ) : (
                        <div className="flex-1">
                          {step === AccountManageMobileStepsEnum.ACCOUNT_MANAGE_MENU ? (
                            <AccountManageNavigation />
                          ) : (
                            children
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </EjAuthorised.LoggedIn>
            </EjAuthorised>
          </main>
        </HideWhenSiteUnderMaintenance>
      </div>
    </div>
  )
}

const AccountManageLayoutWithProvider = ({ children }) => (
  <AccountManageMobileStepProvider>
    <AccountManageLayout>{children}</AccountManageLayout>
  </AccountManageMobileStepProvider>
)

export { AccountManageLayoutWithProvider as AccountManageLayout }
