import { useState } from 'react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

import { Button, Card, IconButton, Typography } from '@electro/shared-ui-components'
import { RestrictToLocales } from '@electro/consumersite/src/components'
import { Locales } from '@electro/shared/types/locales'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { RequestMagicLinkForm } from '@electro/consumersite/src/components/Signup/components'
import { useLoginStore } from '@electro/consumersite/src/hooks/stores'

interface LoginWithLinkProps {
  showTerms?: boolean
  showBusinessLink?: boolean
  showLoginWithCredentials?: boolean
  onBack?: () => void
  onMagicLinkSent?: ({ email }: { email: string }) => void
}

export const LoginWithLink = ({
  showTerms = false,
  showBusinessLink = false,
  showLoginWithCredentials = false,
  onBack,
  onMagicLinkSent,
}: LoginWithLinkProps) => {
  const [complete, setComplete] = useState(false)
  const userEmail = useLoginStore((state) => state.email)
  const { t } = useTranslation('common')
  const handleMagicLinkSent = ({ email }) => {
    setComplete(true)
    onMagicLinkSent?.({ email })
  }
  return (
    <Card
      frosted
      className="max-w-md w-[calc(100vw-theme(spacing.4))] md:w-1/2 mx-auto"
      density="low"
    >
      {!complete ? (
        <>
          {onBack ? (
            <IconButton
              className="absolute top-2 left-2 flex items-center"
              onClick={() => {
                onBack()
              }}
              aria-label="back"
            >
              <ArrowLeftIcon className="w-5 h-5 text-secondary" />
            </IconButton>
          ) : null}
          <div className="text-center">
            <Typography variant="h2" as="h1">
              {t('login_welcome_new')}
            </Typography>
            <Image
              src="/images/zap-man-black-hole.svg"
              alt="Zapman falling through a black hole"
              width={194}
              height={202}
              className="mx-auto"
            />
            <Typography variant="small" as="p">
              {t('login_subtitle')}
            </Typography>
          </div>
          <RequestMagicLinkForm
            emailAddress={userEmail}
            showTerms={showTerms}
            onComplete={handleMagicLinkSent}
          />
          {showBusinessLink ? (
            <RestrictToLocales locales={[Locales.EN]}>
              <hr className="mt-8 border-secondary" />
              <Link href="/business" className="no-underline" data-testid="business-link">
                <Button variant="outline" fullWidth className="mt-8">
                  Are you a business?
                </Button>
              </Link>
            </RestrictToLocales>
          ) : null}
        </>
      ) : (
        <div className="text-center">
          <Typography variant="h2" as="h1">
            {t('login_email_sent')}
          </Typography>
          <Image
            src="/images/mr-zap-checking-his-email.svg"
            alt="illustration of mr zap and constantine checking their emails"
            width={160}
            height={200}
            className="m-auto my-8"
          />
          <Typography variant="body" as="p">
            {t('login_magic_link')}
          </Typography>

          {showLoginWithCredentials ? (
            <Link
              data-testid="login-with-password-link"
              href="/log-in/credentials/"
              className="no-underline block mt-8"
            >
              <Button fullWidth variant="outline">
                {t('login_with_password')}
              </Button>
            </Link>
          ) : null}
        </div>
      )}
    </Card>
  )
}
