import { useCallback, memo } from 'react'
import { EjAuthorised } from '@electro/consumersite/src/components'
import { Button } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  root: 'uppercase hover:text-primary-light bg-base/70 tracking-widest shrink-0 focus:ring-0',
}

/* 
wrapping the component in React.memo and passing the onClick in the useCallback 
is to make sure that the component does not re-render when navigating across
the different pages
*/
export const LoginButton = memo(
  ({ fullWidth = false, onClick }: { fullWidth?: boolean; onClick: () => void }) => {
    const { t } = useTranslation('common')

    const handleClick = useCallback(() => {
      onClick()
    }, [onClick])

    return (
      <EjAuthorised>
        <EjAuthorised.LoggedOut>
          <Button
            size="sm"
            variant="outline"
            className={styles.root}
            onClick={handleClick}
            data-testid="login-button"
            id="login-button"
            fullWidth={fullWidth}
          >
            {t('user.log_in')}
          </Button>
        </EjAuthorised.LoggedOut>
      </EjAuthorised>
    )
  },
)
