export const ukVehicleRegistrationValidator = (postcode: string): boolean => {
  if (postcode) {
    const cleanedRegistration = postcode.replace(/ /g, '')
    const UK_VEHICLE_REGISTRATION_REGEX =
      /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/
    return cleanedRegistration.match(UK_VEHICLE_REGISTRATION_REGEX) !== null
  }

  return false
}

export const postcodeValidator = (postcode: string): boolean => {
  // Validate postcode
  // FYI this currently only works with an uppercase value
  if (postcode) {
    const cleanedPostcode = postcode.replace(/ /g, '')
    const POSTCODE_REGEX =
      /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[A-HJKMNPR-Y0-9]? *[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/
    return cleanedPostcode.match(POSTCODE_REGEX) !== null
  }

  return false
}

export const ukMobileNumberValidator = (mobileNumber: string): boolean => {
  if (mobileNumber) {
    const cleanedMobileNumber = mobileNumber.replace(/ /g, '')
    const UK_MOBILE_REGEX = /^(07[\d]{8,12})$/g
    return cleanedMobileNumber.match(UK_MOBILE_REGEX) !== null
  }

  return false
}

interface ukDrivingLicenceValidatorArgs {
  required?: boolean
}

export const ukDrivingLicenceValidator =
  ({ required = true }: ukDrivingLicenceValidatorArgs) =>
  (licenceNumber: string): boolean => {
    if (licenceNumber) {
      // eslint-disable-next-line react/destructuring-assignment
      const cleanedLicenceNumber = licenceNumber.replace(/ /g, '')
      const UK_DRIVING_LICENCE_REGEX =
        /^([A-Z]{2}[9]{3}|[A-Z]{3}[9]{2}|[A-Z]{4}[9]{1}|[A-Z]{5})[0-9]{6}([A-Z]{1}[9]{1}|[A-Z]{2})[A-Z0-9]{3}([0-9]{2})?$/g
      return cleanedLicenceNumber.match(UK_DRIVING_LICENCE_REGEX) !== null
    }

    return !required
  }

export const simplePasswordValidator = async (password: string): Promise<boolean> => {
  if (!password) return false
  const commonPassword = (await import('common-password-checker')).default
  return !commonPassword(password)
}

export const companiesHouseNumberValidator = (companiesHouseNumber: string) => {
  if (companiesHouseNumber) {
    const cleanedCompaniesHouseNumber = companiesHouseNumber.replace(/ /g, '')
    const COMPANIES_HOUSE_NUMBER_REGEX = /\d{6,8}|^[A-Z]{2}\d{6,8}/

    return cleanedCompaniesHouseNumber.match(COMPANIES_HOUSE_NUMBER_REGEX) !== null
  }

  return false
}

/**
 * Validates a UK charity commission number
 */
export const charityNumberValidator = (charityNumber: string) => {
  if (charityNumber) {
    const cleanedCharityNumber = charityNumber.replace(/ /g, '')
    const CHARITY_NUMBER_REGEX = /\d{6,8}|^[A-Z]{2}\d{6,8}/

    return cleanedCharityNumber.match(CHARITY_NUMBER_REGEX) !== null
  }

  return false
}

export const emailValidator = (email: string): boolean => {
  if (!email) return false
  const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return email.match(EMAIL_REGEX) !== null
}

// matches both http, https, www and non-www urls
export const websiteUrlRegex =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

export const ukVATNumberValidator = (vatNumber: string): boolean => {
  const vatPattern = /^([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$/
  return vatPattern.test(vatNumber)
}

/**
 * Validates Electrocard number
 */
export const electrocardNumberValidator = (electrocardNumber: string): boolean => {
  const cardNumberRegex = /^[a-fA-F0-9]{14}$/

  return cardNumberRegex.test(electrocardNumber)
}
