export enum FORM_STAGES {
  LOADING = 'LOADING',
  LANDING = 'LANDING',
  LOGIN_WITH_KRAKEN = 'LOGIN_WITH_KRAKEN',
  EXISTING_USER = 'EXISTING_USER',
  CONNECT_KRAKEN_ACCOUNT = 'CONNECT_KRAKEN_ACCOUNT',
  CREATE_NEW_EJN_ACCOUNT = 'CREATE_NEW_EJN_ACCOUNT',
  CREATE_PASSWORD = 'CREATE_PASSWORD',
  ORDER_A_JUICE_CARD_CONTINUE = 'ORDER_A_JUICE_CARD_CONTINUE',
  ORDER_A_JUICE_CARD = 'ORDER_A_JUICE_CARD',
  CONFIRMATION = 'CONFIRMATION',
  EXISTING_KRAKEN_EMAIL = 'EXISTING_KRAKEN_EMAIL',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  RETURNING_USER_LOGGED_IN = 'RETURNING_USER_LOGGED_IN',
}

export type FormStagesType = keyof typeof FORM_STAGES

export enum OrderCardFormFieldNames {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  ADDRESS1 = 'address1',
  ADDRESS_CITY = 'address2',
  ADDRESS_REGION = 'address3',
  POSTCODE = 'postcode',
  COUNTRY_CODE = 'countryCode',
}

export interface OrderCardFormFields {
  [OrderCardFormFieldNames.FIRST_NAME]: string
  [OrderCardFormFieldNames.LAST_NAME]: string
  [OrderCardFormFieldNames.ADDRESS1]: string
  [OrderCardFormFieldNames.ADDRESS_CITY]: string
  [OrderCardFormFieldNames.ADDRESS_REGION]: string
  [OrderCardFormFieldNames.POSTCODE]: string
  /**
   * TODO: make countryCode required once the new signup flow is in place
   */
  [OrderCardFormFieldNames.COUNTRY_CODE]?: string
}
export type keysOfOrderCardFormFields = keyof OrderCardFormFields

export interface LoginFormData {
  email: string
  password: string
}

export interface PasswordFormFields {
  password: string
}
