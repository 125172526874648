import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { Button, Typography } from '@electro/shared-ui-components'
import { usePlugAndChargeSignup } from '../../hooks'

export const SuccessStage = () => {
  const [{ vehicle }] = usePlugAndChargeSignup()
  const { t } = useTranslation('common')
  return (
    <>
      <Image
        className="m-auto mt-4"
        src="/images/zap-man-success.png"
        width={267}
        height={248}
        alt="zap man with confetti"
      />
      <Typography variant="h1" as="h1">
        {t('plug_and_charge.success_stage.title')}
      </Typography>
      <div className="text-2xl font-medium mb-4">{vehicle?.regNumber}</div>
      <Typography variant="p" as="p">
        {vehicle?.info ? (
          <>
            {t('plug_and_charge.success_stage.certificate_created_vehicle_known')}{' '}
            {vehicle?.info?.colour} {vehicle?.info?.make} {vehicle?.info?.model} (
            {vehicle?.info?.yearOfManufacture}).
          </>
        ) : (
          <>{t('plug_and_charge.success_stage.certificate_created_vehicle_unknown')}</>
        )}
      </Typography>
      <Typography variant="p" as="p">
        {t('plug_and_charge.success_stage.we_will_send_an_email_when_ready')}
      </Typography>
      <Typography variant="p" as="p">
        {' '}
        {t('plug_and_charge.success_stage.sit_back_and_relax')}
      </Typography>
      <div className="flex w-full justify-center py-6">
        <Link
          href={{ pathname: `/` }}
          className="no-underline hover:text-base-dark text-base-dark inline-block"
        >
          <Button variant="outline">{t('plug_and_charge.success_stage.exit_form_button')}</Button>
        </Link>
      </div>
    </>
  )
}
