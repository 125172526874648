import { useState, useEffect, useMemo, createContext, useContext, ReactNode } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { KrakenAccountType } from '@electro/consumersite/generated/graphql'

import { Button, Checkbox } from '@electro/shared-ui-components'
import { TermsLabel } from '@electro/consumersite/src/components/Signup/components'
import { UserIcon } from '@electro/consumersite/src/icons'

import { useFetchKrakenAccounts } from '@electro/consumersite/src/services'
import useTranslation from 'next-translate/useTranslation'

interface SelectKrakenAccountProps {
  onSubmit: (account: KrakenAccountType) => void
  children: ReactNode | ReactNode[]
  showTermsAndConditions?: boolean
}
const styles = {
  list: {
    root: 'flex flex-col w-full gap-2 pt-2 pb-2',
    item: 'block',
    itemButton: tw(
      'flex items-center',
      'rounded-2xl border',
      'text-left px-4 w-full py-4',
      'disabled:opacity-50',
      'transition duration-300 ease-in-out',
      'border-secondary border-2 ring-1 ring-secondary',
      'focus:outline-none focus:ring-1 focus:bg-secondary focus:text-white focus:border-tertiary focus:ring-tertiary',
      'hover:ring-secondary',
    ),
  },
  inactiveListItemButton: 'bg-transparent border-secondary text-white hover:border-tertiary',
  activeListItemButton: 'outline-none ring-0 bg-secondary text-white',
  userIcon: 'w-10 h-10 pr-4',
  checkbox: 'mb-4',
}

const SelectKrakenAccountContext = createContext(null)

function generateValidationSchema({ showTermsAndConditions }: { showTermsAndConditions: boolean }) {
  const validationSchema = {
    terms: Yup.boolean().oneOf([true], 'You must Accept Terms and Conditions and Privacy Policy.'),
  }

  return showTermsAndConditions ? Yup.object().shape(validationSchema) : null
}

const SelectKrakenAccount = ({
  onSubmit,
  children,
  showTermsAndConditions = true,
}: SelectKrakenAccountProps) => {
  const [selectedAccount, setSelectedAccount] = useState({} as KrakenAccountType)
  const { loading, error, data } = useFetchKrakenAccounts()

  const formik = useFormik({
    initialValues: {
      terms: false,
    },
    validationSchema: generateValidationSchema({
      showTermsAndConditions,
    }),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: () => onSubmit(selectedAccount),
  })

  const hasSingleAccount = useMemo(() => data?.krakenAccounts.length === 1, [data])

  const context = useMemo(
    () => ({
      showTermsAndConditions,
      selectedAccount,
      setSelectedAccount,
      hasSingleAccount,
      loading,
      error,
      data,
      formik,
    }),
    [data, error, formik, hasSingleAccount, loading, selectedAccount, showTermsAndConditions],
  )

  return (
    <SelectKrakenAccountContext.Provider value={context}>
      {children}
    </SelectKrakenAccountContext.Provider>
  )
}

const List = () => {
  const { selectedAccount, setSelectedAccount, error, data, hasSingleAccount } = useContext(
    SelectKrakenAccountContext,
  )

  useEffect(() => {
    if (hasSingleAccount) setSelectedAccount(data?.krakenAccounts[0])
  }, [data, hasSingleAccount, setSelectedAccount])

  if (error) {
    return <div className="text-action-danger">{error.message}</div>
  }

  return (
    <ul data-testid="selectKrakenAccount" className={styles.list.root} aria-label="account list">
      {data?.krakenAccounts?.map((account: KrakenAccountType) => (
        <li className={styles.list.item} key={account.pk}>
          <button
            data-testid="selectAccountButton"
            className={tw({
              [styles.inactiveListItemButton]: selectedAccount?.pk !== account.pk,
              [styles.activeListItemButton]: selectedAccount?.pk === account.pk,
              [styles.list.itemButton]: true,
            })}
            onClick={() => setSelectedAccount(account)}
          >
            <UserIcon className={styles.userIcon} />
            <div>
              <div className="font-medium">{`${account.billingAddressLine1} / ${account.billingAddressPostcode}`}</div>
              <div className="text-sm pt-1 font-light">{account.number}</div>
            </div>
          </button>
        </li>
      ))}
    </ul>
  )
}

const Terms = () => {
  const { formik } = useContext(SelectKrakenAccountContext)
  return (
    <>
      <div className="text-white mt-4 text-sm">
        <p className="mb-0">
          We will use the following details for Electroverse:
          <br />
          name, address, contact details, billing details and account number.
        </p>
      </div>
      <div className={styles.checkbox}>
        <Checkbox
          name="terms"
          value=""
          checked={formik.values.terms}
          errorMessage={formik.errors.terms}
          onCheckboxChange={() => formik.setFieldValue('terms', !formik.values.terms)}
          label={<TermsLabel />}
        />
      </div>
    </>
  )
}

const Header = () => {
  const { t } = useTranslation('common')
  return <div>{t('select_account_header')}</div>
}

const SubmitButton = () => {
  const { t } = useTranslation('common')
  const { selectedAccount, loading, formik, showTermsAndConditions } = useContext(
    SelectKrakenAccountContext,
  )

  return (
    <div>
      {showTermsAndConditions ? <Terms /> : null}
      <Button
        aria-label="submit"
        fullWidth
        onClick={formik.submitForm}
        loading={!selectedAccount?.pk || loading}
      >
        {t('common_continue_button_title')}
      </Button>
    </div>
  )
}

SelectKrakenAccount.Header = Header
SelectKrakenAccount.List = List
SelectKrakenAccount.SubmitButton = SubmitButton

export { SelectKrakenAccount }
