import { Button, Typography } from '@electro/shared-ui-components'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

export const ElectrocardNoPaymentMethodNotice = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  return (
    <>
      {t('profile.electrocards.no_payment_method_message')
        .split('\\n')
        .map((text) => (
          <Typography variant="body" as="p" key={text}>
            {text}
          </Typography>
        ))}
      <Button className="mt-8" onClick={() => router.push('/user/account/payment-methods')}>
        {t('profile.payment_methods.payment_method_actions.add_payment_method')}
      </Button>
    </>
  )
}
