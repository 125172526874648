import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useClickAway } from 'react-use'
import { Menu } from '@headlessui/react'

import { useTouchDevice } from '@electro/shared/hooks'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useMapConfig, useMapSearch } from '@electro/consumersite/src/components/Map/hooks'
import { IconButton, Tooltip } from '@electro/shared-ui-components'
import {
  SearchItemsContainer,
  HistoryMenuItems,
  SearchMenuItems,
} from '@electro/consumersite/src/components/Map/components/MapLocationSearch/components'
import NoResultsSadness from '@electro/consumersite/public/images/no-results-sadness.svg'

const styles = {
  root: 'w-full cursor-text',
  searchbox: {
    root: {
      all: 'relative bg-base z-30 p-1',
      map: 'rounded-full',
      routePlanner: 'rounded-2xl',
      isActiveState: 'z-40',
    },
    border: {
      all: 'flex items-center ring-2 ring-secondary',
      map: 'rounded-full',
      routePlanner: 'rounded-xl',
      invalid: 'ring-action-danger',
    },
    input: {
      all: 'bg-base p-1.5 flex-grow outline-none placeholder:opacity-50 text-ellipsis',
      map: 'rounded-full',
      routePlanner: 'rounded-xl pl-2',
    },
    searchIcon: 'w-6 h-6 ml-2',
    closeIcon: {
      map: 'mr-2',
    },
    forwardSlashTooltip: tw(
      'mr-3 py-px px-1.5 cursor-help select-none',
      'text-white/70 bg-slate-600/30 hover:bg-slate-600/50 ring-1 ring-white/30 rounded-lg',
    ),
  },
  list: {
    noResults: {
      root: 'text-center mt-1',
      svg: 'mx-auto my-4',
      title: 'mb-0',
      subtitle: '!text-white/80 mb-0',
    },
  },
}

export const LocationSearch = ({ className }: { className?: string }) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [{ allowUserLogin }] = useMapConfig()
  const isTouchDevice = useTouchDevice()

  const menuItemsRef = useRef<HTMLDivElement>(null)
  const searchInputElemRef = useRef<HTMLInputElement>(null)
  const searchContainerElemRef = useRef<HTMLDivElement>(null)

  const [
    {
      target,
      placeholder,
      showSearchHistory,
      showSearchResults,
      showSearchNotFound,
      search,
      placeSuggestions,
      selectedPlace,
    },
    {
      setShowSearchHistory,
      handleLocationSearchInputChange,
      handleLocationSearchInputClick,
      handleCloseSearch,
      handleClearSearch,
    },
  ] = useMapSearch()

  const [isActiveState, setIsActiveState] = useState<boolean>(null)
  const [isErrorState, setIsErrorState] = useState<boolean>(false)

  /** Close searchbar and show an error border if they did not select a PlaceSuggestion */
  const handleCloseMenuItems = useCallback(() => {
    handleCloseSearch()
    setIsErrorState(search.length > 0 && !selectedPlace)
  }, [handleCloseSearch, search.length, selectedPlace])

  /** Increases the z-index when in-use. This allows another searchbar to sit underneath the menu items. */
  useEffect(() => {
    if (showSearchNotFound || showSearchHistory || showSearchResults) {
      setIsActiveState(true)
    } else setTimeout(() => setIsActiveState(false), 350) // The timeout corresponds to the closing animation of the menu items
  }, [showSearchNotFound, showSearchHistory, showSearchResults, setIsActiveState])

  /** Prevent displaying the menu items if user clicks off the searchbar during debounce hook */
  useEffect(() => {
    if (document.activeElement?.id !== searchInputElemRef.current?.id) handleCloseMenuItems()
  }, [placeSuggestions, handleCloseMenuItems])

  /**
   * Handles specific cases for KeyDown when focused on the searchbar.
   * E.g. Changing focus to menu items
   */
  const handleLocationSearchInputKeyDown = (event) => {
    setIsErrorState(false)
    if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
      const keyboardEvent = new KeyboardEvent('keydown', { key: event.key, bubbles: true })
      menuItemsRef.current.dispatchEvent(keyboardEvent)
    }
  }

  /** Moves focus to the searchbar and displays the relevant menu items */
  const simulateClickSearchbar = useCallback(() => {
    // Ensures it is not null to prevent the menu items appearing above if it is opened too early
    if (isActiveState === false) {
      handleLocationSearchInputClick()
      searchInputElemRef.current?.focus()
    }
  }, [isActiveState, handleLocationSearchInputClick])

  /** Clear the searchbar, results, and map pins */
  const handleClearSearchButton = useCallback(() => {
    handleClearSearch()
    if (isActiveState) simulateClickSearchbar()
  }, [handleClearSearch, isActiveState, simulateClickSearchbar])

  /** Sets focus on the searchbar when typing '/' anywhere on the map
   * and closes the menu items when pressing 'Escape' */
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (document.activeElement?.id !== searchInputElemRef.current?.id && event.key === '/') {
        simulateClickSearchbar()
        event.preventDefault()
      } else if (isActiveState && event.key === 'Escape') {
        searchInputElemRef.current?.blur()
        handleCloseMenuItems()
      }
    }
    if (target === 'map') document.addEventListener('keydown', handleKeydown)
    return () => document.removeEventListener('keydown', handleKeydown)
  }, [simulateClickSearchbar, isActiveState, handleCloseMenuItems, target])

  /** Removes focus and closes menu list when clicking away from searchbar */
  useClickAway(searchContainerElemRef, () => handleCloseMenuItems())

  /** Clear all results and error states if the user clears their search */
  useEffect(() => {
    if (search.length === 0) {
      handleClearSearch()
      setIsErrorState(false)
      if (document.activeElement?.id === searchInputElemRef.current?.id) {
        setShowSearchHistory(true)
      }
    }
  }, [search, handleClearSearch, setShowSearchHistory])

  /** EASTER EGG: Sends the user to a GeoGuessr clone to guess their location near a charger */
  useEffect(() => {
    if (target === 'map' && allowUserLogin && search.toLocaleLowerCase() === 'the electroverse') {
      router.push('/where-in-the-universe')
    }
  }, [target, allowUserLogin, search, router])

  /* On non-touch devices, show a quick action tooltip on the map searchbar when it is not active.
   * The tooltip is hidden on embedded maps as you'd need to be focused on the embed window for it to work */
  const showForwardSlashTooltip =
    target === 'map' &&
    !router.asPath?.includes('embed') &&
    search.length === 0 &&
    !isActiveState &&
    !isTouchDevice

  return (
    <search
      data-testid={`${target}-location-search`}
      className={tw(styles.root, className)}
      ref={searchContainerElemRef}
    >
      <Menu>
        <div
          className={tw({
            [styles.searchbox.root.all]: true,
            [styles.searchbox.root[target]]: true,
            [styles.searchbox.root.isActiveState]: isActiveState,
          })}
        >
          <div
            className={tw({
              [styles.searchbox.border.all]: true,
              [styles.searchbox.border[target]]: true,
              [styles.searchbox.border.invalid]: isErrorState,
            })}
          >
            {target === 'map' ? (
              <MagnifyingGlassIcon className={styles.searchbox.searchIcon} />
            ) : null}

            <input
              id="search-input"
              aria-label="search-input"
              ref={searchInputElemRef}
              type="text"
              value={search}
              placeholder={placeholder}
              className={tw(styles.searchbox.input.all, styles.searchbox.input[target])}
              onChange={handleLocationSearchInputChange}
              onKeyDown={handleLocationSearchInputKeyDown}
              onClick={simulateClickSearchbar}
              data-hj-allow
            />

            {search.length > 0 ? (
              <IconButton
                onClick={handleClearSearchButton}
                className={styles.searchbox.closeIcon[target]}
                aria-label="Clear search text"
                size="sm"
              >
                <XMarkIcon />
              </IconButton>
            ) : null}

            {showForwardSlashTooltip ? (
              <Tooltip>
                <Tooltip.Trigger delay={300} ariaLabel="Forward slash keyboard shortcut">
                  <kbd className={styles.searchbox.forwardSlashTooltip}>/</kbd>
                </Tooltip.Trigger>
                <Tooltip.Body width={250}>{t('map.search.keyboard_shortcut_tooltip')}</Tooltip.Body>
              </Tooltip>
            ) : null}
          </div>
        </div>

        <SearchItemsContainer show={showSearchNotFound || showSearchHistory || showSearchResults}>
          {showSearchNotFound ? (
            <div className={styles.list.noResults.root}>
              <NoResultsSadness className={styles.list.noResults.svg} />
              <h3 className={styles.list.noResults.title}>{t('map.search.no_results_title')}</h3>
              <p className={styles.list.noResults.subtitle}>{t('map.search.try_again_message')}</p>
            </div>
          ) : null}

          <HistoryMenuItems ref={menuItemsRef} />

          <SearchMenuItems ref={menuItemsRef} />
        </SearchItemsContainer>
      </Menu>
    </search>
  )
}
