import { Menu, Typography, Skeleton } from '@electro/shared-ui-components'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import {
  EjnCardEdge,
  EjnEjnTokenStatusChoices,
  useElectrocardsQuery,
  ElectrocardsDocument,
  useCancelElectrocardMutation,
} from '@electro/consumersite/generated/graphql'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import useTranslation from 'next-translate/useTranslation'
import { CardItem } from '@electro/consumersite/src/components/AccountElectrocardsManager/components'
import { electrocardNumberValidator } from '@electro/shared/utils/validators'

const styles = {
  root: 'px-1',
}

const LoadingSkeleton = () => (
  <div data-testid="loading-electrocards">
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-2" />
    <Skeleton height={105} className="mb-0" />
    <div className="my-8 grid grid-cols-1 gap-4 lg:grid-cols-2 items-center">
      <Skeleton height={39} variant="circular" />
      <Skeleton height={39} variant="circular" />
    </div>
  </div>
)

export const ManageElectrocards = () => {
  const { t } = useTranslation('common')
  const { loading, data, error } = useElectrocardsQuery({ fetchPolicy: 'network-only' })
  const electrocards = data?.ejnCards.edges ?? []

  const [cancelElectrocard, { loading: cancelElectrocardLoading, error: cancelElectrocardError }] =
    useCancelElectrocardMutation({
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
      refetchQueries: [ElectrocardsDocument],
    })

  const handleCancelElectrocard = (referenceNumber: number) => () => {
    cancelElectrocard({ variables: { referenceNumber } })
  }

  useErrorNotificationEffect({
    error: cancelElectrocardError,
    message: 'Could not cancel electrocard!', // should this message be localized?
  })

  return (
    <div data-testid="manage-electrocards" className={styles.root}>
      {error && <div className="text-action-danger">{error?.message}</div>}
      {loading && <LoadingSkeleton />}
      {electrocards.length === 0 && (
        <Typography variant="small">{t('profile.electrocards.no_electrocards_message')}</Typography>
      )}

      {!error &&
        !loading &&
        electrocards.map((electrocard: EjnCardEdge) => {
          const { id, nameOnCard, number, status, pk } = electrocard.node
          const isActiveOrIsPending =
            status === EjnEjnTokenStatusChoices.Active ||
            status === EjnEjnTokenStatusChoices.Pending
          const electrocardNumberIsValid = electrocardNumberValidator(number)

          return (
            <CardItem key={id} nameOnCard={nameOnCard} number={number} cardStatus={status}>
              <Menu>
                <Menu.Button noPadding data-testid="electrocard-menu-button" chevron={false}>
                  <EllipsisVerticalIcon className="h-6 w-6" />
                </Menu.Button>
                <Menu.MenuItemList>
                  <Menu.MenuItem
                    disabled={
                      cancelElectrocardLoading || !electrocardNumberIsValid || !isActiveOrIsPending
                    }
                    onSelect={handleCancelElectrocard(pk)}
                  >
                    {t(
                      cancelElectrocardLoading
                        ? 'utility.loading_message'
                        : 'utility.button.cancel',
                    )}
                  </Menu.MenuItem>
                </Menu.MenuItemList>
              </Menu>
            </CardItem>
          )
        })}
    </div>
  )
}
