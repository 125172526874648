import { useState, MouseEvent } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { BatteryIcon } from '@electro/consumersite/src/icons'
import { MinusCircleIcon, TrashIcon } from '@heroicons/react/16/solid'
import { Button, IconButton, Tooltip } from '@electro/shared-ui-components'
import { SavedRouteMetadata } from '@electro/consumersite/generated/graphql'
import { useRoutePlanner, useSavedRoutes } from '@electro/consumersite/src/components/Map/hooks'
import RouteWaypointIcon from '@electro/consumersite/public/images/markers/route-waypoint.svg'

const styles = {
  root: 'w-full flex-col shrink-0 bg-base rounded-lg p-2 text-left',
  header: {
    root: 'w-full flex justify-between items-center',
    text: {
      root: 'text-xs ml-1.5 mb-0.5 [&>p]:mb-0',
      title: 'text-primary-light font-semibold',
    },
    removeButton: {
      minusIcon: '!w-5 !h-5 mr-1 fill-secondary hover:fill-action-danger',
      trashIcon: 'w-4 h-4 inline-block mr-0.5 mb-1',
    },
  },
  breakpoint: 'w-full border-secondary my-1 mx-1',
  waypoints: {
    root: 'w-full',
    dottedLine:
      'absolute w-0.5 h-full py-12 -mt-[29px] ml-[11px] vertical-dashed-line bg-clip-content',
    stop: {
      root: 'flex items-center gap-x-1.5 mb-1',
      icon: 'shrink-0 z-10 py-px',
      address: 'mb-0 text-sm font-semibold line-clamp-2 w-full',
      battery: {
        root: 'min-w-7 ml-auto mr-1 flex flex-col items-center text-xs font-mono font-semibold',
        icon: 'w-6 h-6 -mb-1.5 ml-0.5',
      },
    },
  },
}

export const SavedRoutesSummaryButton = ({ route }: { route: SavedRouteMetadata }) => {
  const { t } = useTranslation('common')
  const [, { submitRoutePlan }] = useRoutePlanner()
  const [, { removeSavedRoute }] = useSavedRoutes()

  const [loading, setLoading] = useState<boolean>(false)

  /** Removes the route from their saved list.
   *  Also blocks it being opened until the list is updated */
  const onRemoveSavedRoute = (e: MouseEvent<HTMLButtonElement>) => {
    setLoading(true)
    e.stopPropagation()
    removeSavedRoute(route.planUuid)
  }

  return (
    <Button
      variant="raised"
      className={styles.root}
      onClick={() => submitRoutePlan({ savedPlan: route })}
      loading={loading}
    >
      <div className={styles.header.root}>
        <div className={styles.header.text.root}>
          <h4
            className={styles.header.text.title}
          >{`${route.vehicle?.vehicleMake} ${route.vehicle?.vehicleModel}`}</h4>
          <p>{route.vehicle?.vehicleModelVersion}</p>
        </div>

        <Tooltip>
          <Tooltip.Trigger ariaLabel="remove">
            <IconButton size="xs" onClick={onRemoveSavedRoute}>
              <MinusCircleIcon className={styles.header.removeButton.minusIcon} />
            </IconButton>
          </Tooltip.Trigger>
          <Tooltip.Body>
            <TrashIcon className={styles.header.removeButton.trashIcon} />
            {t('map.saved_routes.remove')}
          </Tooltip.Body>
        </Tooltip>
      </div>

      <hr className={styles.breakpoint} />

      <div className={styles.waypoints.root}>
        <div className={styles.waypoints.dottedLine} />

        {route?.waypointNames?.map((address, index) => (
          <span className={styles.waypoints.stop.root} key={address}>
            <RouteWaypointIcon className={styles.waypoints.stop.icon} />

            <p className={styles.waypoints.stop.address}>{address}</p>

            <span className={styles.waypoints.stop.battery.root}>
              {index === 0 ? (
                <>
                  <BatteryIcon
                    percentage={route?.initialSocPerc}
                    className={styles.waypoints.stop.battery.icon}
                  />
                  {route?.initialSocPerc}%
                </>
              ) : null}

              {index === route.waypointNames.length - 1 ? (
                <>
                  <BatteryIcon
                    percentage={route?.arrivalSocPerc}
                    className={styles.waypoints.stop.battery.icon}
                  />
                  {route?.arrivalSocPerc}%
                </>
              ) : null}
            </span>
          </span>
        ))}
      </div>
    </Button>
  )
}
