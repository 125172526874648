/* eslint-disable no-underscore-dangle */
import {
  useState,
  useCallback,
  useMemo,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useRef,
} from 'react'
import router from 'next/router'
import { useMap } from 'react-map-gl'
import useTranslation from 'next-translate/useTranslation'
import { useToastNotification } from '@electro/shared-ui-components'
import { GeolocateControl as MapBoxGeolocateControl } from 'mapbox-gl'
import { fetchReverseLookup } from '@electro/shared/services/mapbox'
import {
  PlaceDetails,
  formatMapboxSearchToPlaceDetails,
} from '@electro/consumersite/src/components/Map/helpers'

interface State {
  geoControlRef: React.MutableRefObject<MapBoxGeolocateControl>
  currentGeolocation: GeolocationPosition
  geolocationPermission: PermissionState
  currentLocationDetails: PlaceDetails
}

interface Handlers {
  onGeolocate: (position: GeolocationPosition) => void
  onError: (error: GeolocationPositionError) => void
}

type UseCurrentLocation = [state: State, handlers: Handlers]

const UseCurrentLocationContext = createContext<UseCurrentLocation>(null)

function useCurrentLocationProvider(): UseCurrentLocation {
  const { t } = useTranslation('common')
  const { showToastNotification } = useToastNotification()

  const { baseMap } = useMap()
  const geoControlRef = useRef<MapBoxGeolocateControl>()

  const [onFirstTrigger, setOnFirstTrigger] = useState<boolean>(true)
  const [currentGeolocation, setCurrentGeolocation] = useState<GeolocationPosition>()
  const [currentLocationDetails, setCurrentLocationDetails] = useState<PlaceDetails>()
  const [geolocationPermission, setGeolocationPermission] = useState<PermissionState>()

  /** Retrieve existing geolocation permissions on page load */
  useEffect(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: 'geolocation' }).then((res) => {
        setGeolocationPermission(res.state)
      })
    }
  }, [setGeolocationPermission])

  /** Format user geolocation lat/lng into PlaceDetails type */
  const retrieveCurrentLocationPlaceDetails = useCallback(
    async (geolocation: GeolocationPosition) => {
      const { locale } = router
      const { longitude, latitude } = geolocation.coords

      const { features } = await fetchReverseLookup({ lng: longitude, lat: latitude, locale })
      const postcodeDetails = formatMapboxSearchToPlaceDetails([features[0].properties])[0]

      const updatedPlaceDetails = {
        ...postcodeDetails,
        name: t('map.search.current_location'),
        coordinates: { lat: latitude, lng: longitude },
        hasCustomMarker: true,
      }

      setCurrentLocationDetails(updatedPlaceDetails)
      return updatedPlaceDetails
    },
    [t],
  )

  /** Activate current location marker on map load. Requires permanent geolocation permission */
  useEffect(() => {
    baseMap?.on('load', () => {
      // @ts-ignore - Delete the camera private function. We'll handle it ourselves with flyTo()
      if (geoControlRef.current) geoControlRef.current._updateCamera = () => {}
      if (geolocationPermission === 'granted') geoControlRef.current?.trigger()
    })
  }, [baseMap, geolocationPermission])

  /** Geolocation API - On success, store the information in state and update permissions */
  const onGeolocate = useCallback(
    async (position: GeolocationPosition) => {
      if (onFirstTrigger) {
        setOnFirstTrigger(false)
        setCurrentGeolocation(position)
        setGeolocationPermission('granted')
        retrieveCurrentLocationPlaceDetails(position)
      }
    },
    [
      onFirstTrigger,
      retrieveCurrentLocationPlaceDetails,
      setCurrentGeolocation,
      setGeolocationPermission,
    ],
  )

  /** Geolocation API - On failure, show a toast notification and update permissions */
  const onError = useCallback(
    (error: GeolocationPositionError) => {
      setCurrentGeolocation(undefined)
      if (error.code === error.PERMISSION_DENIED) setGeolocationPermission('denied')
      showToastNotification({
        heading: t('utility.something_went_wrong.title'),
        body: error.message,
        position: 'bottomLeft',
        variant: 'error',
      })
    },
    [showToastNotification, t],
  )

  const state = useMemo(
    () => ({
      geoControlRef,
      currentGeolocation,
      geolocationPermission,
      currentLocationDetails,
    }),
    [geoControlRef, currentGeolocation, geolocationPermission, currentLocationDetails],
  )

  const handlers = useMemo(() => ({ onGeolocate, onError }), [onGeolocate, onError])

  return [state, handlers]
}

export const UseCurrentLocationProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useCurrentLocationProvider()
  return (
    <UseCurrentLocationContext.Provider value={ctx}>{children}</UseCurrentLocationContext.Provider>
  )
}

export const useCurrentLocation = (): UseCurrentLocation => {
  const context = useContext(UseCurrentLocationContext)
  if (!context)
    throw new Error('useCurrentLocation() cannot be used outside of <UseCurrentLocationProvider/>')
  return context
}
