import { Button } from '@electro/shared-ui-components'
import * as Sentry from '@sentry/nextjs'
import {
  useGenerateKrakenOauthUriMutation,
  useUserQuery,
} from '@electro/consumersite/generated/graphql'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import { setChallengeCodeToLocalStorage } from '@electro/shared/utils/asynclLocalStorage'
import { useCallback } from 'react'
import { useRouter } from 'next/router'

export const RequestKrakenOauthLinkButton = () => {
  const router = useRouter()
  const [generateOauthUri, { error: generateOauthUriError, loading }] =
    useGenerateKrakenOauthUriMutation({ fetchPolicy: 'network-only' })
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useUserQuery({ fetchPolicy: 'cache-first' })

  useErrorNotificationEffect({
    error: generateOauthUriError,
    message: "Oops! Something went wrong and we couldn't connect your Octopus account!",
  })

  const handleGetOauthLink = useCallback(async () => {
    try {
      const { data } = await generateOauthUri({
        variables: {
          countryCode: userData.me.countryCode,
          redirectUri: `${window.location.origin}/user/account/oauth/callback`,
        },
      })
      await setChallengeCodeToLocalStorage(data?.generateKrakenOauthUri.codeChallenge)
      const redirectUri = data?.generateKrakenOauthUri?.uri
      router.push(redirectUri)
    } catch (err) {
      Sentry.captureException(err)
    }
  }, [generateOauthUri, router, userData])

  return !userLoading && !userError ? (
    <Button className="w-full md:w-auto" onClick={handleGetOauthLink} disabled={loading}>
      Pay with Octopus
    </Button>
  ) : null
}
