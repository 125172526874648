import { LoadingBolt } from '@electro/shared-ui-components'
import { tw } from '@electro/shared/utils/tailwind-merge'

const styles = {
  root: 'flex flex-grow flex-col items-center justify-center w-full h-full bg-base',
  fixed: 'fixed top-0 left-0 z-50',
}

interface LoadingOverlayProps {
  fixed?: boolean
  className?: string
  loadingMessage?: string
}

export const LoadingOverlay = ({
  fixed = true,
  className,
  loadingMessage,
}: LoadingOverlayProps) => (
  <div
    className={tw({
      [styles.root]: true,
      [styles.fixed]: fixed,
      [className]: !!className,
    })}
  >
    <LoadingBolt subtitle={loadingMessage} />
  </div>
)
