import { tw } from '@electro/shared/utils/tailwind-merge'
import React, { ReactElement } from 'react'

interface TabsListProps {
  children: ReactElement<any, string> | ReactElement<any, string>[]
  fullWidth?: boolean
  className?: string
}

interface TabProps {
  fullWidth?: boolean
  children: any
  active?: boolean
  onClick?: (index: number) => void | (() => void)
}

const styles = {
  tab: {
    root: tw(
      'pt-3 pb-2.5 mr-2 last:mr-0 font-medium normal-case',
      'flex items-center justify-center rounded-full outline-none',
      'bg-secondary text-white border-secondary',
      'text-sm',
    ),
    inactive: tw(
      'focus:outline-none focus:ring-2',
      'ring-0 bg-secondary bg-opacity-0',
      'hover:bg-opacity-40 ',
      'focus:ring-2 focus:ring-secondary',
    ),
    active: tw(
      'cursor-default focus:bg-opacity-100',
      'border-none ring-inset ring-2 ring-secondary bg-opacity-20 text-white',
    ),
    inline: 'px-8',
    fullWidth: 'flex-1',
  },
  tablist: {
    root: tw('p-0 flex m-0 border  rounded-full', 'bg-secondary bg-opacity-30 border-none'),
    fullWidth: 'items-stretch',
  },
}

export const Tab = ({ children, active, onClick, fullWidth }: TabProps) => (
  <button
    onClick={onClick as any}
    role="tab"
    className={tw({
      [styles.tab.root]: true,
      [styles.tab.inactive]: !active,
      [styles.tab.active]: active,
      [styles.tab.inline]: !fullWidth,
      [styles.tab.fullWidth]: fullWidth,
    })}
  >
    {children}
  </button>
)

export const TabsList = ({ children, fullWidth, className }: TabsListProps) => (
  <div
    role="tablist"
    className={tw({
      [styles.tablist.root]: true,
      [styles.tablist.fullWidth]: fullWidth,
      [className]: !!className,
    })}
  >
    {React.Children.map(children, (child) =>
      child ? React.cloneElement(child, { ...child.props, fullWidth }) : null,
    )}
  </div>
)
