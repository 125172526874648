import clsx, { ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Tailwind classnames merge utility.
 * Includes overrides from `tailwind-merge` and object handling from `clsx`.
 * @see https://github.com/dcastil/tailwind-merge#tailwind-merge
 * @see https://github.com/dcastil/tailwind-merge/discussions/137#discussioncomment-3482513
 */
export function tw(...args: ClassValue[]): string {
  return twMerge(clsx(args))
}
