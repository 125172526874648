import { ChangeEvent, ReactNode } from 'react'
import { Input, Button } from '@electro/shared-ui-components'
import { AnimatedComponent, ANIMATIONS } from '@electro/animations'
import {
  ActivateElectrocardProvider,
  useActivateElectrocard,
} from '@electro/consumersite/src/components/AccountElectrocardsManager/components/ActivateElectrocardForm/hooks'
import useTranslation from 'next-translate/useTranslation'
import { formatAlphaNumericAllCaps } from '@electro/shared/utils/formatters'

export enum ActivateElectrocardFormFieldsNames {
  NUMBER = 'number',
}

const { NUMBER } = ActivateElectrocardFormFieldsNames

export interface ActivateElectrocardFormFields {
  [NUMBER]: string
}

export interface ActivateElectrocardFormProps {
  formFields?: Partial<ActivateElectrocardFormFields>
  children: ReactNode | ReactNode[]
}

const ActivateElectrocardForm = ({
  children,
  formFields = { number: '' },
}: ActivateElectrocardFormProps) => (
  <ActivateElectrocardProvider formFields={formFields}>{children}</ActivateElectrocardProvider>
)

const Fields = () => {
  const { formik, validateOnBlur, success } = useActivateElectrocard()
  const { t } = useTranslation('common')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatAlphaNumericAllCaps(event.target.value)
    formik.setFieldValue(NUMBER, formattedValue)
  }

  if (success) return null

  return (
    <form
      aria-label="activate-electrocard-form"
      id="activate-electrocard-form"
      onBlur={validateOnBlur}
      onSubmit={formik.handleSubmit}
    >
      <div className="flex justify-center mb-8">
        <AnimatedComponent
          name="activate-electrocard"
          getAnimationData={ANIMATIONS.activateElectrocard}
        />
      </div>

      <Input
        fullWidth
        name={NUMBER}
        label={t('profile.electrocards.electrocard.number')}
        placeholder="E.g 058F78DA9F7788"
        value={formik.values[NUMBER]}
        errorMessage={formik.errors[NUMBER]}
        onChange={handleChange}
      />
    </form>
  )
}

const Success = ({ children }) => {
  const { success } = useActivateElectrocard()

  return success ? children : null
}

const Actions = ({ children }) => {
  const { success } = useActivateElectrocard()

  return !success ? <div data-testid="activate-electrocard-form-success">{children}</div> : null
}

const SubmitButton = ({
  buttonText = 'profile.electrocards.actions.activate',
}: {
  buttonText?: string
}) => {
  const { formik, loading, success } = useActivateElectrocard()
  const disabled = formik.values.number?.length < 14
  const { t } = useTranslation('common')
  if (success) return null

  return (
    <Button
      disabled={disabled}
      loading={loading}
      form="activate-electrocard-form"
      data-testid="submit"
      fullWidth
      type="submit"
    >
      {t(buttonText)}
    </Button>
  )
}

ActivateElectrocardForm.Fields = Fields
ActivateElectrocardForm.Success = Success
ActivateElectrocardForm.Actions = Actions
ActivateElectrocardForm.SubmitButton = SubmitButton

export { ActivateElectrocardForm }
