import Image from 'next/image'
import { useRouter } from 'next/router'
import { FlagIcon, FlagIconCode } from 'react-flag-kit'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { Button, Tooltip } from '@electro/shared-ui-components'
import { useMarkers } from '@electro/consumersite/src/components/Map/hooks'
import { formatSecondsToLocalisedDuration } from '@electro/shared/utils/formatters'
import { getMarkerIconPath } from '@electro/consumersite/src/components/Map/helpers'
import { useChargingLocationFragmentQuery } from '@electro/consumersite/generated/graphql'
import { mapAlpha3CountryCodeToAlpha2 } from '@electro/shared/utils/mapLocaleToCountryDetails'
import { ChevronDoubleRightIcon } from '@heroicons/react/16/solid'
import { BatteryIcon } from '@electro/consumersite/src/icons'
import { RouteChargerIcon } from '@electro/icons/16'
import {
  ElasticLocation,
  RouteDictionaryRouteType,
} from '@electro/consumersite/src/components/Map/types'

const styles = {
  root: 'flex items-center',
  markerIcon: 'z-10 w-7 h-fit -ml-1 mr-2',
  button: {
    root: 'w-full flex flex-col rounded-lg bg-base/70',
    active: 'border-primary hover:bg-base/70 cursor-default',
    locationInfo: {
      root: 'w-full flex items-center justify-between',
      operatorLogo: {
        root: 'flex items-center bg-white rounded h-5 w-20 p-1',
        img: 'h-full w-full object-contain',
        tooltipText: 'py-1 text-sm',
      },
      flag: 'rounded-sm shadow-lg',
    },
    address: 'w-full text-left line-clamp-2 mt-1.5',
    chargingInfo: {
      root: 'w-full flex items-center justify-between px-0.5 mt-0.5',
      chargeDuration: {
        root: 'flex items-center gap-x-1 [&>svg]:size-5',
        duration: 'mb-0 text-left text-xs font-semibold',
        subtitle: 'mb-0 text-left text-[9px] font-semibold leading-none text-tertiary-lavender',
      },
      batteryInfo: {
        root: 'flex items-center',
        arrowIcon: 'w-4 h-4 mx-1 text-tertiary-lavender',
        battery: {
          root: 'flex flex-col items-center text-xs font-mono font-semibold',
          icon: 'w-6 h-6 -mb-1.5 ml-0.5',
        },
      },
    },
  },
}

interface RouteBreakdownChargerStepProps {
  step: RouteDictionaryRouteType['markers'][number]
}

export const RouteBreakdownChargerStep = ({ step }: RouteBreakdownChargerStepProps) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const [{ activeLocationMarker }, { handleSelectLocation }] = useMarkers()

  const { data } = useChargingLocationFragmentQuery({ variables: { pk: step.charger.id } })

  if (!data) return null

  const { isEjnLocation, coordinates, operator, country, address } = data.chargingLocation
  const isActive = activeLocationMarker?.id === step.charger.id

  /** Open the location drawer for that charger if it is not already visible */
  const handleSelectChargerStep = () => {
    if (isActive) return

    handleSelectLocation({
      properties: { _id: step.charger.id, is_ejn_location: isEjnLocation },
      geometry: { coordinates },
    } as ElasticLocation)
  }

  return (
    <div className={styles.root}>
      <Image
        src={getMarkerIconPath(step.icon)}
        className={styles.markerIcon}
        alt="route marker"
        height={28}
        width={28}
      />

      <Button
        size="xs"
        variant="outline"
        onClick={handleSelectChargerStep}
        disableClickFeedback={isActive}
        className={tw({
          [styles.button.root]: true,
          [styles.button.active]: isActive,
        })}
      >
        <div className={styles.button.locationInfo.root}>
          <Tooltip placement="right">
            <Tooltip.Trigger
              delay={250}
              ariaLabel={operator.name}
              className={styles.button.locationInfo.operatorLogo.root}
            >
              <Image
                className={styles.button.locationInfo.operatorLogo.img}
                src={operator.logoDark}
                alt={operator.name}
                width={80}
                height={20}
              />
            </Tooltip.Trigger>
            <Tooltip.Body className={styles.button.locationInfo.operatorLogo.tooltipText}>
              {operator.name}
            </Tooltip.Body>
          </Tooltip>

          <FlagIcon
            code={mapAlpha3CountryCodeToAlpha2(country) as FlagIconCode}
            className={styles.button.locationInfo.flag}
            size={22}
          />
        </div>

        <h4 className={styles.button.address}>{address}</h4>

        <div className={styles.button.chargingInfo.root}>
          <span className={styles.button.chargingInfo.chargeDuration.root}>
            <RouteChargerIcon />

            <div>
              <p className={styles.button.chargingInfo.chargeDuration.duration}>
                {formatSecondsToLocalisedDuration(step.charger.chargeDuration, { locale })}
              </p>
              <p className={styles.button.chargingInfo.chargeDuration.subtitle}>
                {t('map.route_overview.summary.charge_duration')}
              </p>
            </div>
          </span>

          <div className={styles.button.chargingInfo.batteryInfo.root}>
            <span className={styles.button.chargingInfo.batteryInfo.battery.root}>
              <BatteryIcon
                percentage={step.arrivalBatteryPercentage}
                className={styles.button.chargingInfo.batteryInfo.battery.icon}
              />
              {step.arrivalBatteryPercentage}%
            </span>

            <ChevronDoubleRightIcon className={styles.button.chargingInfo.batteryInfo.arrowIcon} />

            <span className={styles.button.chargingInfo.batteryInfo.battery.root}>
              <BatteryIcon
                percentage={step.departureBatteryPercentage}
                className={styles.button.chargingInfo.batteryInfo.battery.icon}
              />
              {step.departureBatteryPercentage}%
            </span>
          </div>
        </div>
      </Button>
    </div>
  )
}
