import Link from 'next/link'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { Button } from '@electro/shared-ui-components'
import { useMapConfig } from '@electro/consumersite/src/components/Map/hooks'
import { EjAuthorised } from '@electro/consumersite/src/components/EjAuthorised'
import {
  getActiveHolidaySeason,
  getSeasonalImagePath,
} from '@electro/consumersite/src/helpers/seasonalRendering'

const styles = {
  container: 'flex flex-grow flex-col justify-between items-center text-center',
  image: 'px-12 pb-4 -mt-3',
  text: {
    root: 'text-center mb-8 override-list-style',
    list: tw(
      'mx-4 !mt-1 pt-2 pb-1 !pl-10 text-left',
      'border-2 border-primary rounded-lg',
      'shadow-primary shadow-md',
    ),
    subtext: 'text-sm font-light mt-8',
  },
  buttons: 'w-full flex flex-col gap-y-2 [&_a]:w-full [&_a]:no-underline',
}

export const ElectroverseFeaturesPanelContent = () => {
  const { t } = useTranslation('common')
  const [{ allowUserLogin }] = useMapConfig()
  const activeHolidaySeason = getActiveHolidaySeason()

  return (
    <div className={styles.container}>
      <div>
        <Image
          src={getSeasonalImagePath('where-to-next', activeHolidaySeason)}
          data-testid={`${activeHolidaySeason}-directions-sign`}
          alt="Where to next sign!"
          className={styles.image}
          height={504}
          width={448}
        />

        <div className={styles.text.root}>
          <p>{t('map.electroverse_features.unlock_features')}</p>

          <ul className={styles.text.list}>
            <li>
              <p>{t('map.electroverse_features.list.filter_by_vehicle')}</p>
            </li>
            <li>
              <p>{t('map.electroverse_features.list.route_planner')}</p>
            </li>
            <li>
              <p>{t('map.electroverse_features.list.manage_evs')}</p>
            </li>
          </ul>

          <p className={styles.text.subtext}>
            {t('map.electroverse_features.tailored_experience')}
          </p>
        </div>
      </div>

      <EjAuthorised>
        <EjAuthorised.LoadingOrLoggedOut>
          <div className={styles.buttons}>
            <Link href="/sign-up/magic" target={allowUserLogin ? '_self' : '_blank'}>
              <Button size="sm" fullWidth>
                {t('user.sign_up')}
              </Button>
            </Link>

            {allowUserLogin ? (
              <Link href="/log-in">
                <Button size="sm" fullWidth variant="raised">
                  {t('user.log_in')}
                </Button>
              </Link>
            ) : null}
          </div>
        </EjAuthorised.LoadingOrLoggedOut>
      </EjAuthorised>
    </div>
  )
}
