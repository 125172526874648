import { FormattingOptionsEnum, useLocalisedDateFormat } from '@electro/shared/hooks'
import { tw } from '@electro/shared/utils/tailwind-merge'

type Props = {
  date: string
  className?: string
}

export const PostDate = ({ date, className }: Props) => {
  const localisedDateFormat = useLocalisedDateFormat({
    date,
    formattingOption: FormattingOptionsEnum.LLLL_yyyy,
  })
  return date ? (
    <div data-testid="post-date" className={tw(`uppercase text-sm text-base-dark/40 ${className}`)}>
      {localisedDateFormat}
    </div>
  ) : null
}
