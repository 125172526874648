import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { tw } from '@electro/shared/utils/tailwind-merge'

type Item = {
  name: string
  [key: string]: any
}

type Items = Item[]

interface SelectMenuProps {
  value?: Item
  staticPosition?: boolean
  items: Items
  onChange?: (Item) => void
  initialSelectedItem?: Item
  placeholder?: string
  disabled?: boolean
  label?: string
  name: string
  className?: string
  errorMessage?: string
  position?: 'top' | 'bottom'
}

const styles = {
  select: {
    button: {
      root: tw(
        'relative w-full',
        'py-2 pl-3 pr-10',
        'text-left bg-transparent',
        'rounded-lg',
        'border-2',
        'focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75',
        'focus-visible:ring-tertiary focus-visible:ring-offset-tertiary',
        'focus-visible:ring-offset-2 ',
      ),
      active: 'border-tertiary',
      inactive: 'border-secondary',
      text: {
        root: 'block truncate text-white/60',
        selected: 'text-white',
      },
      iconWrapper: 'absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none',
      icon: 'w-5 h-5 text-tertiary-shade',
      disabled: 'cursor-not-allowed border-white opacity-60',
    },
    list: {
      root: tw(
        'absolute w-full py-2 mt-1 overflow-auto text-base rounded-xl z-10',
        'shadow-lg max-h-100 focus:outline-none',
        'bg-base border-2 border-secondary',
      ),
      absolute: 'absolute max-h-60',
      positionTop: 'bottom-16',
      static: 'relative',
      option: {
        root: tw(
          'cursor-default select-none relative py-2 pl-10 pr-4',
          'hover:bg-tertiary-shade hover:bg-opacity-25',
          'text-white',
        ),
        active: 'text-tertiary bg-white bg-opacity-10',
        inactive: 'text-white',
        icon: {
          root: 'absolute inset-y-0 left-0 flex items-center pl-3',
          active: 'text-tertiary',
          inactive: 'text-base',
        },
      },
    },
  },
  label: {
    root: 'block font-normal text-sm pl-2 mb-1 text-white',
    error: 'text-action-danger ',
    disabled: 'text-white opacity-60',
  },
  error: {
    root: 'error border-action-danger',
    message: 'text-sm text-action-danger mt-2',
  },
}

export const SelectMenu = ({
  value,
  staticPosition = false,
  items,
  onChange,
  initialSelectedItem = null,
  placeholder = 'Select...',
  disabled = false,
  label,
  name,
  className,
  errorMessage,
  position = 'bottom',
}: SelectMenuProps) => {
  const initialSelectedValue = value !== undefined ? value : initialSelectedItem
  const [selectedItem, setSelectedItem] = useState(initialSelectedValue)

  useEffect(() => {
    if (value !== undefined) {
      setSelectedItem(value)
    }
  }, [value])

  function changeHandler(item: Item) {
    if (onChange) onChange(item)
    setSelectedItem(item)
  }

  return (
    <Listbox
      value={selectedItem}
      onChange={changeHandler}
      data-testid="select-box"
      disabled={disabled}
    >
      <div className={tw({ 'relative mt-1 mb-4 pb-4': true, [className]: className })}>
        {label ? (
          <label
            htmlFor={name}
            className={tw({
              [styles.label.root]: true,
              [styles.label.error]: errorMessage,
              [styles.label.disabled]: !!disabled,
            })}
          >
            {label}
          </label>
        ) : null}
        <Listbox.Button id={name} className="w-full" data-testid="select-box-button" type="button">
          {({ open }) => (
            <div
              className={tw({
                [styles.select.button.root]: true,
                [styles.select.button.active]: open,
                [styles.select.button.inactive]: !open,
                [styles.select.button.disabled]: !!disabled,
                [styles.error.root]: errorMessage,
              })}
            >
              <span
                className={tw({
                  [styles.select.button.text.root]: true,
                  [styles.select.button.text.selected]: !!selectedItem,
                })}
              >
                {selectedItem ? selectedItem.name : placeholder}
              </span>
              <span className={styles.select.button.iconWrapper}>
                <ChevronUpDownIcon className={styles.select.button.icon} aria-hidden="true" />
              </span>
            </div>
          )}
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={tw({
              [styles.select.list.positionTop]: position === 'top',
              [styles.select.list.root]: true,
              [styles.select.list.static]: staticPosition,
              [styles.select.list.absolute]: !staticPosition,
            })}
          >
            {items.map((item: Item) => (
              <Listbox.Option
                data-testid="select-box-option"
                key={item.name}
                className={({ active }) =>
                  tw({
                    [styles.select.list.option.root]: true,
                    [styles.select.list.option.active]: active,
                    [styles.select.list.option.inactive]: !active,
                  })
                }
                value={item}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={tw({
                        'block truncate': true,
                        'font-medium': selected,
                        'font-normal': !selected,
                      })}
                    >
                      {item.name}
                    </span>
                    {selected ? (
                      <span
                        className={tw({
                          [styles.select.list.option.icon.root]: true,
                          [styles.select.list.option.icon.active]: active,
                          [styles.select.list.option.icon.inactive]: !active,
                        })}
                      >
                        <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
        {errorMessage ? <div className={styles.error.message}>{errorMessage}</div> : null}
      </div>
    </Listbox>
  )
}
