import { UserQuery } from '@electro/consumersite/generated/graphql'
import { isArray } from '@electro/shared/utils/typed'

export function isActiveAccountFleetDriver(user: UserQuery): boolean {
  if (!user) return false
  const activeAccountId = user.me.activeAccount.id
  const activeAccount = user.me?.accounts?.filter(({ id }) => activeAccountId === id)

  if (isArray(activeAccount)) {
    return activeAccount.some(
      ({ entityDomain, isFleetDriver }) => entityDomain === 'FLEETS' && isFleetDriver,
    )
  }

  return false
}
