import { tw } from '@electro/shared/utils/tailwind-merge'
import Image from 'next/image'

import { LoadingDoubleRowPlaceholder, Menu, Typography } from '@electro/shared-ui-components'
import {
  ConnectorsHumanNameType,
  retrieveSocketTypeDetails,
} from '@electro/consumersite/src/components/Map/utils'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

import {
  UserVehicleType,
  UserVehicleTypeEdge,
  UserVehiclesDocument,
  useDeleteUserVehicleMutation,
  useUserVehiclesQuery,
} from '@electro/consumersite/generated/graphql'
import { useErrorNotificationEffect } from '@electro/shared/hooks'
import { useUserVehicles } from '@electro/consumersite/src/hooks'
import { FC, ReactNode, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'

const styles = {
  root: 'mb-2 px-1',
  listItem: {
    root: tw(
      'flex items-center mb-2 p-1 last:mb-0 w-full rounded-xl group text-sm xs:text-base',
      'hover:border-tertiary border-2',
    ),
    inactive: 'border-secondary bg-opacity-50 bg-base/30',
    active: tw(
      'ring-1 border-secondary bg-secondary ring-secondary cursor-default',
      'hover:ring-1 hover:border-secondary',
    ),
    selectEvButton: 'flex-grow flex items-center disabled:opacity-30',
    menuButton: 'hover:bg-base',
    connector: {
      root: 'border mr-2 flex items-center p-2 rounded-lg w-20 sm:w-auto justify-center',
      inactive: 'border-secondary group-hover:border-tertiary',
      active: 'bg-opacity-30 border-secondary bg-base/30',
    },
    label: {
      root: tw('text-left flex-grow text-xs sm:text-base', 'text-white hover:text-tertiary'),
      regNumber: 'text-xs xs:text-sm text-white',
    },
  },
}

const UserVehiclesEmptyMessage = (
  <div className="flex justify-center flex-col items-center pt-4">
    <Image
      src="/images/mr-zap-waiting.png"
      alt="mr zap waiting for you to add an EV"
      width={139}
      height={249}
    />
    <div className="mt-4">Nothing here yet!</div>
  </div>
)

interface Props {
  emptyMessage?: ReactNode | ReactNode[]
}

export const ManageUserVehicles: FC<Props> = ({ emptyMessage }) => {
  const { t } = useTranslation('common')
  const { loading, data, error } = useUserVehiclesQuery({ fetchPolicy: 'network-only' })

  const [deleteVehicle, { loading: deleteVehicleLoading, error: deleteVehicleError }] =
    useDeleteUserVehicleMutation({
      refetchQueries: [{ query: UserVehiclesDocument }],
      notifyOnNetworkStatusChange: true,
      awaitRefetchQueries: true,
    })

  const userVehiclesEmptyMessage = emptyMessage ?? UserVehiclesEmptyMessage

  const [{ activeVehicle }, { updateActiveVehicle }] = useUserVehicles()

  useErrorNotificationEffect({
    error: deleteVehicleError,
    message: 'Could not update vehicle!',
  })

  function handleDeleteVehicle({ pk }: { pk: number }) {
    return () => {
      if (pk === activeVehicle?.pk) updateActiveVehicle()
      deleteVehicle({ variables: { pk } })
    }
  }

  function handleSetPrimaryVehicle(vehicle: UserVehicleType) {
    return () => updateActiveVehicle(vehicle)
  }

  useEffect(() => {
    if (data?.userVehicles?.edges.length === 1) {
      const [defaultPrimaryVehicle] = data.userVehicles.edges
      updateActiveVehicle(defaultPrimaryVehicle.node)
    }
  }, [data?.userVehicles, updateActiveVehicle])

  return (
    <div data-testid="manage-user-vehicles" className={styles.root}>
      {error && (
        <div className="text-action-danger">Could not fetch vehicles! Try again later.</div>
      )}
      {loading && <LoadingDoubleRowPlaceholder rows={2} />}
      {data?.userVehicles?.edges.length === 0 && userVehiclesEmptyMessage}
      {!error &&
        !loading &&
        data?.userVehicles?.edges
          .slice()
          .sort((a, b) => a.node.name.localeCompare(b.node.name))
          .map((vehicle: UserVehicleTypeEdge) => {
            const { chargePlug, fastchargePlug } = vehicle.node.vehicle
            const plug = retrieveSocketTypeDetails(chargePlug as ConnectorsHumanNameType)
            const fastPlug = retrieveSocketTypeDetails(fastchargePlug as ConnectorsHumanNameType)

            const isPrimary = activeVehicle?.pk === vehicle.node.pk
            const updating = deleteVehicleLoading

            return (
              <div
                key={vehicle.node.id}
                data-testid={
                  isPrimary ? `${vehicle.node.regNumber} selected` : vehicle.node.regNumber
                }
                className={tw({
                  [styles.listItem.root]: true,
                  [styles.listItem.inactive]: !isPrimary,
                  [styles.listItem.active]: isPrimary,
                })}
              >
                <button
                  value={vehicle.node.vehicle.chargePlug}
                  disabled={updating}
                  className={styles.listItem.selectEvButton}
                  onClick={handleSetPrimaryVehicle(vehicle.node)}
                >
                  <div
                    className={tw({
                      [styles.listItem.connector.root]: true,
                      [styles.listItem.connector.inactive]: !isPrimary,
                      [styles.listItem.connector.active]: isPrimary,
                    })}
                  >
                    {chargePlug && (
                      <img
                        className="w-6 h-6 sm:w-auto sm:h-auto "
                        src={plug.iconURL}
                        alt={chargePlug}
                      />
                    )}
                    {fastchargePlug && (
                      <img
                        className="ml-2 w-6 h-6 sm:w-auto sm:h-auto"
                        src={fastPlug.iconURL}
                        alt={fastchargePlug}
                      />
                    )}
                  </div>
                  <div className="text-left">
                    <Typography variant="body">{vehicle.node.name}</Typography>
                    <Typography variant="body">{vehicle.node.regNumber}</Typography>
                  </div>
                </button>
                {!updating && (
                  <Menu>
                    <Menu.Button
                      data-testid="vehicle-menu-button"
                      chevron={false}
                      className={styles.listItem.menuButton}
                    >
                      <EllipsisVerticalIcon className="h-6 w-6" />
                    </Menu.Button>
                    <Menu.MenuItemList>
                      <Menu.MenuItem
                        disabled={deleteVehicleLoading}
                        onSelect={handleDeleteVehicle({ pk: vehicle.node.pk })}
                      >
                        {t('my_evs_remove_ev_button_text')}
                      </Menu.MenuItem>
                    </Menu.MenuItemList>
                  </Menu>
                )}
              </div>
            )
          })}
    </div>
  )
}
