import ACCEPT_TERMS_MUTATION from '@electro/consumersite/src/graphql/acceptTermsAndConditionsMutation.graphql'

import { useMutation } from '@apollo/client'

import { ApolloMutationFunction } from '@electro/shared/types/queriesMutations'
import { Mutation } from '@electro/consumersite/generated/graphql'

type AcceptTermsAndConditionsMutation = Pick<Mutation, 'acceptTermsAndConditions'>

interface UseAcceptTermsAndConditions {
  acceptTermsAndConditionsMutation: ApolloMutationFunction<AcceptTermsAndConditionsMutation>
}

export function useAcceptTermsAndConditions(): UseAcceptTermsAndConditions {
  const [acceptTermsAndConditionsMutation] =
    useMutation<AcceptTermsAndConditionsMutation>(ACCEPT_TERMS_MUTATION)

  return { acceptTermsAndConditionsMutation }
}
