export const FeedbackIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 26"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    {...props}
  >
    <path d="M10.7709 19.1501C10.0954 19.2937 9.41905 19.4689 8.74576 19.6746L8.02848 19.8937C6.88707 20.2423 5.65391 20.1233 4.60027 19.5627C3.54663 19.0022 2.75882 18.046 2.41016 16.9046C2.0615 15.7632 2.18054 14.53 2.7411 13.4764C3.30165 12.4228 4.25781 11.635 5.39922 11.2863L6.1165 11.0672C6.78979 10.8615 7.44857 10.6289 8.08903 10.3705M10.7709 19.1501C11.2939 19.9962 11.8821 20.7889 12.5259 21.5239C12.9228 21.9778 12.9368 22.6636 12.5246 23.1043L12.0072 23.6596C11.5732 24.1247 10.8364 24.1396 10.4122 23.6648C9.4056 22.5388 8.52438 21.3067 7.78405 19.9903M10.7709 19.1501C9.95729 17.8319 9.31696 16.4145 8.86569 14.9327C8.40236 13.4159 8.1491 11.8851 8.08903 10.3705M10.7709 19.1501C13.7951 18.5024 16.9165 18.4492 19.961 18.9934M8.08903 10.3705C10.959 9.21775 13.5773 7.51768 15.798 5.36507M19.961 18.9934C19.9592 19.3913 19.947 19.7861 19.9263 20.1804M19.961 18.9934C19.9691 17.1575 19.7656 15.3269 19.3547 13.5375M15.798 5.36507C15.5754 5.03772 15.3447 4.71598 15.1059 4.40016M15.798 5.36507C16.8306 6.8831 17.6848 8.51499 18.3439 10.2285M18.3439 10.2285C18.938 10.4789 19.4219 10.9814 19.6249 11.6461C19.828 12.3108 19.7075 12.998 19.3547 13.5375M18.3439 10.2285C18.7589 11.3061 19.0967 12.412 19.3547 13.5375" />
    <path d="M20 4.87671L24.1196 0.999978" />
    <path d="M23.7 16L29.3458 16.3531" />
    <path d="M23.0413 10.2L28.2701 8.04148" />
  </svg>
)
