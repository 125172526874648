import Image, { ImageProps } from 'next/image'

const loadingBlank = (w, h) => `
<svg style="filter:blur(30px);" width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">  
  <rect width="${w}" height="${h}" fill="rgba(0,0,0,0)" />
</svg>`

const toBase64 = (str) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str)

interface LazyImageProps extends ImageProps {
  className?: string
}

export const LazyImage = ({ className, src, alt, width, height, ...restProps }: LazyImageProps) => (
  <Image
    className={className}
    src={`${src}`}
    alt={alt}
    width={width}
    height={height}
    placeholder="blur"
    blurDataURL={`data:image/svg+xml;base64,${toBase64(loadingBlank(width, height))}`}
    {...restProps}
  />
)
