import Link from 'next/link'
import Image from 'next/image'
import { useState } from 'react'
import UAParser from 'ua-parser-js'
import { useMount } from 'react-use'
import Trans from 'next-translate/Trans'
import { tw } from '@electro/shared/utils/tailwind-merge'
import useTranslation from 'next-translate/useTranslation'
import { Typography } from '@electro/shared-ui-components'
import {
  CheckCircleIcon,
  FaceFrownIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/16/solid'

const styles = {
  page: 'w-full h-screen flex items-center justify-center',
  container: {
    root: 'flex flex-col items-center lg:flex-row mt-20 ml-10',
    image: 'w-40 md:w-64',
    info: {
      root: 'm-10 flex flex-col gap-y-2 w-auto',
      highlightText: 'text-primary-light',
      list: {
        root: 'mt-4 ml-2 [&>span]:flex',
        text: {
          success: 'text-action-success',
          failure: 'text-action-danger',
        },
        icons: {
          all: 'w-5 min-w-5 mr-2',
          question: 'text-secondary-light',
          unhappy: 'text-action-warning',
        },
      },
    },
  },
}

/** Provides a fallback if the map doesn't successfully load, with some guides to resolve the issue */
export const MapUnsupported = () => {
  const { t } = useTranslation('common')
  const [browserName, setBrowserName] = useState<string>()
  const [supportsWebGL2, setSupportsWebGL2] = useState<boolean>()

  useMount(() => {
    const { name, version } = new UAParser(window.navigator.userAgent).getBrowser()
    let isBrowserSupported: boolean

    if (name === 'Firefox') isBrowserSupported = parseInt(version, 10) >= 51
    else if (name === 'Edge') isBrowserSupported = parseInt(version, 10) >= 79
    else if (name === 'Chrome') isBrowserSupported = parseInt(version, 10) >= 56
    else if (name === 'Safari') isBrowserSupported = parseInt(version, 10) >= 15

    if (isBrowserSupported !== undefined) {
      setBrowserName(name)
      setSupportsWebGL2(isBrowserSupported)
    }
  })

  return (
    <div className={styles.page}>
      <div className={styles.container.root}>
        <Image
          width={504}
          height={456}
          alt="Maintenance required for map illustration"
          src="/images/zapman-map-maintenance.svg"
          className={styles.container.image}
        />

        <div className={styles.container.info.root}>
          <Typography variant="h1">{t('utility.something_went_wrong.title')}</Typography>

          <div>
            <Typography>
              <Trans
                i18nKey="common:map.unsupported.description"
                components={{
                  tech: <span className={styles.container.info.highlightText}>WebGL2</span>,
                }}
              />
            </Typography>

            <div className={styles.container.info.list.root}>
              {browserName === undefined ? (
                <span>
                  <QuestionMarkCircleIcon
                    className={tw(
                      styles.container.info.list.icons.all,
                      styles.container.info.list.icons.question,
                    )}
                  />
                  <span>
                    {`${t('map.unsupported.browser_version.undefined')} - `}
                    <Link href="https://caniuse.com/webgl2">
                      {t('map.unsupported.browser_version.undefined_resource_link')}
                    </Link>
                  </span>
                </span>
              ) : null}

              {browserName && supportsWebGL2 ? (
                <span className={styles.container.info.list.text.success}>
                  <CheckCircleIcon className={styles.container.info.list.icons.all} />
                  <span>{t('map.unsupported.browser_version.success')}</span>
                </span>
              ) : null}

              {browserName && !supportsWebGL2 ? (
                <span className={styles.container.info.list.text.failure}>
                  <XCircleIcon className={styles.container.info.list.icons.all} />
                  <span>{t('map.unsupported.browser_version.failure')}</span>
                </span>
              ) : null}

              <span>
                <QuestionMarkCircleIcon
                  className={tw(
                    styles.container.info.list.icons.all,
                    styles.container.info.list.icons.question,
                  )}
                />
                <span>
                  {`${t('map.unsupported.browser_settings.enable')} `}
                  <span className={styles.container.info.highlightText}>
                    {t('map.unsupported.browser_settings.graphics_acceleration')}
                  </span>
                </span>
              </span>

              <span>
                <QuestionMarkCircleIcon
                  className={tw(
                    styles.container.info.list.icons.all,
                    styles.container.info.list.icons.question,
                  )}
                />
                <span>
                  {t('map.unsupported.graphics_driver.requires_update')} -{' '}
                  <Link href="https://support.mozilla.com/kb/upgrade-graphics-drivers-use-hardware-acceleration">
                    {t('map.unsupported.graphics_driver.resource_link')}
                  </Link>
                </span>
              </span>

              <span>
                <FaceFrownIcon
                  className={tw(
                    styles.container.info.list.icons.all,
                    styles.container.info.list.icons.unhappy,
                  )}
                />
                <span>{t('map.unsupported.hardware')}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
