import { SidebarPanelContainer } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarPanelContainer'
import { ElectroverseFeaturesPanelContent } from '@electro/consumersite/src/components/Map/components/MapSidebar/panels'
import { SidebarNavIcon } from '@electro/consumersite/src/components/Map/components/MapSidebar/components/SidebarNav'
import { SidebarPanels } from '@electro/consumersite/src/components/Map/types'
import useTranslation from 'next-translate/useTranslation'
import { SparklesIcon } from '@heroicons/react/16/solid'

const styles = {
  navIcon: 'w-full p-1',
}

export const ElectroverseFeaturesIcon = () => (
  <SidebarNavIcon panelName={SidebarPanels.ELECTROVERSE_FEATURES}>
    <SparklesIcon className={styles.navIcon} />
  </SidebarNavIcon>
)

export const ElectroverseFeaturesPanel = () => {
  const { t } = useTranslation('common')

  return (
    <SidebarPanelContainer
      panelType={SidebarPanels.ELECTROVERSE_FEATURES}
      label={t('map.electroverse_features.title')}
    >
      <ElectroverseFeaturesPanelContent />
    </SidebarPanelContainer>
  )
}
