import * as Sentry from '@sentry/nextjs'

/**
 * Utility function to verify reCAPTCHA token with Google reCAPTCHA Enterprise API.
 *
 * See Googles docs for more info:
 * https://cloud.google.com/recaptcha/docs/reference/rest
 */
export const verifyWithRecaptcha = async (): Promise<boolean> => {
  try {
    if (typeof window !== 'undefined') {
      const token = await window.grecaptcha.enterprise.execute(
        process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY,
      )

      const response = await fetch(`/api/recaptcha/verify/${token}`)

      const { success } = await response.json()
      if (!success) {
        Sentry.captureMessage('Sign up,  reCAPTCHA verification failed')
        return false
      }
      return success
    }
  } catch (error) {
    Sentry.captureException(error)
  }
  return false
}
