import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client'
import KRAKEN_ACCOUNTS_QUERY from '@electro/consumersite/src/graphql/krakenAccountsQuery.graphql'

import { Query } from '@electro/consumersite/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchKrakenAccounts = Pick<Query, 'krakenAccounts'>

interface UseLazyFetchKrakenAccounts {
  lazyFetchKrakenAccounts: ApolloLazyQueryFunction<FetchKrakenAccounts>
  krakenAccounts: FetchKrakenAccounts
}

type FetchExtendedVehicleLookupArgs = LazyQueryHookOptions<FetchKrakenAccounts>

export function useLazyFetchKrakenAccounts(
  options?: FetchExtendedVehicleLookupArgs,
): UseLazyFetchKrakenAccounts {
  const [lazyFetchKrakenAccounts, { data }] =
    useLazyQuery<FetchKrakenAccounts>(KRAKEN_ACCOUNTS_QUERY)

  return {
    lazyFetchKrakenAccounts,
    krakenAccounts: data,
  }
}
