import React, { ChangeEvent, ForwardedRef, ReactNode, forwardRef } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { useRadioGroup } from '../RadioGroup/RadioGroupContext'
import RadioButtonChecked from './RadioCheckedIcon'
import RadioButtonUnChecked from './RadioUncheckedIcon'

export interface Props extends React.HTMLAttributes<HTMLInputElement> {
  className?: string
  label?: ReactNode | ReactNode[]
  disabled?: boolean
  value?: string
  name: string
  checked?: boolean
  errorMessage?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const styles = {
  root: 'flex flex-col',
  radio: 'inline-flex relative w-6 h-6 p-1',
  input: 'opacity-0 absolute h-full w-full -m-1',
  label: 'inline-flex items-center  hover:cursor-pointer text-white',
  labelText: 'ml-2',
  hidden: 'hidden',
  check: 'text-tertiary',
  disabled: 'text-white opacity-70 hover:cursor-not-allowed',
  errorMessage: 'text-sm text-action-danger border-action-danger mt-1',
}

export const Radio = forwardRef(
  (
    {
      disabled = false,
      checked: checkProp,
      label = '',
      value,
      name,
      errorMessage,
      className,
      onChange,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const radioGroup = useRadioGroup()
    let checked = checkProp

    if (radioGroup) {
      if (checked === undefined) {
        checked = radioGroup.value === value
      }
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (radioGroup) {
        radioGroup.onChange(event)
      } else if (typeof onChange === 'function') {
        onChange(event)
      }
    }

    return (
      <div
        className={tw({
          [styles.root]: true,
          [className]: !!className,
        })}
      >
        <label
          className={tw({
            [styles.label]: true,
            [styles.disabled]: disabled,
          })}
          htmlFor={name}
        >
          <div className={styles.radio}>
            <input
              ref={ref}
              id={name}
              className={styles.input}
              onChange={changeHandler}
              disabled={disabled}
              type="radio"
              value={value}
              name={name}
              checked={checked}
              data-hj-allow
              {...rest}
            />

            <RadioButtonChecked
              className={tw({
                [styles.hidden]: !checked,
                [styles.disabled]: disabled,
                [styles.check]: checked,
              })}
            />
            <RadioButtonUnChecked
              className={tw({ [styles.check]: checked, [styles.disabled]: disabled })}
            />
          </div>

          <span
            className={tw({
              [styles.labelText]: true,
              [styles.disabled]: disabled,
            })}
          >
            {label}
          </span>
        </label>

        {!!errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
      </div>
    )
  },
)
