export const MyEVsIcon = (props: React.ComponentProps<'svg'>) => (
  <svg
    width="31"
    height="22"
    viewBox="0 0 31 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0144 9.48757L12.9801 4.17738C13.076 3.89888 13.491 3.96526 13.487 4.25847L13.4433 7.4747C13.4411 7.63547 13.5865 7.75727 13.7468 7.72887L15.2364 7.4747C15.4228 7.44165 15.5794 7.60954 15.5314 7.79099L13.6554 13.8046C13.5751 14.1082 13.1215 14.0394 13.1437 13.7269L13.4432 9.50461C13.455 9.33815 13.3065 9.20638 13.1403 9.23584L11.3038 9.82189C11.1092 9.85639 10.9505 9.67302 11.0144 9.48757Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="1"
    />
    <path
      d="M4 18C1.6 18 1 16.6667 1 16V10.5C1 9.5 1.5 9 2 8.5C2.5 8 3 7 3.5 4C3.9 1.6 6.33333 1 7.5 1H14.5C17.7 1 18.9916 1.98883 20.5 4C22 6 22 6.5 23.5 8C24 8.5 25.9 9.6 27.5 10C29.1 10.4 29.5 12.1667 29.5 13C29.5 13 29.5 13.5 29.5 15.5C29.5 17.5 27.617 18 26.6755 18H25.5M11 18C14.2059 18 14.7775 18 18.5 18"
      stroke="currentColor"
      strokeWidth="2.2"
    />
    <circle cx="7.875" cy="17.375" r="3.375" stroke="currentColor" strokeWidth="2.2" />
    <circle cx="21.875" cy="17.375" r="3.375" stroke="currentColor" strokeWidth="2.2" />
  </svg>
)
