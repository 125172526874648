import { useMemo } from 'react'
import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import Trans from 'next-translate/Trans'

import { Alert, Button, Card, LoadingOverlay, Typography } from '@electro/shared-ui-components'
import {
  RestrictToLocales,
  GoogleSignIn,
  useGoogleSignIn,
} from '@electro/consumersite/src/components'
import { Locales } from '@electro/shared/types/locales'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { getMessageFromErrorCode } from '@electro/consumersite/src/utils/getMessageFromErrorCode'
import { AppleSignIn } from '@electro/consumersite/src/components/Login/AppleSignIn'

interface LoginV2Props {
  // the path to the continue with email page where the user can login/sign up with a magic link
  continueWithEmailPath: string
  showTerms?: boolean
  showBusinessLink?: boolean
}

export const LoginV2 = ({
  showTerms = false,
  showBusinessLink = false,
  continueWithEmailPath,
}: LoginV2Props) => {
  const { t } = useTranslation('common')
  const [{ error: googleSignInError, loading }] = useGoogleSignIn()

  const localisedErrorMessage = useMemo(() => {
    if (googleSignInError) {
      const [firstError] = googleSignInError.graphQLErrors
      return t(getMessageFromErrorCode(firstError?.errorCode))
    }
    return null
  }, [googleSignInError, t])

  return (
    <>
      {loading ? <LoadingOverlay loadingMessage={t('utility.loading_message')} fixed /> : null}
      <Card
        frosted
        className="max-w-md w-[calc(100vw-theme(spacing.4))] md:w-1/2 mx-auto"
        density="low"
      >
        <div className="text-center">
          <Typography variant="h2" as="h1">
            {t('login_welcome_new')}
          </Typography>
          <Typography variant="small" as="p">
            {t('login_subtitle')}
          </Typography>
          <Image
            src="/images/zap-man-black-hole.svg"
            alt="Zapman falling through a black hole"
            width={194}
            height={202}
            className="mx-auto mb-6"
          />
        </div>
        <GoogleSignIn.Button className="flex justify-center my-3" />
        <AppleSignIn />
        {localisedErrorMessage ? (
          <Alert variant="error" className="mt-4 mb-4">
            {localisedErrorMessage}
          </Alert>
        ) : null}
        <Typography
          variant="small"
          className={tw(
            'flex flex-row justify-between gap-2 items-center text-white/50 mx-4',
            'after:content-[""] after:flex-1 after:border-b after:border-white/50',
            'before:content-[""] before:flex-1 before:border-b before:border-white/50',
          )}
        >
          {t('common.or')}
        </Typography>
        <Link href={continueWithEmailPath} className="no-underline" data-testid="email-magic-link">
          <Button variant="outline" fullWidth className="mt-3">
            {t('common.signup.continue_with_email')}
          </Button>
        </Link>

        {showTerms ? (
          <div className="mt-6">
            <Typography variant="small" as="p" className="mb-0">
              <Trans
                i18nKey="common:common.terms_and_conditions"
                components={{
                  termsLink: <Link href="/legal/terms/">{t('common.terms')}</Link>,
                  privacy: <Link href="/legal/privacy/">{t('common.privacy_policy')}</Link>,
                }}
              />
            </Typography>
          </div>
        ) : null}
      </Card>

      {showBusinessLink ? (
        <RestrictToLocales locales={[Locales.EN]}>
          <div className="max-w-md w-[calc(100vw-theme(spacing.4))] md:w-1/2 mx-auto">
            <Link href="/business" className="no-underline">
              <Button variant="outline" fullWidth className="mt-8 ">
                Are you a business?
              </Button>
            </Link>
          </div>
        </RestrictToLocales>
      ) : null}
    </>
  )
}
