import { Locales } from '@electro/shared/types/locales'
import {
  DesktopPrimaryNavItemProps,
  MobilePrimaryNavItemProps,
  DesktopSecondaryNavItemProps,
  MobileSecondaryNavItemProps,
} from '@electro/consumersite/src/components/TopNavigation/types'

const allLocales = Object.values(Locales)

export const COMMUNITY_MENU: DesktopSecondaryNavItemProps[] | MobileSecondaryNavItemProps[] = [
  {
    label: 'nav.menu.secondary_item.blogs',
    href: '/community',
  },
  {
    label: 'nav.menu.secondary_item.faqs',
    href: '/faqs',
  },
]

export const PRIMARY_NAV: DesktopPrimaryNavItemProps[] | MobilePrimaryNavItemProps[] = [
  {
    label: 'nav.menu.item.map',
    href: '/map',
    locales: allLocales,
  },
  {
    label: 'nav.menu.item.fleets',
    href: '/business',
    locales: [Locales.EN],
  },
  {
    label: 'nav.menu.item.community',
    href: '/community',
    locales: allLocales,
    secondaryNav: COMMUNITY_MENU,
  },
  {
    label: 'nav.menu.item.stats',
    href: '/stats',
    locales: [Locales.EN],
  },
]
