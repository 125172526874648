import { useMap } from 'react-map-gl'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { useUpdateEffect } from 'react-use'
import { testEnv } from '@electro/shared/utils/isEnv'
import { useState, useCallback, useMemo, createContext, useContext, ReactNode, useRef } from 'react'
import {
  useMapFilters,
  useMapSidebar,
  useMarkers,
} from '@electro/consumersite/src/components/Map/hooks'
import {
  MapParamsEnum,
  MapFiltersEnum,
  MapFiltersType,
  SidebarPanels,
  SidebarPanelsType,
} from '@electro/consumersite/src/components/Map/types'
import {
  CameraAnimationOptionsProps,
  getCameraAnimationOptions,
  PlaceDetails,
} from '@electro/consumersite/src/components/Map/helpers'
// eslint-disable-next-line import/no-unresolved
import { mapFiltersOptions } from '@electro/consumersite/generated/mapBuildData'
import { countryBoundingBoxes } from '@electro/consumersite/generated/mapStaticData'
import { useAuth } from '@electro/consumersite/src/hooks'
import { featureFlagEnableMapLocationSharing } from '@electro/consumersite/src/utils/envFeatureFlags'
import { GTM } from '@electro/consumersite/src/utils/event-triggers'

const {
  POSITION,
  SPEED,
  PITCH,
  BEARING,

  ELECTROVERSE_COMPATIBLE,
  OPERATORS,
  SOCKET_TYPES,
  CHARGE_POINT_SPEEDS,
  MIN_CHARGE_POINTS,
  ACCESS,
  LAT,
  LNG,
  ZOOM,
  PITCH_FULL,
  BEARING_FULL,
  EJN_ONLY_FULL,
} = MapParamsEnum

/** @deprecated */
const tempSocketMap = {
  'TYPE%201': 'TYPE_1',
  'TYPE%202': 'TYPE_2',
  'TYPE 1': 'TYPE_1',
  'TYPE 2': 'TYPE_2',
}

interface State {}

interface Handlers {}

type UseMapParams = [state: State, handlers: Handlers]

const UseMapParamsContext = createContext<UseMapParams>(null)

function useMapParamsProvider(): UseMapParams {
  const { baseMap } = useMap()
  const { locale, asPath, push } = useRouter()
  const [{ sessionLoading }] = useAuth()
  const [, { handleSelectLocation }] = useMarkers()
  const [, { applyInitialFilters }] = useMapFilters()
  const [{ visiblePanel }, { showPanelExclusive }] = useMapSidebar()
  const { bbox } = countryBoundingBoxes[locale] as PlaceDetails

  const [mapBoundsDefined, setMapBoundsDefined] = useState<boolean>(false)
  const [sidebarPanelDefined, setSidebarPanelDefined] = useState<boolean>(false)
  const visibleSidebarPanel = useRef<SidebarPanelsType>(visiblePanel)

  /** Convert URL search params into a usable format for the Map filters */
  const formatFiltersParams = useCallback((params: URLSearchParams): Partial<MapFiltersType> => {
    const filters = {}

    // Electroverse Only Toggle
    if (params.has(ELECTROVERSE_COMPATIBLE)) {
      filters[MapFiltersEnum.ELECTROVERSE_COMPATIBLE] = JSON.parse(
        params.get(ELECTROVERSE_COMPATIBLE),
      )
    } else if (params.has(EJN_ONLY_FULL)) {
      filters[MapFiltersEnum.ELECTROVERSE_COMPATIBLE] = JSON.parse(params.get(EJN_ONLY_FULL))
    }

    // Operators List
    if (params.has(OPERATORS)) {
      const operatorsIDs = params.get(OPERATORS).split(',')
      filters[MapFiltersEnum.OPERATORS] = mapFiltersOptions.operators.filter(({ pk }) =>
        operatorsIDs.some((id: string) => parseInt(id, 10) === pk),
      )
    }

    // Socket Types List
    if (params.has(SOCKET_TYPES)) {
      filters[MapFiltersEnum.SOCKET_TYPES] = params
        .get(SOCKET_TYPES)
        .toUpperCase()
        .split(',')
        // TEMPORARY - Handles deprecated socket type format
        .map((socket) => (tempSocketMap[socket] ? tempSocketMap[socket] : socket))
        .filter((socket) => mapFiltersOptions.connectors.includes(socket))
    }

    // Charge Speed Range Slider
    if (params.has(CHARGE_POINT_SPEEDS)) {
      filters[MapFiltersEnum.CHARGE_POINT_SPEEDS] = params
        .get(CHARGE_POINT_SPEEDS)
        .split(',')
        .map((speed: string) => parseInt(speed, 10))
        .splice(0, 2)
    }

    // Minimum Charge Points Slider
    if (params.has(MIN_CHARGE_POINTS)) {
      filters[MapFiltersEnum.MIN_CHARGE_POINTS] = parseInt(params.get(MIN_CHARGE_POINTS), 10)
    }

    // Access List
    if (params.has(ACCESS)) {
      filters[MapFiltersEnum.ACCESS] = params
        .get(ACCESS)
        .toUpperCase()
        .split(',')
        .filter((accessType) => mapFiltersOptions.capabilities.includes(accessType))
    }

    return filters as Partial<MapFiltersType>
  }, [])

  /** Retrieve the URL search params and create an object to be consumed by initial functions across the map */
  const mapURLParams = useMemo(() => {
    if (!asPath) return {}
    const { pathname, search, hash } = new URL(asPath, 'https://electroverse.octopus.energy')
    const searchParams = new URLSearchParams(search)

    // Position param - split into lat, lng, zoom
    const pos = searchParams.get(POSITION)?.split(',').map(parseFloat)
    const position = {
      lat: pos?.[0] ?? parseFloat(searchParams.get(LAT)),
      lng: pos?.[1] ?? parseFloat(searchParams.get(LNG)),
      zoom: pos?.[2] ?? parseFloat(searchParams.get(ZOOM)),
    }

    // Charging Location ID
    const pathnameArray = pathname.split('/')
    const locationID = pathnameArray[pathnameArray.indexOf('location') + 1]

    // Sidebar Panel
    const maybeSidebarPanel = hash.slice(1) as SidebarPanels
    const isSidebarPanel = Object.values(SidebarPanels).includes(maybeSidebarPanel)
    const sidebarPanel = isSidebarPanel ? maybeSidebarPanel : undefined

    return {
      mapbox: {
        position,
        speed: parseFloat(searchParams.get(SPEED)),
        pitch: parseFloat(searchParams.get(PITCH) ?? searchParams.get(PITCH_FULL)),
        bearing: parseFloat(searchParams.get(BEARING) ?? searchParams.get(BEARING_FULL)),
      },
      filters: formatFiltersParams(searchParams),
      locationID,
      sidebarPanel,
    }
  }, [asPath, formatFiltersParams])

  /** On page load, the map will fly to an area determined by the URL params.
   * In order of priority: 1) lat/lng coordinates   2) charging location   3) country bbox. */
  const flyToMapAreaOnLoad = useCallback(async () => {
    const { mapbox, locationID, sidebarPanel } = mapURLParams
    const { position, speed, pitch, bearing } = mapbox
    const { lat, lng, zoom } = position

    const isSidebarOpen = Boolean(sidebarPanel)
    const isDrawerOpen = Boolean(locationID)

    // Creates an object for the camera options based on the URL params
    const getCameraOptions = ({ type, zoomLevel }: CameraAnimationOptionsProps) => ({
      ...getCameraAnimationOptions({ type, zoomLevel, isSidebarOpen, isDrawerOpen }),
      ...(bearing ? { bearing } : {}),
      ...(pitch ? { pitch } : {}),
      ...(speed ? { speed } : {}),
      ...(zoom ? { zoom } : {}),
    })

    const locationMetadata = { properties: { _id: locationID, is_ejn_location: true } }

    try {
      /**
       * NOTE: We're placing the map locationID behind a feature flag because we're experiencing higher
       * levels of web scraping on the map page. This should allow us to get a handle on the scale
       * of the problem over a controlled period.
       */
      if (lat && lng) {
        // Open the location, but do not fly to it
        if (locationID && featureFlagEnableMapLocationSharing) {
          handleSelectLocation(locationMetadata)
        } else if (!featureFlagEnableMapLocationSharing) {
          GTM.locationSharingBlocked(locationID)
        }

        // Fly to the specific coordinates defined in the URL
        baseMap.flyTo({
          ...getCameraOptions({ zoomLevel: 'country' }),
          ...(lat && lng ? { center: { lat, lng } } : {}),
        })
      } else if (locationID) {
        if (featureFlagEnableMapLocationSharing) {
          // Open the location and fly to it
          const coords = (await handleSelectLocation(locationMetadata))?.coordinates
          baseMap.flyTo({ ...getCameraOptions({}), center: [coords?.longitude, coords?.latitude] })
        } else {
          baseMap.fitBounds(bbox, getCameraOptions({ zoomLevel: 'country', type: 'fitBounds' }))
          GTM.locationSharingBlocked(locationID)
          push('/map')
        }
      } else {
        // Fly to the country defined by the locale
        baseMap.fitBounds(bbox, getCameraOptions({ zoomLevel: 'country', type: 'fitBounds' }))
      }
    } catch (e) {
      baseMap.fitBounds(bbox, getCameraOptions({ zoomLevel: 'country', type: 'fitBounds' }))
      Sentry.captureException(e, (scope) => scope.setExtras({ nextJsRoute: asPath }))
    }
  }, [mapURLParams, baseMap, handleSelectLocation, push, bbox, asPath])

  /** Ensures the URL search params are interpreted on map load only once */
  useUpdateEffect(() => {
    if (baseMap && mapURLParams && !mapBoundsDefined) {
      setMapBoundsDefined(true)
      applyInitialFilters(mapURLParams.filters)
      flyToMapAreaOnLoad()
    }
  }, [baseMap, mapBoundsDefined, applyInitialFilters, mapURLParams, flyToMapAreaOnLoad])

  /** Store visiblePanel in a ref to be accessed in the timeout below */
  useUpdateEffect(() => {
    if (!sidebarPanelDefined) visibleSidebarPanel.current = visiblePanel
  }, [sidebarPanelDefined, visiblePanel])

  /** Open the panel defined in the URL after a short delay.
   * Provided the user has not opened any panels during this delay. */
  useUpdateEffect(() => {
    if (!sessionLoading && !sidebarPanelDefined && mapURLParams.sidebarPanel) {
      setSidebarPanelDefined(true)
      // prettier-ignore
      setTimeout(() => {
          const noPanelsOpened = visibleSidebarPanel.current === SidebarPanels.MAP
          if (noPanelsOpened) showPanelExclusive(mapURLParams.sidebarPanel)
        }, testEnv ? 0 : 1000)
    }
  }, [sessionLoading, sidebarPanelDefined, mapURLParams.sidebarPanel, showPanelExclusive])

  const state = useMemo(() => ({}), [])

  const handlers = useMemo(() => ({}), [])

  return [state, handlers]
}

export const UseMapParamsProvider = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const ctx = useMapParamsProvider()
  return <UseMapParamsContext.Provider value={ctx}>{children}</UseMapParamsContext.Provider>
}

export const useMapParams = (): UseMapParams => {
  const context = useContext(UseMapParamsContext)
  if (!context) throw new Error('UseMapParams() cannot be used outside of <UseMapParamsProvider/>')
  return context
}
