import {
  ConnectionFee,
  ConsumptionRate,
  ParkingTimeRate,
  TimeRate,
} from '@electro/consumersite/generated/graphql'
import { Locales, LOCALES_USING_MILES_UNIT } from '@electro/types/locales'
import getMilesFromMeters from '@electro/shared/utils/getMilesFromMeters'

export const formatPostcode = (postcode: string): string => {
  const lastMatch = '[0-9][a-zA-Z][a-zA-Z]'
  const match = postcode.match(lastMatch)
  const hasSpace = postcode.indexOf(' ') >= 2

  if (match !== null && !hasSpace) {
    return postcode.replace(/^(.*)(.{3})$/, '$1 $2')
  }

  return postcode
}

export const formatMobileNumber = (mobileNumber: string): string =>
  mobileNumber.replace(/[^0-9]+/g, '')

export const formatAlphaNumeric = (name: string): string => name.replace(/[^a-zA-Z\d\s]/g, '')

export const formatAlphaNumericAllCaps = (name: string): string =>
  formatAlphaNumeric(name).toUpperCase()

/**
 * @param {string, currency } price 4 digit value of price in pence
 * @returns {Record<'pound' | 'decimal' | 'pence', string>}
 */
interface FormatPriceToLocalisedPartsArgs {
  price: number
  currency?: string
  locale?: string
  isFloat?: boolean
}
export const formatPriceToLocalisedValue = ({
  price,
  currency = 'GBP',
  locale = Locales.EN,
  isFloat = false,
}: FormatPriceToLocalisedPartsArgs) => {
  const GBPCurrencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    currencyDisplay: 'narrowSymbol',
  })
  const priceFloat = isFloat ? price : price / 100

  return GBPCurrencyFormatter.format(priceFloat)
}

export const formatNoSpaceChars = (input: string): string => input.replace(/\s/g, '')

export const formatPriceComponentToLocalisedValue = (
  priceComponent: ConsumptionRate | TimeRate | ParkingTimeRate | ConnectionFee,
) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!priceComponent.__typename) return null
  const { unitAmount, currencyDetails } = priceComponent

  if (unitAmount === undefined || unitAmount === null || !currencyDetails) return null
  const { symbol, minorUnitConversion, decimalDigits } = currencyDetails

  const pricePerUnit = (unitAmount / minorUnitConversion).toFixed(decimalDigits ?? 0)
  const perUnitText =
    'perUnit' in priceComponent ? `/${priceComponent?.perUnit?.replace('/', '')}` : ''

  return `${symbol}${pricePerUnit}${perUnitText}`
}

interface FormatMetresToLocalisedDistanceOptions {
  locale?: string
  shortUnit?: boolean
}

/** Converts distances to relevant format with localised unit text */
export const formatKilometresToLocalisedDistance = (
  kilometres: number,
  options: FormatMetresToLocalisedDistanceOptions,
) => {
  const defaultOptions = { locale: Locales.EN, shortUnit: true }
  const { locale, shortUnit } = { ...defaultOptions, ...options }

  const unit = LOCALES_USING_MILES_UNIT.includes(locale as Locales) ? 'mile' : 'kilometer'
  const distance = unit === 'mile' ? getMilesFromMeters(kilometres * 1000) : kilometres

  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    unitDisplay: shortUnit ? 'short' : 'long',
    maximumFractionDigits: 0,
  })
    .format(distance)
    .replace(/\u202f|\xa0/, ' ')
}

interface FormatSecondsToLocalisedDurationOptions {
  locale?: string
}

/** Converts seconds to duration format */
export const formatSecondsToLocalisedDuration = (
  seconds: number,
  options: FormatSecondsToLocalisedDurationOptions,
) => {
  const defaultOptions = { locale: Locales.EN }
  const { locale } = { ...defaultOptions, ...options }

  const hours = Math.floor(seconds / 3600)
  const localisedHours = new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'hour',
    unitDisplay: 'short',
  }).format(hours)

  const minutes = Math.floor((seconds % 3600) / 60)
  const localisedMinutes = new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: 'minute',
    unitDisplay: 'short',
  }).format(minutes)

  const hideZeroUnits = [localisedHours, localisedMinutes].filter((unit) => !unit.includes('0'))

  return hideZeroUnits.join(' ').replace(/\u202f|\xa0/g, ' ')
}

export const formatDistanceToLocalisedValue = ({
  distance,
  locale = Locales.EN,
  unit = 'mile',
  shortUnit = false,
}: {
  distance: number
  locale?: string
  unit: 'mile' | 'kilometer'
  shortUnit?: boolean
}) =>
  new Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    unitDisplay: shortUnit ? 'short' : 'long',
  }).format(distance)

export const formatDurationToLocalisedValue = ({
  duration,
  locale = Locales.EN,
  unit = 'minute',
}: {
  duration: number
  locale?: string
  unit: 'minute' | 'hour' | 'second' | 'day'
}) => {
  if (!duration) return null
  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit,
    unitDisplay: 'short',
  }).format(duration)
}

export const formatCompaniesHouseNumber = (houseNumber: string): string =>
  houseNumber.toUpperCase().replace(/[^A-Za-z0-9]/g, '')

export const formatNumbersOnly = (input: string): string => input.replace(/[^0-9]/g, '')
