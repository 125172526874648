import { useEffect, FC, MouseEvent } from 'react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Transition } from '@headlessui/react'
import { PrimaryNavItem } from '@electro/consumersite/src/components/TopNavigation/components'
import {
  SecondaryNavContainer,
  SecondaryNav,
} from '@electro/consumersite/src/components/TopNavigation/components/DesktopNavigation/components/'
import { DesktopPrimaryNavItemProps } from '@electro/consumersite/src/components/TopNavigation/types'
import { PRIMARY_NAV } from '@electro/consumersite/src/components/TopNavigation/constants'
import { useDesktopNavigation } from '@electro/consumersite/src/components/TopNavigation/components/DesktopNavigation/hooks'

const styles = {
  root: 'self-center',
  list: 'items-stretch flex w-full max-w-full justify-between lg:gap-8 xl:gap-16 2xl:gap-24 my-auto',
  secondaryNav:
    'absolute top-12 z-40 transition-all shadow bg-base-dark border-2 border-solid border-secondary rounded-xl w-[350px]',
}

export const PrimaryNav: FC = () => {
  const { locale } = useRouter()
  const {
    subNavIndex,
    setSubNavIndex,
    hideSecondaryNav,
    hasSecondaryNav,
    subNavLeft,
    setSubNavLeft,
    subNavHeight,
    setSubNavHeight,
    secondaryNavRefs,
  } = useDesktopNavigation()
  const { t } = useTranslation('common')

  const secondaryNavigation = PRIMARY_NAV.filter((item) => item.secondaryNav !== undefined).map(
    (item, index) => ({
      secondaryNav: item.secondaryNav,
      index,
      ref: secondaryNavRefs[subNavIndex],
      key: item.label,
    }),
  )

  useEffect(() => {
    setSubNavHeight(secondaryNavRefs[subNavIndex]?.current?.getBoundingClientRect().height)
  }, [secondaryNavRefs, setSubNavHeight, subNavIndex])

  const handleMouseEnter = (event: MouseEvent, item: DesktopPrimaryNavItemProps, index: number) => {
    const { secondaryNav } = item

    if (!secondaryNav) {
      hideSecondaryNav()
      return
    }

    // the value passed here should always match the margin left passed in the DesktopNavigation
    const leftPosition =
      event.currentTarget.getBoundingClientRect().left -
      document.getElementsByTagName('nav')[0].offsetLeft

    if (secondaryNavigation.length === 1) {
      setSubNavIndex(0)
    } else {
      setSubNavIndex(index - 1)
    }

    setSubNavLeft(leftPosition)
  }

  return (
    <nav data-testid="desktop-navigation" className={styles.root} onMouseLeave={hideSecondaryNav}>
      <ul className={styles.list}>
        {PRIMARY_NAV.map((item: DesktopPrimaryNavItemProps, index: number) =>
          item.locales?.includes(locale) ? (
            <PrimaryNavItem.Desktop
              onMouseEnter={(event) => handleMouseEnter(event, item, index)}
              key={t(item.label)}
              label={t(item.label)}
              href={item.href}
              secondaryNav={item.secondaryNav}
            />
          ) : null,
        )}
      </ul>

      {hasSecondaryNav ? (
        <Transition
          data-testid="secondary-navigation"
          show={hasSecondaryNav}
          appear
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          style={{ left: subNavLeft, height: subNavHeight }}
          className={styles.secondaryNav}
        >
          {secondaryNavigation.map(({ index, secondaryNav, key }) => (
            <SecondaryNavContainer key={key} index={index}>
              <SecondaryNav items={secondaryNav} ref={secondaryNavRefs[index]} />
            </SecondaryNavContainer>
          ))}
        </Transition>
      ) : null}
    </nav>
  )
}
