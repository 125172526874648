import { useStripe, useElements, PaymentElement, AddressElement } from '@stripe/react-stripe-js'

import { Button, Skeleton, Typography, useToastNotification } from '@electro/shared-ui-components'
import {
  useState,
  SyntheticEvent,
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'
import { Stripe, StripeElements } from '@stripe/stripe-js'
import { StripeProvider } from '@electro/consumersite/src/hooks'
import useTranslation from 'next-translate/useTranslation'

export interface PaymentMethodFormProps {
  onPaymentMethodConfirmed?: (paymentMethod: { success: boolean; setupIntentId: string }) => void
  children: ReactNode | ReactNode[]
}

const PaymentMethodFormContext = createContext(null)

const FormLoadingSkeleton = () => (
  <div className="flex flex-col gap-4" data-testid="stripe-payment-element-skeleton">
    <Skeleton variant="rounded" className="h-18 w-full" />
    <Skeleton variant="text" className="w-1/2" />
    <Skeleton variant="text" className="h-10 w-full" />
    <Skeleton variant="text" className="w-1/2" />
    <Skeleton variant="text" className="h-10 w-full" />
    <Skeleton variant="text" className="w-1/2" />
    <Skeleton variant="text" className="h-10 w-full" />
    <Skeleton variant="text" className="w-1/2" />
    <Skeleton variant="text" className="w-1/4" />
  </div>
)
const PaymentMethodFormBase = ({ onPaymentMethodConfirmed, children }: PaymentMethodFormProps) => {
  const stripe: Stripe = useStripe()
  const elements: StripeElements = useElements()
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const { showToastNotification } = useToastNotification()
  const handleSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault()
      setLoading(true)

      if (!stripe || !elements) {
        return
      }

      try {
        const payload = await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
          confirmParams: {
            // this is where the user will return to if they are redirected to a third party e.g. Paypal
            return_url: `${window.location.origin}/user/account/payment-methods/add-payment/external-return`,
          },
        })
        if (!payload.error) {
          await onPaymentMethodConfirmed({
            success: true,
            setupIntentId: payload.setupIntent.id,
          })
        } else {
          await onPaymentMethodConfirmed({ success: false, setupIntentId: null })
        }
      } catch (err) {
        showToastNotification({
          heading: 'Nope',
          variant: 'error',
          body: err.message,
        })
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [elements, onPaymentMethodConfirmed, showToastNotification, stripe],
  )

  const context = useMemo(
    () => ({
      ready,
      setReady,
      stripe,
      loading,
      handleSubmit,
    }),
    [ready, stripe, loading, handleSubmit],
  )

  return (
    <PaymentMethodFormContext.Provider value={context}>
      {children}
    </PaymentMethodFormContext.Provider>
  )
}

const Fields = () => {
  const { handleSubmit, setReady, ready } = useContext(PaymentMethodFormContext)
  const [cardPayment, setCardPayment] = useState(true)
  const { t } = useTranslation('common')

  const handlePaymentElementChange = (e) => {
    if (e.value.type === 'card') {
      setCardPayment(true)
    } else {
      setCardPayment(false)
    }
  }

  return (
    <form
      aria-label="stripe-payment-method-form"
      id="stripe-payment-method-form"
      onSubmit={handleSubmit}
    >
      <div>
        {!ready ? <FormLoadingSkeleton /> : null}

        <PaymentElement
          onChange={handlePaymentElementChange}
          options={{
            layout: 'tabs',
          }}
          onLoaderStart={() => setReady(true)}
          data-testid="stripeCardElement"
        />

        {cardPayment && ready ? (
          <Typography as="h2" variant="h2" className="mt-6">
            {t('profile.payment_methods.add_payment_form.billing_address')}
          </Typography>
        ) : null}
        {cardPayment ? (
          <AddressElement
            className="mt-2"
            options={{
              mode: 'billing',
            }}
          />
        ) : null}
      </div>
    </form>
  )
}

const SubmitButton = () => {
  const { loading } = useContext(PaymentMethodFormContext)
  const { t } = useTranslation('common')
  return (
    <Button
      form="stripe-payment-method-form"
      data-testid="submit-payment-method"
      loading={loading}
      type="submit"
      fullWidth
    >
      {t('profile.payment_methods.payment_method_actions.add_payment_method')}
    </Button>
  )
}

/**
 * The paymentMethodForm component handles the Stripe iframe and returns a callback once the form has
 * been successfully submitted, you will still need to send the returned setup intent id to the BE in
 * order to complete a payment setup. In the case of a Payment card the id is returned here.
 * In the case of external providers the setup id comes as a param in the the return url.
 * For us this is pulled from the url param on the route:
 * /user/account/payment-methods/add-payment/external-return
 */
const PaymentMethodForm = ({ children, onPaymentMethodConfirmed }: PaymentMethodFormProps) => (
  <StripeProvider>
    <PaymentMethodFormBase onPaymentMethodConfirmed={onPaymentMethodConfirmed}>
      {children}
    </PaymentMethodFormBase>
  </StripeProvider>
)

PaymentMethodForm.Fields = Fields
PaymentMethodForm.SubmitButton = SubmitButton

export { PaymentMethodForm }
