import { tw } from '@electro/shared/utils/tailwind-merge'

interface Props {
  className?: string
}

export const RadioCheckedIcon = ({ className = '' }: Props) => (
  <svg
    className={tw('fill-current absolute top-0 right-0', className)}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="radio-checked-icon"
  >
    <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z" />
  </svg>
)

export default RadioCheckedIcon
