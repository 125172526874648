import { ChangeEvent, ReactNode } from 'react'
import { tw } from '@electro/shared/utils/tailwind-merge'
import { RadioGroupProvider } from './RadioGroupContext'

type Direction = 'row' | 'column'

interface Props {
  direction?: Direction
  label?: string
  value: string
  className?: string
  errorMessage?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  children: ReactNode
}

export const radioGroupStyles = {
  root: 'flex flex-col',
  content: {
    column: 'flex flex-col space-y-2',
    row: 'flex flex-row space-x-2',
  },
  label: {
    default: 'mb-2 inline-block text-white text-sm',
    error: 'mb-2 inline-block text-action-danger text-sm',
  },
  errorMessage: 'text-sm text-action-danger border-action-danger mt-1',
}

export const RadioGroup = ({
  direction = 'column',
  label,
  onChange,
  value,
  errorMessage,
  className,
  children,
}: Props) => {
  const directionColumn = direction === 'column'
  const directionRow = direction === 'row'

  return (
    <RadioGroupProvider onChange={onChange} value={value}>
      <div
        className={tw({
          [radioGroupStyles.root]: true,
          [className]: !!className,
        })}
      >
        {!!label && (
          <div
            className={tw({
              [radioGroupStyles.label.default]: !errorMessage,
              [radioGroupStyles.label.error]: errorMessage,
            })}
          >
            {label}
          </div>
        )}

        <div
          data-testid="check-group-content"
          className={tw({
            [radioGroupStyles.content.column]: directionColumn,
            [radioGroupStyles.content.row]: directionRow,
          })}
        >
          {children}
        </div>

        {!!errorMessage && <div className={radioGroupStyles.errorMessage}>{errorMessage}</div>}
      </div>
    </RadioGroupProvider>
  )
}
