import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useState,
  useContext,
  ReactNode,
  useMemo,
  createRef,
  RefObject,
} from 'react'
import { PRIMARY_NAV } from '@electro/consumersite/src/components/TopNavigation/constants'

interface DesktopNavigationProviderProps {
  children: ReactNode
}

interface UseDesktopNavigationContextReturnType {
  subNavIndex: number | null
  setSubNavIndex: Dispatch<SetStateAction<number | null>>
  hideSecondaryNav: () => void
  hasSecondaryNav: boolean
  subNavLeft: number | null
  setSubNavLeft: Dispatch<SetStateAction<number | null>>
  subNavHeight: number | null
  setSubNavHeight: Dispatch<SetStateAction<number | null>>
  secondaryNavRefs: RefObject<HTMLUListElement>[]
}

const useDesktopNavigationProvider = (): UseDesktopNavigationContextReturnType => {
  const [subNavIndex, setSubNavIndex] = useState<number | null>(null)
  const [subNavLeft, setSubNavLeft] = useState<number | null>(null)
  const [subNavHeight, setSubNavHeight] = useState<number | null>(null)
  const secondaryNavRefs = useMemo(
    () =>
      PRIMARY_NAV.filter((item) => item.secondaryNav !== undefined).map(() =>
        createRef<HTMLUListElement | null>(),
      ),
    [],
  )

  const hasSecondaryNav = useMemo(() => subNavIndex !== null, [subNavIndex])

  const hideSecondaryNav = useCallback(() => {
    setSubNavIndex(null)
    setSubNavLeft(null)
    setSubNavHeight(null)
  }, [setSubNavIndex])

  return {
    subNavIndex,
    setSubNavIndex,
    hideSecondaryNav,
    hasSecondaryNav,
    subNavLeft,
    setSubNavLeft,
    subNavHeight,
    setSubNavHeight,
    secondaryNavRefs,
  }
}

const DesktopNavigationContext = createContext<UseDesktopNavigationContextReturnType>(null)

const useDesktopNavigation = () => {
  const context = useContext(DesktopNavigationContext)
  if (!context) {
    throw new Error(
      `useDesktopNavigation() cannot be used outside the context of <DesktopNavigationProvider/> `,
    )
  }
  return context
}

const DesktopNavigationProvider = ({ children }: DesktopNavigationProviderProps) => {
  const context = useDesktopNavigationProvider()
  return (
    <DesktopNavigationContext.Provider value={context}>
      {children}
    </DesktopNavigationContext.Provider>
  )
}

export { DesktopNavigationProvider, useDesktopNavigation }
