import { ReactNode } from 'react'
import {
  ShelterIcon,
  EntertainmentIcon,
  FoodDrinkIcon,
  LightingIcon,
  LodgingIcon,
  NaturalAreaIcon,
  FreeParkingIcon,
  PhoneSignalIcon,
  RetailIcon,
  ToiletIcon,
  TransportIcon,
} from '@electro/consumersite/src/icons'

import { Facility } from '@electro/consumersite/generated/graphql'
import useTranslation from 'next-translate/useTranslation'
import { Typography } from '@electro/shared-ui-components'

type FacilityNames = `${Facility}`

interface FacilitiesProps {
  facilities: FacilityNames[]
}

interface FacilityItem {
  name: string
  Icon: ReactNode
}

const {
  Covered,
  Entertainment,
  FoodDrink,
  Lighting,
  Lodging,
  Nature,
  Parking,
  PhoneSignal,
  Retail,
  Toilets,
  Transport,
} = Facility

type facilityMapDict = { [key in FacilityNames]: FacilityItem }

const mapFacilityToInfo = (facility: FacilityNames): FacilityItem => {
  const iconStyle = 'w-5 h-5 mr-2'
  const facilityMap: facilityMapDict = {
    [Covered]: {
      name: 'map.location.info.facility.sheltered',
      Icon: <ShelterIcon className={iconStyle} />,
    },
    [Entertainment]: {
      name: 'map.location.info.facility.entertainment',
      Icon: <EntertainmentIcon className={iconStyle} />,
    },
    [FoodDrink]: {
      name: 'map.location.info.facility.food',
      Icon: <FoodDrinkIcon className={iconStyle} />,
    },
    [Lighting]: {
      name: 'map.location.info.facility.lighting',
      Icon: <LightingIcon className={iconStyle} />,
    },
    [Lodging]: {
      name: 'map.location.info.facility.lodging',
      Icon: <LodgingIcon className={iconStyle} />,
    },
    [Nature]: {
      name: 'map.location.info.facility.natural_area',
      Icon: <NaturalAreaIcon className={iconStyle} />,
    },
    [Parking]: {
      name: 'map.location.info.facility.free_parking',
      Icon: <FreeParkingIcon className={iconStyle} />,
    },
    [PhoneSignal]: {
      name: 'map.location.info.facility.phone_signal',
      Icon: <PhoneSignalIcon className={iconStyle} />,
    },
    [Retail]: {
      name: 'map.location.info.facility.shops',
      Icon: <RetailIcon className={iconStyle} />,
    },
    [Toilets]: {
      name: 'map.location.info.facility.toilets',
      Icon: <ToiletIcon className={iconStyle} />,
    },
    [Transport]: {
      name: 'map.location.info.facility.transport',
      Icon: <TransportIcon className={iconStyle} />,
    },
  }

  return facilityMap[facility] || { name: '', Icon: null }
}

export const Facilities = ({ facilities }: FacilitiesProps) => {
  const { t } = useTranslation('common')
  return facilities.length > 0 ? (
    <section>
      <h3 className="mt-4 mb-2">{t('map.location.facilities_title')}</h3>
      <ul className="grid grid-cols-2 gap-2 text-white">
        {facilities.map((facility) => {
          const { name, Icon } = mapFacilityToInfo(facility)
          return name ? (
            <li key={name} data-testid="availability-item" className="flex">
              {Icon} <Typography as="span">{t(name)}</Typography>
            </li>
          ) : null
        })}
      </ul>
    </section>
  ) : null
}
