import { OperationVariables, QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client'
import LOCATION_PHOTOS_QUERY from '@electro/consumersite/src/graphql/locationPhotosQuery.graphql'

import { Query, QueryLocationPhotosArgs } from '@electro/consumersite/generated/graphql'
import { ApolloLazyQueryFunction } from '@electro/shared/types/queriesMutations'

export type FetchLocationPhotos = Pick<Query, 'locationPhotos'>

type UseFetchLocationPhotos = [
  ApolloLazyQueryFunction<FetchLocationPhotos>,
  QueryResult<FetchLocationPhotos, OperationVariables>,
]

type FetchLocationPhotosArgs = QueryHookOptions<FetchLocationPhotos, QueryLocationPhotosArgs>

export function useFetchLocationPhotos(options?: FetchLocationPhotosArgs): UseFetchLocationPhotos {
  const [lazyFetchLocationPhotos, LocationPhotosResult] = useLazyQuery<FetchLocationPhotos>(
    LOCATION_PHOTOS_QUERY,
    options,
  )

  return [lazyFetchLocationPhotos, LocationPhotosResult]
}
