import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { mapLocaleToCountryDetails } from '@electro/shared/utils/mapLocaleToCountryDetails'

const styles = {
  root: 'text-sm text-center px-4',
  text: 'mb-0',
  email: 'font-semibold text-primary',
}

/** Displays a localised support email for users who require a response */
export const FeedbackSupportEmail = () => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')

  const email = mapLocaleToCountryDetails(locale).supportEmail

  return (
    <div className={styles.root}>
      <p className={styles.text}>{t('map.feedback.support_email')}</p>
      <a href={`mailto:${email}`} className={styles.email} target="_blank" rel="noreferrer">
        {email}
      </a>
    </div>
  )
}
