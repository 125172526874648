import useTranslation from 'next-translate/useTranslation'
import { Modal, Typography, LoadingSpinner } from '@electro/shared-ui-components'

export const PaymentProcessingModalScreen = () => {
  const { t } = useTranslation('common')

  return (
    <Modal.Body>
      <div className="flex justify-center mt-8 mb-10">
        <LoadingSpinner className="w-[39%]" />
      </div>
      <Typography variant="h3" as="h1" className="text-center">
        {t('profile.outstanding_payments.resolve_payment_modal.processing_payment_screen.title')}
      </Typography>
      <Typography className="text-center mb-4">
        {t(
          'profile.outstanding_payments.resolve_payment_modal.processing_payment_screen.description',
        )}
      </Typography>
    </Modal.Body>
  )
}
