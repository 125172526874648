/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactNode } from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid'
import { tw } from '@electro/shared/utils/tailwind-merge'

import { Alert } from '@electro/shared-ui-components'
import { DiscountIcon } from '@electro/consumersite/src/icons'
import {
  LocationAlertType,
  LocationsLocationAlertTypeChoices,
} from '@electro/consumersite/generated/graphql'

interface LocationAlertsProps {
  alerts?: LocationAlertType[]
}

interface AlertComponentProps {
  alertTypeChoice: `${LocationsLocationAlertTypeChoices}`
  children: ReactNode | ReactNode[]
}

const styles = {
  root: 'mt-2 mb-2 py-1 items-center px-4 rounded-4xl flex text-xs bg-primary-dark border border-primary',
  alert: {
    root: 'text-primary',
    icon: 'w-6 h-6 mr-2 flex-shrink-0 text-primary',
  },
  discount: {
    root: 'text-primary',
    icon: 'w-6 h-6 mr-2 flex-shrink-0 text-primary',
  },
}

const LocationAlertComponentByType = ({ alertTypeChoice, children }: AlertComponentProps) => {
  switch (alertTypeChoice) {
    case LocationsLocationAlertTypeChoices.Alert:
      return (
        <div className={tw(styles.root, styles.alert.root)}>
          <ExclamationTriangleIcon data-testid="info-icon" className={styles.alert.icon} />
          {children}
        </div>
      )
    case LocationsLocationAlertTypeChoices.Discount:
      return (
        <div className={tw(styles.root, styles.discount.root)}>
          <DiscountIcon data-testid="info-icon" className={styles.discount.icon} />
          {children}
        </div>
      )
    default:
      return (
        <Alert variant="info" className="mt-2 mb-2 py-2">
          {children}
        </Alert>
      )
  }
}

export const LocationAlerts = ({ alerts }: LocationAlertsProps) =>
  alerts?.length > 0 ? (
    <>
      {alerts?.map((alert: LocationAlertType) => (
        <LocationAlertComponentByType key={alert?.content} alertTypeChoice={alert.type}>
          {alert?.content}
        </LocationAlertComponentByType>
      ))}
    </>
  ) : null
